import styled from "styled-components";
import Dialog from "../../../../Monolith-UI/Dialog/Dialog";
import { Button } from "@monolith-forensics/monolith-ui";
import { useState } from "react";
import Loader from "../../../../components/Loader.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useQuery } from "@tanstack/react-query";
import DocumentTemplatesAPI from "../../../../api/DocumentTemplates/DocumentTemplates.js";
import { monolithMoment } from "../../../../utils/date-format.js";

const Menu = styled.div`
  display: flex;
  flex-direction: row;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 90vh;
  max-width: 90vw;
  width: 600px;
  height: 600px;
  padding: 5px;

  // Allow height to increase with content
  height: fit-content;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const TemplateList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 40px;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 15px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`;

const TemplateListItem = styled.div`
  cursor: pointer;
  user-select: none;

  padding: 10px;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const TemplateListItemLabel = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;

  &[data-selected="true"] {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &[data-selected="true"]:after {
    content: "✓";
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 12px;
    margin-left: 10px;
  }
`;

const TemplateListItemDetail = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SelectReportTemplateModal = ({
  open,
  onClose,
  onSubmit,
  defaultInfo,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultInfo);
  const { currentUser } = usePermissions();

  const { data } = useQuery({
    queryKey: [
      "document-templates",
      "list",
      { created_by_id: currentUser.user_id, include_shared: true },
    ],
    queryFn: () =>
      DocumentTemplatesAPI.get({
        created_by_id: currentUser.user_id,
        include_shared: true,
      }),
  });

  const handleClose = () => {
    onClose?.();
  };

  const handleSubmit = async () => {
    onSubmit?.(selectedItem);
    handleClose();
  };

  const handleSelectItem = (template) => {
    setSelectedItem(template);
  };

  const templates = data?.data || [];

  return (
    <Dialog open={open} onClose={handleClose} showCloseButton={false}>
      <DialogContent>
        <h2>Select Report Template</h2>
        {submitting && <Loader />}
        {!submitting && (
          <div>
            {templates.length > 0 && (
              <TemplateList>
                {templates.map((template) => (
                  <TemplateListItem
                    key={template.uuid}
                    onClick={() => handleSelectItem(template)}
                    data-selected={selectedItem?.uuid === template.uuid}
                    data-uuid={template.uuid}
                  >
                    <TemplateListItemLabel
                      data-selected={selectedItem?.uuid === template.uuid}
                    >
                      {template.name}
                    </TemplateListItemLabel>
                    <TemplateListItemDetail title={template.description}>
                      {template.description}
                    </TemplateListItemDetail>
                    <TemplateListItemDetail>
                      Created by: {template.created_by.full_name}
                    </TemplateListItemDetail>
                    <TemplateListItemDetail>
                      Uploaded:{" "}
                      {monolithMoment({ timestamp: template.created_on })}
                    </TemplateListItemDetail>
                  </TemplateListItem>
                ))}
              </TemplateList>
            )}
            {templates.length === 0 && <div>No Templates Available</div>}
            <div style={{ marginTop: "auto" }}>
              <Menu topBorder style={{ justifyContent: "flex-end", gap: 10 }}>
                <Button
                  variant="subtle"
                  size="xs"
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="xs"
                  onClick={() => handleSubmit()}
                >
                  Select Template
                </Button>
              </Menu>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SelectReportTemplateModal;
