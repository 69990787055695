import { nanoid } from "nanoid";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useOverlayScrollbars } from "overlayscrollbars-react";

import styled, { useTheme } from "styled-components";
import {
  cloneElement,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CheckBox from "../CheckBox.js";
import { Maximize2Icon } from "lucide-react";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { getDateFormat } from "../../utils/date-format.js";
import moment from "moment";

const MIN_COLUMN_WIDTH = 125;

const StyledTable = styled.table`
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  border-top: none;
  border-radius: 4px;
`;

const StyledThead = styled.thead`
  position: relative;
`;

const StyledTbody = styled.tbody`
  & [data-even="true"].tr td {
    background-color: ${({ stripes, theme }) =>
      stripes ? theme.palette.background.secondary : "transparent"};
  }
  & .tr:hover td {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const StyledTr = styled.tr`
  position: relative;
  height: ${({ compact, rowHeight }) =>
    rowHeight ? rowHeight : compact ? 28 : 38}px;
  width: 100%;
  display: flex;

  & .action-button {
    opacity: 0;
    border-radius: 4px;
  }
  * .custom-action-button {
    opacity: 0;
  }

  &:hover .action-button,
  &:hover .custom-action-button {
    opacity: 1;
  }

  &.focused .td {
    border-top: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
    transition: border 0.3s ease;
  }
  &.focused .td:first-child {
    border-left: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
  &.focused .td:last-child {
    border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  // last item in row
  & .td:last-child {
    width: auto !important;
    max-width: none !important;
  }
`;

const StyledHeaderRow = styled(StyledTr)`
  height: ${({ compact, headerHeight }) =>
    headerHeight ? headerHeight : compact ? 28 : 38}px;

  display: table-row;

  // last item in row
  & .th:last-child {
    width: auto !important;
    max-width: none !important;
  }
`;

const StyledTd = styled.td`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  padding: ${({ compact }) => (compact ? "3px" : "8px")};
  font-size: ${({ compact }) => (compact ? "10px" : "12px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  background-color: ${({ theme }) => theme.palette.background.default};

  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
  }

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

const StyledTh = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  flex: 1;
  vertical-align: middle;
  user-select: none;
  padding: ${({ compact }) => (compact ? "3px" : "8px")};
  font-size: ${({ compact }) => (compact ? "10px" : "12px")};
  text-align: left;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  background-color: ${({ theme }) => theme.palette.background.alt};

  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
    border-top-right-radius: 4px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.name === "DARK" ? "#2e2e2e" : "#f5f5f5"};
    cursor: pointer;
  }
  &.dragging:active {
    cursor: grabbing;
  }
  &.dragover .resizer {
    opacity: 1;
    background: ${(props) => props.theme.palette.primary.main};
    cursor: grabbing;
  }
`;

const enableResizeClass = (dataField) => {
  document
    .querySelectorAll(`.resizer.column-${dataField}`)
    .forEach((resizer) => {
      resizer.classList.add("isResizing");
    });
};

const disableResizeClass = (dataField) => {
  document
    .querySelectorAll(`.resizer.column-${dataField}`)
    .forEach((resizer) => {
      resizer.classList.remove("isResizing");
    });
};

const resizeHandler = ({
  event,
  columnId,
  columnProps,
  onResize,
  onResizeFinished,
}) => {
  let col = event.target.parentElement;
  let currentTableWidth =
    col.parentElement.parentElement.parentElement.offsetWidth;
  let newColumns = [];
  let x = 0;
  let w = 0;

  const mouseMoveHandler = function (e) {
    const table = col.parentElement.parentElement.parentElement;
    const selectedColumn = document.querySelectorAll(`.col-${columnId}`);
    const columnHeaders = document.querySelectorAll(`.th.col-${columnId}`);

    const dx = e.clientX - x;
    let newWidth = w + dx;
    if (newWidth < MIN_COLUMN_WIDTH) {
      newWidth = MIN_COLUMN_WIDTH;
    }

    selectedColumn.forEach((col) => {
      col.style.width = `${newWidth}px`;
      col.style.maxWidth = `${newWidth}px`;
      col.style.minWidth = `${newWidth}px`;
      newColumns.push({
        dataField: col.dataset.field,
        width: `${newWidth}px`,
      });
    });

    newColumns = Array.from(columnHeaders).map((col, index) => {
      return {
        dataField: col.dataset.field,
        width: `${newWidth}px`,
      };
    });

    onResize({
      columns: newColumns,
    });
  };

  const mouseUpHandler = function () {
    disableResizeClass(columnId);
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
    onResizeFinished?.({
      column: columnProps,
      columnId: columnId,
      columns: newColumns,
      targetColumn: document.querySelector(`.col-${columnId}`),
      newWidth: document.querySelector(`.col-${columnId}`).style.width,
    });
  };

  x = event.clientX;

  const styles = window.getComputedStyle(col);
  w = parseInt(styles.width, 10);

  document.addEventListener("mousemove", mouseMoveHandler);
  document.addEventListener("mouseup", mouseUpHandler);

  enableResizeClass(columnId);
};

const Resizer = styled(
  ({
    className,
    resizeHandler,
    onResize = () => {},
    onResizeFinished,
    ...others
  }) => {
    return (
      <div
        className={`${className} resizer column-${others.column.props.columnId}`}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          e.preventDefault();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          e.preventDefault();
          if (resizeHandler)
            resizeHandler({
              event: e,
              columnId: others.column.props.columnId,
              columnProps: others.column.props,
              onResize: onResize,
              onResizeFinished: onResizeFinished,
            });
        }}
      ></div>
    );
  }
)`
  &.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: ${(props) => props.theme.palette.divider};
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    opacity: 0;
    &.isResizing {
      opacity: 1;
      background: ${(props) => props.theme.palette.primary.main};
    }
    // dragover css
    &.dragover {
      opacity: 1;
      background: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const HeaderRowComponent = ({
  columns,
  columnProps,
  _compact,
  resizeState,
  headerHeight,
  onHeaderClick,
  onColumnResize,
  dropColumn,
  dragColumn,
  showSelection,
  selectAll,
  selectionState,
  handleSelectAll,
  handleColumnReorder,
}) => {
  const theme = useTheme();

  return (
    <StyledThead>
      <StyledHeaderRow compact={_compact} headerHeight={headerHeight}>
        {columns.map((column) => {
          let savedColSize = resizeState?.current?.columns?.find(
            (col) => col.dataField === column.props.dataField
          );

          if (savedColSize < MIN_COLUMN_WIDTH) {
            savedColSize = MIN_COLUMN_WIDTH;
          }

          return (
            <StyledTh
              key={nanoid()}
              {...(columnProps || {})}
              {...column.props}
              className={`th ${
                column?.props?.columnId ? `col-${column.props.columnId}` : ""
              }`}
              data-field={column.props.dataField}
              data-type="th"
              compact={_compact}
              onClick={(e) => {
                if (column.props.allowSorting !== false)
                  onHeaderClick?.(column.props);
              }}
              draggable={column.props.allowReorder !== false ? true : false}
              onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = "move";
              }}
              onDragEnter={(e) => {
                e.target.classList.add("dragover");
                dropColumn.current = column.props;
              }}
              onDragLeave={(e) => {
                e.target.classList.remove("dragover");
              }}
              onDrop={(e) => {
                e.preventDefault();
                e.target.classList.remove("dragover");
                handleColumnReorder(dragColumn.current, dropColumn.current);
              }}
              onDragStart={(e) => {
                //set dragging class
                dragColumn.current = column.props;
                e.target.classList.add("dragging");
              }}
              onDragEnd={(e) => {
                e.target.classList.remove("dragging");
              }}
              style={{
                width: column?.props?.width
                  ? column?.props?.width
                  : savedColSize?.width || "auto",
                maxWidth: column?.props?.width
                  ? column?.props?.width
                  : savedColSize?.width || "auto",
                minWidth: column?.props?.width
                  ? column?.props?.width
                  : savedColSize?.width || MIN_COLUMN_WIDTH,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  pointerEvents:
                    column.props.dataField === "action" ? "all" : "none",
                }}
              >
                {showSelection && column.props.dataField === "action" ? (
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      marginRight: 5,
                      marginLeft: 3,
                    }}
                  >
                    {selectAll && (
                      <CheckBox
                        checked={selectionState === "all"}
                        partialCheck={selectionState.includes("some")}
                        onChange={handleSelectAll}
                      />
                    )}
                  </div>
                ) : (
                  <div>{column.props.caption}</div>
                )}
                {column.props?.sorting?.active && (
                  <div style={{ marginLeft: 5 }}>
                    {column.props.sorting.direction === "asc" ? (
                      <NorthIcon style={{ fontSize: 12 }} />
                    ) : (
                      <SouthIcon style={{ fontSize: 12 }} />
                    )}
                  </div>
                )}
              </div>
              {column?.props?.resizeEnabled !== false && (
                <Resizer
                  resizeHandler={resizeHandler}
                  draggable={true}
                  column={column}
                  onResize={(e) => {
                    resizeState.current = e;
                  }}
                  onResizeFinished={(e) => {
                    onColumnResize?.(e);
                  }}
                />
              )}
            </StyledTh>
          );
        })}
      </StyledHeaderRow>
    </StyledThead>
  );
};

const InnerElement = forwardRef(
  (
    {
      children,
      columns,
      columnProps,
      resizeState,
      _compact,
      stripes,
      onHeaderClick,
      dropColumn,
      dragColumn,
      showSelection,
      selectAll,
      selectionState,
      handleSelectAll,
      handleColumnReorder,
      onColumnResize,
      headerHeight,
      ...rest
    },
    ref
  ) => {
    return (
      <StyledTable ref={ref} {...rest}>
        <HeaderRowComponent
          columns={columns}
          columnProps={columnProps}
          resizeState={resizeState}
          _compact={_compact}
          headerHeight={headerHeight}
          onHeaderClick={onHeaderClick}
          dropColumn={dropColumn}
          dragColumn={dragColumn}
          showSelection={showSelection}
          selectAll={selectAll}
          selectionState={selectionState}
          handleSelectAll={handleSelectAll}
          handleColumnReorder={handleColumnReorder}
          onColumnResize={onColumnResize}
        />
        <StyledTbody
          className={"tbody" + (stripes ? " striped" : "")}
          stripes={stripes}
        >
          {children}
        </StyledTbody>
      </StyledTable>
    );
  }
);

// jsDoc
/*
 * @typedef {Object} ColumnProps
 * @property {string} dataField - The data field to be displayed in the column
 * @property {string} caption - The caption to be displayed in the column header
 * @property {string} columnId - The unique ID of the column
 * @property {boolean} sorting - Enable sorting for the column
 * @property {string} dataType - The data type of the column data
 * @property {Object} format - The format of the column data
 * format.type - The type of format to be applied to the column data
 * @property {number} width - The width of the column
 *
 */
export const Column = ({
  dataField,
  caption,
  columnId = nanoid(),
  sorting = null,
  format = null,
}) => {
  return null;
};

Column.displayName = "Column";

const Row = ({ data, index, style }) => {
  const {
    data: dataRows,
    columns,
    focusedRow,
    keyValue,
    _compact,
    queryKey,
    reloadFn,
    rowHeight,
    headerHeight,
    resizeState,
    onRowUpdated,
  } = data;
  const item = dataRows?.[index] || {};

  const handleRowUpdated = (data) => {
    onRowUpdated?.({ rowData: item, updateData: data });
  };

  const isRowFocused = focusedRow
    ? focusedRow[keyValue] === item[keyValue]
    : false;

  const row = columns.map((column) => {
    let value = null;
    let savedColSize = resizeState?.current?.columns?.find(
      (col) => col.dataField === column.props.dataField
    );
    if (savedColSize < MIN_COLUMN_WIDTH) {
      savedColSize = MIN_COLUMN_WIDTH;
    }
    // parse datafield dot syntax
    if (column.props.dataField?.includes(".")) {
      const fields = column.props.dataField?.split(".");

      value = fields.reduce((acc, field) => {
        return acc?.[field];
      }, item);
    } else value = item?.[column.props.dataField];

    // format date values
    // format date values
    if (
      column.props.dataType === "date" &&
      column?.props?.format?.type &&
      value
    ) {
      const dateFormat =
        column?.props?.format?.type || getDateFormat(true, false);

      value = moment(value).format(dateFormat);
    }

    if (column.props.render) {
      return (
        <StyledTd
          key={nanoid()}
          className={`td ${
            column?.props?.columnId ? `col-${column.props.columnId}` : ""
          }`}
          title={
            column?.props?.titleRender ? column?.props?.titleRender(item) : null
          }
          compact={_compact}
          style={{
            width: column?.props?.width
              ? column?.props?.width
              : savedColSize?.width || "auto",
            maxWidth: column?.props?.width
              ? column?.props?.width
              : savedColSize?.width || "auto",
            minWidth: column?.props?.width
              ? column?.props?.width
              : savedColSize?.width || MIN_COLUMN_WIDTH,
            ...(column?.props?.style || {}),
          }}
          data-type="td"
        >
          {column.props.resizeEnabled !== false && <Resizer column={column} />}
          {column.props.render(item, {
            queryKey: queryKey || "",
            reload: reloadFn,
            onRowUpdated: handleRowUpdated,
          })}
        </StyledTd>
      );
    }

    return (
      <StyledTd
        key={nanoid()}
        className={`td ${
          column?.props?.columnId ? `col-${column.props.columnId}` : ""
        }`}
        compact={_compact}
        title={
          column?.props?.titleRender ? column?.props?.titleRender(item) : null
        }
        data-type="td"
        style={{
          width: column?.props?.width
            ? column?.props?.width
            : savedColSize?.width || "auto",
          maxWidth: column?.props?.width
            ? column?.props?.width
            : savedColSize?.width || "auto",
          minWidth: column?.props?.width
            ? column?.props?.width
            : savedColSize?.width || MIN_COLUMN_WIDTH,
        }}
      >
        {column.props.resizeEnabled !== false && <Resizer column={column} />}
        {value}
      </StyledTd>
    );
  });

  return (
    <StyledTr
      className={`tr${isRowFocused ? " focused" : ""}`}
      key={nanoid()}
      data-key={item[keyValue]}
      data-type="tr"
      data-even={index % 2 === 0 ? "true" : "false"}
      compact={_compact}
      style={{ ...style, top: style.top + headerHeight }} // adjust position to account for sticky header
    >
      {row}
    </StyledTr>
  );
};

export const useTable = () => {
  const [tableInstance, setTableInstance] = useState(null);

  return {
    setTableInstance,
    toggleCompact: () => tableInstance?.toggleCompact(),
    toggleStripes: () => tableInstance?.toggleStripes(),
    isCompact: tableInstance?.isCompact || false,
    isStriped: tableInstance?.isStriped || false,
    getVirtualSelectionTotal: () =>
      tableInstance?.getVirtualSelectionTotal() || 0,
    getSelectedRows: () => tableInstance?.getSelectedRows() || [],
    getExcludedRows: () => tableInstance?.getExcludedRows() || [],
    getSelectionState: () => tableInstance?.getSelectionState() || {},
    clearSelections: () => tableInstance?.clearSelections(),
    onColumnResize: (e) => tableInstance?.onColumnResize(e),
  };
};

const Table = styled(
  ({
    className,
    children,
    data,
    rowHeight,
    headerHeight,
    tableInstance,
    queryKey,
    totalRecords,
    compact,
    focusedRow,
    keyValue,
    onScroll,
    allowColumnResize,
    allowColumnReorder,
    showActionColumn,
    showSelection,
    selectAll,
    reloadFn,
    onColumnReorder,
    onSelectionChanged,
    onActionButtonClick,
    onHeaderClick,
    onColumnResize,
    onRowUpdated,
    ...others
  }) => {
    const theme = useTheme();
    const rootRef = useRef(null);
    const [initialize, getInstance] = useOverlayScrollbars({
      options: {
        scrollbars: {
          autoHide: "scroll",
          autoHideDelay: 500,
        },
      },
      events: { scroll: (_, event) => onScroll?.(event) },
    });
    const [targetElm, setTargetElm] = useState(null);
    const [listElm, setListElm] = useState(null);

    const dragColumn = useRef(null);
    const dropColumn = useRef(null);
    const [_compact, setCompact] = useState(compact);
    const [stripes, setStripes] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [virtualSelectionTotal, setVirtualSelectionTotal] = useState(0);
    const [excludedRows, setExcludedRows] = useState([]);
    const [selectionState, setSelectionState] = useState("none");
    const resizeState = useRef([]);

    let columns = useMemo(
      () =>
        children
          .filter((child) => child.type.displayName === "Column")
          // Set unique ID for each column
          .map((column) => {
            return cloneElement(column, {
              columnId: nanoid(),
              allowResize:
                allowColumnResize !== undefined
                  ? allowColumnResize
                  : column.props.allowResize,
              allowReorder:
                allowColumnReorder !== undefined
                  ? allowColumnReorder
                  : column.props.allowReorder,
              resizeEnabled:
                allowColumnResize !== undefined
                  ? allowColumnResize
                  : column.props.resizeEnabled,
            });
          }),
      [children]
    );

    const [columnOrder, setColumnOrder] = useState(
      columns.map((col, index) => {
        return {
          column: col.props?.dataField,
          order: col.props?.order || index,
        };
      })
    );

    const handleColumnReorder = (dragColumn, dropColumn) => {
      const dropColumnOrder = columnOrder.find(
        (col) => col.column === dropColumn.dataField
      )?.order;

      const newColumnOrder = columnOrder.map((col) => {
        if (col.column === dragColumn.dataField) {
          return { column: col.column, order: dropColumnOrder + 1 };
        } else {
          return col.order > dropColumnOrder
            ? { ...col, order: col.order + 1 }
            : col;
        }
      });
      onColumnReorder?.(newColumnOrder);
      setColumnOrder(newColumnOrder);
    };

    const handleSelectAll = () => {
      let newSelectionState = null;
      let newVirtualSelectionTotal = null;
      if (selectionState === "all") {
        // clear current selections
        setSelectedRows([]);
        // turn off all rows selected
        newSelectionState = "none";
        setSelectionState("none");
        // clear excluded rows
        setExcludedRows([]);
        newVirtualSelectionTotal = 0;
        setVirtualSelectionTotal(newVirtualSelectionTotal);
      } else {
        // clear current selections
        // setSelectedRows([]);
        // turn on all rows selected
        newSelectionState = "all";
        setSelectionState("all");
        // clear excluded rows
        setExcludedRows([]);
        newVirtualSelectionTotal = totalRecords;
        setVirtualSelectionTotal(newVirtualSelectionTotal);
      }
      onSelectionChanged?.({
        selectedRows: [],
        excludedRows: [],
        selectionState: newSelectionState,
        virtualSelectionTotal: newVirtualSelectionTotal,
      });
    };

    const handleSelectRow = ({ rowData, checked }) => {
      let newSelectedRows = [];
      let newExcludedRows = [];
      let newSelectionState = null;
      let newVirtualSelectionTotal = null;

      if (selectionState === "all") {
        newExcludedRows = [...excludedRows, rowData];
        newSelectionState = "someExcluded";
        newVirtualSelectionTotal = totalRecords - 1;
      } else if (selectionState === "none") {
        newSelectedRows = [rowData];
        newSelectionState = "someIncluded";
        newVirtualSelectionTotal = 1;
      } else if (selectionState === "someExcluded") {
        newSelectionState = "someExcluded";
        newSelectedRows = selectedRows;

        if (checked) {
          // remove from excluded rows
          newExcludedRows = excludedRows.filter(
            (row) => row[keyValue] !== rowData[keyValue]
          );
          newVirtualSelectionTotal = virtualSelectionTotal + 1;
        } else {
          // add to excluded rows
          newExcludedRows = [...excludedRows, rowData];
          newVirtualSelectionTotal = virtualSelectionTotal - 1;
        }
      } else if (selectionState === "someIncluded") {
        newSelectionState = "someIncluded";
        newExcludedRows = excludedRows;

        if (checked) {
          // add to selected rows
          newSelectedRows = [...selectedRows, rowData];
          newVirtualSelectionTotal = virtualSelectionTotal + 1;
        } else {
          // remove from selected rows
          newSelectedRows = selectedRows.filter(
            (row) => row[keyValue] !== rowData[keyValue]
          );
          newVirtualSelectionTotal = virtualSelectionTotal - 1;
        }
      }

      if (newVirtualSelectionTotal === totalRecords) {
        newSelectionState = "all";
      } else if (newVirtualSelectionTotal === 0) {
        newSelectionState = "none";
      }

      setSelectedRows(newSelectedRows);
      setExcludedRows(newExcludedRows);
      setVirtualSelectionTotal(newVirtualSelectionTotal);
      setSelectionState(newSelectionState);
      onSelectionChanged?.({
        selectedRows: newSelectedRows,
        excludedRows: newExcludedRows,
        selectionState: newSelectionState,
        virtualSelectionTotal: newVirtualSelectionTotal,
      });
    };

    // sort columns
    columns = useMemo(
      () =>
        columns.sort((a, b) => {
          const aOrder = columnOrder.find(
            (col) => col.column === a.props.dataField
          )?.order;
          const bOrder = columnOrder.find(
            (col) => col.column === b.props.dataField
          )?.order;
          return aOrder - bOrder;
        }),
      [columnOrder, columns]
    );

    const hiddenColumns = columns
      .filter((col) => col.props.visible === false)
      .map((col) => col.props.dataField);

    // insert action column as first column
    showActionColumn &&
      (columns = [
        <Column
          key={nanoid()}
          className="action-column"
          dataField="action"
          allowSorting={false}
          allowReorder={false}
          caption=""
          width={showSelection ? 55 : 35}
          resizeEnabled={false}
          render={(rowData) => (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                padding: 4,
                color: theme.palette.text.secondary,
              }}
            >
              {showSelection && (
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    color: theme.palette.text.secondary,
                    marginRight: 5,
                  }}
                >
                  <CheckBox
                    checked={
                      selectionState === "all"
                        ? true
                        : selectionState === "someIncluded"
                        ? !!selectedRows.find(
                            (row) => row[keyValue] === rowData[keyValue]
                          )
                        : selectionState === "someExcluded"
                        ? !excludedRows.find(
                            (row) => row[keyValue] === rowData[keyValue]
                          )
                        : false
                    }
                    onChange={(e) =>
                      handleSelectRow({ rowData, checked: e.newValue })
                    }
                  />
                </div>
              )}
              <div
                className="action-button"
                title="Show More Details"
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  marginRight: 5,
                }}
                onClick={(e) => {
                  onActionButtonClick?.(rowData);
                }}
              >
                <Maximize2Icon size={_compact ? 8 : 12} />
              </div>
            </div>
          )}
        />,
        ...columns,
      ]);

    useEffect(() => {
      if (targetElm && listElm) {
        initialize({
          target: targetElm,
          elements: {
            viewport: listElm,
          },
        });

        return () => getInstance()?.destroy();
      }
    }, [targetElm, listElm]);

    useEffect(() => {
      if (tableInstance) {
        tableInstance.setTableInstance({
          getSelectedRows: () => selectedRows,
          getExcludedRows: () => excludedRows,
          getVirtualSelectionTotal: () => virtualSelectionTotal,
          getSelectionState: () => selectionState,
          clearSelections: () => {
            setSelectedRows([]);
            setVirtualSelectionTotal(0);
            setSelectionState("none");
            setExcludedRows([]);
          },
          toggleCompact: () => setCompact(!_compact),
          isCompact: _compact,
          toggleStripes: () => setStripes(!stripes),
          isStriped: stripes,
          onColumnResize: (e) => {},
        });
      }
    }, [
      selectedRows,
      excludedRows,
      virtualSelectionTotal,
      selectionState,
      _compact,
      stripes,
    ]);

    useEffect(() => {
      // Scroll to selected row if outside of viewport
      if (focusedRow) {
        const focusedRowElm = document.querySelector(
          `[data-type="tr"][data-key="${focusedRow[keyValue]}"]`
        );
        if (focusedRowElm) {
          focusedRowElm.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }
      }
    }, [focusedRow]);

    return (
      <div ref={rootRef} className={className + " table-wrapper"}>
        <AutoSizer>
          {({ height, width }) => (
            <div
              style={{ width, height }}
              data-overlayscrollbars=""
              ref={setTargetElm}
            >
              <FixedSizeList
                overscanCount={10}
                itemData={{
                  data,
                  columns: columns.filter(
                    (col) => !hiddenColumns.includes(col.props.dataField)
                  ),
                  focusedRow,
                  keyValue,
                  _compact,
                  queryKey,
                  reloadFn,
                  rowHeight: rowHeight ? rowHeight : _compact ? 28 : 38,
                  headerHeight: headerHeight
                    ? headerHeight
                    : _compact
                    ? 28
                    : 38,
                  resizeState,
                  onRowUpdated,
                  tableWidth: width,
                }}
                height={height}
                innerElementType={(props) => (
                  <InnerElement
                    {...props}
                    columns={columns.filter(
                      (col) => !hiddenColumns.includes(col.props.dataField)
                    )}
                    tableWidth={width}
                    columnProps={others.columnProps}
                    resizeState={resizeState}
                    _compact={_compact}
                    stripes={stripes}
                    onHeaderClick={onHeaderClick}
                    dropColumn={dropColumn}
                    dragColumn={dragColumn}
                    showSelection={showSelection}
                    selectAll={selectAll}
                    selectionState={selectionState}
                    handleSelectAll={handleSelectAll}
                    handleColumnReorder={handleColumnReorder}
                    onColumnResize={onColumnResize}
                  />
                )}
                itemCount={data?.length}
                itemSize={rowHeight ? rowHeight : _compact ? 28 : 38}
                stickyIndices={[0]}
                width={width}
                outerRef={setListElm}
              >
                {Row}
              </FixedSizeList>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
)`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .os-scrollbar {
    pointer-events: auto;
    visibility: visible;
  }

  .action-column {
    max-width: 55px;
  }
`;

export default Table;
