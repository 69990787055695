import { Modal } from "@monolith-forensics/monolith-ui";
import CreateDocumentTemplateForm from "../Forms/CreateDocumentTemplateForm.js";

const CreateDocumentTemplateModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  buttonProps?: { cancelText?: string; submitText?: string };
}) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Create Document Template</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Upload a new report template in docx format.
      </Modal.SubTitle>
      <CreateDocumentTemplateForm
        width={400}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onClose={onClose}
        buttonProps={buttonProps}
      />
    </Modal>
  );
};

export default CreateDocumentTemplateModal;
