import {
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { usePermissions } from "../../hooks/usePermissions";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import TaskButton from "../../Monolith-UI/TaskButton.js";

import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getDateFormat, monolithMoment } from "../../utils/date-format.js";
import { nanoid } from "nanoid";
import CaseStorageColumnsDefs from "../../components/CaseStorage/CaseStorageColumnsDefs.js";
import OverviewContent from "./components/OverviewContent.js";
import CocContent from "./components/CocContent.js";
import StoredDataContent from "./components/StoredDataContent.js";
import EditStoragePopup from "./modals/EditStoragePopup.js";
import CaseStorageAPI from "../../api/storage/index.js";
import { logActivity } from "../../api/index.js";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import { DevexEditors } from "../../utils/devex-editors.js";
import ToolBarItems from "../../components/ToolBarItems.js";
import { Loader } from "react-feather";
import { useSnackbar } from "notistack";
import AuditContent from "./components/AuditContent.js";
import { safeParseJSON } from "../../utils/File-parsers/safeParseJSON.js";
import { MONOLITH_PERMISSIONS } from "../../constants.js";

const StorageOverview = styled(({ className, storageInfo: details }) => {
  return (
    <div className={className}>
      <div className="inner-content">
        <SideContent details={details} />
        <MainContent details={details} />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  .inner-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 10px;
    width: 100%;
    overflow-y: auto;
  }
`;

const SideContent = styled(({ className, details }) => {
  const { storage_id } = useParams();
  const { hasPermission } = usePermissions();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(true);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditCustomFieldsModal, setShowEditCustomFieldsModal] =
    useState(false);

  const dataFieldIgnoreList = ["description", "case_ref", "case_number"];

  const storageInfo = {
    ...details,
  };

  delete storageInfo.custom_attributes;
  delete storageInfo.case_ref;
  delete storageInfo.case_number;

  const caseInfo = {
    case_number: details?.case_number,
    case_ref: details?.case_ref,
  };

  const storageDetails = (
    <div className="detail-container">
      {CaseStorageColumnsDefs.filter(
        (c) => !dataFieldIgnoreList.includes(c.dataField)
      ).map((c) => {
        return (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{c.caption}</div>
            {c.render ? (
              c.render(storageInfo)
            ) : c.dataType === "date" ? (
              <>
                {!!storageInfo[c.dataField] &&
                  monolithMoment({
                    timestamp: storageInfo[c.dataField],
                    includeTime: true,
                  })}
              </>
            ) : (
              <div className="detail-value">{storageInfo[c.dataField]}</div>
            )}
          </div>
        );
      })}
    </div>
  );

  const caseDetails = (
    <div className="detail-container">
      {CaseStorageColumnsDefs.map((c) => {
        if (!caseInfo[c.dataField]) return null;
        return (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{c.caption}</div>
            {c.render ? (
              c.render(details)
            ) : c.dataType === "date" ? (
              <>
                {monolithMoment({
                  timestamp: caseInfo[c.dataField],
                  includeTime: true,
                })}
              </>
            ) : (
              <div className="detail-value">{caseInfo[c.dataField]}</div>
            )}
          </div>
        );
      })}
    </div>
  );

  const customFieldData = details?.custom_attributes?.filter((c) =>
    c.value === null || c.value === "" || c.value === "[]" ? false : true
  );

  const customDetails = (
    <div className="detail-container">
      {customFieldData?.map((c) => {
        if (c.value === null) return null;
        if (c.value === "") return null;
        if (c.value === "[]") return null;

        const parsedValue = safeParseJSON(c.value);

        return (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{c.field_name}</div>
            {c.editor_type === "dateBox" ? (
              <>
                {monolithMoment({
                  timestamp: c.value,
                  includeTime: false,
                })}
              </>
            ) : c.editor_type === "tagBox" ? (
              <div className="detail-value">
                {Array.isArray(parsedValue)
                  ? parsedValue.map((v) => v).join(", ")
                  : parsedValue}
              </div>
            ) : (
              <div className="detail-value">{parsedValue}</div>
            )}
          </div>
        );
      }) || []}
      {customFieldData?.length === 0 && (
        <div className="detail-item">
          <div className="detail-label">No custom data</div>
        </div>
      )}
    </div>
  );

  const onEdit = (newData) => {
    queryClient.refetchQueries([
      "storage:list",
      {
        uuid: Number.isNaN(Number(storage_id)) ? storage_id : null,
        storage_id: Number.isNaN(Number(storage_id)) ? null : storage_id,
      },
    ]);
  };

  return (
    <div className={className + (open ? " expanded" : " collapsed")}>
      <div
        className={
          open
            ? "collapse-btn side-content-expanded"
            : "collapse-btn side-content-collapsed"
        }
      >
        {open && <div className="header">Case Info</div>}
        <TaskButton variant="outlined" onClick={() => setOpen(!open)}>
          <KeyboardTabOutlinedIcon style={{ fontSize: 16 }} />
        </TaskButton>
      </div>
      {open && (
        <>
          {caseDetails}
          <div className="header-container">
            <div className="header" style={{ marginRight: 15 }}>
              Storage Item Details
            </div>
            {hasPermission(MONOLITH_PERMISSIONS.STORAGE_UPDATE) && (
              <div
                className="action-menu-item"
                onClick={() => setShowEditModal(true)}
              >
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
            )}
          </div>
          {storageDetails}
          <div className="header-container">
            <div className="header" style={{ marginRight: 15 }}>
              Custom Fields
            </div>
            {hasPermission(MONOLITH_PERMISSIONS.STORAGE_UPDATE) && (
              <div
                className="action-menu-item"
                onClick={() => setShowEditCustomFieldsModal(true)}
              >
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
            )}
          </div>
          {customDetails}
          <EditStoragePopup
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            onSubmit={onEdit}
            defaultInfo={details}
          />
          <EditCustomFieldsModal
            open={showEditCustomFieldsModal}
            handleClose={() => setShowEditCustomFieldsModal(false)}
            onSubmit={onEdit}
            defaultInfo={details}
          />
        </>
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 425px;
  min-width: 425px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.1s ease-in-out;

  &.collapsed {
    min-width: 50px;
    max-width: 50px;
  }

  & .collapse-btn {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .side-content-expanded {
    svg {
      transform: rotate(180deg);
    }
  }

  & .side-content-collapsed {
    justify-content: center;
  }

  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
    cursor: default;
  }
  & .header-container {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 20px 0px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    margin-left: auto;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .detail-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  & .detail-item {
    display: flex;
    align-items: baseline;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
    width: 100%;
    white-space: pre-wrap;
  }
`;

const MainContent = styled(({ className, details }) => {
  const { storage_id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const buttonStyle = {
    minWidth: "150px",
    padding: "2px 5px",
  };

  const handleChange = (event, newGroup) => {
    if (newGroup !== null) {
      setCurrentTab(newGroup);
    }
  };

  const control = {
    value: currentTab,
    onChange: handleChange,
    exclusive: true,
  };

  useEffect(() => {
    setCurrentTab(0);
  }, [storage_id]);

  return (
    <div className={className}>
      <div className="toggle-menu">
        <ToggleButtonGroup size="small" {...control} style={{ margin: "auto" }}>
          <ToggleButton
            value={0}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            Overview
          </ToggleButton>
          <ToggleButton
            value={1}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            Chain of Custody
          </ToggleButton>
          <ToggleButton
            value={2}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Stored Data</>
          </ToggleButton>
          <ToggleButton
            value={3}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Audit Logs</>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {currentTab === 0 && <OverviewContent storageInfo={details} />}
      {currentTab === 1 && <CocContent storageInfo={details} />}
      {currentTab === 2 && <StoredDataContent storageInfo={details} />}
      {currentTab === 3 && <AuditContent storageInfo={details} />}
    </div>
  );
})`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0px;

  & .toggle-menu {
    margin-bottom: 10px;
    display: flex;
    overflow-x: auto;
  }
  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
  }
  & .description {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 12px;
    margin-top: 10px;
  }
`;

const EditCustomFieldsModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef(null);

  const defaultFormData = {};

  defaultInfo.custom_attributes?.forEach((attr) => {
    defaultFormData[`custom_attribute_${attr.field_id}`] = safeParseJSON(
      attr.value
    );
  });

  const { data: customFields } = useQuery({
    queryKey: ["storage:custom-fields:list"],
    queryFn: () =>
      CaseStorageAPI.getStorageCustomAttributes({ fieldsOnly: true }),
    select: (data) => {
      return data?.filter((c) => c.enabled === 1);
    },
  });

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      const formData = Object.assign(
        {},
        form.current.instance.option("formData")
      );

      handleClose();

      const updateData = {};

      Object.keys(formData).forEach((key) => {
        if (
          typeof defaultFormData[key] === "undefined" ||
          (formData[key] ? formData[key].toString() : formData[key]) !==
            (defaultFormData[key]
              ? defaultFormData[key].toString()
              : defaultFormData[key])
        ) {
          updateData[key] = formData[key];
        }
      });

      //Get custom attributes from updateData object
      //Compile them into a single object array with related attrubite values
      //remove original form items from form data
      const customAttributes = [];
      for (const key of Object.keys(updateData)) {
        const field_id = parseInt(key.replace("custom_attribute_", ""));
        if (!field_id) continue;

        const currentAttr = defaultInfo?.custom_attributes?.find(
          (attribute) => {
            return attribute.field_id === field_id;
          }
        );

        //evidence has attribute & it hasnt changed
        if (currentAttr && currentAttr.value === updateData[key]) continue;

        const baseAttr = customFields.find(
          (attr) => attr.field_id === field_id
        );

        customAttributes.push({
          attribute_id: currentAttr ? currentAttr.attribute_id : null,
          field_id: field_id,
          field_name: baseAttr.field_name,
          item_id: defaultInfo.evidence_id,
          value: updateData[key],
          editor_type: baseAttr.editor_type,
          description: baseAttr.description,
          options: baseAttr.options || null,
        });

        delete updateData[key];
      }

      CaseStorageAPI.updateStorage(defaultInfo.storage_id, {
        custom_attributes: customAttributes,
      }).then((res) => {
        enqueueSnackbar("Storage updated successfully.", {
          variant: "success",
        });

        logActivity(
          defaultInfo.case_id,
          currentUser.user_id,
          `Edited Storage Custom Fields ${defaultInfo.storage_number}`
        );

        if (!defaultInfo.custom_attributes) defaultInfo.custom_attributes = [];
        onSubmit({
          custom_attributes: [
            ...defaultInfo?.custom_attributes?.filter((ca) => {
              return !customAttributes.find(
                (ca2) => ca2.field_id === ca.field_id
              );
            }),
            ...customAttributes.map((ca) => {
              return {
                ...ca,
                value:
                  ca.editor_type === "tagBox"
                    ? JSON.stringify(ca.value)
                    : ca.value,
              };
            }),
          ],
        });
      });
    }
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            overflowY: "auto",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Storage Custom Fields
          </div>
          {customFields && (
            <>
              <Form
                ref={form}
                colCount={2}
                defaultFormData={{ ...defaultFormData }}
              >
                {customFields &&
                  customFields.map((attribute) => {
                    return (
                      <SimpleItem
                        key={`custom_attribute_${attribute.field_id}`}
                        dataField={`custom_attribute_${attribute.field_id}`}
                        editorType={DevexEditors[attribute.editor_type]}
                        isRequired={attribute.is_required === 1}
                        label={{ text: attribute.field_name }}
                        editorOptions={{
                          placeholder: attribute.description,
                          hint: attribute.description,
                          items: JSON.parse(attribute.options || "[]"),
                          dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                          useMaskBehavior: true,
                          displayFormat: getDateFormat(false, false),
                          showClearButton: attribute.editor_type !== "textBox",
                          pickerType: "calendar",
                          showDropDownButton: true,
                          multiline: false,
                          showSelectionControls:
                            attribute.editor_type === "tagBox" ? true : false,
                        }}
                      />
                    );
                  })}
              </Form>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <ToolBarItems
                  submitText="Edit Storage"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
          {!customFields && <Loader />}
        </div>
      </Modal>
    </div>
  );
};

export default StorageOverview;
