import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getIconForFile } from "vscode-icons-js";
import styled, { useTheme } from "styled-components";
import { FolderIcon, RecycleIcon, TrashIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import CaseFileAPI from "../../../../api/CaseFiles/index.js";
import {
  Button,
  Flyout,
  FlyoutHeader,
  FlyoutTitle,
} from "@monolith-forensics/monolith-ui";
import FlyoutDetailsTab from "./FlyoutDetailsTab.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import Activity from "./Activity.js";
import DeleteModal from "../DeleteItemModal.js";

const tabDefs = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Activity",
    value: "activity",
  },
];

const TabPanel = styled(({ className, children, value }) => {
  return <div className={className}>{children}</div>;
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

TabPanel.displayName = "TabPanel";

const Tabs = styled(({ className, children, defaultValue, onChange }) => {
  if (!Array.isArray(children)) children = [children]; // Ensure children is an array
  const [activeTab, setActiveTab] = useState(defaultValue || "details");

  const handleChange = (tab) => {
    setActiveTab(tab);
    onChange?.(tab);
  };

  const tabPanels = children.filter(
    (child) => child?.type?.displayName === "TabPanel"
  );

  const activePanel = tabPanels.find(
    (child) => child.props.value === activeTab
  );

  return (
    <div className={className}>
      <div className="tabs-list">
        {tabDefs.map((tab) => (
          <div
            className={`tab`}
            data-active={activeTab === tab.value}
            onClick={() => handleChange(tab.value)}
            key={tab.value}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">{activePanel}</div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .tabs-list {
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.palette.text.secondary};
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }

  .tab {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
  }

  .tab:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  .tab[data-active="true"] {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;
  }
`;

const ItemInfoFlyout = ({
  open = false,
  onClose,
  uuid = null,
  defaultData = {},
  onEdit,
  onDelete,
}) => {
  const { case_id: caseID } = useParams();
  const case_id = parseInt(caseID, 10);
  const { currentUser } = usePermissions();
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["case-files", "list", { case_id, uuid }],
    queryFn: ({ queryKey }) => {
      return CaseFileAPI.get(queryKey[2]);
    },
    enabled: !!uuid,
    initialData: [defaultData],
  });

  const restoreFile = useMutation({
    mutationFn: CaseFileAPI.restoreFiles,
    onMutate: () => {},
    onSuccess: () => {
      refetch();
    },
  });

  const itemData = data?.[0] || {};

  const isFolder = itemData.is_folder === 1;

  const fileIcon = isFolder
    ? null
    : getIconForFile(itemData?.file_name?.toLowerCase() || "text.txt");

  return (
    <Flyout open={open} onClose={() => onClose?.()}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <FlyoutHeader>
            <FlyoutTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                {isFolder ? (
                  <FolderIcon size={25} color="#c98200" />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/static/icons/${fileIcon}`}
                    alt="icon"
                    style={{ height: "25px" }}
                  />
                )}
                <div>{itemData.file_name}</div>
              </div>
            </FlyoutTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                color: theme.palette.text.secondary,
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              <div>{isFolder ? "Case Folder" : "Case File"}</div>
              <Button
                size="xxs"
                variant="text"
                leftSection={<TrashIcon size={14} />}
                color="error"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Button>
              {itemData.status === "archived" && (
                <Button
                  size="xxs"
                  variant="text"
                  leftSection={<RecycleIcon size={14} />}
                  color="green"
                  onClick={() =>
                    restoreFile.mutate({ files: [{ uuid: itemData.uuid }] })
                  }
                >
                  Restore File
                </Button>
              )}
            </div>
            <DeleteModal
              open={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              onSubmit={() => onDelete?.()}
              defaultInfo={[itemData]}
            />
          </FlyoutHeader>
          <Tabs>
            <TabPanel value="details">
              <FlyoutDetailsTab
                defaultData={itemData}
                onChange={() => refetch()}
              />
            </TabPanel>
            <TabPanel value="activity">
              <Activity defaultData={itemData} />
            </TabPanel>
          </Tabs>
        </>
      )}
    </Flyout>
  );
};

export default ItemInfoFlyout;
