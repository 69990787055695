import { useInfiniteQuery } from "@tanstack/react-query";
import { SelectBox, TagBox } from "@monolith-forensics/monolith-ui";
import { useDebouncedCallback } from "use-debounce";
import styled from "styled-components";
import { AnalysisAPI } from "../../../../api/index.js";
import ContactsAPI from "../../../../api/contacts/index.js";

const PAGE_SIZE = 30;

const TooltipContent = styled(({ className, data }) => {
  return (
    <div className={className}>
      <div className="detail-title">Contact Details</div>
      <div className="detail-item">
        <div className="detail-label">Name</div>
        <div className="detail-value">{data.name || "None"}</div>
      </div>
      <div className="detail-item">
        <div className="detail-label">Email</div>
        <div className="detail-value">{data.email || "None"}</div>
      </div>
      <div className="detail-item">
        <div className="detail-label">Description</div>
      </div>
      <div className="detail-value">{data.notes || "None"}</div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
  max-width: 300px;

  .detail-title {
    font-weight: bold;
    font-size: 15px;
  }
  .detail-item {
    display: flex;
    flex-direction: column;
  }

  .detail-label {
    font-weight: bold;
    min-width: 150px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const ContactSelector = ({
  query,
  multiple = false,
  label = "Linked Contact",
  placeholder = "Select Contact",
  description = "Select a related contact.  This is a person that is related to the item you are linking.",
  ...props
}) => {
  const defaultQuery = {
    ...query,
    pageSize: PAGE_SIZE,
  };

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["contacts", "list", defaultQuery],
    queryFn: ({ pageParam, queryKey }) => {
      const [_, __, query] = queryKey;
      return ContactsAPI.get({ ...query, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page - 1 === 0) return null;
      return firstPage.page - 1;
    },
    initialPageParam: 1,
    placeholderData: (data) => data,
  });

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const records = data?.pages
    ?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, [])
    .map((item) => ({
      label: item.name,
      value: item.client_id,
      data: item,
    }));

  if (multiple) {
    return (
      <TagBox
        {...props}
        label={label}
        placeholder={placeholder}
        description={description}
        loading={isLoading}
        data={records}
        onScroll={handleScroll}
        TooltipContent={TooltipContent}
      />
    );
  }

  return (
    <SelectBox
      {...props}
      label={label}
      placeholder={placeholder}
      description={description}
      loading={isLoading}
      data={records}
      onScroll={handleScroll}
      TooltipContent={TooltipContent}
    />
  );
};

export default ContactSelector;
