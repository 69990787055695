import { useQuery } from "@tanstack/react-query";
import MetricsAPI from "../../../../api/Metrics/Metrics.js";
import styled from "styled-components";
import convertBytesToSize from "../../../../utils/convertBytesToSize.js";
import moment from "moment";

const DataCollectedWidget = styled(({ className }) => {
  const metrics = [
    { metric: "dataCollected" },
    {
      metric: "dataCollected",
      args: [
        moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      ],
    },
  ];

  const { data } = useQuery({
    queryKey: ["metrics", { metrics }],
    queryFn: () => MetricsAPI.getMetrics({ metrics }),
  });

  if (!data) return null;
  const dataCollectedThisMonth = data?.data?.[0]?.dataCollected || {};
  const dataCollectedLastMonth = data?.data?.[1]?.dataCollected || {};

  // calculate percentage change
  let percentageChange =
    ((dataCollectedThisMonth.total_size_bytes -
      dataCollectedLastMonth.total_size_bytes) /
      dataCollectedLastMonth.total_size_bytes) *
    100;

  percentageChange = percentageChange.toFixed(2);

  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{"Data Collected (This Month)"}</div>
        <div className="stat-value">
          {convertBytesToSize(dataCollectedThisMonth.active_size_bytes)}{" "}
          <span
            className={
              "percent-change" +
              `${percentageChange > 0 ? " positive" : " negative"}`
            }
          >
            {percentageChange}%
          </span>
        </div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">Active</div>
          <div className="detail-value">
            {dataCollectedThisMonth.active_count}
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Deleted</div>
          <div className="detail-value">
            {dataCollectedThisMonth.delete_count}
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Total Active Data</div>
          <div className="detail-value">
            {convertBytesToSize(dataCollectedThisMonth.active_size_bytes)}
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Total Data</div>
          <div className="detail-value">
            {convertBytesToSize(dataCollectedThisMonth.total_size_bytes)}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;

  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: ${({ theme }) =>
      theme.name === "DARK" ? "#2d2d2d" : "#f5f5f5"};
    border-radius: 4px;

    .stat-title {
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.text.secondary};
      margin-bottom: 10px;
    }

    .stat-value {
      font-size: 18px;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.text.primary};
    }

    .percent-change {
      font-size: 12px;
      font-weight: 400;

      &.positive {
        color: ${({ theme }) => theme.palette.success.main};
      }

      &.negative {
        color: ${({ theme }) => theme.palette.error.main};
      }
    }

    .percent-change::before {
      margin-left: 5px;
      margin-right: 2px;
    }

    .percent-change.positive::before {
      content: "▲";
    }

    .percent-change.negative::before {
      content: "▼";
    }
  }

  .stat-selector {
    position: absolute;
    top: 30px;
    right: 30px;

    svg {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }

  .additional-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-gap: 10px;
    padding: 10px;

    .detail-item {
    }

    .detail-label {
      font-size: 0.75rem;
      font-weight: 300;
      color: ${({ theme }) => theme.palette.text.secondary};
      cursor: default;
    }

    .detail-value {
      user-select: text;
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.text.primary};
      cursor: default;

      &.no-cap {
        text-transform: none;
      }
    }
  }
`;

export default DataCollectedWidget;
