import { Card, IconButton, Tooltip } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import { DataGrid } from "devextreme-react/ui/data-grid.js";
import { getDateFormat } from "../../../../utils/date-format.js";
import Loader from "../../../../components/Loader.js";
import { getCasesReport } from "../../../../api/index.js";

const CaseReportGrid = ({ reportInfo }) => {
  const caseReportGrid = useRef(null);
  const queryClient = useQueryClient();

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
        minHeight: "450px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Cases
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <Tooltip arrow title="Export Grid">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  caseReportGrid.current.instance.exportToExcel(false);
                }}
              >
                <FileDownloadOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Refresh">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  queryClient.refetchQueries({
                    queryKey: ["metrics:case-overview", reportInfo],
                    active: true,
                    exact: true,
                  });
                }}
              >
                <SyncOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Column Select">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  caseReportGrid.current.instance.showColumnChooser();
                }}
              >
                <ViewColumnOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Table caseReportGrid={caseReportGrid} reportInfo={reportInfo} />
    </Card>
  );
};

const Table = ({ caseReportGrid, reportInfo }) => {
  const {
    isLoading,
    isFetching,
    data: cases,
  } = useQuery({
    queryKey: ["reports:case-overview", reportInfo],
    queryFn: () => getCasesReport(reportInfo.report_parameters || {}),
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <div style={{ marginTop: 30 }}>
        <Loader message="Retrieving Data..." />
      </div>
    );

  return (
    <DataGrid
      ref={caseReportGrid}
      dataSource={cases}
      height="350px"
      columns={[
        {
          dataField: "case_number",
          caption: "Case Number",
        },
        {
          dataField: "case_ref",
          caption: "Case Name",
        },
        {
          dataField: "name",
          caption: "Client Name",
        },
        {
          dataField: "organization",
          caption: "Organization",
        },
        {
          dataField: "case_open_date",
          caption: "Open Date",
          dataType: "date",
          format: {
            type: getDateFormat(false, false),
          },
        },
        {
          dataField: "case_closed_date",
          caption: "Close Date",
          dataType: "date",
          format: {
            type: getDateFormat(false, false),
          },
        },
        {
          dataField: "last_activity_date",
          caption: "Last Activity",
          dataType: "date",
          format: {
            type: getDateFormat(false, false),
          },
        },
        {
          dataField: "full_name",
          caption: "Case Lead",
        },
        {
          dataField: "case_status",
          caption: "Status",
        },
        {
          dataField: "case_type",
          caption: "Type",
        },
        {
          dataField: "progress",
        },
      ]}
      showRowLines={true}
      allowColumnReordering={false}
      allowColumnResizing={true}
      grouping={{ contextMenuEnabled: true }}
      onToolbarPreparing={(e) => {
        e.toolbarOptions.visible = false;
      }}
      paging={{
        pageSize: 20,
      }}
      scrolling={{
        mode: "virtual",
        rowRenderingMode: "virtual",
        useNative: false,
      }}
      headerFilter={{
        visible: true,
        allowSearch: true,
      }}
      hoverStateEnabled={true}
      columnChooser={{
        enabled: true,
        mode: "select",
        title: "Filter Columns",
        height: "700",
        width: "325",
      }}
      loadPanel={{
        enabled: false,
      }}
    />
  );
};

export default CaseReportGrid;
