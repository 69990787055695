import { useTheme } from "@mui/styles";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { usePermissions } from "../../../hooks/usePermissions";
import { useState, useMemo, useEffect } from "react";
import Table, { Column, useTable } from "../../../Monolith-UI/Table/Table.js";
import { useQueryFilter } from "../../../Monolith-UI/QueryFilter/QueryFilter.js";
import { getDateFormat } from "../../../utils/date-format.js";
import { Button } from "@mui/material";
import { ItemTotal } from "../CaseEvidence/index";
import ComboButton from "../../../Monolith-UI/ComboButton/ComboButton.js";

//Icons
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";

import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";

import Loader from "../../../components/Loader.js";
import { TextBox } from "devextreme-react/ui/text-box.js";
import CreateCaseReportModal from "./components/CreateCaseReportModal.js";
import CaseArchivesAPI from "../../../api/cases/archives.js";
import CaseArchivesColumnDefs from "../../../components/CaseArchives/CaseArchivesColumnDefs.js";
import synchronizeColumnState from "../../../utils/synchronize-column-state.js";
import { useDebouncedCallback } from "use-debounce";

const ARCHIVE_TIME_LIMIT = 60;

const columnDefs = CaseArchivesColumnDefs;

const DefaultQueryKey = "cases:archives:list";

const CaseArchives = ({ stateStoreKey = "cases:case-archive:view" }) => {
  const { case_id } = useParams();
  const theme = useTheme();
  const { hasPermission } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isArchiving, setIsArchiving] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [flyoutData, setFlyoutData] = useState(null);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      columnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });
  const [pageSize, setPageSize] = useState(20);
  const [query, setQuery] = useState(null);

  const table = useTable();

  const currentSort = useMemo(() => {
    let [sort] = columnState
      ?.filter((c) => c.sorting?.active)
      .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

    return sort;
  }, [columnState]);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        DefaultQueryKey,
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            case_id,
          },
        },
      ],
      queryFn: ({ pageParam }) =>
        CaseArchivesAPI.query({
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            page: pageParam,
            case_id,
          },
        }),
      refetchInterval: isArchiving ? 1500 : false,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data || { pages: [] },
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  const { queryFilter } = useQueryFilter({
    dimensions: [],
    onQuerySet: (newFilter) =>
      setQuery((q) => {
        return { query: { ...q?.query, ...newFilter, page: 1, case_id } };
      }),
    stateStoring: {
      enabled: true,
      type: "localStorage",
      storageKey: stateStoreKey,
    },
  });

  const handleSort = (field) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      query: {
        ...query.query,
        order: newOrder,
      },
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };
  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    CaseArchivesAPI.exportCaseArchiveList({
      query: {
        ...query?.query,
        order: query?.query?.order || currentSort,
        pageSize,
      },
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false && c.showInColumnChooser !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat(true, true),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
    });
  };
  const handleColumnReorder = (newOrder) => {
    setColumnState((cs) => {
      return newOrder.map((o) => {
        return {
          ...cs.find((c) => c.dataField === o.column),
          order: o.order,
        };
      });
    });
  };
  const handleColumnVisibility = (column, visible) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };
  const handleActionButtonClick = (rowData) => {
    setFlyoutData(rowData);
    setIsFlyoutVisible(true);
  };
  const handleClearFilters = () => queryFilter.clear();
  const handleQueryRefetch = (options = { onRefetched: () => {} }) => {
    queryClient
      .refetchQueries({
        queryKey: [
          DefaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              case_id,
              pageSize,
            },
          },
        ],
        exact: true,
      })
      .then((res) => {
        if (options.onRefetched) options.onRefetched();
      });
  };
  const handleGetNextItem = (case_report_id) => {
    const current = records?.findIndex(
      (i) => i.case_report_id === case_report_id
    );
    const nextItem = records[current + 1] || records[0];

    setFlyoutData(nextItem);
  };
  const handleGetPrevItem = (case_report_id) => {
    const current = records?.findIndex(
      (i) => i.case_report_id === case_report_id
    );
    const prevItem = records[current - 1] || records[records.length - 1];

    setFlyoutData(prevItem);
  };
  const handleCreateArchive = () => {
    setIsArchiving(true);
    CaseArchivesAPI.createCaseArchive({
      case_id,
    }).then((res) => {
      handleQueryRefetch();
      enqueueSnackbar("Creating Case Archive...", {
        variant: "info",
      });
    });
  };
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  useEffect(() => {
    if (records.every((item) => item.is_ready)) {
      setIsArchiving(false);
    } else setIsArchiving(true);
  }, [data, records]);

  // Persist column state to local storage
  useEffect(() => {
    let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState, stateStoreKey]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          margin: "5px 0px",
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!hasPermission()}
          onClick={(e) => handleCreateArchive()}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
        >
          + New Case Archive
        </Button>
        <ItemTotal total={totalRecords} Icon={FolderZipOutlinedIcon} />
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="multi-select"
            data={columnState.filter((c) => c.showInColumnChooser !== false)}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Clear Filters"}
            onClick={handleClearFilters}
          >
            <FilterListOffIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact ? (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            ) : (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <TextBox
            stylingMode="filled"
            placeholder="Search Archives"
            labelMode="static"
            height={30}
            style={{ marginLeft: "10px" }}
            onKeyUp={(e) => {
              let searchText = e.event.currentTarget.value;
              if (
                e.event.code === "Enter" ||
                e.event.code === "NumpadEnter" ||
                searchText === ""
              ) {
                setQuery((q) => ({
                  query: {
                    ...q?.query,
                    search: searchText === "" ? null : searchText,
                    page: 1,
                  },
                }));
              }
            }}
          />
        </div>
      </div>
      {data && (
        <>
          <Table
            data={records}
            totalRecords={totalRecords}
            tableInstance={table}
            keyValue="archive_id"
            columnProps={{ minWidth: 150, width: 150 }}
            onHeaderClick={(col) =>
              col?.sorting?.enabled === false ? null : handleSort(col.dataField)
            }
            onScroll={handleScroll}
            onColumnReorder={handleColumnReorder}
            onActionButtonClick={handleActionButtonClick}
            showActionColumn={false}
            focusedRow={flyoutData}
            showSelection={false}
          >
            {columnState.map((col) => {
              return <Column key={col.dataField} {...col} />;
            })}
          </Table>
        </>
      )}
      {!data && <Loader message={"Retrieving Items..."} />}
      <CreateCaseReportModal
        caseInfo={{ case_id }}
        open={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        onSubmit={handleQueryRefetch}
      />
    </>
  );
};

export default CaseArchives;
