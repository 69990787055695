import {
  httpDelete,
  httpFileDownload,
  httpGet,
  httpPatch,
  httpPost,
} from "../connection";
import download from "downloadjs";
import {
  createCocRecord,
  deleteCocRecord,
  getCocMoveReasons,
  getCocRecords,
  updateCocRecord,
} from "./coc";
import EvidencePhotoAPI, {
  deleteEvidencePhotos,
  getEvidencePhotos,
  uploadEvidencePhotos,
} from "./photos";
export * from "./coc";
export * from "./photos";

export async function getEvidence(options = {}) {
  return (await httpGet("/evidence/items", options)).data;
}

async function query(query = {}) {
  return await httpPost("/evidence/query", query);
}

async function exportEvidenceList(query = {}) {
  return await httpPost("/evidence/export", query);
}

export async function createEvidence(
  values,
  options = { is_parent: 1, parent_name: null, parent_id: null }
) {
  values.is_parent = options.is_parent;
  values.parent_name = options.parent_name;
  values.parent_id = options.parent_id;

  return await httpPost(`/evidence/items`, values);
}

export async function updateEvidence(id, values) {
  return await httpPatch(`/evidence/items/${id}`, values);
}

export async function deleteEvidence(options = { evidence_id: null }) {
  return await httpDelete(`/evidence/items/${options.evidence_id}`);
}

export async function createIntakeDetails(values = {}) {
  return await httpPost(`/evidence/intake-details`, values);
}

export async function getCustomEvidenceAttributes(
  options = { fieldsOnly: false }
) {
  return httpGet(`/evidence/attributes?fieldsOnly=${options.fieldsOnly}`);
}

export async function createAttributeField(
  values = { field_name: null, is_required: null, type_id: null }
) {
  return await httpPost(`/evidence/attributes/field`, values);
}

export async function updateAttributeField(id, values = {}) {
  return await httpPost(`/evidence/attributes/field/${id}`, values);
}

export async function deleteAttributeField(id) {
  return httpDelete(`/evidence/attributes/field/${id}`);
}

export async function moveEvidence(values = {}) {
  return await httpPatch(`/evidence/items/move`, values);
}

export async function moveEvidenceItems(values) {
  return await httpPatch(`/evidence/items/move-items`, values);
}

export async function getEvidenceTypes(values = { id: null }) {
  if (values.id == null) return await httpGet(`/evidence/types`);
  else return await httpGet(`/evidence/types/${values.id}`);
}

export async function createEvidenceType(values) {
  return await httpPost(`/evidence/types`, values);
}

export async function updateEvidenceType(evi_type_id, values = {}) {
  return await httpPatch(`/evidence/types/${evi_type_id}`, values);
}

export async function deleteEvidenceType(id, options = { replace: null }) {
  if (options.replace == null) return await httpDelete(`/evidence/types/${id}`);
  else if (options.replace != null)
    return await httpDelete(`/evidence/types/${id}?replace=${options.replace}`);
}

export async function getEvidenceSubmitters() {
  return await httpGet(`/evidence/submitters`);
}

export async function getEvidenceProgress() {
  return await httpGet(`/evidence/progress`);
}

export async function createEvidenceProgress(values) {
  return await httpPost(`/evidence/progress`, values);
}

export async function updateEvidenceProgress(progress_id, values = {}) {
  return await httpPatch(`/evidence/progress/${progress_id}`, values);
}

export async function reorderEvidenceProgress(items) {
  return await httpPatch(`/evidence/progress/reorder`, items);
}

export async function deleteEvidenceProgress(id, options = { replace: null }) {
  if (options.replace == null)
    return await httpDelete(`/evidence/progress/${id}`);
  else if (options.replace != null)
    return await httpDelete(
      `/evidence/progress/${id}?replace=${options.replace}`
    );
}

export async function getEvidenceBrands() {
  return await httpGet(`/evidence/brands`);
}

export async function getEvidenceMeta() {
  return await httpGet(`/evidence/meta`);
}

export async function getEvidenceFieldData(query) {
  return await httpGet(`/evidence/fieldData`, query);
}

export async function getEvidenceNumberFormat() {
  return await httpGet(`/evidence/format`);
}

export async function updateEvidenceNumberFormat(values) {
  return await httpPatch(`/evidence/format`, values);
}

export async function createEvidenceCocPDF(values) {
  const response = await httpPost(`/evidence/items/coc-report`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

export async function createEvidenceIntakePDF(values) {
  const response = await httpPost(`/evidence/items/intake-form`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

export async function createEvidenceMultiIntakePDF(values) {
  const response = await httpPost(`/evidence/items/multi-intake-form`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

export async function createEvidenceReleasePDF(values) {
  const response = await httpPost(`/evidence/items/release-form`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

export async function createEvidenceMultiReleasePDF(values) {
  const response = await httpPost(`/evidence/items/multi-release-form`, values);

  download(
    `data:application/pdf;base64,${response.data}`,
    response.filename,
    "application/pdf"
  );
}

async function migrateItems(values) {
  return await httpPost(`/evidence/migrate-items`, values);
}

const EvidenceAPI = {
  query,
  getEvidence,
  createEvidence,
  updateEvidence,
  deleteEvidence,
  exportEvidenceList,
  getEvidenceBrands,
  getEvidenceProgress,
  getEvidenceTypes,
  getCustomFields: getCustomEvidenceAttributes,
  getCocRecords: getCocRecords,
  getEvidencePhotos: getEvidencePhotos,
  uploadEvidencePhotos: uploadEvidencePhotos,
  getCocMoveReasons: getCocMoveReasons,
  moveEvidence: moveEvidence,
  createCocRecord: createCocRecord,
  createIntakeDetails: createIntakeDetails,
  getFieldData: getEvidenceFieldData,
  updateCocRecord: updateCocRecord,
  deleteCocRecord: deleteCocRecord,
  deleteEvidencePhoto: deleteEvidencePhotos,
  migrateItems,
  EvidencePhotos: EvidencePhotoAPI,
};

export default EvidenceAPI;
