import { useMemo, useRef } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import {
  getContacts,
  getEvidence,
  getSoftwareNames,
  getStorage,
  logActivity,
  userApi,
} from "../../../../api";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../../utils/date-format";
import AcquisitionsAPI, {
  updateAcquisition,
} from "../../../../api/acquisitions";
import {
  Form,
  SimpleItem,
  EmptyItem,
  GroupItem,
  RequiredRule,
} from "devextreme-react/ui/form";
import DataSource from "devextreme/data/data_source";
import ToolBarItems from "../../../../components/ToolBarItems";
import { Modal, useTheme } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import { Template } from "devextreme-react/core/template";
import EvidenceSelectBoxItem from "../../../../components/SelectBoxItems/EvidenceSelectBoxItem.js";
import StorageSelectBoxItem from "../../../../components/SelectBoxItems/StorageSelectBoxItem.js";
import UserSelectBoxItem from "../../../../components/SelectBoxItems/UserSelectBoxItem.js";
import ContactSelectBoxItem from "../../../../components/SelectBoxItems/ContactSelectBoxItem.js";
import Loader from "../../../../components/Loader.js";
import CollapseSection from "../../../../components/CollaspeSection";
import { DevexEditors } from "../../../../utils/devex-editors";

const EditAcquisitionPopup = ({
  caseInfo = null,
  evidenceInfo = null,
  onEdit = () => {},
  handleClose = () => {},
  open = false,
  defaultInfo = {},
}) => {
  const form = useRef(null);
  const advancedDetailsForm = useRef(null);
  const customFieldForm = useRef(null);
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    if (
      form.current.instance.validate().isValid &&
      (customFieldForm?.current?.instance?.validate().isValid || true) &&
      advancedDetailsForm.current.instance.validate().isValid
    ) {
      const formData = {
        ...form.current.instance.option().formData,
        ...(customFieldForm?.current?.instance?.option()?.formData || {}),
        ...advancedDetailsForm.current.instance.option().formData,
      };

      handleClose();

      let updateData = {};

      //Determine new values
      for (const key of Object.keys(formData)) {
        if (defaultInfo[key] !== formData[key]) {
          updateData[key] = formData[key];
        }
      }

      let customAttributes = [];

      for (let key of Object.keys(updateData)) {
        if (key.indexOf("custom_attribute_") !== -1) {
          let field_id = parseInt(key.replace("custom_attribute_", ""));
          let currentAttr = defaultInfo.custom_attributes.find((attribute) => {
            return attribute.field_id === field_id;
          });

          //evidence has attribute & it hasnt changed
          if (currentAttr && currentAttr.value === updateData[key]) {
            //do nothing
          } else {
            const baseAttr = fieldData.customFields.find(
              (attr) => attr.field_id === field_id
            );
            customAttributes.push({
              field_id: field_id,
              field_name: baseAttr.field_name,
              item_id: defaultInfo.acq_id,
              value: updateData[key],
            });
          }
          delete updateData[key];
        }
      }

      if (customAttributes?.length > 0)
        updateData.custom_attributes = customAttributes;

      //Set new Acquire date if applicable
      if (updateData.acquire_date)
        updateData.acquire_date = db_timestamp(updateData.acquire_date);

      if (Object.keys(updateData)?.length > 0) {
        await updateAcquisition(defaultInfo.acq_id, updateData);

        onEdit({ acq_id: defaultInfo.acq_id, ...updateData });

        enqueueSnackbar(`Acquisition Edited.`, {
          variant: "success",
        });

        logActivity(
          caseInfo.case_id,
          currentUser.user_id,
          `Acquisition Edited (Evidence: ${defaultInfo.evidence_number}) - ${defaultInfo.acq_name}`
        );
      } else {
        enqueueSnackbar(`No new data to update.`, {
          variant: "warning",
        });
      }
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const results = useQueries({
    queries: [
      {
        queryKey: [
          "storage:list",
          {
            case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
            active_only: true,
            include_general_storage: true,
          },
        ],
        queryFn: () =>
          getStorage({
            case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
            active_only: true,
            include_general_storage: true,
          }),
        enabled: open,
      },
      {
        queryKey: ["acquisition:software:list"],
        queryFn: () => getSoftwareNames(),
        enabled: open,
      },
      {
        queryKey: ["acquisitions:formats:list"],
        queryFn: () => AcquisitionsAPI.getFormats(),
        enabled: open,
      },
      {
        queryKey: ["acquisitions:type:list"],
        queryFn: () => AcquisitionsAPI.getTypes(),
        enabled: open,
      },
      {
        queryKey: [
          "people:contacts",
          { case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id },
        ],
        queryFn: () =>
          getContacts({
            case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
          }),
        enabled: open,
      },
      {
        queryKey: ["cases:leads"],
        queryFn: () =>
          userApi.getUsers({
            includeInactive: false,
            includeObservers: false,
          }),
        enabled: open,
      },
      !evidenceInfo && {
        queryKey: [
          "evidence:list",
          {
            case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
          },
        ],
        queryFn: () =>
          getEvidence({
            case_id: caseInfo ? caseInfo.case_id : evidenceInfo.case_id,
          }),
        enabled: open,
      },
      {
        // Fetch custom fields boxes
        queryKey: ["acquisitions:customFields:list"],
        queryFn: () =>
          AcquisitionsAPI.getCustomAttributes({ fieldsOnly: true }),
        enabled: open,
        select: (data) => data?.filter((c) => c.enabled === 1),
      },
      {
        // Fetch custom fields values
        queryKey: ["acquisitions:custom_fields:values"],
        queryFn: () => AcquisitionsAPI.getCustomAttributes(),
        enabled: open,
      },
    ],
  });

  const isDone = results.every((result) => result.isFetched);

  let mainDetailsData = {};
  let advancedDetailsData = {};
  // Createconvenience objects to access defaultInfo fields for main and advanced details
  if (defaultInfo) {
    mainDetailsData = {
      evidence_id: defaultInfo.evidence_id || null,
      acq_name: defaultInfo.acq_name || null,
      format: defaultInfo.format || null,
      acq_type: defaultInfo.acq_type || null,
      acq_tool: defaultInfo.acq_tool || null,
      size: defaultInfo.size || null,
      size_unit: defaultInfo.size_unit || null,
      tool_version: defaultInfo.tool_version || null,
      storage_id: defaultInfo.storage_id || null,
    };
    advancedDetailsData = {
      hash_1: defaultInfo.hash_1 || null,
      hash_1_type: defaultInfo.hash_1_type || null,
      hash_2: defaultInfo.hash_2 || null,
      hash_2_type: defaultInfo.hash_2_type || null,
      acquire_date: defaultInfo.acquire_date
        ? new Date(defaultInfo.acquire_date)
        : null,
      duration_hours: defaultInfo.duration_hours || null,
      duration_mins: defaultInfo.duration_mins || null,
      acquired_by_id: defaultInfo.acquired_by_id || null,
      person_id: defaultInfo.person_id || null,
      acq_notes: defaultInfo.acq_notes || null,
    };
  }

  // Convenience object to access query results
  const fieldData = {
    storage: results[0].data,
    software: results[1].data,
    formats: results[2].data,
    types: results[3].data,
    contacts: results[4].data,
    users: results[5].data,
    customFields: results[7].data,
  };

  const customFormData = {};

  defaultInfo?.custom_attributes?.forEach((attr) => {
    customFormData[`custom_attribute_${attr.field_id}`] =
      attr.editor_type === "tagBox"
        ? typeof attr.value === "object"
          ? attr.value
          : JSON.parse(attr.value)
        : attr.value;
  });

  if (!evidenceInfo) fieldData["evidence"] = results[6].data;

  const MainDetailsFormSection = useMemo(() => {
    return (
      <Form ref={form} colCount={2} defaultFormData={mainDetailsData}>
        {!evidenceInfo && (
          <SimpleItem
            dataField="evidence_id"
            label={{ text: "Evidence" }}
            isRequired={false}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: new DataSource({
                store: fieldData?.evidence || [],
                paginate: true,
                pageSize: 10,
              }),
              dropDownOptions: {
                maxHeight: 300,
              },
              hint: "Select an evidence item to acquire.",
              noDataText: "No evidence items are in this case.",
              searchEnabled: true,
              valueExpr: "evidence_id",
              displayExpr: "evidence_number",
              placeholder: "Select...",
              itemTemplate: "evidence_id",
            }}
          />
        )}
        <Template
          name="evidence_id"
          render={(data) => <EvidenceSelectBoxItem data={data} />}
        />
        {!evidenceInfo && <EmptyItem />}
        <SimpleItem
          dataField="acq_name"
          label={{ text: "Acquisition Name" }}
          isRequired={true}
        />
        <GroupItem colCount={2}>
          <SimpleItem
            dataField="format"
            label={{ text: "Format" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: fieldData?.formats || [],
              displayExpr: "format",
              valueExpr: "format",
              placeholder: "Select...",
              dropDownOptions: {
                height: "200px",
              },
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text !== "") {
                  e.customItem = { format: e.text };
                  e.component.getDataSource().store().insert(e.customItem);
                  e.component.getDataSource().reload();
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
          <SimpleItem
            dataField="acq_type"
            label={{ text: "Type" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: fieldData?.types || [],
              placeholder: "Select...",
              displayExpr: "type",
              valueExpr: "type",
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text !== "") {
                  e.customItem = { type: e.text };
                  e.component.getDataSource().store().insert(e.customItem);
                  e.component.getDataSource().reload();
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
        </GroupItem>
        <SimpleItem
          dataField="acq_tool"
          label={{ text: "Forensic Software" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: new DataSource({
              store: fieldData?.software || [],
              paginate: true,
              pageSize: 10,
            }),
            displayExpr: "software_name",
            valueExpr: "software_name",
            placeholder: "Select...",
            acceptCustomValue: true,
            onCustomItemCreating: (e) => {
              if (e.text != "") {
                e.customItem = { software_name: e.text };
                e.component.getDataSource().store().insert(e.customItem);
                e.component.getDataSource().reload();
              } else {
                e.customItem = null;
              }
            },
          }}
        />
        <SimpleItem
          dataField="tool_version"
          label={{ text: "Software Version" }}
        />
        <GroupItem colCount={2}>
          <SimpleItem
            dataField="size"
            label={{ text: "Size" }}
            editorType="dxNumberBox"
            editorOptions={{
              min: 0,
              max: 999,
              showSpinButtons: true,
            }}
          />
          <SimpleItem
            dataField="size_unit"
            label={{ text: "Unit" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: ["KB", "MB", "GB", "TB"],
            }}
          />
        </GroupItem>
        <SimpleItem
          dataField="storage_id"
          label={{ text: "Storage Item" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: new DataSource({
              store: fieldData?.storage || [],
              paginate: true,
              pageSize: 10,
              key: "storage_id",
              group: "is_general_storage",
              sort: [
                "Position",
                { selector: "is_general_storage", desc: true },
              ],
            }),
            hint: "Select storage items that this acquisition is stored on.",
            noDataText: "Assign storage to the case to choose a storage item.",
            searchEnabled: true,
            displayExpr: "storage_number",
            valueExpr: "storage_id",
            showDropDownButton: true,
            grouped: true,
            dropDownOptions: {
              height: "350px",
            },
            groupTemplate: (data) => {
              return `<div>${
                data.key === 1 ? "General Storage" : "Assigned Storage"
              }</div>`;
            },
            itemTemplate: "storage_id",
          }}
        />
        <Template
          name="storage_id"
          render={(data) => <StorageSelectBoxItem data={data} />}
        />
      </Form>
    );
  }, [isDone, defaultInfo]);

  const AdvDetailsFormSection = useMemo(() => {
    return (
      <Form
        ref={advancedDetailsForm}
        colCount={2}
        defaultFormData={advancedDetailsData}
      >
        <SimpleItem dataField="hash_1" label={{ text: "Hash 1" }} />
        <SimpleItem
          dataField="hash_1_type"
          label={{ text: "Algorithm" }}
          editorType="dxSelectBox"
          editorOptions={{
            items: ["MD5", "SHA1", "SHA256", "Checksum-64"],
            placeholder: "Select...",
          }}
        />
        <SimpleItem dataField="hash_2" label={{ text: "Hash 2" }} />
        <SimpleItem
          dataField="hash_2_type"
          label={{ text: "Algorithm" }}
          editorType="dxSelectBox"
          editorOptions={{
            items: ["MD5", "SHA1", "SHA256", "Checksum-64"],
            placeholder: "Select...",
          }}
        />
        <SimpleItem
          dataField="acquire_date"
          label={{ text: "Acquire Date" }}
          editorType="dxDateBox"
          editorOptions={{
            type: "datetime",
            useMaskBehavior: true,
            displayFormat: getDateFormat(false, true),
          }}
          validationRules={[
            {
              type: "custom",
              message: "Invalid Date",
              validationCallback: validateTimestamp,
            },
          ]}
        />
        <GroupItem colCount={2}>
          <SimpleItem
            dataField="duration_hours"
            label={{ text: "Duration Hours" }}
            editorType="dxNumberBox"
            editorOptions={{
              min: 0,
              showSpinButtons: true,
            }}
          />
          <SimpleItem
            dataField="duration_mins"
            label={{ text: "Duration Mins" }}
            editorType="dxNumberBox"
            editorOptions={{
              max: 59,
              min: 0,
              showSpinButtons: true,
            }}
          />
        </GroupItem>
        <SimpleItem
          dataField="acquired_by_id"
          label={{ text: "Acquired By" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: fieldData?.users || [],
            dropDownOptions: {
              maxHeight: 250,
            },
            placeholder: "Examimer name",
            displayExpr: "full_name",
            valueExpr: "user_id",
            searchEnabled: true,
            hint: "Select the examiner that acquired this data.",
            showClearButton: true,
            itemTemplate: "acquired_by_id",
          }}
        />
        <Template
          name="acquired_by_id"
          render={(data) => <UserSelectBoxItem data={data} />}
        />
        <SimpleItem
          dataField="person_id"
          label={{ text: "Linked Contact" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: fieldData?.contacts || [],
            placeholder: "Select Contact...",
            dropDownOptions: {
              maxHeight: 300,
            },
            displayExpr: "name",
            valueExpr: "client_id",
            searchEnabled: true,
            showClearButton: true,
            openOnFieldClick: false,
            onFocusIn: (e) => {
              e.component.open();
            },
            onValueChanged: (e) => {},
            itemTemplate: "person_id",
          }}
        />
        <Template
          name="person_id"
          render={(data) => <ContactSelectBoxItem data={data} />}
        />
        <SimpleItem
          dataField="acq_notes"
          label={{ text: "Notes" }}
          editorType="dxTextArea"
          colSpan={2}
          editorOptions={{ height: 125 }}
        />
      </Form>
    );
  }, [isDone, defaultInfo]);

  const CustomFieldFormSection = useMemo(() => {
    const defaultFormData = {};

    defaultInfo?.custom_attributes?.forEach((attr) => {
      defaultFormData[`custom_attribute_${attr.field_id}`] =
        attr.editor_type === "tagBox"
          ? typeof attr.value === "object"
            ? attr.value
            : JSON.parse(attr.value)
          : attr.value;
    });

    return (
      <Form
        ref={customFieldForm}
        colCount={2}
        style={{ padding: 10 }}
        defaultFormData={defaultFormData}
      >
        {fieldData?.customFields?.map((attribute) => (
          <SimpleItem
            key={`custom_attribute_${attribute.field_id}`}
            dataField={`custom_attribute_${attribute.field_id}`}
            editorType={DevexEditors[attribute.editor_type]}
            label={{ text: attribute.field_name }}
            editorOptions={{
              placeholder: attribute.description || "",
              hint: attribute.description || "",
              items: JSON.parse(attribute.options || "[]"),
              dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, false),
              showClearButton: attribute.editor_type !== "textBox",
              pickerType: "calendar",
              showDropDownButton: true,
              multiline: false,
              showSelectionControls:
                attribute.editor_type === "tagBox" ? true : false,
            }}
          >
            {attribute.is_required === 1 && <RequiredRule message="" />}
          </SimpleItem>
        ))}
      </Form>
    );
  }, [isDone, defaultInfo]);

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Acquisition
          </div>
          {!isDone && <Loader />}
          {isDone && (
            <>
              <CollapseSection title="Main Details" visible={true}>
                {MainDetailsFormSection}
              </CollapseSection>
              <CollapseSection title="Advanced Details">
                {AdvDetailsFormSection}
              </CollapseSection>
              {fieldData?.customFields?.length === 0 ||
              fieldData?.customFields === null ? (
                <></>
              ) : (
                <CollapseSection title="Custom Fields">
                  {CustomFieldFormSection}
                </CollapseSection>
              )}
              <div
                style={{
                  marginTop: 30,
                }}
              >
                <ToolBarItems
                  submitText="Edit Acquisition"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EditAcquisitionPopup;
