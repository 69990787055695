import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import QaChecklistAPI from "../../../api/quality_assurance/checklists.js";
import { Button } from "@monolith-forensics/monolith-ui";
import {
  ArrowLeftIcon,
  EditIcon,
  PlusIcon,
  Trash2Icon,
  TrashIcon,
} from "lucide-react";
import { useState } from "react";
import CreateQaChecklistGroupModal from "../../../components/Modals/CreateQaChecklistGroupModal.js";
import ButtonMenu from "../../../components/Forms/components/ButtonMenu.js";
import DeleteQaChecklistGroupModal from "../../../components/Modals/DeleteQaChecklistGroupModal.js";
import CreateQaChecklistItemModal from "../../../components/Modals/CreateQaChecklistItemModal.js";
import DeleteQaChecklistItemModal from "../../../components/Modals/DeleteQaChecklistItemModal.js";
import EditQaChecklistItemModal from "../../../components/Modals/EditQaChecklistItemModal.js";

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
`;

const ChecklistItem = styled(
  ({ className, data, onDeleteItem, onEditItem }) => {
    const theme = useTheme();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleEditQaChecklistItem = async (values) => {
      setShowEditModal(false);
      onEditItem?.();
    };

    const handleDeleteItem = async () => {
      setShowDeleteModal(false);
      onDeleteItem?.();
    };

    return (
      <div className={className}>
        <div>{data.item_name}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
            justifyContent: "end",
            fontWeight: 600,
            color: theme.palette.text.secondary,
            userSelect: "none",
            minWidth: 200,
          }}
        >
          <Button
            size="xxs"
            variant="text"
            color="primary"
            leftSection={<EditIcon size={12} />}
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            Edit
          </Button>
          <Button
            size="xxs"
            variant="text"
            color="error"
            leftSection={<Trash2Icon size={12} />}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            Delete
          </Button>
        </div>
        {showEditModal && (
          <EditQaChecklistItemModal
            show={showEditModal}
            onClose={() => setShowEditModal(false)}
            onCancel={() => setShowEditModal(false)}
            onSubmit={handleEditQaChecklistItem}
            defaultFormData={{
              checklist_id: data.checklist_id,
              item_name: data.item_name,
              group_id: data.group_id,
              item_id: data.item_id,
            }}
          />
        )}
        {showDeleteModal && (
          <DeleteQaChecklistItemModal
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            onSubmit={handleDeleteItem}
            defaultInfo={data}
          />
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const ChecklistGroup = styled(
  ({ className, data, onAddItem, onDeleteGroup, onDeleteItem, onEditItem }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeleteGroup = async () => {
      setShowDeleteModal(false);
      onDeleteGroup?.();
    };

    const handleCreateItem = async () => {
      setShowAddModal(false);
      onAddItem?.();
    };

    const items = data.checklist_items || [];

    return (
      <div className={className}>
        <div className="group-header">
          <div className="group-name">{data.group_name}</div>
          <ButtonMenu style={{ margin: 0, width: 250, gap: 20 }}>
            <Button
              size="xxs"
              variant="text"
              color="primary"
              leftSection={<PlusIcon size={14} />}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => setShowAddModal(true)}
            >
              Add Item
            </Button>
            <Button
              size="xxs"
              variant="text"
              color="error"
              leftSection={<TrashIcon size={14} />}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Group
            </Button>
          </ButtonMenu>
          {showDeleteModal && (
            <DeleteQaChecklistGroupModal
              show={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onCancel={() => setShowDeleteModal(false)}
              onSubmit={handleDeleteGroup}
              defaultInfo={data}
            />
          )}
          {showAddModal && (
            <CreateQaChecklistItemModal
              show={showAddModal}
              onClose={() => setShowAddModal(false)}
              onCancel={() => setShowAddModal(false)}
              onSubmit={handleCreateItem}
              defaultFormData={{
                group_id: data.group_id,
                checklist_id: data.checklist_id,
                item_name: null,
              }}
            />
          )}
        </div>

        {items.map((item) => {
          return (
            <ChecklistItem
              key={item.item_id}
              data={{ group_id: data.group_id, ...item }}
              onDeleteItem={onDeleteItem}
              onEditItem={onEditItem}
            />
          );
        })}
      </div>
    );
  }
)`
  .group-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
    margin-top: 10px;
    padding-right: 10px;
  }

  .group-name {
    font-size: 18px;
    font-weight: 600;
  }
`;

const QaCheckList = styled(({ className }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { checklist_id } = useParams();

  const { data: checklistItems, refetch } = useQuery({
    queryKey: ["qa-checklist-items", "list", { checklist_id }],
    queryFn: () =>
      QaChecklistAPI.ChecklistItems.getCheckListItems(checklist_id),
  });

  const { data: checklist } = useQuery({
    queryKey: ["qa-checklists", "list", { checklist_id }],
    queryFn: () => QaChecklistAPI.getQAChecklists({ checklist_id }),
  });

  const handleCreateQaChecklistGroup = async (values) => {
    setShowAddModal(false);
    refetch();
  };

  return (
    <div className={className}>
      <div>
        <Link to={"/settings/qa-checklists"} style={{ width: "fit-content" }}>
          <Button
            size="xxs"
            variant="text"
            color="gray"
            leftSection={<ArrowLeftIcon size={14} />}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            QA Checklists
          </Button>
        </Link>
        {checklist && (
          <Title>QA Checklist - {checklist[0].checklist_name}</Title>
        )}
      </div>
      <div>
        <Button
          size="xxs"
          variant="contained"
          color="primary"
          onClick={() => setShowAddModal(true)}
        >
          + Add Checklist Group
        </Button>
      </div>
      {showAddModal && (
        <CreateQaChecklistGroupModal
          show={showAddModal}
          defaultFormData={{
            checklist_id: parseInt(checklist_id, 10),
            group_name: null,
          }}
          onClose={() => setShowAddModal(false)}
          onCancel={() => setShowAddModal(false)}
          onSubmit={handleCreateQaChecklistGroup}
        />
      )}
      {checklistItems &&
        checklistItems.map((item) => {
          return (
            <ChecklistGroup
              key={item.group_id}
              data={item}
              onAddItem={() => refetch()}
              onDeleteGroup={() => refetch()}
              onDeleteItem={() => refetch()}
              onEditItem={() => refetch()}
            />
          );
        })}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  padding-right: 10px;
  overflow-y: auto;

  max-width: 100%;
`;

export default QaCheckList;
