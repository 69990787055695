import { formatCurrency } from "../../utils/currency-formatter.js";
import { getDateFormat } from "../../utils/date-format.js";

const ForensicEquipmentColumnDefs = [
  {
    dataField: "asset_number",
    caption: "Asset Number",
  },
  {
    dataField: "barcode",
    caption: "Barcode",
    visible: false,
  },
  {
    dataField: "vendor",
    caption: "Vendor",
  },
  {
    dataField: "name",
    caption: "Name",
  },
  {
    dataField: "category",
    caption: "Category",
  },
  {
    dataField: "model",
    caption: "Model",
    visible: false,
  },
  {
    dataField: "serial_number",
    caption: "Serial Number",
  },
  {
    dataField: "purchase_date",
    caption: "Purchase Date",
    dataType: "date",
    format: {
      type: getDateFormat(true, false),
    },
  },
  {
    dataField: "cost",
    caption: "Cost",
    render: (data) => <div>{formatCurrency(data.cost)}</div>,
  },
  {
    dataField: "location",
    caption: "Location",
  },
  {
    dataField: "description",
    caption: "Description",
    titleRender: (data) => data.description,
    visible: false,
  },
];

export default ForensicEquipmentColumnDefs;
