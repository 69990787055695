import styled, { useTheme } from "styled-components";
import { useRef, useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import { Button, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  EmptyItem,
  Form,
  GroupItem,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/ui/form.js";
import { usePermissions } from "../../../hooks/usePermissions";
import CaseStorageAPI from "../../../api/storage/index.js";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../utils/date-format.js";

import Loader from "../../../components/Loader.js";
import CollapseSection from "../../../components/CollaspeSection.js";
import DataSource from "devextreme/data/data_source";
import { Template } from "devextreme-react/core/template.js";
import LocationSelectBoxItem from "../../../components/SelectBoxItems/LocationSelectBoxItem.js";
import SignatureField from "../../../components/SignatureField/SignatureField.js";
import { getIpAddress } from "../../../api/index.js";
import moment from "moment/moment.js";
import LocationsAPI from "../../../api/locations/index.js";
import { DevexEditors } from "../../../utils/devex-editors.js";

const CreateStoragePopup = styled(
  ({
    className,
    onSubmit = () => {},
    open,
    handleClose,
    caseInfo = null,
    defaultInfo = {},
  }) => {
    const form = useRef(null);
    const advancedDetailsForm = useRef(null);
    const customFieldsForm = useRef(null);
    const assignFormSection = useRef(null);

    const assignedBySig = useRef(null);

    const theme = useTheme();
    const { currentUser } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();

    const result = useQueries({
      queries: [
        {
          queryKey: ["storage:types:list"],
          queryFn: () => CaseStorageAPI.getTypes(),
          enabled: open,
        },
        {
          queryKey: ["storage:providers:list"],
          queryFn: () => CaseStorageAPI.getProviders(),
          enabled: open,
        },
        {
          queryKey: ["storage:models:list"],
          queryFn: () => CaseStorageAPI.getStorageModels(),
          enabled: open,
        },
        {
          queryKey: ["storage:model-numbers:list"],
          queryFn: () => CaseStorageAPI.getStorageModelNumbers(),
          enabled: open,
        },
        {
          queryKey: ["evidence:locations", { include_groups: false }],
          queryFn: (params) =>
            LocationsAPI.getLocations({}, { include_groups: false }),
          enabled: open,
        },
        {
          queryKey: ["storage:custom-fields:list"],
          queryFn: () =>
            CaseStorageAPI.getStorageCustomAttributes({ fieldsOnly: true }),
          enabled: open,
          select: (data) => {
            return data.filter((c) => c.enabled === 1);
          },
        },
      ],
    });

    // check if all queries are done
    const isDone = result.every((x) => x.isSuccess);

    const fieldData = useMemo(
      () => ({
        types: result[0].data,
        providers: result[1].data,
        model_names: result[2].data,
        model_numbers: result[3].data,
        locations: result[4].data,
        customFields: result[5].data,
      }),
      [result]
    );
    const handleSubmit = async (event) => {
      if (
        form.current.instance.validate().isValid &&
        advancedDetailsForm.current.instance.validate().isValid &&
        (customFieldsForm?.current
          ? customFieldsForm?.current?.instance?.validate()?.isValid
          : true) &&
        (caseInfo?.case_id
          ? assignFormSection.current.instance.validate().isValid
          : true)
      ) {
        let data = {
          ...form.current.instance.option().formData,
          ...advancedDetailsForm.current.instance.option().formData,
          ...(customFieldsForm?.current?.instance?.option()?.formData || {}),
        };

        // If assigned to case, add assign form data
        if (caseInfo?.case_id)
          data = {
            ...data,
            ...assignFormSection.current.instance.option().formData,
          };

        const ipAddress = await getIpAddress();

        const signature = {};

        signature.custody_from_sig = assignedBySig?.current?.isEmpty()
          ? null
          : {
              signature: assignedBySig?.current?.getSignature(),
              signer: currentUser.full_name,
              timestamp: moment().toISOString(),
              userAgent: window.navigator.userAgent,
              ip_address: ipAddress,
              monolith_user: {
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                full_name: currentUser.full_name,
                email: currentUser.email,
                title: currentUser.title,
                user_id: currentUser.user_id,
              },
            };

        data.signature = JSON.stringify(signature);

        data.date_added = db_timestamp();
        data.case_id = caseInfo?.case_id;
        data.entered_by = currentUser.full_name;
        data.entered_by_id = currentUser.user_id;
        data.custody_from = "Storage Cache";
        data.reason = data.reason || "Storage item assigned to case.";
        data.timestamp = db_timestamp();

        let customAttributes = [];

        // parse custom attribute field_id from form field
        for (let key of Object.keys(data)) {
          if (key.indexOf("custom_attribute_") !== -1) {
            customAttributes.push({
              field_id: parseInt(key.replace("custom_attribute_", "")),
              value: data[key],
            });

            delete data[key];
          }
        }
        if (customAttributes.length > 0)
          data.custom_attributes = customAttributes;

        CaseStorageAPI.createStorage(data).then((result) => {
          result.showView = true;
          onSubmit(result);
          enqueueSnackbar(`Storage Item Created.`, {
            variant: "success",
          });

          if (caseInfo?.case_id) {
            enqueueSnackbar(`Storage Item Assigned to Case.`, {
              variant: "success",
            });
          }
        });

        handleClose();
      }
    };

    const StorageDetailsFormSection = useMemo(() => {
      const defaultFormData = {
        type: defaultInfo.type || "",
        capacity: defaultInfo.capacity || 0,
        capacity_unit: defaultInfo.capacity_unit || "GB",
      };

      return (
        <Form ref={form} colCount={2} defaultFormData={defaultFormData}>
          <SimpleItem
            dataField="storage_number"
            label={{ text: "Storage Number" }}
            editorOptions={{
              hint: "Enter a unique ID that identifies this storage item.",
              placeholder: "Leave blank to auto-generate storage number",
            }}
          />
          <SimpleItem
            dataField="type"
            label={{ text: "Type" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: fieldData.types,
              placeholder: "Select or enter storage type...",
              dropDownOptions: {
                maxHeight: 250,
              },
              displayExpr: "type",
              valueExpr: "type",
              searchEnabled: true,
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text != "") {
                  e.customItem = { type: e.text };
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
          <SimpleItem
            dataField="serial_number"
            label={{ text: "Serial Number" }}
          />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="capacity"
              label={{ text: "Size" }}
              editorType="dxNumberBox"
              editorOptions={{
                min: 0,
                max: 999,
                showSpinButtons: true,
                value: 0,
              }}
            />
            <SimpleItem
              dataField="capacity_unit"
              label={{ text: "Unit" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: ["KB", "MB", "GB", "TB"],
                value: "GB",
              }}
            />
          </GroupItem>
        </Form>
      );
    }, [fieldData]);

    const AdvancedStorageDetailsFormSection = useMemo(() => {
      const defaultFormData = {
        model_name: defaultInfo.model_name || "",
        model_number: defaultInfo.model_number || "",
        make: defaultInfo.make || "",
        encryption_key: defaultInfo.encryption_key || "",
        is_general_storage: defaultInfo.is_general_storage || 0,
        notes: defaultInfo.notes || "",
      };

      return (
        <Form
          ref={advancedDetailsForm}
          colCount={2}
          defaultFormData={defaultFormData}
        >
          <SimpleItem
            dataField="model_name"
            label={{ text: "Model Name" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: fieldData.model_names,
              placeholder: "Select or enter model name...",
              dropDownOptions: {
                maxHeight: 250,
              },
              displayExpr: "model_name",
              valueExpr: "model_name",
              searchEnabled: true,
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text != "") {
                  e.customItem = { model_name: e.text };
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
          <SimpleItem
            dataField="model_number"
            label={{ text: "Model Number" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: fieldData.model_numbers,
              placeholder: "Select or enter model number...",
              dropDownOptions: {
                maxHeight: 250,
              },
              displayExpr: "model_number",
              valueExpr: "model_number",
              searchEnabled: true,
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text !== "") {
                  e.customItem = { model_number: e.text };
                } else {
                  e.customItem = null;
                }
              },
            }}
          />

          <SimpleItem
            dataField="make"
            label={{ text: "Brand" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: fieldData.providers,
              placeholder: "Select or enter storage manufacturer...",
              dropDownOptions: {
                maxHeight: 250,
              },
              displayExpr: "make",
              valueExpr: "make",
              searchEnabled: true,
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text != "") {
                  e.customItem = { make: e.text };
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
          <SimpleItem
            dataField="encryption_key"
            label={{ text: "Password/Encryption Key" }}
            editorOptions={{
              type: "normal",
              mode: "password",
              inputAttr: { autoComplete: "new-password" },
              buttons: [
                {
                  name: "password",
                  location: "after",
                  options: {
                    icon: `${process.env.PUBLIC_URL}/static/img/eye.png`,
                    type: "default",
                    onClick() {
                      const passwordEditor =
                        advancedDetailsForm.current.instance.getEditor(
                          "encryption_key"
                        );
                      passwordEditor.option(
                        "mode",
                        passwordEditor.option("mode") === "text"
                          ? "password"
                          : "text"
                      );
                    },
                  },
                },
              ],
            }}
          />
          <SimpleItem
            dataField="is_general_storage"
            label={{ text: "General Storage" }}
            editorType="dxSelectBox"
            isRequired={true}
            visible={!caseInfo?.case_id}
            editorOptions={{
              items: [
                { value: 1, text: "Yes" },
                { value: 0, text: "No" },
              ],
              hint: "Is this storage item for general use or will it be assigned to a case?",
              displayExpr: "text",
              valueExpr: "value",
              value: 0,
            }}
          />
          {!caseInfo?.case_id ? <EmptyItem /> : null}
          <SimpleItem
            dataField="notes"
            label={{ text: "Description" }}
            colSpan={2}
            editorType="dxTextArea"
            editorOptions={{
              height: 135,
              placeholder:
                "Enter a brief description of this item and any important details.",
            }}
          />
        </Form>
      );
    }, [fieldData]);

    const AssignFormSection = useMemo(() => {
      return (
        <Form ref={assignFormSection} colCount={2}>
          <SimpleItem
            dataField="location_id"
            label={{ text: "Initial Location" }}
            editorType="dxSelectBox"
            editorOptions={{
              hint: "Select the new location for this storage item.",
              searchEnabled: true,
              dataSource: new DataSource({
                store: fieldData.locations,
                key: "location_id",
                group: "office_name",
              }),
              displayExpr: "location_name",
              valueExpr: "location_id",
              grouped: true,
              dropDownOptions: {
                height: "350px",
              },
              itemTemplate: "location_id",
            }}
          />
          <Template
            name="location_id"
            render={(data) => <LocationSelectBoxItem data={data} />}
          />
          <SimpleItem
            dataField="timestamp"
            label={{ text: "Timestamp" }}
            editorType="dxDateBox"
            editorOptions={{
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, true),
              value: new Date(),
            }}
            validationRules={[
              {
                type: "custom",
                message: "Invalid Date",
                validationCallback: validateTimestamp,
              },
            ]}
          />
          <SimpleItem
            dataField={"reason"}
            label={{ text: "Assign Notes" }}
            editorType="dxTextArea"
          />
          <SimpleItem
            dataField="custody_from_sig"
            label={{ text: "Assigned By Signature" }}
          >
            <SignatureField
              sigFieldRef={assignedBySig}
              title="Assigned By Signature"
            />
          </SimpleItem>
        </Form>
      );
    }, [fieldData]);

    const CustomFieldSection = useMemo(() => {
      let defaultFormData = {};
      if (defaultInfo.custom_attributes?.length > 0) {
        defaultFormData = defaultInfo.custom_attributes.reduce(
          (acc, cur) => ({
            ...acc,
            [`custom_attribute_${cur.field_id}`]:
              cur.editor_type === "tagBox"
                ? JSON.parse(cur.value || "[]")
                : cur.value,
          }),
          {}
        );
      }

      return (
        <Form
          ref={customFieldsForm}
          colCount={2}
          style={{ padding: 10 }}
          defaultFormData={defaultFormData}
        >
          {fieldData?.customFields?.map((attribute) => (
            <SimpleItem
              key={`custom_attribute_${attribute.field_id}`}
              dataField={`custom_attribute_${attribute.field_id}`}
              editorType={DevexEditors[attribute.editor_type]}
              label={{ text: attribute.field_name }}
              editorOptions={{
                placeholder: attribute.description || "",
                hint: attribute.description || "",
                items: JSON.parse(attribute.options || "[]"),
                dateSerializationFormat: "yyyy-MM-dd",
                useMaskBehavior: true,
                displayFormat: getDateFormat(false, false),
                showClearButton: attribute.editor_type !== "textBox",
                pickerType: "calendar",
                showDropDownButton: true,
                multiline: false,
                showSelectionControls:
                  attribute.editor_type === "tagBox" ? true : false,
              }}
            >
              {attribute.is_required === 1 && <RequiredRule message="" />}
            </SimpleItem>
          ))}
        </Form>
      );
    }, [fieldData]);

    return (
      <>
        <Modal
          className={className}
          open={open}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") handleClose();
          }}
          style={{ zIndex: 1400 }}
        >
          <div
            className="modal-container"
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: 700,
              backgroundColor: theme.palette.background.default,
              position: "fixed",
              left: "calc(50% - 350px)",
              maxHeight: "calc(100% - 40px)",
              overflowY: "auto",
              padding: 20,
              outline: "none",
            }}
          >
            <div style={{ marginBottom: 15, fontSize: "large" }}>
              +Create {caseInfo?.case_id ? "and Assign" : ""} Storage Item
            </div>
            {!isDone && <Loader />}
            {isDone && (
              <>
                <CollapseSection title="Storage Details" visible={true}>
                  {StorageDetailsFormSection}
                </CollapseSection>
                <CollapseSection title="Advanced Details">
                  {AdvancedStorageDetailsFormSection}
                </CollapseSection>
                {fieldData?.customFields.length === 0 ? (
                  <></>
                ) : (
                  <CollapseSection title="Custom Fields">
                    {CustomFieldSection}
                  </CollapseSection>
                )}
                {caseInfo?.case_id && (
                  <CollapseSection title="Initial Chain of Custody">
                    {AssignFormSection}
                  </CollapseSection>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: 5,
                    marginTop: 30,
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    style={{ marginRight: 10 }}
                    onClick={(e) => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Create Storage
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </>
    );
  }
)`
  // full screen modal on small devices
  @media (max-width: 900px) {
    .modal-container {
      width: 100vw !important;
      height: 100vh !important;
      max-height: 100vh !important;
      margin: 0px !important;
      left: 0% !important;
      top: 0% !important;
      transform: translate(0%, 0%) !important;
    }
  }
  .dx-widget.dx-button {
    border-radius: 0px !important;
    margin: 0px !important;
    border: 1px solid ${({ theme }) => theme.palette.divider} !important;
    background-color: ${({ theme }) =>
      theme.palette.background.default} !important;
    transition: border 0.2s ease-in-out !important;
    :hover {
      border: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
    }
  }
`;

export default CreateStoragePopup;
