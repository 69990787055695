import { useParams } from "react-router-dom";
import styled from "styled-components";
import { usePermissions } from "../../hooks/usePermissions";
import { QueryKey, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import TaskBoard from "../../components/TaskBoard/TaskBoard.js";
import AddTaskModal from "../../components/TaskBoard/components/AddTaskModal.js";
import UserApi from "../../api/users/users.js";
import shortUUID from "short-uuid";
import TaskStatuses from "../../components/TaskBoard/components/TaskStatuses.js";
import TaskPriorities from "../../components/TaskBoard/components/TaskPriorities.js";
import moment from "moment";
import TaskViewer from "../../components/TaskBoard/components/TaskViewer.js";
import IndexedDBService from "../../IndexedDB/IndexedDBService.js";
import { LucideIcon } from "lucide-react";
import { User } from "../Users/types/Users";
import { Task, TaskState } from "./types/Tasks";

interface DefaultTaskFormData {
  icon: LucideIcon;
  iconColor: "string";
  id: number;
  label: string;
  taskValue: string;
  type: string;
  tasks: Task[];
}

interface TaskQueryData {
  data: Task[];
}

interface TaskBoardRef {
  taskQueryKey: QueryKey;
  reload: () => void;
  addTask: () => void;
  getTasks: () => any;
}

const Tasks = styled(({ className }) => {
  const { item_id } = useParams();
  const { currentUser, hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const queryClient = useQueryClient();
  const taskBoardInstance = useRef<TaskBoardRef>(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [defaultFormData, setDefaultFormData] = useState({});
  const [displayPreferences, setDisplayPreferences] = useState({
    showLinkedObject: true,
    showDueDate: true,
    showStatus: true,
    showPriority: true,
    showCategory: true,
    showUsers: true,
    showSubTaskCount: true,
    showSubTasks: true,
    showDuration: true,
    showAssignees: true,
  });

  const query = {
    include_archived: false,
    user_id: currentUser.user_id,
  };

  const { data: caseUsers } = useQuery<User[]>({
    queryKey: [
      "users",
      "list",
      { include_observers: false, include_inactive: false },
    ],
    queryFn: () =>
      UserApi.getUsers({
        include_observers: false,
        include_inactive: false,
      }),
  });

  const { data: savedTaskState, isFetching } = useQuery({
    queryKey: ["tasks", "state", "tasks:dashboard"],
    queryFn: async () =>
      IndexedDBService.TaskState.getTaskState({
        uuid: "tasks:dashboard",
      }),
  });

  const handleAddItem = useCallback(
    (data: DefaultTaskFormData) => {
      setDefaultFormData({
        task_name: null,
        description: null,
        uuid: shortUUID.generate(),
        priority_id: data?.taskValue === "priority" ? data.id : 2,
        status_id: data?.taskValue === "status" ? data.id : 4,
        sort_value:
          data?.tasks?.[0]?.sort_value !== undefined
            ? data.tasks[0].sort_value - 1
            : Math.random() * 1000000,
      });
      setShowAddModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const onCreateTask = useCallback(
    (newTaskData: Task) => {
      if (newTaskData.sub_tasks && newTaskData.sub_tasks.length > 0) {
        taskBoardInstance.current?.reload();
        return;
      }
      queryClient.setQueryData<TaskQueryData>(
        taskBoardInstance.current!.taskQueryKey,
        (oldData) => {
          const prevTasks = [...oldData!.data];
          const newTask = {
            due_date: null,
            status_id: null,
            priority_id: null,
            task_name: null,
            description: null,
            object_id: null,
            object_type: null,
            uuid: null,
            parent_id: null,
            sort_value: null,
            subtasks: [],
            task_id: null,
            time_category: null,
            time_category_id: null,
            ...newTaskData, // override defaults listed above - these are values that may potentailly come from task creation
            assignees:
              caseUsers?.filter((user) =>
                newTaskData.assignees?.some(
                  (assignee) => assignee.user_id === user.user_id
                )
              ) || [],
            complete_date: null,
            completed_by: null,
            completed_by_id: null,
            created_by: {
              full_name: currentUser.full_name,
              user_id: 1,
              email: currentUser.email,
            },
            created_by_id: currentUser.user_id,
            created_on: moment().format("YYYY-MM-DD HH:mm:ss"),
            duration: null,
            is_complete: 0,
            status_name: TaskStatuses.find(
              (status) => status.status_id === newTaskData.status_id
            )?.status_name,
            priority_name: TaskPriorities.find(
              (priority) => priority.priority_id === newTaskData.priority_id
            )?.priority_name,
          };

          prevTasks.push(newTask as Task);
          return {
            ...oldData,
            data: prevTasks,
          };
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [caseUsers]
  );

  const handleTaskStateChange = useCallback(
    ({ key, state }: { key: string; state: TaskState }) => {
      queryClient.setQueryData(["tasks", "state", key], state);

      IndexedDBService.TaskState.putTaskState(key, state);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={className}>
      {useMemo(() => {
        if (isFetching) {
          return null;
        }
        return (
          <>
            {item_id ? (
              <TaskViewer
                task={{
                  uuid: item_id,
                }}
                itemHref={(task: Task) => `/dashboard/tasks/v/${task.uuid}`}
              />
            ) : (
              <TaskBoard
                taskBoardInstance={taskBoardInstance}
                displayPreferences={displayPreferences}
                setDisplayPreferences={setDisplayPreferences}
                handleAddItem={handleAddItem}
                taskQuery={query}
                defaultTaskState={{
                  ...savedTaskState,
                  allTasks: hasPermission(MONOLITH_PERMISSIONS.CASES_READ_ALL)
                    ? savedTaskState?.allTasks
                    : false,
                }} // Ensure that non admin users cannot load all tasks
                onTaskStateChange={handleTaskStateChange}
                showHeader
                itemHref={(task: Task) => `/dashboard/tasks/v/${task.uuid}`}
              />
            )}
          </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [displayPreferences, setDisplayPreferences, item_id, isFetching])}
      {showAddModal && (
        <AddTaskModal
          open={showAddModal}
          onClose={() => setShowAddModal(false)}
          defaultFormData={defaultFormData}
          onSubmit={onCreateTask}
        />
      )}
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  h1 {
    margin: 0;
    padding: 0;
  }
`;

export default Tasks;
