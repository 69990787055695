import styled from "styled-components";
import EvidenceAPI from "../../api/evidence/index.js";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Modal, useTheme } from "@mui/material";
import ToolBarItems from "../../components/ToolBarItems.js";
import { useRef, useState } from "react";
import { usePermissions } from "../../hooks/usePermissions";
import { useSnackbar } from "notistack";
import Loader from "../../components/Loader.js";
import ContactSelectBoxItem from "../../components/SelectBoxItems/ContactSelectBoxItem.js";
import { Template } from "devextreme-react/core/template";
import { Form, SimpleItem, GroupItem } from "devextreme-react/ui/form";
import ContactsAPI from "../../api/contacts/index.js";
import { DevexEditors } from "../../utils/devex-editors.js";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../utils/date-format.js";
import { getIpAddress, logActivity } from "../../api/index.js";
import moment from "moment";
import CollapseSection from "../../components/CollaspeSection.js";
import LocationsAPI from "../../api/locations/index.js";
import LocationSelectBoxItem from "../../components/SelectBoxItems/LocationSelectBoxItem.js";
import SignatureField from "../../components/SignatureField/SignatureField.js";
import DataSource from "devextreme/data/data_source";

const ChildItemsContent = styled(({ className, evidenceInfo }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery({
    queryKey: [
      "evidence:list",
      { uuid: evidenceInfo.uuid, include_children: true },
    ],
    queryFn: () =>
      EvidenceAPI.getEvidence({
        uuid: evidenceInfo.uuid,
        include_children: true,
      }),
    select: (data) => data[0],
  });

  const onCreate = () => {
    queryClient.refetchQueries({
      queryKey: [
        "evidence:list",
        { uuid: evidenceInfo.uuid, include_children: true },
      ],
    });
  };

  const childItems = data?.child_list || [];

  return (
    <div className={className}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setShowCreateModal(true)}
        disabled={!hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_CREATE)}
        style={{
          minWidth: "fit-content",
          fontSize: 11,
          padding: "3px 6px",
        }}
      >
        + New Child Item
      </Button>
      <div className="child-items-grid">
        {childItems.length === 0 && (
          <div style={{ marginTop: 10, marginBottom: 20 }}>
            No child evidence items.
          </div>
        )}
        {childItems.map((item) => {
          return <ChildItem key={item.uuid} item={item} />;
        })}
      </div>
      <CreateChildModal
        open={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        onSubmit={onCreate}
        defaultInfo={evidenceInfo}
      />
    </div>
  );
})`
  .child-items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
  }
`;

const ChildItem = styled(({ className, item }) => {
  return (
    <div className={className}>
      <Link to={`/evidence/${item.uuid}/overview`}>
        <div className="evidence-number">{item.evidence_number}</div>
      </Link>

      <div className="item-type-label">{item.item_type}</div>
      <div className="provider-label">
        <div>{item.manufacturer || "No Provider"}</div>
        <div>{item.serial_number || "No Unique ID"}</div>
      </div>
    </div>
  );
})`
  min-height: 100px;
  width: 300px;
  max-width: 300px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  a {
    width: fit-content;
  }
  .evidence-number {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.palette.primary.main};
    width: fit-content;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .item-type-label {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .provider-label {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.secondary};
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
`;

const CreateChildModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const intakeForm = useRef(null);
  const customFieldForm = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const receivedBySig = useRef(null);

  const [itemType, setItemType] = useState(null);

  const result = useQueries({
    queries: [
      {
        queryKey: ["evidence:types"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
        enabled: open,
      },
      {
        queryKey: ["evidence:providers"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
        enabled: open,
      },
      {
        queryKey: ["contacts:list", { case_id: defaultInfo.case_id }],
        queryFn: () =>
          ContactsAPI.getContacts({ case_id: defaultInfo.case_id }),
        enabled: open,
      },
      {
        queryKey: ["evidence:customFields", { fieldsOnly: true }],
        queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
        enabled: open,
      },
      {
        queryKey: ["evidence:locations", { include_groups: false }],
        queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
        enabled: open,
      },
    ],
  });

  const handleSubmit = async (event) => {
    if (
      form.current.instance.validate().isValid &&
      customFieldForm.current.instance.validate().isValid &&
      validateIntakeFields()
    ) {
      const formData = Object.assign(
        {},
        {
          ...form.current.instance.option("formData"),
          ...customFieldForm.current.instance.option("formData"),
          ...intakeForm.current.instance.option("formData"),
        }
      );

      formData.case_id = defaultInfo.case_id;

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = receivedBySig.current.isEmpty()
        ? null
        : {
            signature: receivedBySig.current.getSignature(),
            signer: formData.custody_to.location_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      handleClose();

      //Get custom attributes from formData object
      //Compile them into a single object array with related attrubite values
      //remove original form items from form data
      let customAttributes = [];

      for (let key of Object.keys(formData)) {
        if (key.indexOf("custom_attribute_") !== -1) {
          customAttributes.push({
            field_id: parseInt(key.replace("custom_attribute_", "")),
            value: formData[key],
          });

          delete formData[key];
        }
      }

      if (customAttributes.length > 0)
        formData.custom_attributes = customAttributes;

      //Convert intake time to UTC
      if (formData.coc_intake_timestamp) {
        formData.coc_intake_timestamp = db_timestamp(
          formData.coc_intake_timestamp,
          true
        );

        formData.signature = JSON.stringify(signature);
        formData.custody_from = defaultInfo.evidence_number;
        formData.custody_to = formData.custody_to.location_id;
        formData.coc_type = "Intake";
        formData.entered_by = currentUser.full_name;
        formData.reason = formData.reason || "Evidence removed from parent";
      }

      if (formData.contact) {
        formData.contact_id = formData.contact.client_id;
      }

      EvidenceAPI.createEvidence(formData, {
        is_parent: 0,
        parent_id: defaultInfo.evidence_id,
        parent_name: defaultInfo.evidence_number,
      }).then((result) => {
        enqueueSnackbar(
          `Created Child Evidence Item ${result.evidence_number}`,
          {
            variant: "success",
          }
        );

        logActivity(
          result.case_id,
          currentUser.user_id,
          `Created Child Evidence Item: ${result.evidence_number}`
        );

        onSubmit(result);
      });
    } else {
      enqueueSnackbar(`Please complete all required fields.`, {
        variant: "error",
      });
    }
  };

  const validateIntakeFields = () => {
    let isValid = true;
    const formData = { ...intakeForm.current.instance.option("formData") };

    // Check if any values exist in the form
    let hasValues = Object.keys(formData).some((key) => {
      return formData[key] && formData[key] !== "";
    });

    if (hasValues) {
      // check that all required fields are filled out

      // custody_to
      if (!formData.custody_to) {
        isValid = false;
        intakeForm.current.instance
          .getEditor("custody_to")
          .option("isValid", false);
      } else {
        intakeForm.current.instance
          .getEditor("custody_to")
          .option("isValid", true);
      }

      // timestamp
      if (!formData.coc_intake_timestamp) {
        isValid = false;
        intakeForm.current.instance
          .getEditor("coc_intake_timestamp")
          .option("isValid", false);
      } else {
        intakeForm.current.instance
          .getEditor("coc_intake_timestamp")
          .option("isValid", true);
      }
    }

    return isValid;
  };

  const isDone = result.every((q) => q.isFetched);

  const types = result[0]?.data || [];
  const brands = result[1]?.data || [];
  const contacts = result[2]?.data || [];
  const customFields = result[3]?.data || [];
  const locations = result[4]?.data || [];

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 700,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 350px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Create Child Item
        </div>
        <div style={{ maxWidth: 800, margin: "auto" }}>
          {isDone ? (
            <>
              <CollapseSection title="Child Evidence Details" visible={true}>
                <Form ref={form} colCount={2}>
                  <SimpleItem
                    dataField="evidence_number"
                    label={{ text: "Evidence Number" }}
                    editorOptions={{
                      hint: "Enter a unique ID that identifies this evidence.",
                      placeholder:
                        "Leave blank to auto-generate evidence number",
                    }}
                  />
                  <SimpleItem
                    dataField="item_type"
                    label={{ text: "Evidence Type" }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      dataSource: types,
                      placeholder: "Select Evidence Type...",
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                      displayExpr: "evi_type",
                      valueExpr: "evi_type",
                      searchEnabled: true,
                      onItemClick: (e) => {
                        setTimeout(() => {
                          setItemType(e.itemData.evi_type_id);
                        }, 25);
                      },
                    }}
                  />
                  <SimpleItem
                    dataField="manufacturer"
                    label={{ text: "Item Brand" }}
                    editorType="dxAutocomplete"
                    editorOptions={{
                      dataSource: brands,
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                      placeholder: "Apple, Google, Samsung...",
                      displayExpr: "manufacturer",
                      valueExpr: "manufacturer",
                      searchEnabled: true,
                      acceptCustomValue: true,
                    }}
                  />
                  <SimpleItem
                    dataField="model_name"
                    label={{ text: "Item Name" }}
                    editorOptions={{
                      placeholder:
                        "iPhone Xs, WINSRV003, Smith Email Account...",
                      hint: "Enter the model name of the device.",
                    }}
                  />
                  <SimpleItem
                    dataField="model_number"
                    label={{ text: "Model Number or Service" }}
                    editorOptions={{
                      placeholder: "A1234, WD2500, GMail...",
                      hint: "Enter the model number for this item.",
                    }}
                  />
                  <SimpleItem
                    dataField="serial_number"
                    label={{ text: "Unique Identifier" }}
                    editorOptions={{
                      placeholder: "first.last@email.com, serial number...",
                      hint: "Enter a unique identifier for this item.",
                    }}
                  />
                  <GroupItem colCount={2} cssClass="dxform-group-no-padding">
                    <SimpleItem
                      dataField="capacity"
                      label={{ text: "Size" }}
                      editorType="dxNumberBox"
                      editorOptions={{
                        min: 0,
                        max: 999,
                        showSpinButtons: true,
                      }}
                    />
                    <SimpleItem
                      dataField="capacity_unit"
                      label={{ text: "Size Unit" }}
                      editorType="dxSelectBox"
                      editorOptions={{
                        items: ["KB", "MB", "GB", "TB"],
                        value: "GB",
                      }}
                    />
                  </GroupItem>
                  <SimpleItem
                    dataField="contact"
                    label={{ text: "Linked Contact" }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      dataSource: contacts,
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                      placeholder: "Select or Enter New Contact",
                      searchExpr: "name",
                      displayExpr: "name",
                      searchTimeout: 20,
                      showClearButton: true,
                      searchEnabled: true,
                      hint: "Person that this evidence is linked to.",
                      acceptCustomValue: true,
                      onCustomItemCreating: (e) => {
                        if (e.text != "") {
                          e.customItem = { name: e.text, client_id: null };
                          e.component
                            .getDataSource()
                            .store()
                            .insert(e.customItem);
                          e.component.getDataSource().reload();
                        } else {
                          e.customItem = null;
                        }
                      },
                      itemTemplate: "contact",
                    }}
                  />
                  <Template
                    name="contact"
                    render={(data) => <ContactSelectBoxItem data={data} />}
                  />
                  <SimpleItem
                    dataField="description"
                    label={{ text: "Description" }}
                    colSpan={2}
                    editorType="dxTextArea"
                    editorOptions={{
                      height: 135,
                      placeholder:
                        "Enter a brief description of this item and any important details.",
                    }}
                  />
                </Form>
              </CollapseSection>
              <CollapseSection title="Custom Fields" visible={false}>
                <Form ref={customFieldForm} colCount={2}>
                  {customFields &&
                    customFields
                      .filter(
                        (r) =>
                          r?.type_ids?.length === 0 ||
                          (itemType && r?.type_ids?.includes?.(itemType))
                      )
                      .map((attribute) => (
                        <SimpleItem
                          key={`custom_attribute_${attribute.field_id}`}
                          dataField={`custom_attribute_${attribute.field_id}`}
                          editorType={DevexEditors[attribute.editor_type]}
                          isRequired={attribute.is_required === 1}
                          label={{ text: attribute.field_name }}
                          editorOptions={{
                            placeholder: attribute.description || "",
                            hint: attribute.description || "",
                            items: JSON.parse(attribute.options || "[]"),
                            dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                            useMaskBehavior: true,
                            displayFormat: getDateFormat(false, false),
                            showClearButton:
                              attribute.editor_type !== "textBox",
                            pickerType: "calendar",
                          }}
                        />
                      ))}
                </Form>
              </CollapseSection>
              <CollapseSection title="Intake Details" visible={false}>
                <Form
                  ref={intakeForm}
                  colCount={1}
                  style={{ maxWidth: 500, margin: "auto" }}
                >
                  <SimpleItem
                    dataField="custody_to"
                    label={{ text: "Item Removed By" }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      dataSource: new DataSource({
                        store: locations,
                        key: "location_id",
                        group: "office_name",
                      }),
                      grouped: true,
                      hint: "This is the person or location in your org that received the item.",
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                      displayExpr: "location_name",
                      searchEnabled: true,
                      itemTemplate: "custody_to",
                    }}
                  />
                  <Template
                    name="custody_to"
                    render={(data) => <LocationSelectBoxItem data={data} />}
                  />
                  <SimpleItem
                    dataField="custody_to_sig"
                    label={{ text: "Item Removed By Signature" }}
                  >
                    <SignatureField
                      sigFieldRef={receivedBySig}
                      title="Item Removed By Signature"
                    />
                  </SimpleItem>
                  <SimpleItem
                    dataField="coc_intake_timestamp"
                    label={{ text: "Item Removal Timestamp" }}
                    editorType="dxDateBox"
                    editorOptions={{
                      hint: "Date and time the item was removed from parent.",
                      type: "datetime",
                      useMaskBehavior: true,
                      displayFormat: getDateFormat(false, true),
                      pickerType: "calendar",
                    }}
                    validationRules={[
                      {
                        type: "custom",
                        message: "Invalid Date",
                        validationCallback: validateTimestamp,
                      },
                    ]}
                  />
                  <SimpleItem
                    dataField="reason"
                    label={{ text: "Notes" }}
                    colSpan={2}
                    editorType="dxTextArea"
                    editorOptions={{
                      height: 75,
                    }}
                  ></SimpleItem>
                </Form>
              </CollapseSection>
              <ToolBarItems
                submitText="Create Child Item"
                onSubmit={handleSubmit}
                onCancel={() => handleClose()}
                style={{ marginTop: 10 }}
              />
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ChildItemsContent;
