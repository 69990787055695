import { useQuery } from "@tanstack/react-query";
import styled, { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import MonolithNotesAPI from "../../../api/Notes/index.js";
import Table, { Column } from "../../../Monolith-UI/Table/Table.js";
import { useState } from "react";
import { Button, Switch } from "@monolith-forensics/monolith-ui";
import { EditIcon, Trash2Icon } from "lucide-react";
import { monolithMoment } from "../../../utils/date-format.js";
import { DeleteEditorTemplateModal } from "../../../components/Modals/index";
import { Link } from "react-router-dom";
import LoggingAPI from "../../../api/logging/index.js";

const EditorTemplatesSection = styled(({ className }) => {
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const { data: templates, refetch } = useQuery({
    queryKey: [
      "editorTemplates",
      "list",
      {
        data_only: true,
        include_content: false,
        created_by_id: currentUser.user_id,
        include_shared: false,
      },
    ],
    queryFn: () =>
      MonolithNotesAPI.getTemplates({
        data_only: true,
        include_content: false,
        created_by_id: currentUser.user_id,
        include_shared: false,
      }),
  });

  const handleUpdateTemplate = async (values = {}) => {
    const template = templates.find((t) => t.uuid === values.uuid);
    await MonolithNotesAPI.updateTemplate(values);

    refetch();

    LoggingAPI.createLog({
      user_id: currentUser.user_id,
      message: `Updated editor template: ${template.name}`,
      event: "update_editor_template",
      object_type: "editor_template",
      object_uuid: template.uuid,
    });
  };

  if (!templates) return <div>Loading...</div>;

  return (
    <div className={className}>
      <div style={{ marginBottom: 10 }}>
        These are your editor templates. These can be used to quickly create a
        note that has a predefined structure. Shared templates can be used by
        other Monolith users.
      </div>
      <Link to="create">
        <Button size="xxs" variant="contained" color="primary">
          + New Template
        </Button>
      </Link>

      <div style={{ marginBottom: 10 }}></div>
      <DeleteEditorTemplateModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={() => refetch()}
        defaultInfo={selectedTemplate}
      />
      <Table data={templates} keyValue="uuid">
        <Column dataField="name" caption="Template Name" width={350} />
        <Column
          dataField="created_on"
          caption="Created On"
          width={150}
          render={(rowData) =>
            monolithMoment({ timestamp: rowData.created_on })
          }
        />
        <Column
          dataField="actions"
          caption=""
          render={(data) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 35,
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: 600,
                  color: theme.palette.text.secondary,
                  width: "100%",
                  userSelect: "none",
                  paddingRight: 10,
                }}
                title="Shared templates are visible to all users in Monolith."
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    alignItems: "center",
                    justifyContent: "flex-end",
                    fontWeight: 600,
                    color: theme.palette.text.secondary,
                    width: "100%",
                    userSelect: "none",
                  }}
                >
                  <div>Shared</div>
                  <Switch
                    size="xs"
                    defaultValue={data.is_shared}
                    onChange={(value) => {
                      handleUpdateTemplate({
                        uuid: data.uuid,
                        is_shared: value,
                      });
                    }}
                  />
                </div>
                <Link to={`edit/${data.uuid}`}>
                  <Button
                    size="xxs"
                    variant="text"
                    color="primary"
                    leftSection={<EditIcon size={12} />}
                  >
                    Edit
                  </Button>
                </Link>

                <Button
                  size="xxs"
                  variant="text"
                  color="error"
                  leftSection={<Trash2Icon size={12} />}
                  onClick={() => {
                    setSelectedTemplate(data);
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default EditorTemplatesSection;
