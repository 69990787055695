import { Modal, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createEvidence,
  getContacts,
  getIpAddress,
  logActivity,
} from "../../../../api";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../../utils/date-format";

import {
  Form,
  SimpleItem,
  EmptyItem,
  GroupItem,
  RequiredRule,
} from "devextreme-react/ui/form";
import dxAutocomplete from "devextreme/ui/autocomplete";
import DataSource from "devextreme/data/data_source";
import { DevexEditors } from "../../../../utils/devex-editors";
import SignatureField from "../../../../components/SignatureField/SignatureField";
import ToolBarItems from "../../../../components/ToolBarItems";
import CollapseSection from "../../../../components/CollaspeSection";
import CreateContactFlyout from "../../../Contacts/CreateContactFlyout";
import { Template } from "devextreme-react/core/template";
import CaseSelectBoxItem from "../../../../components/SelectBoxItems/CaseSelectBoxItem.js";
import ContactSelectBoxItem from "../../../../components/SelectBoxItems/ContactSelectBoxItem.js";
import LocationSelectBoxItem from "../../../../components/SelectBoxItems/LocationSelectBoxItem.js";
import EvidenceAPI from "../../../../api/evidence";
import LocationsAPI from "../../../../api/locations";
import CasesApi from "../../../../api/cases";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import Loader from "../../../../components/Loader.js";
import { usePermissions } from "../../../../hooks/usePermissions";

const CreateEvidenceModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  caseInfo,
  includeCaseField,
  navigateToItem = false,
  defaultInfo = {},
}) => {
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [itemType, setItemType] = useState(null);
  const [showCreateContactFlyout, setShowCreateContactFlyout] = useState(false);

  const form = useRef(null);
  const customFieldForm = useRef(null);
  const intakeForm = useRef(null);
  const receivedBySig = useRef(null);
  const receivedFromSig = useRef(null);

  const case_id = includeCaseField ? null : caseInfo.case_id;

  const handleSubmit = async (event) => {
    if (
      form.current.instance.validate().isValid &&
      customFieldForm.current.instance.validate().isValid &&
      validateIntakeFields()
    ) {
      let formData = Object.assign(
        {},
        {
          ...form.current.instance.option("formData"),
          ...customFieldForm.current.instance.option("formData"),
          ...intakeForm.current.instance.option("formData"),
        }
      );
      if (!includeCaseField) formData.case_id = caseInfo.case_id;

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = receivedBySig.current.isEmpty()
        ? null
        : {
            signature: receivedBySig.current.getSignature(),
            signer: formData.custody_to.location_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: formData.custody_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      handleClose();

      //Get custom attributes from formData object
      //Compile them into a single object array with related attrubite values
      //remove original form items from form data
      let customAttributes = [];

      // parse custom attribute field_id from form field
      for (let key of Object.keys(formData)) {
        if (key.indexOf("custom_attribute_") !== -1) {
          customAttributes.push({
            field_id: parseInt(key.replace("custom_attribute_", "")),
            value: formData[key],
          });

          delete formData[key];
        }
      }

      if (customAttributes.length > 0)
        formData.custom_attributes = customAttributes;

      //Convert intake time to UTC
      if (formData.coc_intake_timestamp) {
        formData.coc_intake_timestamp = db_timestamp(
          formData.coc_intake_timestamp,
          true
        );

        formData.signature = JSON.stringify(signature);
        formData.custody_to = formData.custody_to.location_id;
        formData.coc_type = "Intake";
        formData.entered_by = currentUser.full_name;
        formData.reason = formData.reason || "Evidence Intake";
      }

      if (formData.contact) {
        formData.contact_id = formData.contact.client_id;
      }

      createEvidence(formData, {
        is_parent: defaultInfo?.is_parent,
        parent_name: defaultInfo?.parent_name || null,
        parent_id: defaultInfo?.parent_id || null,
      }).then((result) => {
        enqueueSnackbar(`Created Evidence Item ${result.evidence_number}`, {
          variant: "success",
        });

        logActivity(
          result.case_id,
          currentUser.user_id,
          `Created Evidence Item: ${result.evidence_number}`
        );

        onSubmit(result);

        if (navigateToItem)
          navigate(`/evidence/${result.uuid}/overview`, {
            state: { from: "case:evidence" },
          });
      });
    } else {
      enqueueSnackbar(`Please complete all required fields.`, {
        variant: "error",
      });
    }
  };

  const handleCancel = () => handleClose();

  const validateIntakeFields = () => {
    let isValid = true;
    const formData = { ...intakeForm.current.instance.option("formData") };

    // Check if any values exist in the form
    let hasValues = Object.keys(formData).some((key) => {
      return formData[key] && formData[key] !== "";
    });

    if (hasValues) {
      // check that all required fields are filled out

      // custody_to
      if (!formData.custody_to) {
        isValid = false;
        intakeForm.current.instance
          .getEditor("custody_to")
          .option("isValid", false);
      } else {
        intakeForm.current.instance
          .getEditor("custody_to")
          .option("isValid", true);
      }

      // custody_from
      if (!formData.custody_from) {
        isValid = false;
        intakeForm.current.instance
          .getEditor("custody_from")
          .option("isValid", false);
      } else {
        intakeForm.current.instance
          .getEditor("custody_from")
          .option("isValid", true);
      }

      // timestamp
      if (!formData.coc_intake_timestamp) {
        isValid = false;
        intakeForm.current.instance
          .getEditor("coc_intake_timestamp")
          .option("isValid", false);
      } else {
        intakeForm.current.instance
          .getEditor("coc_intake_timestamp")
          .option("isValid", true);
      }
    }

    return isValid;
  };

  const onContactCreated = (newContact) => {
    queryClient.setQueryData(["people:contacts", { case_id }], (oldData) => {
      return [...oldData, newContact];
    });
    form.current.instance.option("formData.contact", newContact);
    form.current.instance.getEditor("contact").option("value", newContact);
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["evidence:customFields"],
        queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
        enabled: open,
        select: (data) => data.filter((c) => c.enabled === 1),
      },
      {
        queryKey: ["evidence:types"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
        enabled: open,
      },
      {
        queryKey: ["evidence:providers"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
        enabled: open,
      },
      {
        queryKey: ["evidence:locations", { include_groups: false }],
        queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
        enabled: open,
      },
      {
        queryKey: ["people:contacts", { case_id }],
        queryFn: () =>
          getContacts({ case_id: includeCaseField ? null : caseInfo.case_id }),
        enabled: open,
      },
      {
        queryKey: ["cases:caseNumbers"],
        queryFn: () => CasesApi.getCaseNumbers(),
        enabled: includeCaseField && open,
      },
    ],
  });

  const isDone = results.every((result) => result.isFetched);

  const fieldData = {
    customFields: results[0]?.data || [],
    evidenceTypes: results[1]?.data || [],
    evidenceProviders: results[2]?.data || [],
    evidenceLocations: results[3]?.data || [],
    contacts: [
      { name: "Create New Contact", client_id: "new" },
      ...(results[4]?.data || []),
    ],
    caseNumbers: results[5]?.data || [],
  };

  return (
    <>
      <CreateContactFlyout
        open={showCreateContactFlyout}
        onSubmit={onContactCreated}
        handleClose={() => setShowCreateContactFlyout(false)}
        caseInfo={caseInfo}
      />
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Evidence
          </div>
          {isDone ? (
            <>
              <FormContent
                form={form}
                customFieldForm={customFieldForm}
                intakeForm={intakeForm}
                showCreateContactFlyout={showCreateContactFlyout}
                includeCaseField={includeCaseField}
                fieldData={fieldData}
                setItemType={setItemType}
                itemType={itemType}
                setShowCreateContactFlyout={setShowCreateContactFlyout}
                receivedBySig={receivedBySig}
                receivedFromSig={receivedFromSig}
                defaultInfo={defaultInfo}
              />
              <ToolBarItems
                submitText="Create Evidence"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                style={{ marginTop: 20 }}
              />
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Modal>
    </>
  );
};

const FormContent = ({
  form,
  customFieldForm,
  intakeForm,
  showCreateContactFlyout,
  includeCaseField,
  fieldData,
  setItemType,
  itemType,
  setShowCreateContactFlyout,
  receivedBySig,
  receivedFromSig,
  defaultInfo = {},
}) => {
  let defaultFormData = {
    case_id: defaultInfo.case_id || null,
    item_type: defaultInfo.item_type || null,
    manufacturer: defaultInfo.manufacturer || null,
    model_name: defaultInfo.model_name || null,
    model_number: defaultInfo.model_number || null,
    serial_number: defaultInfo.serial_number || null,
    capacity: defaultInfo.capacity || null,
    capacity_unit: defaultInfo.capacity_unit || "GB",
    description: defaultInfo.description || null,
  };

  return (
    <>
      <CollapseSection title="Evidence Details" visible={true}>
        {useMemo(() => {
          return (
            <Form
              ref={form}
              colCount={2}
              style={{ padding: 10 }}
              disabled={showCreateContactFlyout}
              defaultFormData={defaultFormData}
            >
              {includeCaseField && (
                <SimpleItem
                  dataField="case_id"
                  label={{ text: "Case" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: new DataSource({
                      store: fieldData.caseNumbers,
                      paginate: true,
                      pageSize: 10,
                    }),
                    placeholder: "Select Case...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "case_number",
                    valueExpr: "case_id",
                    searchEnabled: true,
                    searchExpr: ["case_number", "case_ref"],
                    itemTemplate: "case_id",
                  }}
                />
              )}
              <Template
                name="case_id"
                render={(data) => <CaseSelectBoxItem data={data} />}
              />
              {includeCaseField && <EmptyItem />}
              <SimpleItem
                dataField="evidence_number"
                label={{ text: "Evidence Number" }}
                editorOptions={{
                  hint: "Enter a unique ID that identifies this evidence.",
                  placeholder: "Leave blank to auto-generate evidence number",
                }}
              />
              <SimpleItem
                dataField="item_type"
                label={{ text: "Evidence Type" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: fieldData.evidenceTypes,
                  placeholder: "Select Evidence Type...",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  displayExpr: "evi_type",
                  valueExpr: "evi_type",
                  searchEnabled: true,
                  onItemClick: (e) => {
                    setTimeout(() => {
                      setItemType(e.itemData.evi_type_id);
                    }, 25);
                  },
                }}
              />
              <SimpleItem
                dataField="manufacturer"
                label={{ text: "Item Brand" }}
                editorType="dxAutocomplete"
                editorOptions={{
                  dataSource: fieldData.evidenceProviders,
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  placeholder: "Apple, Google, Samsung...",
                  displayExpr: "manufacturer",
                  valueExpr: "manufacturer",
                  searchEnabled: true,
                  acceptCustomValue: true,
                }}
              />
              <SimpleItem
                dataField="model_name"
                label={{ text: "Item Name" }}
                editorOptions={{
                  placeholder: "iPhone Xs, WINSRV003, Smith Email Account...",
                  hint: "Enter the model name of the device.",
                }}
              />
              <SimpleItem
                dataField="model_number"
                label={{ text: "Model Number or Service" }}
                editorOptions={{
                  placeholder: "A1234, WD2500, GMail...",
                  hint: "Enter the model number for this item.",
                }}
              />
              <SimpleItem
                dataField="serial_number"
                label={{ text: "Unique Identifier" }}
                editorOptions={{
                  placeholder: "first.last@email.com, serial number...",
                  hint: "Enter a unique identifier for this item.",
                }}
              />
              <GroupItem colCount={2} cssClass="dxform-group-no-padding">
                <SimpleItem
                  dataField="capacity"
                  label={{ text: "Size" }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    min: 0,
                    max: 999,
                    showSpinButtons: true,
                  }}
                />
                <SimpleItem
                  dataField="capacity_unit"
                  label={{ text: "Size Unit" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    items: ["KB", "MB", "GB", "TB"],
                    value: defaultInfo.capacity_unit || "GB",
                  }}
                />
              </GroupItem>
              <SimpleItem
                dataField="contact"
                label={{ text: "Linked Contact" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: fieldData.contacts,
                  placeholder: "Select Contact...",
                  dropDownOptions: {
                    maxHeight: 300,
                  },
                  displayExpr: "name",
                  searchEnabled: true,
                  showClearButton: true,
                  openOnFieldClick: false,
                  onFocusIn: (e) => {
                    e.component.open();
                  },
                  onValueChanged: (e) => {
                    if (e.value && e.value.client_id === "new") {
                      e.event.preventDefault();
                      e.event.stopPropagation();
                      e.component.option("value", null);
                      setShowCreateContactFlyout(true);
                    }
                  },
                  itemTemplate: "contact",
                }}
              />
              <Template
                name="contact"
                render={(data) => <ContactSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="description"
                label={{ text: "Description" }}
                colSpan={2}
                editorType="dxTextArea"
                editorOptions={{
                  height: 135,
                  placeholder:
                    "Enter a brief description of this item and any important details.",
                }}
              />
            </Form>
          );
        }, [fieldData])}
      </CollapseSection>
      <CollapseSection title="Custom Fields">
        {useMemo(() => {
          let customFormData = {};

          if (defaultInfo.custom_attributes?.length > 0) {
            customFormData = defaultInfo.custom_attributes.reduce(
              (acc, cur) => ({
                ...acc,
                [`custom_attribute_${cur.field_id}`]:
                  cur.editor_type === "tagBox"
                    ? JSON.parse(cur.value || "[]")
                    : cur.value,
              }),
              {}
            );
          }
          return (
            <Form
              ref={customFieldForm}
              colCount={2}
              style={{ padding: 10 }}
              disabled={showCreateContactFlyout}
              defaultFormData={customFormData}
            >
              {fieldData.customFields &&
                fieldData.customFields
                  .filter(
                    (r) =>
                      r?.type_ids?.length === 0 ||
                      (itemType && r?.type_ids?.includes?.(itemType))
                  )
                  .map((attribute) => (
                    <SimpleItem
                      key={`custom_attribute_${attribute.field_id}`}
                      dataField={`custom_attribute_${attribute.field_id}`}
                      editorType={DevexEditors[attribute.editor_type]}
                      label={{ text: attribute.field_name }}
                      editorOptions={{
                        placeholder: attribute.description || "",
                        hint: attribute.description || "",
                        items: JSON.parse(attribute.options || "[]"),
                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                        useMaskBehavior: true,
                        displayFormat: getDateFormat(false, false),
                        showClearButton: attribute.editor_type !== "textBox",
                        pickerType: "calendar",
                        showDropDownButton: true,
                        multiline: false,
                        showSelectionControls:
                          attribute.editor_type === "tagBox" ? true : false,
                      }}
                    >
                      {attribute.is_required === 1 && (
                        <RequiredRule message="" />
                      )}
                    </SimpleItem>
                  ))}
            </Form>
          );
        }, [fieldData, itemType])}
      </CollapseSection>
      <CollapseSection title="Intake Details">
        {useMemo(() => {
          return (
            <Form
              ref={intakeForm}
              colCount={2}
              style={{ padding: 10 }}
              disabled={showCreateContactFlyout}
            >
              <SimpleItem
                dataField="custody_to"
                label={{ text: "Received By" }}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: fieldData.evidenceLocations,
                  hint: "This is the person or location in your org that received the item.",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  displayExpr: "location_name",
                  searchEnabled: true,
                  itemTemplate: "custody_to",
                }}
              ></SimpleItem>
              <Template
                name="custody_to"
                render={(data) => <LocationSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="custody_from"
                label={{ text: "Received From" }}
                editorOptions={{
                  placeholder: "John Smith, FedEx: tracking 12345...",
                  hint: "This is the person or entity the item was received from.",
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="custody_to_sig"
                label={{ text: "Received By Signature" }}
              >
                <SignatureField
                  sigFieldRef={receivedBySig}
                  title="Received By Signature"
                />
              </SimpleItem>
              <SimpleItem
                dataField="custody_from_sig"
                label={{ text: "Received From Signature" }}
              >
                <SignatureField
                  sigFieldRef={receivedFromSig}
                  title="Received From Signature"
                />
              </SimpleItem>
              <SimpleItem
                dataField="coc_intake_timestamp"
                label={{ text: "Intake Timestamp" }}
                editorType="dxDateBox"
                editorOptions={{
                  hint: "Date and time the item was received.",
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  pickerType: "calendar",
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              ></SimpleItem>
              <SimpleItem
                dataField="location_received"
                label={{ text: "Location Received" }}
                editorOptions={{
                  placeholder: "Address, Front Office, etc...",
                }}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Notes" }}
                colSpan={2}
                editorType="dxTextArea"
                editorOptions={{
                  height: 75,
                }}
              ></SimpleItem>
            </Form>
          );
        }, [])}
      </CollapseSection>
    </>
  );
};

export default CreateEvidenceModal;
