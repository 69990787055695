// External Library Imports
import { useMemo, useRef } from "react";
import { useSnackbar } from "notistack";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form";

// Relative Imports
import Modal from "../../../Monolith-UI/Modal/Modal";
import CollapseSection from "../../../components/CollaspeSection";
import { updateClient } from "../../../api";
import ContactsAPI from "../../../api/contacts";
import { useQuery } from "@tanstack/react-query";

const EditContactModal = ({
  clientInfo,
  isOpen,
  handleClose,
  handleClientUpdate,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: contactTypes, isFetched: isDone } = useQuery({
    queryKey: ["contacts:types"],
    queryFn: () => ContactsAPI.getContactTypes(),
    enabled: isOpen,
  });

  // DevExtreme Form Refs
  const clientInfoRef = useRef(null);
  const contactInfoRef = useRef(null);

  //  new clientInfo object that does not re-render form when mutated
  const clientDetails = { ...clientInfo };

  const handleSubmit = (event) => {
    if (
      clientInfoRef.current.instance.validate().isValid &&
      contactInfoRef.current.instance.validate().isValid
    ) {
      let formData = {
        ...clientInfoRef.current.instance.option().formData,
        ...contactInfoRef.current.instance.option().formData,
      };

      let updateData = {};

      for (const key of Object.keys(clientDetails)) {
        if (clientDetails[key] !== formData[key]) {
          updateData[key] = formData[key];
        }
      }

      updateClient(clientDetails.client_id, updateData);

      handleClientUpdate(updateData);

      enqueueSnackbar(`Contact Updated.`, {
        variant: "success",
      });
    }
    handleClose();
  };

  const contactDetailsForm = useMemo(() => {
    return (
      <Form
        ref={clientInfoRef}
        defaultFormData={clientInfo}
        colCount={2}
        labelLocation={"top"}
        label={{
          visible: true,
          alignment: "left",
        }}
      >
        <SimpleItem
          dataField="name"
          label={{ text: "Name" }}
          isRequired={true}
        />
        <SimpleItem dataField="title" label={{ text: "Title" }} />
        <SimpleItem dataField="organization" label={{ text: "Organization" }} />
        <SimpleItem dataField="email" label={{ text: "Email" }} />
        <SimpleItem
          dataField="office_number"
          label={{ text: "Office Number" }}
        />
        <SimpleItem
          dataField="mobile_number"
          label={{ text: "Mobile Number" }}
        />
        <SimpleItem dataField="unique_id" label={{ text: "Unique ID" }} />
        <SimpleItem
          dataField="contact_type"
          label={{ text: "Contact Type" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: contactTypes,
            valueExpr: "contact_type",
            displayExpr: "contact_type",
            acceptCustomValue: true,
            onCustomItemCreating: (e) => {
              e.customItem = { contact_type: e.text };
              // Adds the entry to the data source
              e.component.getDataSource().store().insert(e.customItem);
              // Reloads the data source
              e.component.getDataSource().reload();
            },
          }}
        />
        <SimpleItem
          dataField="notes"
          label={{ text: "Notes" }}
          colSpan={2}
          editorType="dxTextArea"
          editorOptions={{
            height: 135,
          }}
        />
      </Form>
    );
  }, [clientInfo]);

  const ContactInfoForm = useMemo(() => {
    return (
      <Form
        ref={contactInfoRef}
        defaultFormData={clientInfo}
        colCount={2}
        labelLocation={"top"}
        label={{
          visible: true,
          alignment: "left",
        }}
      >
        <SimpleItem dataField="address" label={{ text: "Address" }} />
        <SimpleItem dataField="city" label={{ text: "City" }} />
        <SimpleItem dataField="state" label={{ text: "State/Province" }} />
        <SimpleItem dataField="zipcode" label={{ text: "Postal Code" }} />
        <SimpleItem dataField="country" label={{ text: "Country" }} />
      </Form>
    );
  }, [clientInfo]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
        title="Edit Contact"
        onSubmit={handleSubmit}
        onCancel={handleClose}
        submitText="Edit"
        showCloseButton={false}
      >
        <CollapseSection title="Contact's Information" visible={true}>
          {contactDetailsForm}
        </CollapseSection>
        <CollapseSection title="Mailing Address">
          {ContactInfoForm}
        </CollapseSection>
      </Modal>
    </>
  );
};

export default EditContactModal;
