import { Button, styled, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import { getEvidence } from "../../api";
import { Link } from "react-router-dom";
import { getDateFormat, monolithMoment } from "../../utils/date-format";
import { useSnackbar } from "notistack";
import Loader from "../../components/Loader";
import { usePermissions } from "../../hooks/usePermissions";

import CreateEvidenceModal from "./components/Popups/CreateEvidenceModal";
import EditEvidenceModal from "./components/Popups/EditEvidenceModal";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton";

import EvidenceAPI from "../../api/evidence";
import OfficesApi from "../../api/offices";
import ClientsAPI from "../../api/clients";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table";
import LocationsAPI from "../../api/locations";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout";
import TaskButton from "../../Monolith-UI/TaskButton";
import { addCustomFieldsToColumnDefs } from "../../components/Evidence/CustomFieldCell.js";
import DeleteEvidencePopup from "../../components/Evidence/DeleteEvidencePopup.js";
import TableColumnDefs from "../../components/Evidence/TableColumnDefs.js";
import EvidenceDimensions from "../../components/Evidence/EvidenceDimensions.js";
import { nanoid } from "nanoid";
import Menu, { MenuItem } from "../../Monolith-UI/Menu.js";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import { MonitorSmartphoneIcon } from "lucide-react";
import { Input } from "@monolith-forensics/monolith-ui";

const columnDefs = TableColumnDefs;

const EvidenceFlyoutContent = styled(
  ({
    className,
    defaultEvidenceInfo,
    editEvidence = () => {},
    copyEvidence = () => {},
    deleteEvidencePopup,
    updateCache = () => {},
    columnDefs,
  }) => {
    const queryClient = useQueryClient();
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const theme = useTheme();

    const { data: evidenceInfo } = useQuery({
      queryKey: [
        "evidence:list",
        { evidence_id: defaultEvidenceInfo.evidence_id },
      ],
      queryFn: () =>
        getEvidence({ evidence_id: defaultEvidenceInfo.evidence_id }),
      placeholderData: (data) => data || [defaultEvidenceInfo],
      select: (data) => {
        return data[0];
      },
    });

    const handleEditEvidence = () => {
      editEvidence();
      queryClient.refetchQueries({
        queryKey: ["evidence:list", { evidence_id: evidenceInfo.evidence_id }],
      });
    };

    const handleDeleteEvidence = () => {
      deleteEvidencePopup.current.instance.setEvidenceInfo(evidenceInfo);
      deleteEvidencePopup.current.instance.show();
    };

    const handleCopyEvidence = () => {
      copyEvidence();
    };

    const handleChange = (data) => {
      queryClient.setQueryData(
        ["evidence:list", { evidence_id: defaultEvidenceInfo.evidence_id }],
        (oldData) => {
          const dataObj = oldData[0] || {};
          return [{ ...dataObj, ...data }];
        }
      );
    };

    const dataFieldIgnoreList = ["description"];

    const data = {
      ...evidenceInfo,
      ...(evidenceInfo?.custom_attributes?.reduce((acc, attr) => {
        acc[`custom_field_${attr.field_id}`] = attr.value;
        return acc;
      }, {}) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
          .filter((c) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data, {
                    onRowUpdated: (updateData) =>
                      updateCache({ rowData: data, updateData }),
                  })
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        <div className="action-menu">
          <Link
            className={className}
            to={`/evidence/${evidenceInfo.uuid}/overview`}
            style={{ padding: 0, margin: 0 }}
          >
            <div className="action-menu-item">
              <LaunchOutlinedIcon
                style={{ color: theme.palette.primary.main }}
              />
              <div className="action-menu-label">View Evidence</div>
            </div>
          </Link>
          {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_CREATE) && (
            <div className="action-menu-item" onClick={handleCopyEvidence}>
              <ContentCopyOutlinedIcon
                style={{
                  color: theme.palette.primary.main,
                }}
              />
              <div className="action-menu-label">Copy</div>
            </div>
          )}
          {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE) && (
            <>
              <div className="action-menu-item" onClick={handleEditEvidence}>
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
              {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_DELETE) && (
                <div
                  className="action-menu-item"
                  onClick={handleDeleteEvidence}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{ color: theme.palette.error.main }}
                  />
                  <div className="action-menu-label">Delete</div>
                </div>
              )}
            </>
          )}
        </div>
        {detail}
        <div className="detail-label" style={{ marginTop: 30 }}>
          Description
        </div>
        <div style={{ position: "relative" }}>
          {useMemo(() => {
            return (
              <div className="description">{evidenceInfo.description}</div>
            );
          }, [evidenceInfo])}
        </div>
      </div>
    );
  }
)`
  padding: 20px;

  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .detail-container {
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 25px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const EvidenceHeader = styled(({ className, onChange = () => {} }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const theme = useTheme();
  const [currentView, setCurrentView] = useState(
    localStorage.getItem("evidenceView") || "My Evidence"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (item) => {
    handleClose();
    localStorage.setItem("evidenceView", item.text);
    setCurrentView(item.text);
    onChange(item);
  };

  const hasReadAllPermission = hasPermission(
    MONOLITH_PERMISSIONS.EVIDENCE_READ_ALL
  );

  return (
    <div className={className}>
      <div
        className={`view-selector` + (hasReadAllPermission ? " admin" : "")}
        onClick={hasReadAllPermission ? handleClick : null}
      >
        {hasReadAllPermission ? (
          <>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">{currentView}</Typography>
              <ArrowDropDownOutlinedIcon
                style={{ fontSize: 26, marginLeft: 5 }}
              />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onItemSelect={handleSelect}
              dropDownTitle={""}
            >
              <MenuItem
                onClick={handleClose}
                data={{ text: "My Evidence", value: 0 }}
                style={{ minWidth: 200 }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    My Evidence
                  </div>
                }
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                data={{ text: "All Evidence", value: 1 }}
                style={{ minWidth: 200 }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    All Evidence
                  </div>
                }
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Typography variant="h3">My Evidence</Typography>
        )}
      </div>
      <Typography color="textSecondary" variant="subtitle2"></Typography>
    </div>
  );
})`
  .view-selector {
    border: 1px solid transparent;
    transition: all 0.2s ease;
    width: fit-content;
    user-select: none;
  }
  .view-selector.admin:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 5px;
  }
`;

const EvidenceSection = styled(
  ({ className, stateStoreKey = "evidence:list:query" }) => {
    const [currentView, setCurrentView] = useState(
      localStorage.getItem("evidenceView") || "My Evidence"
    );

    const { currentUser, hasPermission, MONOLITH_PERMISSIONS } =
      usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [showCreateEvidenceModal, setShowCreateEvidenceModal] =
      useState(false);
    const [showEditEvidenceModal, setShowEditEvidenceModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);

    const deleteEvidencePopup = useRef(null);
    const queryClient = useQueryClient();
    const theme = useTheme();
    const queryFilter = useRef(null);
    const [pageSize, setPageSize] = useState(100);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);

    const table = useTable();

    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const [columnState, setColumnState] = useState(() => {
      // Synchronizes stored state in local storage and column defs object
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    let filterDimensions = EvidenceDimensions;

    const currentSort = useMemo(() => {
      let [sort] =
        columnState
          ?.filter((c) => c.sorting?.active)
          ?.map((c) => ({ field: c.dataField, sort: c.sorting?.direction })) ||
        [];

      return sort;
    }, [columnState]);

    const [query, setQuery] = useState(null);
    const userID = currentView === "All Evidence" ? null : currentUser.user_id;

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          EvidenceAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage, pages) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage, pages) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    const { data: customFields } = useQuery({
      queryKey: ["evidence:customFields:list"],
      queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
    });

    useQueries({
      queries: [
        {
          queryKey: ["evidence:types"],
          queryFn: () => EvidenceAPI.getEvidenceTypes(),
          enabled: !!data,
        },
        {
          queryKey: ["evidence:providers"],
          queryFn: () => EvidenceAPI.getEvidenceBrands(),
          enabled: !!data,
        },
        {
          queryKey: ["evidence:progress"],
          queryFn: () => EvidenceAPI.getEvidenceProgress(),
          enabled: !!data,
        },
        {
          queryKey: ["evidence:locations", { include_groups: false }],
          queryFn: () =>
            LocationsAPI.getLocations({}, { include_groups: false }),
          enabled: !!data,
        },
        {
          queryKey: ["offices:list"],
          queryFn: () => OfficesApi.getOffices(),
          enabled: !!data,
        },
        {
          queryKey: ["clients:list"],
          queryFn: () => ClientsAPI.getClients(),
          enabled: !!data,
        },
        {
          queryKey: ["clients:organizations"],
          queryFn: () => ClientsAPI.getOrganizations(),
          enabled: !!data,
        },
      ],
    });

    // update filter dimensions with custom fields
    filterDimensions = useMemo(() => {
      if (customFields) {
        //update filter dimensions
        return [
          ...EvidenceDimensions,
          ...customFields.map((field) => ({
            name: field.field_name,
            field: `custom_field_${field.field_id}`,
            id: `custom_field_${field.field_id}`,
            type:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "string"
                : "text",
            mode:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "multi-select"
                : "text",
            options: field.options
              ? JSON.parse(field.options).map((o) => ({
                  option: o,
                }))
              : null,
            selectionDisplayName: field.options ? "option" : null,
            selectionIdField: field.options ? "option" : null,
          })),
        ];
      }
      return EvidenceDimensions;
    }, [customFields]);

    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };
    const handleFilter = (newFilter) => {
      setQuery((q) => {
        return {
          query: {
            ...q?.query,
            ...newFilter,
            page: 1,
            pageSize,
            user_id: userID,
          },
        };
      });
    };
    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        const currentColumn = cs.find((c) => c.dataField === column.dataField);
        if (currentColumn) {
          return cs.map((c) => {
            if (c.dataField === column.dataField) {
              return {
                ...c,
                visible,
              };
            }
            return c;
          });
        } else {
          return [
            ...cs,
            {
              ...column,
              visible,
            },
          ];
        }
      });
    };
    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting evidence table...", {
        variant: "info",
      });
      EvidenceAPI.exportEvidenceList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat(true, true),
        custom_date_format: getDateFormat(true, false),
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();

        // remove element
        el.remove();

        // remove snackbar
      });
    };
    const handleReload = () => {
      queryClient.refetchQueries({
        queryKey: [
          "evidence:list",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        exact: true,
      });
    };
    const handleClearFilters = () => {
      queryFilter.current.clear();
    };
    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };
    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnResize = (e) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          const col = e.columns.find((col) => col.dataField === c.dataField);
          if (col) {
            return {
              ...c,
              width: parseInt(col.width.replace(/px/g, "")),
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };
    const handleGetNextItem = (uuid) => {
      const current = records?.findIndex((i) => i.uuid === uuid);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (uuid) => {
      const current = records?.findIndex((i) => i.uuid === uuid);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    const handleRowSelection = (rows) => {
      setSelectedRows(rows);
    };
    const handleEditFinished = (e) => {
      if (e.updateData?.custom_attributes) {
        const oldAttrs = [...e.evidenceInfo.custom_attributes];
        const newAttrs = [...e.updateData.custom_attributes];

        e.updateData.custom_attributes = oldAttrs.map((oldAttr) => {
          const newAttr = newAttrs.find((a) => a.field_id === oldAttr.field_id);

          if (newAttr) {
            // remove from old attrs
            newAttrs.splice(
              newAttrs.findIndex((a) => a.field_id === newAttr.field_id),
              1
            );
            return {
              ...oldAttr,
              ...newAttr,
            };
          }
          return oldAttr;
        });

        // add any remaining old attrs
        e.updateData.custom_attributes = [
          ...e.updateData.custom_attributes,
          ...newAttrs,
        ];
      }
      updateCache({ rowData: e.evidenceInfo, updateData: e.updateData });
      setFlyoutData((prev) => ({ ...prev, ...e.updateData })); // update flyout data so that data is displayed correctly in flyout, including edit modal
      queryClient.setQueryData(
        ["evidence:list", { evidence_id: e.evidenceInfo.evidence_id }],
        (oldData) => {
          return [
            {
              ...(oldData?.[0] || {}),
              ...e.updateData,
            },
          ];
        }
      );
    };
    const updateCache = (updateDetails = { rowData: {}, updateData: {} }) => {
      const itemUUID = updateDetails.rowData.uuid;
      queryClient.setQueryData(
        [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
            },
          },
        ],
        (data) => {
          if (!data) return null;
          const newPages = data?.pages.map((page) => {
            return {
              ...page,
              data: page?.data?.map((item) => {
                if (item.uuid === itemUUID) {
                  return {
                    ...item,
                    ...updateDetails.updateData,
                  };
                }
                return item;
              }),
            };
          });

          return {
            ...data,
            pages: newPages,
          };
        }
      );
    };
    const handleEvidenceDeleted = (deletedEvidence) => {
      setIsFlyoutVisible(false);
      setFlyoutData(null);
      queryClient.setQueryData(
        [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
            },
          },
        ],
        (data) => {
          if (!data) return null;
          const newPages = data?.pages.map((page) => {
            page.data =
              page?.data?.filter(
                (item) => item.uuid !== deletedEvidence.uuid
              ) || page.data;
            return page;
          });

          return {
            ...data,
            pages: newPages,
          };
        }
      );
    };
    const handleViewChange = (item) => {
      setCurrentView(item.text);
      setQuery((q) => {
        return {
          query: {
            ...q?.query,
            user_id: item.text === "All Evidence" ? null : currentUser.user_id,
          },
        };
      });
    };

    // Save Column State to Local Storage
    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      if (!customFields) return;
      // Add custom fields to column defs
      setColumnState((cs) => {
        return addCustomFieldsToColumnDefs(customFields, cs);
      });
    }, [customFields]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    const { queryButton, conditions } = useQueryFilter({
      dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    return (
      <div className={className}>
        <Helmet title="Evidence Items" />
        <EvidenceHeader onChange={handleViewChange} />
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_CREATE)}
            onClick={(e) => {
              setShowCreateEvidenceModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Evidence
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={MonitorSmartphoneIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <Input
              placeholder="Search Evidence"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e) => {
                const currentValue = e.target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && customFields && (
          <>
            <div>{conditions}</div>
            <Table
              data={records || []}
              totalRecords={totalRecords}
              reloadFn={handleReload}
              keyValue="uuid"
              tableInstance={table}
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onRowUpdated={(e) => updateCache(e)}
              onColumnReorder={handleColumnReorder}
              onColumnResize={handleColumnResize}
              onActionButtonClick={handleActionButtonClick}
              onSelectionChanged={handleRowSelection}
              selectedRows={selectedRows}
              showActionColumn={true}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
            {isFlyoutVisible && (
              <Flyout
                onClose={() => {
                  setIsFlyoutVisible(false);
                  setFlyoutData(null);
                }}
              >
                <FlyoutHeader>
                  <div style={{ width: "100%" }}>
                    <h4
                      style={{
                        margin: "5px 0px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {flyoutData.evidence_number}
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                        }}
                      >
                        Evidence Details
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TaskButton
                          onClick={() => handleGetPrevItem(flyoutData.uuid)}
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowLeftOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                        <TaskButton
                          onClick={() => handleGetNextItem(flyoutData.uuid)}
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowRightOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                      </div>
                    </div>
                  </div>
                </FlyoutHeader>
                <EvidenceFlyoutContent
                  defaultEvidenceInfo={flyoutData}
                  editEvidence={() => setShowEditEvidenceModal(true)}
                  copyEvidence={() => setShowCopyModal(true)}
                  deleteEvidencePopup={deleteEvidencePopup}
                  setIsFlyoutVisible={setIsFlyoutVisible}
                  updateCache={updateCache}
                  columnDefs={columnState}
                />
              </Flyout>
            )}
          </>
        )}
        {!(data && customFields) && (
          <Loader message={"Retrieving Evidence..."} />
        )}
        <CreateEvidenceModal
          open={showCreateEvidenceModal}
          handleClose={() => setShowCreateEvidenceModal(false)}
          handleOpen={() => setShowCreateEvidenceModal(true)}
          navigateToItem={true}
          includeCaseField={true}
        />
        <EditEvidenceModal
          open={showEditEvidenceModal}
          onEditSubmit={handleEditFinished}
          handleClose={() => setShowEditEvidenceModal(false)}
          defaultEvidenceInfo={flyoutData}
        />
        <CreateEvidenceModal
          open={showCopyModal}
          handleClose={() => setShowCopyModal(false)}
          defaultInfo={flyoutData}
          includeCaseField={true}
          navigateToItem={true}
        />
        {useMemo(() => {
          return (
            <>
              <DeleteEvidencePopup
                deleteEvidencePopup={deleteEvidencePopup}
                onDelete={handleEvidenceDeleted}
              />
            </>
          );
        }, [])}
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default EvidenceSection;
