import { Box, Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";

import { useEffect, useRef, useState } from "react";
import {
  createCaseProgress,
  deleteCaseProgress,
  getCaseProgress,
  updateCaseProgress,
} from "../../api";
import Loader from "../../components/Loader";
import ToolBarItems from "../../components/ToolBarItems";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { usePermissions } from "../../hooks/usePermissions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

const calculateNewSortValue = (droppableArr, destination) => {
  if (!Array.isArray(droppableArr)) {
    throw new Error('Invalid data type "droppableArr" must be an array');
  }

  // If list is empty, do nothing
  if (droppableArr.length === 0)
    return droppableArr[destination.index].progress_order;

  // was the item inserted at the end of the list
  if (destination.index === droppableArr.length - 1) {
    return droppableArr[destination.index - 1].progress_order + 1;
  }

  // If item inserted at the beginning of the list
  if (destination.index === 0) {
    return droppableArr[destination.index + 1].progress_order - 1;
  }

  // if the item inserted between two items
  if (destination.index > 0 && destination.index < droppableArr.length - 1) {
    const first = droppableArr[destination.index - 1].progress_order;
    const second = droppableArr[destination.index + 1].progress_order;
    return (first + second) / 2;
  }
};

const CaseProgressItemsList = styled(
  ({
    className,
    caseProgressItems,
    setCaseProgressItems,
    setDeleteSelection,
    deleteCaseProgressPopup,
  }) => {
    useEffect(() => {
      getCaseProgress().then((result) => {
        setCaseProgressItems(result);
      });
    }, []);

    return (
      <div className={className}>
        {!caseProgressItems && <Loader />}
        <Droppable droppableId="droppable-1">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {caseProgressItems &&
                caseProgressItems.map((progress, index) => {
                  return (
                    <CaseProgressItem
                      key={progress.progress_id}
                      data={progress}
                      index={index}
                      caseProgressItems={caseProgressItems}
                      setCaseProgressItems={setCaseProgressItems}
                      setDeleteSelection={setDeleteSelection}
                      deleteCaseProgressPopup={deleteCaseProgressPopup}
                    />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const CaseProgressItem = ({
  data,
  caseProgressItems,
  setCaseProgressItems,
  setDeleteSelection,
  deleteCaseProgressPopup,
  index,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const handleTypeUpdate = (event) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.progress_name;
      return;
    }
    if (event.currentTarget.innerText !== data.progress_name) {
      updateCaseProgress(data.progress_id, {
        progress_name: event.currentTarget.innerText,
      });
      setCaseProgressItems(
        caseProgressItems.map((progress) => {
          if (progress.progress_id === data.progress_id) {
            progress.progress_name = event.currentTarget.innerText;
          }
          return progress;
        })
      );
    }
  };

  const handleDelete = (event) => {
    setDeleteSelection(data);
    deleteCaseProgressPopup.current.instance.show();
  };

  return (
    <Draggable draggableId={String(data.progress_order)} index={index}>
      {(provided) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px",
            cursor: "pointer",
            backgroundColor: theme.palette.background.default,
            "&:hover": { backgroundColor: theme.palette.action.hover },
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <DragIndicatorIcon
            fontSize="small"
            sx={{ marginRight: "10px", cursor: "move" }}
          />
          <div>
            <Box
              contentEditable={true}
              suppressContentEditableWarning
              sx={{
                fontSize: "larger",
                padding: "2px",
                cursor: "text",
                "&:hover": { outline: `1px solid slategray` },
                "&:focus": { outline: `1px solid slategray` },
              }}
              onBlur={handleTypeUpdate}
            >
              {data.progress_name}
            </Box>
            <div style={{ color: "slategrey", padding: "2px" }}>
              {data.total} Cases
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button
              variant="text"
              color="error"
              size="small"
              disabled={!hasPermission()}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </Box>
      )}
    </Draggable>
  );
};

const CreateCaseProgressPopup = ({
  createCaseProgressPopup,
  caseProgressItems,
  setCaseProgressItems,
}) => {
  const form = useRef(null);

  return (
    <Popup
      ref={createCaseProgressPopup}
      showTitle={true}
      showCloseButton={true}
      title="Create New Case Progress"
      defaultHeight={185}
      defaultWidth={400}
      onHidden={(e) => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form}>
        <SimpleItem
          dataField="progress_name"
          label={{ text: "Case Progress" }}
          isRequired={true}
        ></SimpleItem>
      </Form>
      <ToolBarItems
        submitText="Create Case Progress"
        onSubmit={() => {
          if (form.current.instance.validate().isValid) {
            let values = Object.assign(
              {},
              form.current.instance.option("formData")
            );

            createCaseProgress(values).then((result) => {
              setCaseProgressItems([
                {
                  progress_id: result.progress_id,
                  progress_name: values.progress_name,
                  progress_order:
                    caseProgressItems[caseProgressItems.length - 1]
                      .progress_order + 1,
                  total: 0,
                },
                ...caseProgressItems,
              ]);
            });

            createCaseProgressPopup.current.instance.hide();
          }
        }}
        onCancel={() => {
          createCaseProgressPopup.current.instance.hide().then(() => {
            form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const DeleteCaseProgressPopup = ({
  deleteCaseProgressPopup,
  caseProgressItems,
  setCaseProgressItems,
  deleteSelection,
}) => {
  const [items, setItems] = useState(caseProgressItems);
  const form = useRef(null);

  useEffect(() => {
    deleteSelection &&
      setItems(
        caseProgressItems.filter(
          (item) => item.progress_id !== deleteSelection.progress_id
        )
      );
  }, [deleteSelection]);

  return (
    <Popup
      ref={deleteCaseProgressPopup}
      showTitle={true}
      showCloseButton={true}
      title={`Delete Case Progress ${deleteSelection?.progress_name || ""}`}
      defaultHeight={250}
      defaultWidth={450}
      onHidden={(e) => {
        if (form.current) form.current.instance.resetValues();
      }}
    >
      {deleteSelection?.total === 0 && (
        <div style={{ margin: "15px 0px", minHeight: 115 }}>
          Are you sure you want to delete this case progress?
        </div>
      )}
      {deleteSelection?.total > 0 && (
        <div style={{ minHeight: 115 }}>
          <div style={{ margin: "15px 0px" }}>
            Select the case progress that should replace this one when deleted:
          </div>
          <Form ref={form}>
            <SimpleItem
              dataField="progress_name"
              label={{ text: "Case Progress" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: items,
                displayExpr: "progress_name",
              }}
            ></SimpleItem>
          </Form>
        </div>
      )}
      <ToolBarItems
        submitText="Delete Case Progress"
        submitColor="error"
        onSubmit={() => {
          if (form.current) {
            if (form.current.instance.validate().isValid) {
              let values = Object.assign(
                {},
                form.current.instance.option("formData")
              );
              deleteCaseProgress(deleteSelection.progress_id, {
                replace: values.progress_name.progress_name,
              });
              setCaseProgressItems(
                items.map((item) => {
                  if (item.progress_id === values.progress_name.progress_id) {
                    item.total += deleteSelection.total;
                  }
                  return item;
                })
              );
              deleteCaseProgressPopup.current.instance.hide();
            }
          } else {
            deleteCaseProgressPopup.current.instance.hide();
            deleteCaseProgress(deleteSelection.progress_id);
            setCaseProgressItems(items);
          }
        }}
        onCancel={() => {
          deleteCaseProgressPopup.current.instance.hide().then(() => {
            if (form.current) form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const CaseProgressSettings = styled(({ className }) => {
  const [caseProgressItems, setCaseProgressItems] = useState(null);
  const [deleteSelection, setDeleteSelection] = useState(null);
  const createCaseProgressPopup = useRef(null);
  const deleteCaseProgressPopup = useRef(null);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If there is no destination, do nothing
    if (!destination) {
      return;
    }

    // If user puts back in original place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newProgressItems = Array.from(caseProgressItems);

    const [removed] = newProgressItems.splice(source.index, 1);
    newProgressItems.splice(destination.index, 0, removed);

    const newSortValue = calculateNewSortValue(newProgressItems, destination);
    newProgressItems[destination.index].progress_order = newSortValue;

    setCaseProgressItems(newProgressItems);
    updateCaseProgress(newProgressItems[destination.index].progress_id, {
      progress_order: newSortValue,
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
        <div>
          Create, delete, edit, and reorder the progress events for cases. Case
          progress items can be used to illustrate the current stage of a
          particular case. These items should be edited and ordered to fit your
          organization's workflow for cases.
        </div>
        <div style={{ margin: "25px 0px" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              createCaseProgressPopup.current.instance.show();
            }}
          >
            + Create Case Progress
          </Button>
        </div>
        <CaseProgressItemsList
          caseProgressItems={caseProgressItems}
          setCaseProgressItems={setCaseProgressItems}
          setDeleteSelection={setDeleteSelection}
          deleteCaseProgressPopup={deleteCaseProgressPopup}
        />
        <CreateCaseProgressPopup
          createCaseProgressPopup={createCaseProgressPopup}
          caseProgressItems={caseProgressItems}
          setCaseProgressItems={setCaseProgressItems}
        />
        {caseProgressItems && (
          <DeleteCaseProgressPopup
            deleteCaseProgressPopup={deleteCaseProgressPopup}
            caseProgressItems={caseProgressItems}
            setCaseProgressItems={setCaseProgressItems}
            deleteSelection={deleteSelection}
            setDeleteSelection={setDeleteSelection}
          />
        )}
      </div>
    </DragDropContext>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default CaseProgressSettings;
