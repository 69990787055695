import React, { useRef } from "react";
import { DataGrid, Column } from "devextreme-react/ui/data-grid";
import { QaMetricsApi } from "../../../../api";
import { getDateFormat } from "../../../../utils/date-format";
import { Card, IconButton, Tooltip } from "@mui/material";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../../../components/Loader.js";

const ReportDataGrid = ({ reportInfo }) => {
  const reportDataGrid = useRef(null);
  const queryClient = useQueryClient();

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
        minHeight: 450,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          QA Entries
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <Tooltip arrow title="Export Grid">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  reportDataGrid.current.instance.exportToExcel();
                }}
              >
                <FileDownloadOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Refresh">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  queryClient.refetchQueries({
                    queryKey: ["reports:qa-overview", reportInfo],
                    active: true,
                    exact: true,
                  });
                }}
              >
                <SyncOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Column Select">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  reportDataGrid.current.instance.showColumnChooser();
                }}
              >
                <ViewColumnOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Table reportDataGrid={reportDataGrid} reportInfo={reportInfo} />
    </Card>
  );
};

const Table = ({ reportDataGrid, reportInfo }) => {
  const {
    isLoading,
    isFetching,
    data: qaData,
  } = useQuery({
    queryKey: ["reports:qa-overview", reportInfo],
    queryFn: () =>
      QaMetricsApi.getQaEntries(reportInfo.report_parameters || {}),
  });

  if (isLoading)
    return (
      <div style={{ marginTop: 30 }}>
        <Loader message="Retrieving Data..." />
      </div>
    );

  return (
    <DataGrid
      ref={reportDataGrid}
      dataSource={qaData}
      showRowLines={true}
      height="350px"
      allowColumnReordering={true}
      allowColumnResizing={true}
      onToolbarPreparing={(e) => {
        e.toolbarOptions.visible = false;
      }}
      paging={{
        pageSize: 20,
      }}
      stateStoring={{
        enabled: true,
        type: "localStorage",
        storageKey: "reports.qa-entries.datagrid",
        savingTimeout: 10,
      }}
      scrolling={{
        mode: "virtual",
        rowRenderingMode: "virtual",
        useNative: false,
      }}
      headerFilter={{
        visible: true,
        allowSearch: true,
      }}
      hoverStateEnabled={true}
      columnChooser={{
        enabled: true,
        mode: "select",
        title: "Filter Report Columns",
        height: "700",
        width: "325",
      }}
      loadPanel={{
        enabled: false,
      }}
    >
      <Column dataField="examiner_name" caption="Examiner" />
      <Column dataField="reviewer_name" caption="Reviewer" />
      <Column
        dataField="date"
        caption="Created On"
        visible={false}
        dataType="datetime"
        format={{
          type: getDateFormat(false, false),
        }}
      />
      <Column dataField="case_number" caption="Case Number" />
      <Column dataField="case_ref" caption="Case Name" />
      <Column dataField="evidence_number" caption="Evidence Number" />
      <Column dataField="issue_type" caption="Issue Type" />
      <Column
        dataField="resolved"
        caption="Resolved"
        visible={false}
        lookup={{ dataSource: ["Yes", "No"] }}
        cellRender={(data) => {
          if (data.value === "Yes")
            return (
              <div>
                <i
                  className="fa fa-check"
                  style={{ color: "limegreen", marginRight: "10px" }}
                ></i>
                {data.value}
              </div>
            );
          if (data.value === "No")
            return (
              <div>
                <i
                  className="fa fa-times"
                  style={{ color: "orangered", marginRight: "10px" }}
                ></i>
                {data.value}
              </div>
            );
          return <></>;
        }}
      />
      <Column dataField="item_name" caption="QA Item" visible={false} />
      <Column
        dataField="checklist_name"
        caption="QA Checklist"
        visible={false}
      />
      <Column dataField="details" caption="Details" visible={false} />
      <Column dataField="resolution" caption="Resolution" visible={false} />
      <Column dataField="notes" caption="Notes" visible={false} />
    </DataGrid>
  );
};

export default ReportDataGrid;
