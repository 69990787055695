import styled from "styled-components";
import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { getDateFormat } from "../../../utils/date-format.js";
import { usePermissions } from "../../../hooks/usePermissions";
import CaseReportsAPI from "../../../api/cases/reports.js";
import ToolBarItems from "../../../components/ToolBarItems.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { DownloadIcon, Trash2Icon, TrashIcon } from "lucide-react";
import { Button } from "@monolith-forensics/monolith-ui";

const DefaultQueryKey = "cases:reports:exports";

const CaseReportNameComponent = styled(({ className, rowData, color }) => {
  return (
    <div className={className} title="Download Report">
      {rowData.is_ready ? (
        <a
          className={"item-link"}
          href={rowData.link}
          download={rowData.report_name}
        >
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              {!!rowData.report_name ? rowData.report_name : "View Report"}
            </div>
            <DownloadOutlinedIcon
              className="action-button"
              style={{ fontSize: 13, marginLeft: 5 }}
            />
          </div>
        </a>
      ) : (
        <>{rowData.report_name}</>
      )}
    </div>
  );
})`
  & {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & .item-link {
    color: ${({ color, theme }) => color || theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActionCellOld = ({ rowData }) => {
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const handleSubmit = () => {
    queryClient.refetchQueries({
      queryKey: [DefaultQueryKey],
      type: "active",
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <DropdownMenu
          menuItems={[
            {
              label: "Delete",
              icon: TrashIcon,
              iconColor: "orangered",
              onClick: () => setShowDeleteModel(true),
            },
          ]}
          title={"Actions"}
          variant="outlined"
          disabled={!hasPermission()}
        >
          <MoreHorizOutlinedIcon style={{ fontSize: 13 }} />
        </DropdownMenu>
      </div>
      <DeleteModal
        open={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
    </>
  );
};

const ActionCell = ({ rowData }) => {
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const handleSubmit = () => {
    queryClient.refetchQueries({
      queryKey: [DefaultQueryKey],
      type: "active",
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        width: "100%",
        userSelect: "none",
      }}
    >
      <DeleteModal
        open={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
      {rowData.is_ready && (
        <Button
          size="xxs"
          variant="text"
          color="primary"
          href={rowData.link}
          download={rowData.report_name}
          leftSection={<DownloadIcon size={12} />}
        >
          Download
        </Button>
      )}
      <Button
        size="xxs"
        variant="text"
        color="error"
        leftSection={<Trash2Icon size={12} />}
        onClick={() => {
          setShowDeleteModel(true);
        }}
      >
        Delete
      </Button>
    </div>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    CaseReportsAPI.deleteCaseReportExport({
      report_id: defaultInfo.report_id,
    }).then(() => {
      enqueueSnackbar(`Case Report Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete Case Report
          </div>
          <div style={{ margin: "10px 0px" }}>
            Are you sure you want to delete this Case Report?
          </div>
          <div style={{ margin: "10px 0px" }}>"{defaultInfo.report_name}"</div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Delete Case Report"
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ReportDetailsColumnDefs = [
  {
    dataField: "report_name",
    caption: "Name",
    titleRender: (rowData) => rowData.report_name,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    format: {
      type: getDateFormat(true, true),
    },
  },
  {
    dataField: "created_by",
    caption: "Created By",
  },
  {
    dataField: "status",
    caption: "Status",
  },
  {
    dataField: "is_shared",
    caption: "",
    render: (rowData) => <ActionCell rowData={rowData} />,
  },
];

export default ReportDetailsColumnDefs.slice();
