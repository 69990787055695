import CaseMembers from "./CaseMembers";
import CaseProgress from "./CaseProgress";
import styled from "styled-components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import CasesApi from "../../../api/cases/index.js";
import CaseColumnDefs from "../../../components/Cases/CaseColumnDefs.js";
import { monolithMoment } from "../../../utils/date-format.js";
import { nanoid } from "nanoid";
import resolveCustomFieldValue from "../../../utils/customFieldValueResolver.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { MoreHorizontalIcon } from "lucide-react";
import Loader from "../../../components/Loader.js";
import convertBytesToSize from "../../../utils/convertBytesToSize.js";
import BasicProgressBar from "../../../Monolith-UI/BasicProgressBar/BasicProgressBar.js";
import { formatCurrency } from "../../../utils/currency-formatter.js";
import { CustomAttribute } from "@/types";

interface NewMetric {
  metric: string;
  name: string;
}

interface Metric {
  metric: string;
  data: {
    active_tasks: number;
    completed_tasks: number;
    total_tasks: number;
  };
  name: string;
}

interface SelectedStats {
  stat_1: string;
  stat_2: string;
  stat_3: string;
}

const StatList = [
  {
    metric: "active_tasks",
    name: "Active Tasks",
  },
  {
    metric: "my_active_tasks",
    name: "My Active Tasks",
  },
  {
    metric: "time_tracked",
    name: "Time Tracked",
  },
  {
    metric: "my_time_tracked",
    name: "My Time Tracked",
  },
  {
    metric: "billed_hours",
    name: "Billed Hours",
  },
  {
    metric: "unbilled_hours",
    name: "Unbilled Hours",
  },
  {
    metric: "evidence_count",
    name: "Evidence Count",
  },
  {
    metric: "acquisition_count",
    name: "Acquisition Count",
  },
  {
    metric: "storage_count",
    name: "Storage Count",
  },
  {
    metric: "case_docs_count",
    name: "File Count",
  },
];

const TimeTrackedComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.time_tracked} h</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">Billed Hours</div>
          <div className="detail-value">{data.total_billed}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Billed Amount</div>
          <div className="detail-value">
            {formatCurrency(data.total_billed_price)}
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Un-Billed Hours</div>
          <div className="detail-value">{data.total_unbilled}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Un-billed Amount</div>
          <div className="detail-value">
            {formatCurrency(data.total_unbilled_price)}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const MyTimeTrackedComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.time_tracked} h</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">Billed Hours</div>
          <div className="detail-value">{data.total_billed}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Billed Amount</div>
          <div className="detail-value">
            {formatCurrency(data.total_billed_price)}
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Un-Billed Hours</div>
          <div className="detail-value">{data.total_unbilled}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Un-billed Amount</div>
          <div className="detail-value">
            {formatCurrency(data.total_unbilled_price)}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const BilledHoursComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.billed_hours} h</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">Billed Amount</div>
          <div className="detail-value">
            {formatCurrency(data.total_billed)}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const UnbilledHoursComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.unbilled_hours} h</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">Un-Billed Amount</div>
          <div className="detail-value">
            {formatCurrency(data.total_unbilled)}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const ActiveTasksComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;

  const completedPercentage = Math.round(
    (data.completed_tasks / data.total_tasks) * 100
  );

  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.active_tasks} Tasks</div>
      </div>
      <div
        className="additional-content"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          justifyContent: "center",
        }}
      >
        {data.total_tasks > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="detail-label">{data.completed_tasks}</div>
              <div className="detail-label">{data.total_tasks}</div>
            </div>
            <BasicProgressBar percentage={completedPercentage} />
          </div>
        ) : (
          <div className="empty-data-text">Create tasks to start tracking</div>
        )}
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;

  .empty-data-text {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const MyActiveTasksComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;

  const completedPercentage = Math.round(
    (data.completed_tasks / data.total_tasks) * 100
  );

  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.active_tasks} Tasks</div>
      </div>
      <div
        className="additional-content"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          justifyContent: "center",
        }}
      >
        {data.total_tasks > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="detail-label">{data.completed_tasks}</div>
              <div className="detail-label">{data.total_tasks}</div>
            </div>
            <BasicProgressBar percentage={completedPercentage} />
          </div>
        ) : (
          <div className="empty-data-text">Create tasks to start tracking</div>
        )}
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;

  .empty-data-text {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const EvidenceCountComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.evidence_count} Items</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">In Custody</div>
          <div className="detail-value">{data.in_custody_count}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Un-Tracked</div>
          <div className="detail-value">{data.untracked_count}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Total Capacity</div>
          <div className="detail-value">
            {convertBytesToSize(data.total_size_bytes)}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const AcquisitionCountComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.acquisition_count} Items</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">Active</div>
          <div className="detail-value">{data.active_count}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Deleted</div>
          <div className="detail-value">{data.delete_count}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Total Active Data</div>
          <div className="detail-value">
            {convertBytesToSize(data.active_size_bytes)}
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Total Data</div>
          <div className="detail-value">
            {convertBytesToSize(data.total_size_bytes)}
          </div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const StorageCountComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.storage_count} Items</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">In Custody</div>
          <div className="detail-value">{data.in_custody_count}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Released</div>
          <div className="detail-value">{data.released_count}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Un-Tracked</div>
          <div className="detail-value">{data.untracked_count}</div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const CaseDocCountComponent = styled(({ className, statInfo }) => {
  const { data, name } = statInfo;
  return (
    <div className={className}>
      <div className="main-content">
        <div className="stat-title">{name}</div>
        <div className="stat-value">{data.file_count} Files</div>
      </div>
      <div className="additional-content">
        <div className="detail-item">
          <div className="detail-label">Data Stored</div>
          <div className="detail-value">
            {convertBytesToSize(data.total_size_bytes)}
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Folder Count</div>
          <div className="detail-value">{data.folder_count}</div>
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const StatWidget = styled(({ className, statInfo, onMetricChanged }) => {
  const [selectedWidget, setSelectedWidget] = useState(statInfo);

  const menuItems = [
    {
      header: true,
      label: "Select Stat",
    },
    ...StatList.map((s) => {
      return {
        label: s.name,
        value: s.metric,
        onClick: () => {
          setSelectedWidget(s);
          onMetricChanged?.(s);
        },
      };
    }),
  ];

  return (
    <div className={className}>
      {!statInfo ? (
        <></>
      ) : (
        <>
          <div className="stat-selector">
            <DropdownMenu
              defaultValue={null}
              dropdownContent={null}
              menuItems={menuItems}
              variant="text"
            >
              <MoreHorizontalIcon size={12} />
            </DropdownMenu>
          </div>
          {selectedWidget.metric === "active_tasks" && (
            <ActiveTasksComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "my_active_tasks" && (
            <MyActiveTasksComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "time_tracked" && (
            <TimeTrackedComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "my_time_tracked" && (
            <MyTimeTrackedComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "billed_hours" && (
            <BilledHoursComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "unbilled_hours" && (
            <UnbilledHoursComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "evidence_count" && (
            <EvidenceCountComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "acquisition_count" && (
            <AcquisitionCountComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "storage_count" && (
            <StorageCountComponent statInfo={statInfo} />
          )}
          {selectedWidget.metric === "case_docs_count" && (
            <CaseDocCountComponent statInfo={statInfo} />
          )}
        </>
      )}
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 200px;
  padding: 18px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.divider};

  .main-content {
    padding: 10px;
    background-color: ${({ theme }) =>
      theme.name === "DARK" ? "#2d2d2d" : "#f5f5f5"};
    border-radius: 4px;

    .stat-title {
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.text.secondary};
      margin-bottom: 10px;
    }

    .stat-value {
      font-size: 18px;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }

  .stat-selector {
    position: absolute;
    top: 30px;
    right: 30px;

    svg {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }

  .additional-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-gap: 10px;
    padding: 10px;

    .detail-item {
    }

    .detail-label {
      font-size: 0.75rem;
      font-weight: 300;
      color: ${({ theme }) => theme.palette.text.secondary};
      cursor: default;
    }

    .detail-value {
      user-select: text;
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.text.primary};
      cursor: default;

      &.no-cap {
        text-transform: none;
      }
    }
  }
`;

const StatWidgets = styled(({ className, caseInfo }) => {
  const caseUiState = localStorage.getItem("case-ui-state:overview-stats");
  const [selectedStats, setSelectedStats] = useState<SelectedStats>(
    caseUiState
      ? JSON.parse(caseUiState)
      : {
          stat_1: "evidence_count",
          stat_2: "my_active_tasks",
          stat_3: "active_tasks",
        }
  );

  const stats = Object.values(selectedStats).map((s) => s);

  const { data } = useQuery({
    queryKey: [
      "cases",
      "metrics",
      {
        case_id: caseInfo.case_id,
        stats,
      },
    ],
    queryFn: () => CasesApi.metrics({ case_id: caseInfo.case_id, stats }),
    placeholderData: (data) => data,
  });

  const handleMetricChanged = (newMetric: NewMetric, widgetStat: string) => {
    setSelectedStats((prev: SelectedStats) => {
      localStorage.setItem(
        "case-ui-state:overview-stats",
        JSON.stringify({
          ...prev,
          [widgetStat]: newMetric.metric,
        })
      );

      return {
        ...prev,
        [widgetStat]: newMetric.metric,
      };
    });
  };

  const metrics: Metric[] = data?.data || [];

  return (
    <div className={className}>
      {!data && <Loader message="Loading Stats..." />}
      {data && (
        <>
          <StatWidget
            statInfo={metrics.find((m) => m.metric === selectedStats.stat_1)}
            onMetricChanged={(newmetric: NewMetric) =>
              handleMetricChanged(newmetric, "stat_1")
            }
          />
          <StatWidget
            statInfo={metrics.find((m) => m.metric === selectedStats.stat_2)}
            onMetricChanged={(newMetric: NewMetric) =>
              handleMetricChanged(newMetric, "stat_2")
            }
          />
          <StatWidget
            statInfo={metrics.find((m) => m.metric === selectedStats.stat_3)}
            onMetricChanged={(newMetric: NewMetric) =>
              handleMetricChanged(newMetric, "stat_3")
            }
          />
        </>
      )}
    </div>
  );
})`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-gap: 10px;
  min-height: 185px;
  position: relative;
`;

const CaseDetails = styled(({ className, caseID }) => {
  const [viewState, setViewState] = useState({
    caseDetails: true,
    advancedDetails: true,
  });

  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "cases",
      "list",
      {
        case_id: parseInt(caseID),
      },
    ],
    queryFn: () =>
      CasesApi.getCases({
        case_id: parseInt(caseID),
      }),
    select: (res) => {
      return res[0];
    },
  });

  if (isLoading) return <Loader message="Loading Case Details..." />;

  const caseData = data || {};

  const details = CaseColumnDefs.filter(
    (c) => !["case_synopsis", "assigned_users"].includes(c.dataField)
  ).map((c) => {
    return (
      <div className="detail-item" key={nanoid()}>
        <div className="detail-label">{c.caption}</div>
        {c.render ? (
          <div className="detail-value">
            {c.render(caseData, { onRowUpdated: () => refetch() })}
          </div>
        ) : c.dataType === "date" ? (
          <div className="detail-value">
            {monolithMoment({
              timestamp: caseData[c.dataField],
              includeTime: true,
            })}
          </div>
        ) : (
          <div className="detail-value">{caseData[c.dataField]}</div>
        )}
      </div>
    );
  });

  const customDetails = caseData?.custom_attributes
    .filter((c: CustomAttribute) => !!c.value)
    .map((c: CustomAttribute) => {
      return (
        <div className="detail-item" key={nanoid()}>
          <div className="detail-label">{c.field_name}</div>
          <div className="detail-value">{resolveCustomFieldValue(c)}</div>
        </div>
      );
    });

  return (
    <div className={className}>
      <div className="case-details">
        <div className="header">
          <div className="title">Case Details</div>
        </div>
        {viewState.caseDetails && (
          <>
            <div className="details-grid">{details}</div>
            <div className="title">Custom Fields</div>
            <div className="details-grid">{customDetails}</div>
            <div className="detail-item" style={{ marginBottom: 0 }}>
              <div className="title" style={{ marginBottom: 5 }}>
                Description
              </div>
              <div
                className="detail-value no-cap"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {caseData.case_synopsis || "No Description"}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  gap: 20px;

  .case-details {
    user-select: none;
    flex-grow: 1;

    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
    padding: 18px;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .action-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .title {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 3fr));
    grid-gap: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .detail-item {
  }

  .detail-label {
    font-size: 0.75rem;
    font-weight: 300;
    color: ${({ theme }) => theme.palette.text.secondary};
    cursor: default;
  }

  .detail-value {
    user-select: text;
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.text.primary};
    cursor: default;

    text-transform: capitalize;

    &.no-cap {
      text-transform: none;
    }
  }
`;

const CaseOverview = styled(({ className }) => {
  const { case_id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: [
      "cases",
      "list",
      {
        case_id: case_id ? parseInt(case_id) : null,
      },
    ],
    queryFn: () =>
      CasesApi.getCases({
        case_id: case_id ? parseInt(case_id) : null,
      }),
  });

  if (isLoading) return <Loader message="Loading Case Overview..." />;

  const caseInfo = data?.[0] || {};

  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          gap: 10,
        }}
      >
        <>
          <CaseProgress caseInfo={caseInfo} />
          <StatWidgets caseInfo={caseInfo} />
          <div className="case-details-container">
            <CaseDetails caseID={case_id ? parseInt(case_id) : null} />
            <CaseMembers caseInfo={{ case_id }} />
          </div>
        </>
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;

  overflow-y: auto;
  height: 0px;
  padding-right: 10px;

  .case-details-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    gap: 10px;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
`;

export default CaseOverview;
