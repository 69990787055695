import styled from "styled-components";
import { TextInput, Button, SelectBox } from "@monolith-forensics/monolith-ui";
import { useEffect, useState } from "react";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";
import MattermostAPI from "../../../../api/integrations/Mattermost/Mattermost.js";
import { useQuery } from "@tanstack/react-query";
import ButtonMenu from "../../../../components/Forms/components/ButtonMenu.js";

const Title = styled.h1`
  margin: 0;
`;

const Title2 = styled.h2`
  margin: 0;
`;

const SubTitle = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const testApiSchema = z.object({
  host: z.string({ message: "Host is required" }).min(1, {
    message: "Host is required",
  }),
  token: z.string({ message: "Token is required" }).min(1, {
    message: "Token is required",
  }),
});

const MattermostTestForm = styled(
  ({ className, defaultFormData = {}, onSubmit }) => {
    const [submitting, setSubmitting] = useState(false);
    const [apiWorks, setApiWorks] = useState(
      !!(defaultFormData.host && defaultFormData.token)
    );
    const [apiError, setApiError] = useState(false);

    const { data: testResult, isLoading } = useQuery({
      queryKey: [
        "mattermost",
        "test",
        { host: defaultFormData.host, token: defaultFormData.token },
      ],
      queryFn: () =>
        MattermostAPI.testAPI({
          host: defaultFormData.host,
          token: defaultFormData.token,
        }),
      enabled: defaultFormData.host && defaultFormData.token && apiWorks,
    });

    const form = useForm({
      mode: "uncontrolled",
      initialValues: {
        host: defaultFormData.host || null,
        token: defaultFormData.token || null,
      },
      validate: zodResolver(testApiSchema),
    });

    const handleTestAPI = async () => {
      try {
        const validateResult = form.validate();

        if (validateResult.hasErrors) {
          return;
        }

        setSubmitting(true);

        // sleep 500 ms - simulate API timing
        await new Promise((resolve) => setTimeout(resolve, 300));

        // test API URL and Token
        const { host, token } = form.getValues();

        const testResult = await MattermostAPI.testAPI({ host, token });

        if (testResult.success) {
          const updateResult = await MattermostAPI.updateSettings({
            host,
            token,
          });

          if (updateResult.success) {
            setApiWorks(true);
          } else {
            setApiError(true);
          }
        } else {
          setApiError(true);
          setApiWorks(false);
        }

        setSubmitting(false);
        onSubmit?.({ host, token });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setApiError(true);
        setApiWorks(false);
      }
    };

    useEffect(() => {
      if (testResult !== undefined) {
        if (testResult?.success) {
          setApiWorks(true);
          setApiError(false);
        } else {
          setApiWorks(false);
          setApiError(true);
        }
      }
    }, [testResult]);

    return (
      <div className={className}>
        <div>
          <Title2>Connect API</Title2>
          <SubTitle>
            Set the Mattermost API host and personal access token to connect to
            your Mattermost instance.
          </SubTitle>
        </div>
        <TextInput
          size="sm"
          required
          placeholder="http://192.168.1.1:8065, https://mattermost.domain.com, etc..."
          label="Mattermost API Host"
          key={form.key("host")}
          {...form.getInputProps("host")}
        />
        <TextInput
          size="sm"
          required
          placeholder="a7as7das9dasdfa7qwr7fv"
          label="Personal Access Token"
          key={form.key("token")}
          {...form.getInputProps("token")}
        />
        <ButtonMenu style={{ justifyContent: "start" }}>
          <Button
            variant="contained"
            size="xs"
            onClick={handleTestAPI}
            loading={submitting}
          >
            {apiWorks ? "Update API Settings" : "Save API Settings"}
          </Button>
          {!apiError && apiWorks && (
            <span style={{ color: "limegreen", fontWeight: "bold" }}>
              API Connection Successful
            </span>
          )}
          {apiError && (
            <span style={{ color: "orangered", fontWeight: "bold" }}>
              API Connection Error
            </span>
          )}
        </ButtonMenu>
      </div>
    );
  }
)`
  h2 {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
  gap: 20px;
`;

const selectTeamSchema = z.object({
  team_id: z
    .string({ message: "Team is required" })
    .min(1, { message: "Team is required" }),
});

const SelectTeamForm = styled(
  ({ className, defaultFormData = {}, onSubmit }) => {
    const [submitting, setSubmitting] = useState(false);

    const { data, isLoading } = useQuery({
      queryKey: ["mattermost", "teams"],
      queryFn: () => MattermostAPI.getTeams(),
    });

    const form = useForm({
      mode: "uncontrolled",
      initialValues: {
        team_id: defaultFormData.team_id || null,
      },
      validate: zodResolver(selectTeamSchema),
    });

    const selectTeam = async (team) => {
      const validateResult = form.validate();

      if (validateResult.hasErrors) {
        return;
      }

      const formData = form.getValues();

      setSubmitting(true);

      // sleep 300 ms - simulate API timing
      await new Promise((resolve) => setTimeout(resolve, 300));

      // test API URL and Token
      const { team_id } = formData;

      await MattermostAPI.updateSettings({
        team: data?.data?.find((team) => team.id === team_id),
      });

      setSubmitting(false);
      onSubmit?.();
    };

    if (isLoading) return null;

    return (
      <div className={className}>
        <div>
          <Title2>Select Team</Title2>
          <SubTitle>
            Choose the Mattermost team that Monolith will send messages to.
          </SubTitle>
        </div>
        <SelectBox
          label="Team"
          size="sm"
          placeholder="Select a team..."
          required
          data={
            data?.data?.map((team) => ({
              label: team.display_name,
              value: team.id,
            })) || []
          }
          key={form.key("team_id")}
          {...form.getInputProps("team_id")}
        />
        <Button
          variant="contained"
          size="xs"
          onClick={selectTeam}
          loading={submitting}
        >
          {defaultFormData.active ? "Update Team" : "Complete Setup"}
        </Button>
      </div>
    );
  }
)`
  h2 {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
  gap: 20px;
`;

const MattermostIntegration = styled(({ className }) => {
  const [completingSetup, setCompletingSetup] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["mattermost", "settings"],
    queryFn: () => MattermostAPI.getSettings(),
  });

  if (isLoading) return null;

  const settings = data?.data?.settings || {};
  const { host, token, team, status } = settings;

  const handleCompleteSetup = async () => {
    setCompletingSetup(true);
    // complete setup
    await MattermostAPI.completeSetup();

    // sleep 300 ms
    await new Promise((resolve) => setTimeout(resolve, 300));

    setCompletingSetup(false);
    refetch();
  };

  const handleRemoveSetup = async () => {
    setSubmitting(true);

    await MattermostAPI.removeSetup();

    // sleep 300 ms
    await new Promise((resolve) => setTimeout(resolve, 300));

    setSubmitting(false);

    refetch();
  };

  const isActive = status === "active";

  return (
    <div className={className}>
      <div>
        <Title>Mattermost Setup</Title>
        <SubTitle>
          Connect Monolith to your Mattermost instance to receive messages and
          notifications.
        </SubTitle>
      </div>
      <div>
        <Title2>Current Status</Title2>
        {!completingSetup && (
          <SubTitle>
            <span
              style={{
                color: isActive ? "limegreen" : "orangered",
                fontWeight: "bold",
              }}
            >
              {isActive ? "Active" : "Inactive"}
            </span>{" "}
            -{" "}
            {isActive
              ? "Messages will be sent to Mattermost"
              : "No messages will be sent"}
          </SubTitle>
        )}
        {completingSetup && <SubTitle>Completing setup...</SubTitle>}
      </div>
      {isActive && (
        <Button
          variant="contained"
          size="xs"
          onClick={handleRemoveSetup}
          loading={submitting}
        >
          Remove Setup
        </Button>
      )}
      {
        <MattermostTestForm
          defaultFormData={{ host, token }}
          onSubmit={() => refetch()}
        />
      }
      {host && token && (
        <SelectTeamForm
          defaultFormData={{ team_id: team?.id, active: isActive }}
          onSubmit={() => handleCompleteSetup()}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
  gap: 20px;

  width: 600px;
`;

export default MattermostIntegration;
