import React, { useRef, useState } from "react";
import { DataGrid } from "devextreme-react/ui/data-grid";
import { Card, IconButton, Tooltip } from "@mui/material";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import { getStorageReport } from "../../../../api/index.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { storageDimensions } from "./StorageReport.js";
import Loader from "../../../../components/Loader.js";
import { getDateFormat } from "../../../../utils/date-format.js";
import DropDownSelector from "../../../../components/DropDownSelector.js";

const StorageItemGrid = ({ reportInfo }) => {
  const [currentView, setCurrentView] = useState(0);
  const storageReportGrid = useRef(null);
  const hasTimeDimension =
    reportInfo.report_parameters &&
    reportInfo.report_parameters.timestamp_dimension;

  const queryClient = useQueryClient();

  return (
    <Card
      style={{
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Storage Items
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              marginRight: 10,
            }}
          >
            <Tooltip arrow title="Export Grid">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  storageReportGrid.current.instance.exportToExcel();
                }}
              >
                <FileDownloadOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Refresh">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  queryClient.invalidateQueries({
                    queryKey: ["reports:storage-overview"],
                  });
                }}
              >
                <SyncOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Column Select">
              <IconButton
                color="primary"
                size="small"
                disableRipple={false}
                onClick={(e) => {
                  storageReportGrid.current.instance.showColumnChooser();
                }}
              >
                <ViewColumnOutlinedIcon />
              </IconButton>
            </Tooltip>
            {!hasTimeDimension && (
              <DropDownSelector
                style={{ marginLeft: "5px" }}
                items={storageDimensions}
                useSelectMode={true}
                selectedItem={currentView}
                displayExpr="text"
                variant="contained"
                onSelect={(item) => {
                  setCurrentView(item.index);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Table
        reportInfo={reportInfo}
        storageReportGrid={storageReportGrid}
        currentView={currentView}
        hasTimeDimension={hasTimeDimension}
      />
    </Card>
  );
};

const Table = ({
  storageReportGrid,
  reportInfo,
  currentView,
  hasTimeDimension,
}) => {
  const query = {
    ...(reportInfo.report_parameters || {}),
    timestamp_dimension:
      hasTimeDimension || storageDimensions[currentView].value,
  };

  const {
    isLoading,
    isFetching,
    data: storageItems,
  } = useQuery({
    queryKey: ["reports:storage-overview", query],
    queryFn: () => getStorageReport(query),
  });

  if (isLoading)
    return (
      <div style={{ marginTop: 30 }}>
        <Loader message="Retrieving Data..." />
      </div>
    );

  return (
    <DataGrid
      ref={storageReportGrid}
      dataSource={storageItems}
      height="350px"
      columns={[
        {
          dataField: "storage_number",
          caption: "Storage Number",
        },
        {
          dataField: "is_assigned",
          visible: false,
          caption: "Is Assigned to Case",
          calculateCellValue: (rowData) => {
            return rowData.is_assigned === 0 ? "No" : "Yes";
          },
        },
        {
          dataField: "date_added",
          visible: currentView === 0,
          dataType: "date",
          format: {
            type: getDateFormat(false, false),
          },
          caption: "Date Created",
        },
        {
          dataField: "date_assigned",
          visible: currentView === 1,
          caption: "Date Assigned",
          dataType: "date",
          format: {
            type: getDateFormat(false, false),
          },
        },
        {
          dataField: "date_released",
          visible: currentView === 2,
          dataType: "date",
          format: {
            type: getDateFormat(false, false),
          },
          caption: "Date Released",
        },
        {
          dataField: "case_number",
          caption: "Assigned Case",
        },
        {
          dataField: "client_name",
          caption: "Client",
        },
        {
          dataField: "client_org",
          caption: "Client Organization",
        },
        {
          dataField: "is_general_storage",
          visible: false,
          caption: "General Storage",
          alignment: "left",
          calculateCellValue: (rowData) => {
            return rowData.is_general_storage === 0 ? "No" : "Yes";
          },
        },
        {
          dataField: "type",
          caption: "Type",
        },
        {
          dataField: "make",
          caption: "Make",
        },
        {
          dataField: "model_name",
          caption: "Model",
        },
        {
          dataField: "model_number",
          visible: false,
          caption: "Model Number",
        },
        {
          dataField: "serial_number",
          visible: false,
          caption: "Serial Number",
        },
        {
          dataField: "capacity",
          caption: "Size",
          alignment: "left",
          headerFilter: { groupInterval: 100 },
          calculateSortValue: (rowData) => {
            return rowData.capacity_bytes;
          },
          cellTemplate: (container, options) => {
            if (
              options.data.capacity != null &&
              options.data.capacity_unit != null
            ) {
              container.innerHTML =
                options.data.capacity + " " + options.data.capacity_unit;
            }
          },
        },
        {
          dataField: "capacity_unit",
          caption: "Unit",
          visible: false,
        },
        {
          dataField: "used_space",
          visible: false,
          caption: "Used Space",
          alignment: "left",
          allowHeaderFiltering: true,
          calculateCellValue: (rowData) => {
            let value = null;

            if (
              rowData.capacity_bytes != null &&
              rowData.free_space_bytes != null &&
              rowData.capacity_unit != null
            ) {
              if (rowData.capacity_unit == "KB") {
                value = (
                  (rowData.capacity_bytes - rowData.free_space_bytes) /
                  1024
                ).toFixed(2);
              } else if (rowData.capacity_unit == "MB") {
                value = (
                  (rowData.capacity_bytes - rowData.free_space_bytes) /
                  (1024 * 1024)
                ).toFixed(2);
              } else if (rowData.capacity_unit == "GB") {
                value = (
                  (rowData.capacity_bytes - rowData.free_space_bytes) /
                  (1024 * 1024 * 1024)
                ).toFixed(2);
              } else if (rowData.capacity_unit == "TB") {
                value = (
                  (rowData.capacity_bytes - rowData.free_space_bytes) /
                  (1024 * 1024 * 1024 * 1024)
                ).toFixed(2);
              }

              return value;
            }
          },
          calculateSortValue: (rowData) => {
            return rowData.capacity_bytes - rowData.free_space_bytes;
          },
          cellTemplate: (container, options) => {
            if (options.value)
              container.innerHTML =
                options.value + " " + options.data.capacity_unit;
          },
        },
        {
          dataField: "free_space_bytes",
          visible: false,
          caption: "Free Space",
          alignment: "left",
          allowHeaderFiltering: true,
          calculateCellValue: (rowData) => {
            let value = null;

            if (
              rowData.free_space_bytes != null &&
              rowData.capacity_unit != null
            ) {
              if (rowData.capacity_unit == "KB") {
                value = (rowData.free_space_bytes / 1024).toFixed(2);
              } else if (rowData.capacity_unit == "MB") {
                value = (rowData.free_space_bytes / (1024 * 1024)).toFixed(2);
              } else if (rowData.capacity_unit == "GB") {
                value = (
                  rowData.free_space_bytes /
                  (1024 * 1024 * 1024)
                ).toFixed(2);
              } else if (rowData.capacity_unit == "TB") {
                value = (
                  rowData.free_space_bytes /
                  (1024 * 1024 * 1024 * 1024)
                ).toFixed(2);
              }

              return value;
            }
          },
          calculateSortValue: (rowData) => {
            return rowData.free_space_bytes;
          },
          cellTemplate: (container, options) => {
            if (options.value)
              container.innerHTML =
                options.value + " " + options.data.capacity_unit;
          },
        },
        {
          dataField: "used_space_percent",
          visible: false,
          caption: "Percent Used",
          alignment: "left",
          dataType: "number",
          allowHeaderFiltering: true,
          calculateSortValue: (rowData) => {
            return rowData.used_space_percent;
          },
          calculateCellValue: (rowData) => {
            return (rowData.used_space_percent || 0).toFixed(2);
          },
          cellTemplate: (container, options) => {
            container.innerHTML = options.value + " %";

            if (options.data.used_space_percent > 85) {
              container.style.backgroundImage = "linear-gradient(red, red)";
            } else if (options.data.used_space_percent > 60) {
              container.style.backgroundImage =
                "linear-gradient(orange, orange)";
            } else {
              container.style.backgroundImage =
                "linear-gradient(limegreen, limegreen)";
            }

            container.style.backgroundRepeat = "no-repeat";
            container.style.backgroundSize =
              options.data.used_space_percent + "% 20%";
            container.style.backgroundPosition = "left bottom";
          },
        },
        {
          dataField: "location_name",
          caption: "Location",
          visible: false,
        },
        {
          dataField: "notes",
          caption: "Notes",
          visible: false,
        },
      ]}
      showRowLines={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      onToolbarPreparing={(e) => {
        e.toolbarOptions.visible = false;
      }}
      paging={{
        pageSize: 20,
      }}
      stateStoring={{
        enabled: false,
        type: "localStorage",
        storageKey: "reportDetails-grid",
        savingTimeout: 10,
      }}
      scrolling={{
        mode: "virtual",
        rowRenderingMode: "virtual",
        useNative: false,
      }}
      headerFilter={{
        visible: true,
        allowSearch: true,
      }}
      hoverStateEnabled={true}
      columnChooser={{
        enabled: true,
        mode: "select",
        title: "Filter Report Columns",
        height: "350",
        width: "325",
      }}
    />
  );
};

export default StorageItemGrid;
