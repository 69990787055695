import styled from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import TasksAPI from "../../../api/tasks/tasks.js";
import UserApi from "../../../api/users/users.js";
import moment from "moment";
import { db_timestamp } from "../../../utils/date-format.js";
import TimeEntriesAPI from "../../../api/TimeEntries/time-entries.js";
import { useDebouncedCallback } from "use-debounce";
import shortUUID from "short-uuid";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import AddTaskModal from "./AddTaskModal.js";
import { Link, useParams } from "react-router-dom";
import TaskStatusSelector from "./TaskStatusSelector.js";
import TaskStatuses from "./TaskStatuses.js";
import TaskPrioritySelector from "./TaskPrioritySelector.js";
import TaskPriorities from "./TaskPriorities.js";
import TaskDuedateSelector from "./TaskDueDateSelector.js";
import TaskDurationSelector from "./TaskDurationSelector.js";
import DetailItem from "../../../Monolith-UI/DetailItem.js";
import { ArrowLeftIcon, PlusIcon, SaveIcon } from "lucide-react";
import AssigneeSelector from "./AssigneeSelector.js";
import TaskCategorySelector from "./TaskCategorySelector.js";
import LinkedObject from "./LinkedObject.js";
import AddCustomTimeModal from "./AddCustomTimeModal.js";
import SaveTaskTemplateModal from "./SaveTaskTemplateModal.js";
import { TextAreaInput, TextInput } from "@monolith-forensics/monolith-ui";

const TaskOuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const TaskInnerContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 35px;
`;

const TaskContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  width: 100%;
`;

const SubTasksContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 10px 0px;
`;

const SubTaskItem = styled.div`
  height: 35px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  transition: background-color 0.1s ease;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

const SubTaskName = styled.div``;

const TaskSidebar = styled.div`
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  width: 400px;
  padding: 5px 15px;
  padding-left: 25px;
`;

const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 30px;
`;

const TaskViewer = styled(({ className }) => {
  const { currentUser } = usePermissions();
  const { item_id, case_id: caseID } = useParams();
  const case_id = parseInt(caseID, 10);
  const queryClient = useQueryClient();
  const [showSubtaskForm, setShowSubtaskForm] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showCustomTimeModal, setShowCustomTimeModal] = useState(false);
  const nameInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const defaultQuery = [
    "tasks:list",
    { uuid: item_id, data_only: true, include_subtasks: true },
  ];

  const { data, refetch } = useQuery({
    queryKey: defaultQuery,
    queryFn: () => TasksAPI.getTasks(defaultQuery[1]),
    initialData: [],
  });

  const { data: caseUsers } = useQuery({
    queryKey: ["users:list", { case_id: data?.[0]?.case_id }],
    queryFn: () => UserApi.getUsers({ case_id: data?.[0]?.case_id }),
    placeholderData: (data) => data,
    enabled: !!data?.[0],
  });

  const { data: TaskCategories } = useQuery({
    queryKey: ["task:categories"],
    queryFn: () => TimeEntriesAPI.getTimeCategories(),
  });

  const taskState = data?.[0];

  const updateCache = (updateData) => {
    if (updateData.subtaskUUID) {
      queryClient.setQueryData(defaultQuery, (oldData) => {
        const prevTask = oldData?.[0] || {};
        const newSubTasks = (prevTask?.subtasks || []).map((subtask) => {
          if (subtask.uuid === updateData.subtaskUUID) {
            return {
              ...subtask,
              ...updateData,
            };
          }
          return subtask;
        });

        return [
          {
            ...prevTask,
            updated_on: moment().toISOString(), // added to force re-render
            subtasks: newSubTasks,
          },
        ];
      });
    } else {
      queryClient.setQueryData(defaultQuery, (oldData) => {
        const prevTask = oldData?.[0] || {};
        return [
          {
            ...prevTask,
            ...updateData,
          },
        ];
      });
    }
  };

  const handleAddSubTask = () => {
    setShowSubtaskForm(true);
  };

  const onSubtaskCreated = () => {
    setShowSubtaskForm(false);
    queryClient.refetchQueries(defaultQuery);
  };

  const handleStatusChange = (item, subtaskUUID) => {
    TasksAPI.updateTask({
      uuid: subtaskUUID || taskState.uuid,
      status_id: item.status_id,
    });

    updateCache({
      subtaskUUID,
      status_id: item.status_id,
      status: item.status_name,
      is_completed: item.status_id === 2,
      complete_date: item.status_id === 2 ? db_timestamp() : null,
    });
  };

  const handlePriorityChange = (item, subtaskUUID) => {
    TasksAPI.updateTask({
      uuid: subtaskUUID || taskState.uuid,
      priority_id: item.priority_id,
    });

    updateCache({
      subtaskUUID,
      priority_id: item.priority_id,
      priority: item.priority_name,
    });
  };

  const handleDueDateChange = (item, subtaskUUID) => {
    TasksAPI.updateTask({
      uuid: subtaskUUID || taskState.uuid,
      due_date: item?.value || null,
    });

    updateCache({
      subtaskUUID,
      due_date: item?.value || null,
    });
  };

  const handleCategoryChange = (item, subtaskUUID) => {
    TasksAPI.updateTask({
      uuid: subtaskUUID || taskState.uuid,
      time_category_id: item.category_id,
    });

    updateCache({
      subtaskUUID,
      time_category_id: item.category_id,
      time_category: item.category_name,
    });
  };

  const handleDurationChange = (item, subtask) => {
    if (item.label === "Custom") {
      setShowCustomTimeModal(true);
      return;
    }

    let newDuration = (taskState?.duration || 0) + item.duration;
    if (subtask) newDuration = (subtask?.duration || 0) + item.duration;

    const currentTime = db_timestamp();

    //Create Time entry in API
    TimeEntriesAPI.createTimeEntry({
      task_id: subtask ? subtask.task_id : taskState.task_id,
      case_id: taskState.case_id,
      user_id: currentUser.user_id,
      entry_date: currentTime,
      start_time: moment(currentTime)
        .subtract(item.duration, "s")
        .format("YYYY-MM-DD HH:mm:ss"),
      end_time: currentTime,
      duration: item.duration,
      is_bulk: 1,
      category_id: taskState.time_category_id || null,
    });

    updateCache({
      subtaskUUID: subtask?.uuid,
      duration: newDuration,
    });
  };

  const handleAssigneeChange = ({ selections }) => {
    const currentAssignees = taskState?.assignees || [];

    const selectedUsers = selections
      .map((selection) => selection.value)
      .map((user_id) => caseUsers.find((u) => u.user_id === user_id));

    const newAssignees = selectedUsers.filter(
      (user) => !currentAssignees.map((a) => a.user_id).includes(user.user_id)
    );

    const removedAssignees = currentAssignees.filter(
      (user) => !selectedUsers.map((u) => u.user_id).includes(user.user_id)
    );

    updateCache({
      assignees: selectedUsers,
    });

    if (removedAssignees.length > 0) {
      TasksAPI.removeUsersFromTask({
        users: removedAssignees.map((a) => a.user_id),
        task_id: taskState.task_id,
        task_uuid: taskState.uuid,
        removed_by_id: currentUser.user_id,
      });
    }

    if (newAssignees.length > 0) {
      TasksAPI.assignUsersToTask({
        users: newAssignees.map((a) => a.user_id),
        task_id: taskState.task_id,
        task_uuid: taskState.uuid,
        assigned_by_id: currentUser.user_id,
      });
    }
  };

  const handleTaskNameChange = useDebouncedCallback((e) => {
    const newName = e.target.value;
    if (newName === taskState.task_name) return;
    if (!newName || newName === "") {
      return;
    }

    TasksAPI.updateTask({
      uuid: taskState.uuid,
      task_name: newName,
    });

    updateCache({
      task_name: newName,
    });
  }, 500);

  const handleDescriptionChange = useDebouncedCallback((e) => {
    const newDescription = e.target.value;
    if (newDescription === taskState.task_name) return;
    if (!newDescription || newDescription === "") {
      return;
    }

    TasksAPI.updateTask({
      uuid: taskState.uuid,
      task_notes: newDescription,
    });

    updateCache({
      task_notes: newDescription,
    });
  }, 500);

  // Ensure values are correct during navigation
  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.value = taskState.task_name;
    }
    if (descriptionInputRef.current) {
      descriptionInputRef.current.value = taskState.task_notes;
    }
  }, [item_id, taskState?.task_name, taskState?.task_notes]);

  if (!taskState) {
    return null;
  }

  const defaultFormData = {
    case_id: taskState.case_id,
    case_uuid: taskState.case_uuid,
    parent_id: taskState.uuid,
    task_name: null,
    task_notes: null,
    uuid: shortUUID.generate(),
    object_id: taskState.case_uuid,
    object_type: "case",
    priority_id: 2,
    status_id: 4,
    assignees: null,
    // set random float between 0 and 1
    sort_value: Math.random() * 1000000,
  };

  return (
    <div className={className}>
      <TaskOuterContainer>
        <TaskInnerContainer>
          <TaskContent>
            <TextInput
              ref={nameInputRef}
              defaultValue={taskState.task_name}
              placeholder="Add task name..."
              style={{ marginTop: 10, padding: 8 }}
              onChange={handleTaskNameChange}
              size="lg"
              variant="text"
            />
            <TextAreaInput
              ref={descriptionInputRef}
              defaultValue={taskState.description}
              placeholder="Add task description..."
              style={{ marginTop: 10 }}
              onChange={handleDescriptionChange}
              minRows={6}
              maxRows={12}
              size="sm"
              variant="text"
            />
            <div className="actions-menu">
              <TaskButton
                onClick={() => handleAddSubTask()}
                style={{ display: "flex", alignItems: "center", gap: 5 }}
              >
                <PlusIcon size={12} /> Add Subtask
              </TaskButton>
              <TaskButton
                style={{ display: "flex", alignItems: "center", gap: 5 }}
                onClick={() => setShowTemplateModal(true)}
              >
                <SaveIcon size={12} /> Save as Template
              </TaskButton>
            </div>
            {showSubtaskForm && (
              <AddTaskModal
                open={showSubtaskForm}
                onClose={() => setShowSubtaskForm(false)}
                defaultFormData={defaultFormData}
                onSubmit={onSubtaskCreated}
              />
            )}
            {showTemplateModal && (
              <SaveTaskTemplateModal
                open={showTemplateModal}
                onClose={() => setShowTemplateModal(false)}
                onSubmit={() => {}}
                defaultFormData={{
                  template_name: taskState.task_name || "",
                  task_data: {
                    task_name: taskState.task_name || "",
                    task_notes:
                      taskState.task_notes || taskState.description || "",
                    priority_id: taskState.priority_id || 2,
                    time_category_id: taskState.time_category_id || null,
                  },
                  sub_tasks: taskState.subtasks || [],
                }}
              />
            )}
            {taskState?.subtasks?.length > 0 && (
              <SubTasksContainer>
                {taskState?.subtasks
                  .sort((a, b) => a.sort_value - b.sort_value)
                  .map((subtask) => (
                    <Link
                      to={
                        !case_id
                          ? `/dashboard/tasks/v/${subtask.uuid}`
                          : `/cases/${case_id}/tasks/v/${subtask.uuid}`
                      }
                    >
                      <SubTaskItem key={subtask.uuid}>
                        <TaskStatusSelector
                          value={TaskStatuses.find(
                            (s) => s.status_id === subtask.status_id
                          )}
                          showLabel={false}
                          variant="outlined"
                          onSelect={(data) =>
                            handleStatusChange(data, subtask.uuid)
                          }
                        />
                        <TaskPrioritySelector
                          value={TaskPriorities.find(
                            (s) => s.priority_id === subtask.priority_id
                          )}
                          showLabel={false}
                          variant="outlined"
                          onSelect={(data) =>
                            handlePriorityChange(data, subtask.uuid)
                          }
                        />
                        <SubTaskName>{subtask?.task_name}</SubTaskName>
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <TaskDuedateSelector
                            variant="outlined"
                            value={
                              subtask?.due_date
                                ? moment(subtask.due_date).format("YYYY-MM-DD")
                                : null
                            }
                            onSelect={(data) =>
                              handleDueDateChange(data, subtask.uuid)
                            }
                          />
                          <TaskDurationSelector
                            value={subtask?.duration}
                            showLabel={true}
                            variant="outlined"
                            onSelect={(data) =>
                              handleDurationChange(data, subtask)
                            }
                          />
                        </div>
                      </SubTaskItem>
                    </Link>
                  ))}
              </SubTasksContainer>
            )}
          </TaskContent>
        </TaskInnerContainer>
        <TaskSidebar>
          <Link to={!case_id ? `/dashboard/tasks` : `/cases/${case_id}/tasks`}>
            <TaskButton className="close">
              <ArrowLeftIcon size={16} />
            </TaskButton>
          </Link>
          <TaskDetails>
            <DetailItem
              label="Status"
              value={
                <TaskStatusSelector
                  value={TaskStatuses.find(
                    (s) => s.status_id === taskState.status_id
                  )}
                  showLabel={true}
                  variant="outlined"
                  onSelect={handleStatusChange}
                />
              }
            />
            <DetailItem
              label="Priority"
              value={
                <TaskPrioritySelector
                  value={TaskPriorities.find(
                    (s) => s.priority_id === taskState.priority_id
                  )}
                  showLabel={true}
                  variant="outlined"
                  onSelect={handlePriorityChange}
                />
              }
            />
            <DetailItem
              label="Due Date"
              value={
                <TaskDuedateSelector
                  variant="outlined"
                  value={
                    taskState?.due_date
                      ? moment(taskState.due_date).format("YYYY-MM-DD")
                      : null
                  }
                  onSelect={handleDueDateChange}
                />
              }
            />
            <DetailItem
              label="Assignees"
              value={
                <AssigneeSelector
                  value={taskState?.assignees?.map((a) => a.user_id) || null}
                  defaultInfo={taskState?.assignees || []}
                  showLabel={true}
                  variant="outlined"
                  onSelect={handleAssigneeChange}
                  userQuery={{
                    case_id: taskState?.case_id,
                    include_inactive: false,
                    include_observers: false,
                  }}
                />
              }
            />
            <DetailItem
              label="Created By"
              value={taskState?.created_by?.full_name}
            />
            <DetailItem label="Created On" value={taskState?.created_on} />
            <DetailItem
              label="Linked To"
              valueProps={{ style: { textTransform: "capitalize" } }}
              value={
                <LinkedObject
                  object={{
                    type: taskState.object_type,
                    name: taskState.object_name,
                    id: taskState.object_id,
                  }}
                  href={`/cases/${taskState.case_id}/tasks`}
                  variant="highlighted"
                />
              }
            />
            <DetailItem
              label="Time Category"
              value={
                <TaskCategorySelector
                  value={
                    taskState?.time_category_id
                      ? {
                          category_id: taskState?.time_category_id,
                          category_name: taskState?.time_category,
                        }
                      : null
                  }
                  showLabel={true}
                  variant="outlined"
                  onSelect={handleCategoryChange}
                />
              }
            />
            <DetailItem
              label="Time Spent"
              value={
                <TaskDurationSelector
                  value={taskState?.duration}
                  showLabel={true}
                  variant="outlined"
                  onSelect={handleDurationChange}
                />
              }
            />
            <DetailItem label="Task ID" value={taskState?.uuid} />
          </TaskDetails>
          {showCustomTimeModal && (
            <AddCustomTimeModal
              open={showCustomTimeModal}
              onClose={() => setShowCustomTimeModal(false)}
              onSubmit={(formData) => {
                updateCache({
                  duration: taskState?.duration + formData.duration,
                });
              }}
              formOptions={{
                case: { disabled: true },
                task: { disabled: true },
              }}
              defaultFormData={{
                task: taskState,
                task_id: taskState.task_id,
                task_name: taskState.task_name,
                case_id: taskState.case_id,
                case_number: taskState.case_number,
                user_id: currentUser.user_id,
                duration: 0,
                billed: 0,
                category_id: taskState.time_category_id || null,
                category: TaskCategories.find(
                  (c) => c.category_id === taskState.time_category_id
                ),
                start_time: moment().toISOString(),
                end_time: moment().add(1, "h").toISOString(),
              }}
            />
          )}
        </TaskSidebar>
      </TaskOuterContainer>
    </div>
  );
})`
  user-select: none;
  position: absolute;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  width: 100%;
  inset: 0;

  .actions-menu {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .close.task-button {
    margin-left: auto;
    margin-bottom: 10px;
  }
`;

export default TaskViewer;
