import { Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import Page404 from "./pages/auth/Page404";
import Overview from "./pages/Overview";
import Tasks from "./pages/Tasks";
import Cases from "./pages/Cases";
import CaseDetails from "./pages/Cases/CaseDetails/CaseDetails";
import EvidenceSection from "./pages/Evidence/EvidenceSection.js";
import EvidenceDetails from "./pages/Evidence/EvidenceDetails.js";
import AcquisitionDetails from "./pages/Acquisitions/AcquisitionDetails.js";
import StorageSection from "./pages/Storage/StorageSection";
import StorageDetails from "./pages/Storage/StorageDetails.js";
import InquirySection from "./pages/Inquiries/InquirySection.js";
import InquiryDetails from "./pages/Inquiries/InquiryDetails.js";
import EquipmentSection from "./pages/Equipment/EquipmentSection.js";
import SoftwareSection from "./pages/Software/SoftwareSection.js";
import LocationsSection from "./pages/Locations/LocationsSection.js";
import LocationDetails from "./pages/Locations/LocationDetails.js";
import ClientsSection from "./pages/Clients/ClientsSection.js";
import ClientDetails from "./pages/Clients/ClientDetails.js";
import ContactsSection from "./pages/Contacts/ContactsSection.js";
import ContactDetails from "./pages/Contacts/ContactDetails.js";
import OfficesSection from "./pages/Offices/OfficesSection.js";
import OfficeDetails from "./pages/Offices/OfficeDetails.js";
import Reports from "./pages/Reports/index.js";
import ReportDetails from "./pages/Reports/ReportDetails.js";
import MonolithSettings from "./pages/Settings/index";
import AuthGuard from "./components/AuthGuard";
import ResetPassword from "./pages/auth/ResetPassword";
import SSOCallback from "./pages/auth/SSOCallback";
import AuditsPage from "./pages/Audits/AuditsPage.js";
import AuditDetailsPage from "./pages/Audits/AuditDetailsPage.js";
import TemplateEditor from "./pages/Settings/EditorTemplates/TemplateEditor.js";
import SystemSettings from "./pages/Settings/System.js";
import NotificationSettings from "./pages/Settings/NotificationSettings.js";
import OrgInfoSettings from "./pages/Settings/OrgInfo.js";
import EditorTemplatesSection from "./pages/Settings/EditorTemplates/EditorTemplates.js";
import DocumentTemplatesSection from "./pages/Settings/DocumentTemplates/DocumentTemplates.js";
import RelaySettings from "./pages/Settings/RelaySettings/RelaySettings.js";
import ItemNumberFormatSettings from "./pages/Settings/ItemNumberFormats.js";
import CaseTypeSettings from "./pages/Settings/CaseTypes.js";
import CaseStatusSettings from "./pages/Settings/CaseStatus.js";
import CaseProgressSettings from "./pages/Settings/CaseProgress.js";
import EvidenceTypeSettings from "./pages/Settings/EvidenceTypes.js";
import EvidenceProgressSettings from "./pages/Settings/EvidenceProgress.js";
import ItemLabelSettings from "./pages/Settings/ItemLabels.js";
import CustomFieldSettings from "./pages/Settings/CustomFieldSettings/index.js";
import TaskTemplateSettings from "./pages/Settings/TaskTemplates.js";
import TimeCategorySettings from "./pages/Settings/TimeEntryCategories.js";
import QaCheckListSettings from "./pages/Settings/QaCheckLists/QaCheckLists.js";
import QaIssueTypeSettings from "./pages/Settings/QaIssueTypes.js";
import AdminLog from "./pages/Settings/AdminLog.js";
import IntegrationSettings from "./pages/Settings/Integration/Integration.js";
import About from "./pages/Settings/About.js";
import CreateEditorTemplate from "./pages/Settings/EditorTemplates/CreateEditorTemplate.js";
import FprIntegration from "./pages/Settings/Integration/FPR_Integration/index.js";
import IntegrationsList from "./pages/Settings/Integration/IntegrationsList.js";
import MattermostIntegration from "./pages/Settings/Integration/MattermostIntegration/MattermostIntegration.js";

// import Loader from "./components/Loader";
import QaCheckList from "./pages/Settings/QaCheckLists/QaCheckList.js";
import ReleaseNotesSection from "./pages/Settings/ReleaseNotesSection";
import CaseOverview from "./pages/Cases/CaseOverview/CaseOverview";
import CaseEvidence from "./pages/Cases/CaseEvidence/index";
import CaseAcquisition from "./pages/Cases/CaseAcquisitions/index";
import CaseAnalysis from "./pages/Cases/CaseAnalysis/CaseAnalysis";
import CaseTasks from "./pages/Cases/CaseTasks/index.js";
import CaseNotes from "./pages/Cases/CaseNotes/CaseNotes.js";
import CaseDocuments from "./pages/Cases/CaseDocuments/CaseDocuments.js";
import CaseContacts from "./pages/Cases/CaseContacts/index.js";
import CaseQA from "./pages/Cases/CaseQA/index.js";
import QaDetails from "./pages/Cases/CaseQA/components/QaDetails.js";
import QaReviews from "./pages/Cases/CaseQA/components/QaReviews.js";
import CaseReports from "./pages/Cases/CaseReport/index.js";
import CaseReportDetails from "./pages/Cases/CaseReport/ReportDetail.js";
import ReportsAndArchives from "./pages/Cases/CaseReport/ReportsAndArchives.js";
import CaseActivity from "./pages/Cases/CaseActivity/index.js";
import TraceIndicators from "./pages/Cases/CaseAnalysis/sections/Trace-Indicators/Trace-Indicators";
import TraceAttributes from "./pages/Cases/CaseAnalysis/sections/Trace-Attributes/Trace-Attributes";
import TimelineEvents from "./pages/Cases/CaseAnalysis/sections/Timeline-Events/Timeline-Events";
import Accounts from "./pages/Cases/CaseAnalysis/sections/Accounts/Accounts";

import TeamSection from "./pages/Users/TeamSection.js";
import UserRoles from "./pages/Users/components/UserRoles/UserRoles";
import UsersTabContent from "./pages/Users/components/UsersTabContent.js";
import UserDetails from "./pages/Users/components/UserDetails.js";
import GroupsTabContent from "./pages/Users/components/GroupsTabContent.js";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

const unprotectedRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/auth/sso/callback",
    element: <SSOCallback />,
  },
  {
    path: "/password-reset/:uuid",
    element: <ResetPassword />,
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

const protectedRoutes = [
  {
    path: "/",
    element: <Navigate to="/dashboard/overview/user" />,
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "overview",
        element: <Navigate to="/dashboard/overview/user" />,
      },
      {
        path: "overview/:currentTab",
        element: <Overview />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "tasks/v/:item_id",
        element: <Tasks />,
      },
      {
        path: "tasks/:active_tab",
        element: <Tasks />,
      },
      {
        path: "tasks/:active_tab/:view",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "cases",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Cases />,
      },
      {
        path: ":case_id",
        element: <CaseDetails />,
        children: [
          {
            path: "overview",
            element: <CaseOverview />,
          },
          {
            path: "evidence",
            element: <CaseEvidence />,
          },
          {
            path: "acquisitions",
            element: <CaseAcquisition />,
          },
          {
            path: "case-storage",
            element: <StorageSection />,
          },
          {
            path: "analysis",
            element: <CaseAnalysis />,
            children: [
              {
                path: "",
                element: <Navigate to="timeline" replace />,
              },
              {
                path: "timeline",
                element: <TimelineEvents />,
              },
              {
                path: "timeline/:event_uuid",
                element: <TimelineEvents />,
              },
              {
                path: "accounts",
                element: <Accounts />,
              },
              {
                path: "accounts/:uuid",
                element: <Accounts />,
              },
              {
                path: "trace-indicators",
                element: <TraceIndicators />,
              },
              {
                path: "trace-indicators/:uuid",
                element: <TraceIndicators />,
              },
              {
                path: "trace-attributes",
                element: <TraceAttributes />,
              },
              {
                path: "trace-attributes/:uuid",
                element: <TraceAttributes />,
              },
            ],
          },
          {
            path: "tasks",
            element: <CaseTasks />,
          },
          {
            path: "tasks/v/:item_id",
            element: <CaseTasks />,
          },
          {
            path: "notes",
            element: <CaseNotes />,
          },
          {
            path: "notes/:item_id",
            element: <CaseNotes />,
          },
          {
            path: "documents",
            element: <CaseDocuments />,
            children: [
              {
                path: "folders/:folder_id",
                element: <CaseDocuments />,
              },
            ],
          },
          {
            path: "contacts",
            element: <CaseContacts />,
          },
          {
            path: "qa",
            element: <CaseQA />,
            children: [
              {
                path: "",
                element: <QaReviews />,
              },
              {
                path: "review/:review_id",
                element: <QaDetails />,
              },
            ],
          },
          {
            path: "reports",
            element: <CaseReports />,
            children: [
              {
                path: "",
                element: <ReportsAndArchives />,
              },
              {
                path: "generate-report/:case_report_id",
                element: <CaseReportDetails />,
              },
            ],
          },
          {
            path: "activity",
            element: <CaseActivity />,
          },
        ],
      },
    ],
  },
  {
    path: "inquiries",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InquirySection />,
      },
      {
        path: ":inquiry_id",
        element: <InquiryDetails />,
      },
    ],
  },
  {
    path: "evidence",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EvidenceSection />,
      },
      {
        path: ":uuid/:active_tab",
        element: <EvidenceDetails />,
      },
    ],
  },
  {
    path: "audits",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AuditsPage />,
      },
      {
        path: ":uuid/:active_tab",
        element: <AuditDetailsPage />,
      },
    ],
  },
  {
    path: "storage",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <StorageSection />,
      },
      {
        path: ":storage_id",
        element: <StorageDetails />,
      },
    ],
  },
  {
    path: "locations",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <LocationsSection />,
      },
      {
        path: ":location_id/details",
        element: <LocationDetails />,
      },
    ],
  },
  {
    path: "clients",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ClientsSection />,
      },
      {
        path: ":client_id",
        element: <ClientDetails />,
      },
    ],
  },
  {
    path: "contacts",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ContactsSection />,
      },
      {
        path: ":client_id",
        element: <ContactDetails />,
      },
    ],
  },
  {
    path: "software",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <SoftwareSection />,
      },
    ],
  },
  {
    path: "equipment",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EquipmentSection />,
      },
    ],
  },
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "team/users/:user_id/user-details",
        element: <UserDetails />,
      },
      {
        path: "team",
        element: <TeamSection />,
        children: [
          {
            path: "",
            element: <Navigate to="users" replace />,
          },
          {
            path: "users",
            element: <UsersTabContent />,
          },
          {
            path: "roles",
            element: <UserRoles />,
          },
          {
            path: "roles/:uuid",
            element: <UserRoles />,
          },
          {
            path: "groups",
            element: <GroupsTabContent />,
          },
        ],
      },
    ],
  },
  {
    path: "acquisitions",
    element: <DashboardLayout />,
    children: [
      {
        path: ":acq_id",
        element: <AcquisitionDetails />,
      },
    ],
  },
  {
    path: "offices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <OfficesSection />,
      },
      {
        path: ":office_id",
        element: <OfficeDetails />,
      },
    ],
  },
  {
    path: "reports",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Reports />,
      },
      {
        path: ":report_id",
        element: <ReportDetails />,
      },
    ],
  },
  {
    path: "",
    element: <DashboardLayout />,
    children: [
      {
        path: "settings",
        element: <MonolithSettings />,
        children: [
          {
            path: "",
            element: <Navigate to="system" replace />,
          },
          {
            path: "system",
            element: <SystemSettings />,
          },
          {
            path: "notifications",
            element: <NotificationSettings />,
          },
          {
            path: "org-info",
            element: <OrgInfoSettings />,
          },
          {
            path: "editor-templates",
            element: <EditorTemplatesSection />,
          },
          {
            path: "editor-templates/create",
            element: <CreateEditorTemplate />,
          },
          {
            path: "editor-templates/edit/:uuid",
            element: <TemplateEditor />,
          },
          {
            path: "document-templates",
            element: <DocumentTemplatesSection />,
          },
          {
            path: "relay",
            element: <RelaySettings />,
          },
          {
            path: "item-numbers",
            element: <ItemNumberFormatSettings />,
          },
          {
            path: "case-types",
            element: <CaseTypeSettings />,
          },
          {
            path: "case-status",
            element: <CaseStatusSettings />,
          },
          {
            path: "case-progress",
            element: <CaseProgressSettings />,
          },
          {
            path: "evidence-types",
            element: <EvidenceTypeSettings />,
          },
          {
            path: "evidence-progress",
            element: <EvidenceProgressSettings />,
          },
          {
            path: "item-labels",
            element: <ItemLabelSettings />,
          },
          {
            path: "custom-fields",
            element: <CustomFieldSettings />,
          },
          {
            path: "task-templates",
            element: <TaskTemplateSettings />,
          },
          {
            path: "time-categories",
            element: <TimeCategorySettings />,
          },
          {
            path: "qa-checklists",
            element: <QaCheckListSettings />,
          },
          {
            path: "qa-checklists/:checklist_id",
            element: <QaCheckList />,
          },
          {
            path: "qa-issue-types",
            element: <QaIssueTypeSettings />,
          },
          {
            path: "admin-log",
            element: <AdminLog />,
          },
          {
            path: "integrations",
            element: <IntegrationSettings />,
            children: [
              { path: "", element: <IntegrationsList /> },
              { path: "forensic-partner-reports", element: <FprIntegration /> },
              { path: "mattermost", element: <MattermostIntegration /> },
            ],
          },
          {
            path: "release-notes",
            element: <ReleaseNotesSection />,
          },
          {
            path: "about",
            element: <About />,
          },
        ],
      },
    ],
  },
].map((route) => {
  route.element = <AuthGuard>{route.element}</AuthGuard>;
  return route;
});

const routes = [...unprotectedRoutes, ...protectedRoutes];

export default routes;
