import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { getDateFormat } from "../../utils/date-format.js";
import ToolBarItems from "../ToolBarItems.js";
import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { usePermissions } from "../../hooks/usePermissions";
import { useState, useRef } from "react";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import CaseReportsAPI from "../../api/cases/reports.js";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { EditIcon, TrashIcon } from "lucide-react";

const CaseReportNameComponent = styled(({ className, rowData, color }) => {
  return (
    <div className={className}>
      <Link
        className={"item-link"}
        to={`generate-report/${rowData.case_report_id}`}
      >
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div>{!!rowData.name ? rowData.name : "View Report"}</div>
          <LaunchOutlinedIcon
            className="action-button"
            style={{ fontSize: 13, marginLeft: 5 }}
          />
        </div>
      </Link>
    </div>
  );
})`
  & {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & .item-link {
    color: ${({ color, theme }) => color || theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActionCell = ({ rowData, options }) => {
  const { hasPermission } = usePermissions();

  const [showEditModel, setShowEditModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const handleSubmit = () => {
    options?.onRowUpdataed?.();
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <DropdownMenu
          menuItems={[
            {
              label: "Edit",
              icon: EditIcon,
              onClick: () => setShowEditModel(true),
            },
            {
              label: "Delete",
              icon: TrashIcon,
              iconColor: "orangered",
              onClick: () => setShowDeleteModel(true),
            },
          ]}
          title={"Actions"}
          variant="outlined"
          // arrow
          disabled={!hasPermission()}
        >
          <MoreHorizOutlinedIcon style={{ fontSize: 13 }} />
        </DropdownMenu>
      </div>
      <EditModal
        open={showEditModel}
        handleClose={() => setShowEditModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
      <DeleteModal
        open={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
    </>
  );
};

const EditModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      let values = Object.assign({}, form.current.instance.option("formData"));

      CaseReportsAPI.updateCaseReport({ ...values }).then((result) => {
        enqueueSnackbar(`Case Report Updated.`, {
          variant: "success",
        });
        onSubmit();
      });

      handleClose();
    }
  };

  const handleCancel = () => handleClose();

  const formData = {
    case_report_id: defaultInfo.case_report_id,
    name: defaultInfo.name,
    description: defaultInfo.description,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 200px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Case Report
          </div>
          <>
            <Form ref={form} colCount={2} defaultFormData={formData}>
              <SimpleItem
                dataField="name"
                label={{ text: "Case Report Name" }}
                colSpan={2}
                isRequired={true}
              />
              <SimpleItem
                dataField="description"
                editorType="dxTextArea"
                colSpan={2}
                editorOptions={{
                  height: 75,
                }}
              />
            </Form>
            <div
              style={{
                marginTop: 30,
              }}
            >
              <ToolBarItems
                submitText="Edit Case Report"
                onSubmit={handleSubmit}
                onCancel={handleCancel}
              />
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    CaseReportsAPI.deleteCaseReport({
      case_report_id: defaultInfo.case_report_id,
    }).then(() => {
      enqueueSnackbar(`Case Report Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 200px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete Case Report
          </div>
          <div style={{ margin: "10px 0px" }}>
            Are you sure you want to delete this Case Report?
          </div>
          <div style={{ margin: "10px 0px" }}>"{defaultInfo.name}"</div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Delete Case Report"
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const CaseReportColumnDefs = [
  {
    dataField: "actions",
    caption: "",
    width: 55,
    allowSorting: false,
    allowReorder: false,
    showInColumnChooser: false,
    render: (rowData, options) => (
      <ActionCell rowData={rowData} options={options} />
    ),
  },
  {
    dataField: "name",
    caption: "Name",
    render: (rowData) => <CaseReportNameComponent rowData={rowData} />,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    format: {
      type: getDateFormat(true, false),
    },
  },
  {
    dataField: "created_by",
    caption: "Created By",
  },
  {
    dataField: "description",
    caption: "Description",
    titleRender: (rowData) => {
      return rowData.description;
    },
  },
];

export default CaseReportColumnDefs.slice();
