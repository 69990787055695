import { useMediaQuery, useTheme } from "@mui/material";
import DataSource from "devextreme/data/data_source";
import moment from "moment";
import { useSnackbar } from "notistack";

import { useEffect, useMemo, useRef, useState } from "react";
import {
  getCocMoveReasons,
  getIpAddress,
  getLocations,
  moveEvidenceItems,
} from "../../../../../api";
import { useAuth } from "../../../../../contexts/AuthContext";

import { Popup } from "devextreme-react/ui/popup";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form";
import { ScrollView } from "devextreme-react/ui/scroll-view";
import { Template } from "devextreme-react/core/template";
import CollapseSection from "../../../../../components/CollaspeSection";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../../../utils/date-format";
import SignatureField from "../../../../../components/SignatureField/SignatureField";
import ToolBarItems from "../../../../../components/ToolBarItems";
import LocationSelectBoxItem from "../../../../../components/SelectBoxItems/LocationSelectBoxItem.js";
import { useQuery } from "@tanstack/react-query";
import LocationsAPI from "../../../../../api/locations/index.js";

const MoveItemsPopup = ({ moveItemsPopup, onComplete = () => {} }) => {
  const form = useRef(null);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [selectionState, setSelectionState] = useState(null);
  const totalEvidence = useRef(null);

  const receivedFromSig = useRef(null);

  const { data: locations, isSuccess: locationSuccess } = useQuery({
    queryKey: ["evidence:locations", { include_groups: false }],
    queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const { data: reasons, isSuccess: reasonsSuccess } = useQuery({
    queryKey: ["evidence:moveReasons"],
    queryFn: () => getCocMoveReasons(),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: currentUser.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      data.signature = JSON.stringify(signature);

      moveItemsPopup.current.instance.hide();

      const formData = {
        new_location_id: data.new_location.location_id,
        new_location_name: data.new_location.location_name,
        reason: data.reason,
        timestamp: db_timestamp(data.timestamp, true),
        entered_by: currentUser.full_name,
        entered_by_id: currentUser.user_id,
        signature: data.signature,
        coc_type: "Move",
      };

      await moveEvidenceItems({
        formData,
        options: { ...selectionState.options, uuid: selectionState.uuid },
      });

      enqueueSnackbar(
        `Evidence Items Moved to ${data.new_location.location_name}`,
        {
          variant: "success",
        }
      );

      onComplete();
    }
  };

  const handleFormReset = () => {
    if (form.current) form.current.instance.resetValues();
    if (receivedFromSig.current) receivedFromSig.current.clear();
  };

  useEffect(() => {
    if (locations && form.current) {
      form.current.instance.getEditor("new_location").option(
        "dataSource",
        new DataSource({
          store: locations,
          key: "location_id",
          group: "office_name",
        })
      );
    }
  }, [locations, locationSuccess]);

  useEffect(() => {
    if (reasons && form.current) {
      form.current.instance.getEditor("reason").option("items", reasons);
    }
  }, [reasons, reasonsSuccess]);

  useEffect(() => {
    if (moveItemsPopup.current) {
      moveItemsPopup.current.setSelectionState = setSelectionState;
    }
  }, [moveItemsPopup.current]);

  return (
    <Popup
      ref={moveItemsPopup}
      defaultWidth={500}
      defaultHeight={350}
      resizeEnabled={true}
      showCloseButton={true}
      showTitle={true}
      title="Move Evidence Items"
      deferRendering={false}
      fullScreen={true}
      onShowing={(e) => {
        totalEvidence.current.innerText = `${selectionState.total} Evidence Items`;
        form.current.instance
          .getEditor("timestamp")
          .option("value", new Date());
      }}
      onHidden={(e) => {
        handleFormReset();
      }}
    >
      <ScrollView showScrollbar="always" useNative={false}>
        <div
          style={{
            margin: "auto",
            width: matches ? "90vw" : "70vw",
            maxWidth: 500,
            userSelect: "none",
          }}
        >
          <CollapseSection title={"Move Details"} allowCollapse={false}>
            <div style={{ marginBottom: 15 }}>
              <div
                ref={totalEvidence}
                style={{ color: theme.palette.primary.main }}
              ></div>
            </div>
            {useMemo(() => {
              return (
                <Form ref={form} colCount={1}>
                  <SimpleItem
                    dataField="new_location"
                    label={{ text: "New Location" }}
                    editorType="dxSelectBox"
                    isRequired={true}
                    editorOptions={{
                      hint: "Select the new location for this storage item.",
                      searchEnabled: true,
                      dataSource: new DataSource({
                        store: locations || [],
                        key: "location_id",
                        group: "office_name",
                      }),
                      displayExpr: "location_name",
                      grouped: true,
                      dropDownOptions: {
                        height: "350px",
                      },
                      itemTemplate: "new_location",
                    }}
                  />
                  <Template
                    name="new_location"
                    render={(data) => <LocationSelectBoxItem data={data} />}
                  />
                  <SimpleItem
                    dataField="reason"
                    label={{ text: "Move Reason" }}
                    editorType="dxSelectBox"
                    isRequired={true}
                    editorOptions={{
                      items: reasons || [],
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                      placeholder: "Select or add new...",
                      searchEnabled: true,
                      searchExpr: "reason",
                      displayExpr: "reason",
                      valueExpr: "reason",
                      acceptCustomValue: true,
                      onCustomItemCreating: (e) => {
                        e.customItem = { reason: e.text };
                        // Adds the entry to the data source
                        e.component
                          .getDataSource()
                          .store()
                          .insert(e.customItem);
                        // Reloads the data source
                        e.component.getDataSource().reload();
                      },
                    }}
                  />
                  <SimpleItem
                    dataField="timestamp"
                    label={{ text: "Timestamp" }}
                    editorType="dxDateBox"
                    isRequired={true}
                    editorOptions={{
                      type: "datetime",
                      useMaskBehavior: true,
                      displayFormat: getDateFormat(false, true),
                    }}
                    validationRules={[
                      {
                        type: "custom",
                        message: "Invalid Date",
                        validationCallback: validateTimestamp,
                      },
                    ]}
                  />
                  <EmptyItem />
                  <SimpleItem
                    dataField="custody_from_sig"
                    label={{ text: "Moved By Signature" }}
                  >
                    <SignatureField
                      sigFieldRef={receivedFromSig}
                      title="Moved By Signature"
                    />
                  </SimpleItem>
                </Form>
              );
            }, [])}
          </CollapseSection>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Move Evidence"
              onSubmit={handleSubmit}
              onCancel={() => {
                moveItemsPopup.current.instance.hide();
              }}
            />
          </div>
        </div>
      </ScrollView>
    </Popup>
  );
};

export default MoveItemsPopup;
