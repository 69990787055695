import { Modal } from "@monolith-forensics/monolith-ui";
import { EditDocumentTemplateForm } from "../Forms/index.js";
import { DocumentTemplateDefaultInfo } from "./DocumentTemplate.interface";

const EditDocumentTemplateModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  defaultInfo,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
  defaultInfo?: DocumentTemplateDefaultInfo;
}) => {
  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Edit Document Template</Modal.Title>
      <Modal.SubTitle style={{ marginBottom: 15 }}>
        Edit the details for this document template.
      </Modal.SubTitle>
      <EditDocumentTemplateForm
        width={400}
        onSubmit={onSubmit}
        onCancel={onCancel}
        defaultInfo={defaultInfo}
      />
    </Modal>
  );
};

export default EditDocumentTemplateModal;
