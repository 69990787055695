import { createContext, useContext, useState } from "react";
import CaseReportsAPI from "../../../../api/cases/reports.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import { getDateFormat } from "../../../../utils/date-format.js";

const ReportParamsContext = createContext(null);

export const defaultParams = {
  reportOpts: {
    includeHeader: true,
    includeFooter: true,
  },
  coverPageOpts: {
    include: true,
    includeLogo: true,
  },
  tocOpts: {
    include: true,
  },
  caseSummaryOpts: {
    include: true,
    includeMyInfo: true,
    includeClientInfo: true,
    includeCaseSynopsis: true,
    includeReportSummary: true,
    includeEvidenceList: true,
    includeDocumentsList: false,
    includeDocumentAttachments: false,
    includedDocumentIDs: [],
  },
  reportAnalysisOpts: {
    include: true,
  },
  evidenceOpts: {
    include: true,
    includeDescription: true,
    includeDetails: true,
    includeChainOfCustody: true,
    includeAcquisitions: true,
    includeChildEvidence: true,
    includePhotos: true,
    displayOrder: "intake_date_desc",
    includedEvidenceIDs: [],
  },
  tasksOpts: {
    include: true,
  },
  notesOpts: {
    include: true,
    includedCaseNoteIDs: [],
  },
  contactsOpts: {
    include: true,
    includedContactIDs: [],
  },
  activityLogOpts: {
    include: true,
  },
};

const getInitialParams = (params) => {
  let finalParams = defaultParams;

  if (params) {
    finalParams = JSON.parse(params);
  }

  return {
    ...finalParams,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    date_format: getDateFormat(),
  };
};

export const ReportParamsProvider = ({ params, caseInfo, children }) => {
  const [reportParams, setReportParams] = useState(getInitialParams(params));

  const { hasPermission } = usePermissions();

  const updateParams = (section, values) => {
    const currentParams = Object.assign({}, reportParams);
    let currentSection = reportParams[section];

    let newSection = { ...currentSection, ...values };

    currentParams[section] = newSection;

    setReportParams(currentParams);
    CaseReportsAPI.updateCaseReport({
      case_report_id: caseInfo.case_report_id,
      report_parameters: JSON.stringify(currentParams),
    });
    return currentParams;
  };

  return (
    <ReportParamsContext.Provider
      value={{
        caseInfo,
        reportParams,
        updateParams,
        hasPermission,
        defaultParams,
        usingTemplate: !!reportParams?.templateOpts?.selectedTemplate,
      }}
    >
      {children}
    </ReportParamsContext.Provider>
  );
};

export const useReportParams = () => useContext(ReportParamsContext);
