import { useMemo, useState } from "react";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { usePermissions } from "../../hooks/usePermissions";
import { getAllLabelInfo } from "../../api/index.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import PrinterSelectModal from "../../utils/labels/PrinterSelectPopup.js";
import EvidenceTable from "./components/tables/EvidenceTable.js";
import AcquisitionsTable from "./components/tables/AcquisitionsTable.js";
import ContactCasesTable from "./components/tables/ContactCasesTable.js";
import EditContactModal from "./components/EditContactModal.js";

const ContactOverview = styled(
  ({ className, contactInfo: details, handleClientUpdate = () => {} }) => {
    const [showEditClientModal, setShowEditClientModal] = useState(false);

    return (
      <div className={className}>
        <SideContent
          details={details}
          setShowEditClientModal={setShowEditClientModal}
        />
        <MainContent
          details={details}
          handleClientUpdate={handleClientUpdate}
          showEditClientModal={showEditClientModal}
          setShowEditClientModal={setShowEditClientModal}
        />
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  flex: 1 1 auto;
  height: 0px;
`;

const SideContent = styled(({ className, details, setShowEditClientModal }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(true);
  const [showPrintLabelModal, setShowPrintLabelModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const { hasPermission } = usePermissions();

  // Fetching print label options
  const { data: labels } = useQuery({
    initialData: [],
    queryKey: ["labels"],
    queryFn: () => getAllLabelInfo(),
  });

  // Populates labels for 'Print Label' dropdown menu
  const labelItems = useMemo(
    () =>
      labels.map((label) => ({
        ...label,
        label: label.label_name,
        onClick: () => handlePrintLabel(label),
      })),
    [labels]
  );

  const handlePrintLabel = (label) => {
    setSelectedLabel(label);
    setShowPrintLabelModal(true);
  };

  let contactDetails;
  let contactInfo;
  let contactNotes;

  if (details) {
    // Preparing client details for display
    contactDetails = {
      Name: details?.name,
      Title: details?.title,
      Organization: details?.organization,
    };

    // Preparing contact information for display
    contactInfo = {
      Email: details.email,
      "Office Phone": details.office_number,
      "Mobile Phone": details.mobile_number,
      Address: details.address,
      City: details.city,
      State: details.state,
      Zipcode: details.zipcode,
      Country: details.country,
      "Unique ID": details.unique_id,
      "Contact Type": details.contact_type,
    };

    contactNotes = {
      Notes: details.notes,
    };
  }

  const ContactDetailSection = useMemo(() => {
    const createSection = (title, data) => (
      <div style={{ margin: "40px 2px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: 700,
              color: theme.palette.text.secondary,
              marginBottom: 10,
            }}
          >
            {title}
          </div>
          {hasPermission() && title === "Contact Details" && (
            <div
              className="action-menu-item"
              onClick={() => setShowEditClientModal(true)}
            >
              <EditOutlinedIcon style={{ color: theme.palette.primary.main }} />
              <div className="action-menu-label">Edit</div>
            </div>
          )}
        </div>
        {Object.entries(data).map(([key, value]) => (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{key}</div>
            <div className="detail-value">{value}</div>
          </div>
        ))}
      </div>
    );

    return (
      <div className="client-details">
        {createSection("Contact Details", contactDetails)}
        {createSection("Contact Information", contactInfo)}
        <div style={{ margin: "10px 2px" }}>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: 700,
              color: theme.palette.text.secondary,
              margin: "10px 0px",
            }}
          >
            Notes
          </div>
          {details.notes}
        </div>
      </div>
    );
  }, [contactDetails, contactInfo, contactNotes]);

  // Render the side content conditionally based on whether hidden or open
  return (
    <div className={className + (open ? " expanded" : " collapsed")}>
      <div
        className={
          open
            ? "collapse-btn side-content-expanded"
            : "collapse-btn side-content-collapsed"
        }
      >
        {open && <div className="header">Details</div>}
        <TaskButton variant="outlined" onClick={() => setOpen(!open)}>
          <KeyboardTabOutlinedIcon style={{ fontSize: 16 }} />
        </TaskButton>
      </div>
      {open && (
        <>
          {hasPermission() && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "12px 0",
                }}
              >
                <DropdownMenu
                  menuItems={labelItems}
                  title={"Print shipping label"}
                  variant="outlined"
                  arrow
                >
                  Print Label
                </DropdownMenu>
              </div>
            </>
          )}
          {ContactDetailSection}
        </>
      )}
      <PrinterSelectModal
        open={showPrintLabelModal}
        handleClose={() => setShowPrintLabelModal(false)}
        defaultInfo={{ association: details }}
        label={selectedLabel}
      />
    </div>
  );
})`
  max-width: 325px;
  min-width: 325px;
  min-height: 350px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};

  border-radius: 4px;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.1s ease-in-out;

  &.collapsed {
    min-width: 50px;
    max-width: 50px;
  }

  & .collapse-btn {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .side-content-expanded {
    svg {
      transform: rotate(180deg);
    }
  }

  & .side-content-collapsed {
    justify-content: center;
  }

  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
    cursor: default;
  }
  & .header-container {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 20px 0px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: baseline;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 0px;
    min-width: 120px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
    width: 100%;
    white-space: pre-wrap;
  }
`;

const MainContent = styled(
  ({
    className,
    details,
    handleClientUpdate = () => {},
    showEditClientModal,
    setShowEditClientModal,
  }) => {
    const [currentTab, setCurrentTab] = useState(0);

    const { currentUser } = usePermissions();

    const handleChange = (event, newGroup) => {
      if (newGroup !== null) {
        setCurrentTab(newGroup);
      }
    };

    return (
      <div className={className}>
        <div className="toggle-menu">
          <ToggleButtonGroup
            size="small"
            value={currentTab}
            onChange={handleChange}
            exclusive={true}
            style={{ margin: "auto" }}
          >
            <ToggleButton
              value={0}
              size="small"
              disableRipple={true}
              style={{
                minWidth: "150px",
                padding: "2px 5px",
              }}
            >
              Cases
            </ToggleButton>
            <ToggleButton
              value={1}
              size="small"
              disableRipple={true}
              style={{
                minWidth: "150px",
                padding: "2px 5px",
              }}
            >
              Evidence
            </ToggleButton>
            <ToggleButton
              value={2}
              size="small"
              disableRipple={true}
              style={{
                minWidth: "150px",
                padding: "2px 5px",
              }}
            >
              Acquisitions
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {currentTab === 0 && (
          <ContactCasesTable
            contact_id={details.client_id}
            currentUser={currentUser}
            stateStoreKey="cases:list:contact"
          />
        )}
        {currentTab === 1 && (
          <EvidenceTable
            contact_id={details.client_id}
            currentUser={currentUser}
          />
        )}
        {currentTab === 2 && (
          <AcquisitionsTable
            infoData={details}
            contact_id={details.client_id}
            currentUser={currentUser}
          />
        )}

        <EditContactModal
          isOpen={showEditClientModal}
          clientInfo={details}
          handleClose={() => setShowEditClientModal(false)}
          handleClientUpdate={handleClientUpdate}
        />
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  min-width: 0px;

  & .toggle-menu {
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    overflow-x: auto;
  }
  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
  }
  & .description {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 12px;
    margin-top: 10px;
  }
`;

export default ContactOverview;
