import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePermissions } from "../../../hooks/usePermissions";
import MonolithNotesAPI from "../../../api/Notes/index.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@monolith-forensics/monolith-ui";
import { ArrowLeftIcon } from "lucide-react";
import EditorTemplateForm from "../../../components/Forms/EditorTemplateForm.js";
import LoggingAPI from "../../../api/logging/index.js";
import { useSnackbar } from "notistack";

const TemplateEditor = () => {
  const { uuid } = useParams();
  const { currentUser } = usePermissions();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useQuery({
    queryKey: [
      "editorTemplates",
      "list",
      {
        data_only: true,
        include_content: true,
        uuid,
        created_by_id: currentUser.user_id,
        include_shared: false,
      },
    ],
    queryFn: () =>
      MonolithNotesAPI.getTemplates({
        data_only: true,
        include_content: true,
        uuid,
        created_by_id: currentUser.user_id,
        include_shared: false,
      }),
  });

  const onSubmit = async (formData, diffData) => {
    // check if there are any changes
    if (Object.keys(diffData).length === 0) {
      enqueueSnackbar("No changes detected.", {
        variant: "warning",
      });
      return;
    }

    // update cache
    queryClient.setQueryData(
      [
        "editorTemplates",
        "list",
        {
          data_only: true,
          include_content: true,
          uuid,
          created_by_id: currentUser.user_id,
          include_shared: false,
        },
      ],
      (oldData) => {
        return oldData.map((item) => {
          if (item.uuid === uuid) {
            return { ...item, ...diffData };
          }
          return item;
        });
      }
    );

    await MonolithNotesAPI.updateTemplate({
      uuid,
      ...diffData,
    });

    enqueueSnackbar(`Editor Template Updated.`, {
      variant: "success",
    });

    LoggingAPI.createLog({
      user_id: currentUser.user_id,
      message: `Updated editor template: ${formData.name}`,
      event: "update_editor_template",
      object_type: "editor_template",
      object_uuid: uuid,
    });
  };

  const onCancel = () => {
    navigate("/settings/editor-templates");
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Link to={"/settings/editor-templates"} style={{ width: "fit-content" }}>
        <Button
          size="xxs"
          variant="text"
          color="gray"
          leftSection={<ArrowLeftIcon size={14} />}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          Templates
        </Button>
      </Link>
      <EditorTemplateForm
        title="Edit Editor Template"
        defaultInfo={data?.[0] || {}}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
};

export default TemplateEditor;
