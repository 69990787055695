import React, { useEffect, useMemo, useRef, useState } from "react";

import { getDateFormat } from "../../utils/date-format";
import { useTheme, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { usePermissions } from "../../hooks/usePermissions";
import styled from "styled-components";
import Menu, { MenuItem } from "../../Monolith-UI/Menu.js";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { useSnackbar } from "notistack";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import TableDefs from "../../components/Metrics-Reports/TableDefs.js";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import MonolithReportsAPI from "../../api/reports/index.js";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import Loader from "../../components/Loader.js";
import QueryFilterDimensions from "../../components/Metrics-Reports/QueryFilterDimensions.js";
import CreateReportModal from "./CreateReportPopup.js";
import { useNavigate } from "react-router-dom";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import { FileIcon } from "lucide-react";
import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import { MONOLITH_PERMISSIONS } from "../../constants.js";
import { Input } from "@monolith-forensics/monolith-ui";

const columnDefs = TableDefs;

const ReportsHeader = styled(({ className, onChange = () => {} }) => {
  const { hasPermission } = usePermissions();

  const theme = useTheme();
  const [currentView, setCurrentView] = useState("My Reports");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (item) => {
    handleClose();
    setCurrentView(item.text);
    onChange(item);
  };

  const hasReadAllPermission = hasPermission(
    MONOLITH_PERMISSIONS.METRICS_REPORTS_READ_ALL
  );

  return (
    <div className={className}>
      <div
        className={`view-selector` + (hasReadAllPermission ? " admin" : "")}
        onClick={hasReadAllPermission ? handleClick : null}
      >
        {hasReadAllPermission ? (
          <>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">{currentView}</Typography>
              <ArrowDropDownOutlinedIcon
                style={{ fontSize: 26, marginLeft: 5 }}
              />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onItemSelect={handleSelect}
              dropDownTitle={""}
            >
              <MenuItem
                onClick={handleClose}
                data={{ text: "My Reports", value: 0 }}
                style={{ minWidth: 200 }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    My Reports
                  </div>
                }
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                data={{ text: "All Reports", value: 1 }}
                style={{ minWidth: 200 }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      color: theme.palette.text.primary,
                    }}
                  >
                    All Reports
                  </div>
                }
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Typography variant="h3">My Reports</Typography>
        )}
      </div>
      <Typography color="textSecondary" variant="subtitle2">
        <span id="reports-total"></span>
      </Typography>
    </div>
  );
})`
  .view-selector {
    border: 1px solid transparent;
    transition: all 0.2s ease;
    width: fit-content;
    user-select: none;
  }
  .view-selector.admin:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 5px;
  }
`;

const ReportsContainer = styled(
  ({
    className,
    adminView,
    stateStoreKey = "metrics-reports:view",
    defaultQueryKey = "metrics-reports:list",
  }) => {
    const { currentUser, hasPermission } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const theme = useTheme();
    const queryFilter = useRef(null);
    const navigate = useNavigate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [hasInitialized, setHasInitialized] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);

    const table = useTable();

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const [query, setQuery] = useState(null);
    const userID = adminView ? null : currentUser.user_id;

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          MonolithReportsAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };

    const handleFilter = (newFilter) => {
      setQuery((q) => {
        return {
          query: { ...q?.query, ...newFilter, page: 1, user_id: userID },
        };
      });
    };

    const handleRefresh = () => {
      setHasInitialized(false);
      queryClient
        .refetchQueries({
          queryKey: [
            defaultQueryKey,
            {
              query: {
                ...query?.query,
                order: query?.query?.order || currentSort,
                pageSize,
              },
            },
          ],
          exact: true,
        })
        .then(() => setHasInitialized(true));
    };

    const handleClearFilters = () => {
      queryFilter.current.clear();
    };

    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      MonolithReportsAPI.exportReportsList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false && c.showInColumnChooser !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat(true, true),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();
        // remove snackbar
      });
    };

    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };

    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };

    const handleActionButtonClick = (rowData) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };

    const handleGetNextItem = (id) => {
      const current = records?.findIndex((i) => i.user_id === id);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (id) => {
      const current = records?.findIndex((i) => i.user_id === id);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };
    const handleRefetchQuery = () => {
      refetch();
    };
    const onCreate = (result) => {
      navigate(`${result.report_id}`);
    };
    const onEdit = () => handleRefetchQuery();
    const onDelete = () => handleRefetchQuery();

    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    const { queryButton, conditions } = useQueryFilter({
      dimensions: QueryFilterDimensions.filter((q) => {
        if (adminView !== true) return q.admin !== true;
        return true;
      }),
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    return (
      <div className={className}>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={
              !hasPermission(MONOLITH_PERMISSIONS.METRICS_REPORTS_CREATE)
            }
            onClick={(e) => {
              setShowCreateModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Report
          </Button>
          <CreateReportModal
            open={showCreateModal}
            handleClose={() => setShowCreateModal(false)}
            onSubmit={(res) => onCreate(res)}
          />
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={FileIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <Input
              placeholder="Search Reports"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e) => {
                const currentValue = e.target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && hasInitialized && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records}
              totalRecords={totalRecords}
              keyValue="report_id"
              tableInstance={table}
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              showActionColumn={false}
              focusedRow={flyoutData}
            >
              {columnState
                .filter((col) => {
                  if (
                    !hasPermission(
                      MONOLITH_PERMISSIONS.METRICS_REPORTS_DELETE
                    ) &&
                    col.dataField === "actions"
                  ) {
                    return false;
                  }
                  return true;
                })
                .map((col) => {
                  return <Column key={col.dataField} {...col} />;
                })}
            </Table>
          </>
        )}
        {(!data || !hasInitialized) && (
          <Loader message={"Retrieving Reports..."} />
        )}
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ReportSectionContent = styled(({ className }) => {
  const [currentView, setCurrentView] = useState("My Reports");
  const handleReportViewChange = (item) => {
    setCurrentView(item.text);
  };

  return (
    <div className={className}>
      <Helmet title="Monolith Reports" />
      <ReportsHeader onChange={handleReportViewChange} />
      <ReportsContainer adminView={currentView === "All Reports"} />
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ReportSection = () => {
  const { hasPermission } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.METRICS_REPORTS_READ)) {
    return <AccessDeniedMessage />;
  }

  return <ReportSectionContent />;
};

export default ReportSection;
