// React & 3rd party libraries
import { useEffect, useRef, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";
import styled from "styled-components";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { useDebounce, useDebouncedCallback } from "use-debounce";

// Icons
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import { MonitorSmartphoneIcon } from "lucide-react";

// APIs, custom hooks, utilities
import { getEvidence } from "../../../../api/index.js";
import EvidenceAPI from "../../../../api/evidence/index.js";
import OfficesApi from "../../../../api/offices/index.js";
import ClientsAPI from "../../../../api/clients/index.js";
import LocationsAPI from "../../../../api/locations/index.js";
import {
  getDateFormat,
  monolithMoment,
} from "../../../../utils/date-format.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useQueryFilter } from "../../../../Monolith-UI/QueryFilter/QueryFilter.js";
import synchronizeColumnState from "../../../../utils/synchronize-column-state.js";

// Custom components
import Loader from "../../../../components/Loader.js";
import ComboButton from "../../../../Monolith-UI/ComboButton/ComboButton.js";
import Table, {
  Column,
  useTable,
} from "../../../../Monolith-UI/Table/Table.js";
import Flyout, { FlyoutHeader } from "../../../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../../../Monolith-UI/TaskButton.js";
import { addCustomFieldsToColumnDefs } from "../../../../components/Evidence/CustomFieldCell.js";
import TableColumnDefs from "../../../../components/Evidence/TableColumnDefs.js";
import EvidenceDimensions from "../../../../components/Evidence/EvidenceDimensions.js";
import { UserToolTip } from "../UserToolTip.js";
import EditEvidenceModal from "../../../Evidence/components/Popups/EditEvidenceModal.js";
import DeleteEvidencePopup from "../../../../components/Evidence/DeleteEvidencePopup.js";
import { Input } from "@monolith-forensics/monolith-ui";

const columnDefs = TableColumnDefs;

const userTooltipMessage = {
  header: "My Case Evidence",
  body: "Below are only the evidence items that are assigned to you. There may be other evidence items associated with this contact that are not visible here.",
};

const adminTooltipMessage = {
  header: "All Case Evidence",
  body: "Below are all the evidence items associated with this contact.",
};

const EvidenceFlyoutContent = styled(
  ({
    className,
    defaultEvidenceInfo,
    editEvidence = () => {},
    deleteEvidencePopup,
    reloadFn = () => {},
    columnDefs,
  }) => {
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const theme = useTheme();
    const queryClient = useQueryClient();

    const { data: evidenceInfo } = useQuery({
      queryKey: [
        "evidence:list",
        { evidence_id: defaultEvidenceInfo.evidence_id },
      ],
      queryFn: () =>
        getEvidence({ evidence_id: defaultEvidenceInfo.evidence_id }),
      initialData: [defaultEvidenceInfo],
      select: (data) => {
        return data[0];
      },
    });

    const handleEditEvidence = () => {
      editEvidence();
    };

    const handleDeleteEvidence = () => {
      deleteEvidencePopup.current.instance.setEvidenceInfo(evidenceInfo);
      deleteEvidencePopup.current.instance.show();
    };

    const handleChange = (data) => {
      reloadFn();
      queryClient.setQueryData(
        ["evidence:list", { evidence_id: defaultEvidenceInfo.evidence_id }],
        (oldData) => {
          const dataObj = oldData[0] || {};
          return [{ ...dataObj, ...data }];
        }
      );
    };

    const dataFieldIgnoreList = ["description"];

    const data = {
      ...evidenceInfo,
      ...(evidenceInfo?.custom_attributes?.reduce((acc, attr) => {
        acc[`custom_field_${attr.field_id}`] = attr.value;
        return acc;
      }, {}) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
          .filter((c) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data, { onChange: handleChange })
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        <div className="action-menu">
          <Link
            className={className}
            to={`/evidence/${evidenceInfo.uuid}/overview`}
            style={{ padding: 0, margin: 0 }}
          >
            <div className="action-menu-item">
              <LaunchOutlinedIcon
                style={{ color: theme.palette.primary.main }}
              />
              <div className="action-menu-label">View Evidence</div>
            </div>
          </Link>
          {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE) && (
            <>
              <div className="action-menu-item" onClick={handleEditEvidence}>
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
              {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_DELETE) && (
                <div
                  className="action-menu-item"
                  onClick={handleDeleteEvidence}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{ color: theme.palette.error.main }}
                  />
                  <div className="action-menu-label">Delete</div>
                </div>
              )}
            </>
          )}
        </div>
        {detail}
        <div className="detail-label" style={{ marginTop: 30 }}>
          Description
        </div>
        <div style={{ position: "relative" }}>
          {useMemo(() => {
            return (
              <div className="description">{evidenceInfo.description}</div>
            );
          }, [evidenceInfo])}
        </div>
      </div>
    );
  }
)`
  padding: 20px;

  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .detail-container {
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 25px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

export const ItemTotal = styled(
  ({ className, total, Icon = DevicesOutlinedIcon }) => {
    return (
      <div className={className} title="Total Items">
        <Icon
          style={{
            fontSize: 14,
            marginRight: 5,
          }}
          size={14}
        />
        <div
          style={{
            fontSize: 12,
          }}
        >
          {total || 0}
        </div>
      </div>
    );
  }
)`
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.secondary};
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const EvidenceTable = styled(
  ({
    className,
    stateStoreKey = "evidence:list",
    client_id = null,
    contact_id = null,
    viewData = [
      { text: "My Evidence", value: "user-view" },
      { text: "All Evidence", value: "admin-view" },
    ],
    currentUser = null,
  }) => {
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const pageSize = 20;

    const deleteEvidencePopup = useRef(null);
    const queryClient = useQueryClient();
    const theme = useTheme();
    const queryFilter = useRef(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);
    const [showEditEvidenceModal, setShowEditEvidenceModal] = useState(false);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const table = useTable();

    const [columnState, setColumnState] = useState(() => {
      // Synchronizes stored state in local storage and column defs object
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    let filterDimensions = EvidenceDimensions;

    const currentSort = useMemo(() => {
      let [sort] =
        columnState
          ?.filter((c) => c.sorting?.active)
          ?.map((c) => ({ field: c.dataField, sort: c.sorting?.direction })) ||
        [];

      return sort;
    }, [columnState]);

    // Determines scope of cases displayed in Client Table
    const [currentView, setCurrentView] = useState(() => {
      const savedViewState = JSON.parse(
        localStorage.getItem("contact:view:evidence")
      );
      // If the user is an admin and has no view selected, default the view to "All evidence"
      if (hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_READ_ALL)) {
        if (!savedViewState) {
          localStorage.setItem(
            "contact:view:evidence",
            JSON.stringify(viewData[1])
          );
        }
        return savedViewState || viewData[1];
      } else {
        // For regular users, keep "My evidence" as the default view
        // Set to "My Acquisitions" or the default view for regular users
        localStorage.setItem(
          "contact:view:evidence",
          JSON.stringify(viewData[0])
        );
        return viewData[0];
      }
    });

    const userID =
      currentView.value === "user-view" ? currentUser.user_id : undefined;

    const handleFilter = (newFilter) => {
      setQuery((q) => {
        return {
          query: {
            ...q?.query,
            ...newFilter,
            pageSize,
            user_id: userID,
          },
        };
      });
    };
    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        const currentColumn = cs.find((c) => c.dataField === column.dataField);
        if (currentColumn) {
          return cs.map((c) => {
            if (c.dataField === column.dataField) {
              return {
                ...c,
                visible,
              };
            }
            return c;
          });
        } else {
          return [
            ...cs,
            {
              ...column,
              visible,
            },
          ];
        }
      });
    };
    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting evidence table...", {
        variant: "info",
      });
      EvidenceAPI.exportEvidenceList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat(true, true),
        custom_date_format: getDateFormat(true, false),
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();

        // remove element
        el.remove();

        // remove snackbar
      });
    };
    const handleRefresh = () => {
      refetch();
    };
    const handleReload = () => {
      refetch();
    };
    const handleClearFilters = () => {
      queryFilter.current.clear();
    };
    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };
    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnResize = (e) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          const col = e.columns.find((col) => col.dataField === c.dataField);
          if (col) {
            return {
              ...c,
              width: parseInt(col.width.replace(/px/g, "")),
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };
    const handleGetNextItem = (uuid) => {
      const current = records?.findIndex((i) => i.uuid === uuid);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (uuid) => {
      const current = records?.findIndex((i) => i.uuid === uuid);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    const handleRowSelection = (rows) => {
      setSelectedRows(rows);
    };
    const handleEditFinished = (e) => {
      queryClient.refetchQueries({
        queryKey: ["evidence:list", query],
      });
      queryClient.refetchQueries({
        queryKey: [
          "evidence:list",
          { evidence_id: e.evidenceInfo.evidence_id },
        ],
      });
    };
    const handleEvidenceDeleted = (deletedEvidence) => {
      setIsFlyoutVisible(false);
      setFlyoutData(null);
      queryClient.refetchQueries({
        queryKey: ["evidence:list", query],
      });
    };
    // Handler that 'Associated Cases' Header and saves view to local storage
    const handleViewChange = (item) => {
      setCurrentView(item);
      localStorage.setItem("contact:view:evidence", JSON.stringify(item));
    };
    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    const { data: customFields } = useQuery({
      queryKey: ["evidence:customFields:list"],
      queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
    });

    // update filter dimensions with custom fields
    filterDimensions = useMemo(() => {
      if (customFields) {
        //update filter dimensions
        return [
          ...EvidenceDimensions,
          ...customFields.map((field) => ({
            name: field.field_name,
            field: `custom_field_${field.field_id}`,
            id: `custom_field_${field.field_id}`,
            type:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "string"
                : "text",
            mode:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "multi-select"
                : "text",
            options: field.options
              ? JSON.parse(field.options).map((o) => ({
                  option: o,
                }))
              : null,
            selectionDisplayName: field.options ? "option" : null,
            selectionIdField: field.options ? "option" : null,
          })),
        ];
      }
      return EvidenceDimensions;
    }, [customFields]);

    const { queryButton, conditions, resolvedQuery } = useQueryFilter({
      dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    const [query, setQuery] = useState({
      query: {
        conditions: resolvedQuery?.conditions || [],
      },
    });

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          "evidence",
          "list",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
              client_id,
              contact_id,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          EvidenceAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
              client_id,
              contact_id,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage, pages) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage, pages) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    useQueries({
      queries: [
        {
          queryKey: ["evidence:types"],
          queryFn: () => EvidenceAPI.getEvidenceTypes(),
          enabled: !!data,
        },
        {
          queryKey: ["evidence:providers"],
          queryFn: () => EvidenceAPI.getEvidenceBrands(),
          enabled: !!data,
        },
        {
          queryKey: ["evidence:progress"],
          queryFn: () => EvidenceAPI.getEvidenceProgress(),
          enabled: !!data,
        },
        {
          queryKey: ["evidence:locations", { include_groups: false }],
          queryFn: () =>
            LocationsAPI.getLocations({}, { include_groups: false }),
          enabled: !!data,
        },
        {
          queryKey: ["offices:list"],
          queryFn: () => OfficesApi.getOffices(),
          enabled: !!data,
        },
        {
          queryKey: ["clients:list"],
          queryFn: () => ClientsAPI.getClients(),
          enabled: !!data,
        },
        {
          queryKey: ["clients:organizations"],
          queryFn: () => ClientsAPI.getOrganizations(),
          enabled: !!data,
        },
      ],
    });

    // Save Column State to Local Storage
    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      if (!customFields) return;
      // Add custom fields to column defs
      setColumnState((cs) => {
        return addCustomFieldsToColumnDefs(customFields, cs);
      });
    }, [customFields]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    return (
      <div className={className}>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_READ_ALL) ? (
            <>
              <UserToolTip
                info={
                  currentView.value === "admin-view"
                    ? adminTooltipMessage
                    : userTooltipMessage
                }
              />
              <ComboButton
                type="dropDown"
                data={viewData}
                value={currentView}
                showDropdownIcon={true}
                displayField="text"
                variant="outlined"
                useSelectMode={true}
                onItemSelect={(e) => handleViewChange(e)}
                textColor={theme.palette.text.primary}
              />
            </>
          ) : (
            <UserToolTip info={userTooltipMessage} />
          )}
          <div style={{ marginLeft: 2 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={MonitorSmartphoneIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Refresh Table"}
              onClick={handleRefresh}
            >
              <SyncOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            {/* SEARCH */}
            <Input
              placeholder="Search Evidence"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e) => {
                const currentValue = e.target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && customFields && (
          <>
            <div>{conditions}</div>
            <Table
              data={records || []}
              totalRecords={totalRecords || 0}
              reloadFn={handleReload}
              keyValue="uuid"
              tableInstance={table}
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              queryKey={[
                "evidence:list",
                {
                  query: {
                    ...query?.query,
                    order: query?.query?.order || currentSort,
                    pageSize,
                  },
                },
              ]}
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              onColumnResize={handleColumnResize}
              onActionButtonClick={handleActionButtonClick}
              onSelectionChanged={handleRowSelection}
              selectedRows={selectedRows}
              showActionColumn={true}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
            {isFlyoutVisible && (
              <Flyout
                onClose={() => {
                  setIsFlyoutVisible(false);
                  setFlyoutData(null);
                }}
              >
                <FlyoutHeader>
                  <div style={{ width: "100%" }}>
                    <h4
                      style={{
                        margin: "5px 0px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {flyoutData.evidence_number}
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: 12,
                        }}
                      >
                        Evidence Details
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TaskButton
                          onClick={() => handleGetPrevItem(flyoutData.uuid)}
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowLeftOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                        <TaskButton
                          onClick={() => handleGetNextItem(flyoutData.uuid)}
                          style={{
                            fontSize: 10,
                          }}
                          variant="outlined"
                        >
                          <KeyboardArrowRightOutlinedIcon
                            style={{ fontSize: 17 }}
                          />
                        </TaskButton>
                      </div>
                    </div>
                  </div>
                </FlyoutHeader>
                <EvidenceFlyoutContent
                  defaultEvidenceInfo={flyoutData}
                  deleteEvidencePopup={deleteEvidencePopup}
                  setIsFlyoutVisible={setIsFlyoutVisible}
                  reloadFn={handleReload}
                  columnDefs={columnState}
                  editEvidence={() => setShowEditEvidenceModal(true)}
                />
              </Flyout>
            )}
            <EditEvidenceModal
              open={showEditEvidenceModal}
              onEditSubmit={handleEditFinished}
              handleClose={() => setShowEditEvidenceModal(false)}
              defaultEvidenceInfo={flyoutData}
            />

            <DeleteEvidencePopup
              deleteEvidencePopup={deleteEvidencePopup}
              onDelete={handleEvidenceDeleted}
            />
          </>
        )}
        {!(data && customFields) && (
          <Loader message={"Retrieving Evidence..."} />
        )}
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default EvidenceTable;
