import { Box, Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import {
  deleteLabel,
  getAllLabelInfo,
  updateLabel,
  createLabel,
} from "../../api";
import CollapseSection from "../../components/CollaspeSection";
import Loader from "../../components/Loader";
import ToolBarItems from "../../components/ToolBarItems";
import { usePermissions } from "../../hooks/usePermissions";
import LabelExample from "./example_label.PNG";
import {
  labelVariables,
  legacy_labelVariables,
} from "../../utils/labels/labelUtils";
import { useQuery } from "@tanstack/react-query";
import EvidenceAPI from "../../api/evidence";
import CaseStorageAPI from "../../api/storage";
import styled from "styled-components";
import { Download } from "lucide-react";

const VariableItem = ({ item }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = async (event) => {
    try {
      const text = event.target.innerText;
      await navigator.clipboard.writeText(text);
      enqueueSnackbar(`Copied "${text}" to clipboard`, { variant: "success" });
    } catch (err) {
      console.log("Unable to copy!", err);
    }
  };

  return (
    <div style={{ marginBottom: 5 }}>
      <div>
        <div
          style={{
            padding: 2,
            fontWeight: "bold",
            cursor: "pointer",
            width: "fit-content",
          }}
          title="Click to copy"
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = theme.palette.action.hover;
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "unset";
          }}
          onClick={handleCopy}
        >
          {item.value}
        </div>
      </div>
      <div style={{ color: theme.palette.text.secondary }}>
        {item.description}
      </div>
    </div>
  );
};

const LabelList = ({
  itemLabels,
  setItemLabels,
  setDeleteSelection,
  deleteLabelPopup,
}) => {
  useEffect(() => {
    getAllLabelInfo().then((result) => {
      setItemLabels(result);
    });
  }, [setItemLabels]);

  return (
    <>
      {!itemLabels && <Loader />}
      {itemLabels &&
        itemLabels.map((label) => {
          return (
            <LabelItem
              key={label.label_id}
              data={label}
              itemLabels={itemLabels}
              setItemLabels={setItemLabels}
              setDeleteSelection={setDeleteSelection}
              deleteLabelPopup={deleteLabelPopup}
            />
          );
        })}
    </>
  );
};

const LabelItem = ({
  data,
  itemLabels,
  setItemLabels,
  setDeleteSelection,
  deleteLabelPopup,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const handleDownloadLabel = (event) => {
    const element = document.createElement("a");
    const file = new Blob([data.label_data], {
      type: "application/xml",
    });
    element.href = URL.createObjectURL(file);
    element.download = !data.label_name.endsWith(".dymo")
      ? `${data.label_name}.dymo`
      : data.label_name;

    document.body.appendChild(element); // Required for this to work in FireFox

    element.click();
    element.remove();

    // Clean up the blob URL
    window.URL.revokeObjectURL(element.href);
  };

  const handleTypeUpdate = (event) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.label_name;
      return;
    }
    if (event.currentTarget.innerText !== data.label_name) {
      updateLabel(data.label_id, {
        label_name: event.currentTarget.innerText,
      });
      setItemLabels(
        itemLabels.map((label) => {
          if (label.label_id === data.label_id) {
            label.label_name = event.currentTarget.innerText;
          }
          return label;
        })
      );
    }
  };

  const handleDelete = (event) => {
    setDeleteSelection(data);
    deleteLabelPopup.current.instance.show();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px 10px",
          cursor: "pointer",
          "&:hover": { backgroundColor: theme.palette.action.hover },
        }}
      >
        <div>
          <Box
            contentEditable={true}
            suppressContentEditableWarning
            sx={{
              fontSize: "larger",
              padding: "2px",
              cursor: "text",
              "&:hover": {
                outline: `1px solid ${theme.palette.text.secondary}`,
              },
              "&:focus": {
                outline: `1px solid ${theme.palette.text.secondary}`,
              },
            }}
            onBlur={handleTypeUpdate}
          >
            {data.label_name}
          </Box>
        </div>

        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="text"
            color="primary"
            size="small"
            disabled={!hasPermission()}
            onClick={handleDownloadLabel}
          >
            <Download size={16} />
          </Button>
          <Button
            variant="text"
            color="error"
            size="small"
            disabled={!hasPermission()}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Box>
    </>
  );
};

const DeleteLabelPopup = ({
  deleteLabelPopup,
  itemLabels,
  setItemLabels,
  deleteSelection,
}) => {
  return (
    <Popup
      ref={deleteLabelPopup}
      showTitle={true}
      showCloseButton={true}
      title={`Delete Label ${deleteSelection?.label_name || ""}`}
      defaultHeight={150}
      defaultWidth={450}
    >
      <div style={{ marginTop: "10px", marginBottom: "20px" }}>
        Are you sure you want to delete this label?
      </div>
      <ToolBarItems
        submitText="Delete Label"
        submitColor="error"
        onSubmit={() => {
          deleteLabelPopup.current.instance.hide();
          deleteLabel(deleteSelection.label_id);
          setItemLabels(
            itemLabels.filter(
              (item) => item.label_id !== deleteSelection.label_id
            )
          );
        }}
        onCancel={() => {
          deleteLabelPopup.current.instance.hide();
        }}
      />
    </Popup>
  );
};

const ItemLabelSettings = styled(({ className }) => {
  const [itemLabels, setItemLabels] = useState(null);
  const [deleteSelection, setDeleteSelection] = useState(null);
  const deleteLabelPopup = useRef(null);

  // Get evidence custom fields
  const { data: evidenceCustomFields } = useQuery({
    queryKey: ["evidence:customFields:list"],
    queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
  });

  const { data: storageCustomFields } = useQuery({
    queryKey: ["storage:custom-fields:list"],
    queryFn: () =>
      CaseStorageAPI.getStorageCustomAttributes({ fieldsOnly: true }),
  });

  const handleCreateLabel = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // Set SQL blob size limit of 64KB
      const sizeLimit = 65535;

      if (file.size > sizeLimit) {
        alert(
          `File size exceeds the limit of 64 KB. Please upload a smaller file.`
        );
        return;
      }

      const reader = new FileReader();
      reader.readAsText(event.target.files[0]);

      reader.onload = () => {
        createLabel({
          label_name: event.target.files[0].name,
          label_data: reader.result,
          trim: false,
        }).then((result) => {
          setItemLabels([
            ...itemLabels,
            {
              label_id: result.label_id,
              label_name: event.target.files[0].name,
              label_data: reader.result,
            },
          ]);
        });
      };
    }
  };

  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      <CollapseSection title="Your Labels" visible={true}>
        <p>
          Upload DYMO labels to use with evidence, storage items, or people.
          Monolith will print labels to a connected DYMO printer using the
          customized labels provided.
        </p>
        <p>
          Use the variables listed below to create a custom DYMO label, then
          upload it here for use.
        </p>
        <p>Example Label:</p>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <img
            src={LabelExample}
            alt="LabelExample"
            style={{ maxWidth: 300, margin: "auto" }}
          ></img>
        </div>
        <div style={{ margin: "25px 0px" }}>
          <input
            accept="*"
            style={{ display: "none" }}
            id="create-label-input"
            type="file"
            onChange={handleCreateLabel}
            multiple
          />
          <label htmlFor="create-label-input">
            <Button
              size="small"
              variant="contained"
              color="primary"
              component="span"
            >
              + Add Label
            </Button>
          </label>
        </div>
        <LabelList
          itemLabels={itemLabels}
          setItemLabels={setItemLabels}
          setDeleteSelection={setDeleteSelection}
          deleteLabelPopup={deleteLabelPopup}
        />
        {itemLabels && (
          <DeleteLabelPopup
            deleteLabelPopup={deleteLabelPopup}
            itemLabels={itemLabels}
            setItemLabels={setItemLabels}
            deleteSelection={deleteSelection}
            setDeleteSelection={setDeleteSelection}
          />
        )}
      </CollapseSection>
      <CollapseSection title="Organization Variables">
        <p>
          Use these varibales to populate a DYMO label with details from your
          organization.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {labelVariables.Organization.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
        </div>
      </CollapseSection>
      <CollapseSection title="Evidence Variables">
        <p>
          Use these variables to populate a DYMO lable with details from a
          specific evidence item.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {labelVariables.Evidence.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
          <div style={{ fontWeight: 900, margin: "10px auto", fontSize: 14 }}>
            Custom Evidence Variables
          </div>
          {evidenceCustomFields?.map((item) => {
            const labelObject = {
              value: `{{ evidence.custom_field_${item.field_id} }}`,
              description: item.field_name,
            };
            return <VariableItem key={labelObject.value} item={labelObject} />;
          })}
        </div>
      </CollapseSection>
      <CollapseSection title="Storage Variables">
        <p>
          Use these variables to populate a DYMO label with details from a
          specific storage item.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {labelVariables.Storage.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
          <div style={{ fontWeight: 900, margin: "10px auto", fontSize: 14 }}>
            Custom Storage Variables
          </div>
          {storageCustomFields?.map((item) => {
            const labelObject = {
              value: `{{ storage.custom_field_${item.field_id} }}`,
              description: item.field_name,
            };
            return <VariableItem key={labelObject.value} item={labelObject} />;
          })}
        </div>
      </CollapseSection>
      <CollapseSection title="People Variables">
        <p>
          Use these variables to populate a DYMO label with details from a
          specific client or contact.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {labelVariables.People.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
        </div>
      </CollapseSection>
      <h3>Legacy Labels</h3>
      <CollapseSection title="Organization Variables (DEPRECATED)">
        <p>
          Use these varibales to populate a DYMO label with details from your
          organization.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {legacy_labelVariables.Organization.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
        </div>
      </CollapseSection>
      <CollapseSection title="Evidence Variables (DEPRECATED)">
        <p>
          Use these variables to populate a DYMO lable with details from a
          specific evidence item.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {legacy_labelVariables.Evidence.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
          <div style={{ fontWeight: 900, margin: "10px auto", fontSize: 14 }}>
            Custom Evidence Variables
          </div>
          {evidenceCustomFields?.map((item) => {
            const labelObject = {
              value: `#custom_field_${item.field_id}`,
              description: item.field_name,
            };
            return <VariableItem key={labelObject.value} item={labelObject} />;
          })}
        </div>
      </CollapseSection>
      <CollapseSection title="Storage Variables (DEPRECATED)">
        <p>
          Use these variables to populate a DYMO label with details from a
          specific storage item.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {legacy_labelVariables.Storage.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
          <div style={{ fontWeight: 900, margin: "10px auto", fontSize: 14 }}>
            Custom Storage Variables
          </div>
          {storageCustomFields?.map((item) => {
            const labelObject = {
              value: `#custom_field_${item.field_id}`,
              description: item.field_name,
            };
            return <VariableItem key={labelObject.value} item={labelObject} />;
          })}
        </div>
      </CollapseSection>
      <CollapseSection title="Associations Variables (DEPRECATED)">
        <p>
          Use these variables to populate a DYMO label with details from a
          specific client or contact.
        </p>
        <div style={{ maxHeight: 500, overflowY: "auto", paddingRight: 5 }}>
          {legacy_labelVariables.People.map((item) => {
            return <VariableItem key={item.value} item={item} />;
          })}
        </div>
      </CollapseSection>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default ItemLabelSettings;
