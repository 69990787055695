import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import AuditsAPI from "../../api/Audits/Audits.js";
import PassIndicator from "../../components/PassIndicator.js";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import { getDateFormat } from "../../utils/date-format.js";
import { Link } from "react-router-dom";

const AuditContent = styled(({ className, evidenceInfo }) => {
  const { data } = useQuery({
    queryKey: ["audits:logs", { object_uuid: evidenceInfo.uuid }],
    queryFn: () => AuditsAPI.getAuditLogs({ object_uuid: evidenceInfo.uuid }),
  });

  const table = useTable();

  return (
    <div className={className}>
      <Table
        data={data ? data.data : []}
        keyValue="uuid"
        tableInstance={table}
        columnProps={{ minWidth: 150, width: 150 }}
      >
        <Column
          dataField="audit_link"
          caption="Audit"
          render={(data) => {
            return (
              <Link to={`/audits/${data.audit.uuid}/audit-items`}>
                <span title="View Audit">{data.audit.name}</span>
              </Link>
            );
          }}
        />
        <Column
          dataField="created_on"
          caption="Timestamp"
          dataType="date"
          format={{ type: getDateFormat(true, false) }}
        />
        <Column
          dataField="created_by"
          caption="User"
          render={(data) => data.created_by.full_name}
        />
        <Column
          dataField="passed_audit"
          caption="Audit Status"
          render={(data) => <PassIndicator passed={data.passed_audit} />}
        />
        <Column dataField="notes" caption="Notes" />
      </Table>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;

export default AuditContent;
