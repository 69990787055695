import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import AuditsAPI from "../../api/Audits/Audits.js";
import Tabs from "../../Monolith-UI/Tabs/Tabs";
import { Helmet } from "react-helmet-async";
import AuditDetailsHeader from "./Components/AuditDetailsHeader.js";
import AuditItems from "./Details/AuditItems.js";
import AuditLogs from "./AuditLogs/AuditLogs.js";

const AuditDetailsPage = styled(({ className }) => {
  const { uuid, active_tab } = useParams();

  const { data } = useQuery({
    queryKey: ["audit:list", { uuid }],
    queryFn: () => AuditsAPI.get({ uuid }),
  });

  const auditData = data?.data?.[0] || null;

  return (
    <div className={className}>
      <Helmet title={`Audit Details - ${uuid}`} />
      <AuditDetailsHeader auditData={auditData} />
      <div style={{ marginTop: 0 }}>
        <Tabs value={active_tab}>
          <Tabs.List>
            <Link to={`/audits/${uuid}/audit-items`}>
              <Tabs.Tab value="audit-items" onClick={() => {}}>
                Audit Items
              </Tabs.Tab>
            </Link>
            <Link to={`/audits/${uuid}/audit-logs`}>
              <Tabs.Tab value="audit-logs" onClick={() => {}}>
                Audit Logs
              </Tabs.Tab>
            </Link>
          </Tabs.List>
        </Tabs>
      </div>
      {active_tab === "audit-items" && <AuditItems auditData={auditData} />}
      {active_tab === "audit-logs" && <AuditLogs auditData={auditData} />}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default AuditDetailsPage;
