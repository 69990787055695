import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import UserRolesAPI from "../../../../api/UserRoles/UserRoles";
import MonolithPermissionsAPI from "../../../../api/Permissions";
import { ReactNode, useState } from "react";
import { Switch, TextInput } from "@monolith-forensics/monolith-ui";
import Loader from "../../../../components/Loader";
import shortUUID from "short-uuid";
import { useDebouncedCallback } from "use-debounce";

const disabledPermissions = ["evidence:bulk-update"];

type SectionMapObject = {
  [key: string]: string;
};

interface PermissionSetting {
  permission: string;
  description: string;
  protected: boolean;
  uuid: string;
  id: number;
}

interface UserPermissionQuery {
  data: UserPermission[];
}

interface UserPermission {
  id: number;
  uuid: string;
  user_id: number;
  permission_id: number;
  permission_uuid: string;
}

interface PermissionsTabProps {
  className: string;
  defaultData: any;
}

const PermissionsTab = styled(
  ({ className, defaultData }: PermissionsTabProps) => {
    const defaultQueryKey = [
      "user-roles",
      "permissions",
      { uuid: defaultData.uuid },
    ];
    const [searchValue, setSearchValue] = useState("");
    const queryClient = useQueryClient();

    const {
      data: userPermissions,
      isLoading: userPermissionsLoading,
      refetch,
    } = useQuery<UserPermissionQuery>({
      queryKey: defaultQueryKey,
      queryFn: ({ queryKey }) => {
        const [_, __, query] = queryKey as [string, string, any];
        return UserRolesAPI.getPermissions(query);
      },
    });

    const {
      data: availablePermissions,
      isLoading: availablePermissionsLoading,
    } = useQuery({
      queryKey: ["permissions", "list"],
      queryFn: () => MonolithPermissionsAPI.getAllPermissions(),
      select: (data) => {
        return {
          data:
            data?.data?.filter?.((permission: PermissionSetting) => {
              // remove any disabled permissions
              if (disabledPermissions.includes(permission.permission)) {
                return false;
              }
              return true;
            }) || [],
        };
      },
    });

    const deleteRolePermissionMutation = useMutation({
      mutationFn: UserRolesAPI.deletePermission,
      onMutate: async ({ permission_uuid }: any) => {
        await queryClient.cancelQueries({
          queryKey: defaultQueryKey,
        });
        const previousPermissions = queryClient.getQueryData(defaultQueryKey);

        queryClient.setQueryData(
          defaultQueryKey,
          (old: { data: UserPermission[] }) => {
            return {
              ...old,
              data:
                old?.data?.filter?.(
                  (permission) => permission.uuid !== permission_uuid
                ) || [],
            };
          }
        );

        // return the original permissions list sans delete in case we need to rollback the optimistic update
        return { previousPermissions };
      },
      onError: (err, permission_uuid, context) => {
        console.log(err);
        queryClient.setQueryData(defaultQueryKey, context?.previousPermissions);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: defaultQueryKey,
        });
      },
    });

    const createRolePermissionMutation = useMutation({
      mutationFn: UserRolesAPI.createPermission,
      onMutate: async ({ permission_uuid }: any) => {
        await queryClient.cancelQueries({
          queryKey: defaultQueryKey,
        });
      },
      onError: (err, permissionId, context) => {
        console.log(err);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: defaultQueryKey,
        });
      },
    });

    const handleOptionChange = async (
      setting: PermissionSetting,
      value: boolean
    ) => {
      // remove/add perm on toggle
      const settingToChange = availablePermissions?.data?.find?.(
        (permission: PermissionSetting) => permission.id === setting.id
      );

      if (settingToChange) {
        if (value === false) {
          // make api call to remove setting from the user
          try {
            await deleteRolePermissionMutation.mutateAsync({
              permission_uuid: settingToChange.uuid,
              uuid: defaultData.uuid,
            });
          } catch (e) {
            console.log(
              `An error occurred while removing the user permission: ${e}`
            );
          }
        } else {
          try {
            await createRolePermissionMutation.mutateAsync({
              role_permission_uuid: shortUUID.generate(),
              uuid: defaultData.uuid,
              permission_uuid: settingToChange.uuid,
            });
          } catch (e) {
            console.log(
              `An error occurred while creating the user permission: ${e}`
            );
          }
        }
      } else {
        throw new Error("Something went wrong retrieving the setting");
      }
    };

    const handleSearch = useDebouncedCallback((e: any) => {
      setSearchValue(e.target.value);
    }, 150);

    const sectionMapBuilder = (): ReactNode => {
      const sectionMap: SectionMapObject = {};

      availablePermissions?.data
        ?.filter((setting: PermissionSetting) => {
          // implement search
          if (searchValue && searchValue.length > 0) {
            return setting.permission
              .toLowerCase()
              .includes(searchValue.toLowerCase());
          }
          return true;
        })
        ?.forEach((permission: PermissionSetting) => {
          const resource = permission.permission.split(":")[0];
          if (!sectionMap[resource]) {
            sectionMap[resource] =
              resource.charAt(0).toUpperCase() + resource.slice(1);
          }
        });

      const sections: ReactNode[] = Object.entries(sectionMap).map(
        ([key, value]) => {
          const sectionContent = availablePermissions?.data
            ?.filter((setting: PermissionSetting) => {
              // implement search
              if (searchValue && searchValue.length > 0) {
                return setting.permission
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              }
              return true;
            })
            ?.map((setting: PermissionSetting) => {
              const permissionResource = setting.permission.split(":")[0];
              if (permissionResource === key) {
                return (
                  <div key={setting.id} className="permission-option">
                    <Switch
                      size="xs"
                      onChange={(value: boolean) =>
                        handleOptionChange(setting, value)
                      }
                      defaultValue={
                        userPermissions?.data?.some(
                          (userPermission: UserPermission) =>
                            userPermission.id === setting.id
                        ) || false
                      }
                      disabled={defaultData.protected}
                      style={{
                        alignItems: "top",
                      }}
                    />
                    <div style={{ marginLeft: "10px" }}>
                      <div className="permission-label">
                        {setting.permission}
                      </div>
                      <div className="permission-description">
                        {setting.description}
                      </div>
                    </div>
                  </div>
                );
              }
              return null; // Ensure we return null if no match
            })
            .filter(Boolean); // Filter out null values

          return (
            <div key={key}>
              <div
                style={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }}
              >
                {value}
              </div>
              <div className="permission-list">{sectionContent}</div>
            </div>
          );
        }
      );

      return <>{sections}</>;
    };

    if (userPermissionsLoading || availablePermissionsLoading) {
      return <Loader />;
    }

    return (
      <div className={className}>
        <div className="menu">
          <div>
            Select the permissions that should apply to this user role. Any user
            that is assigned to this role will have the selected permissions.
          </div>
          <TextInput
            placeholder="Search permissions..."
            onChange={handleSearch}
            style={{ maxWidth: 300 }}
          />
        </div>
        <div className="permission-options">{sectionMapBuilder()}</div>
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  margin-top: 10px;

  .menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
  }

  .permission-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .permission-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .permission-option {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: top;
    font-weight: 500;
    user-select: none;

    .permission-label {
      font-weight: 600;
    }

    .permission-description {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`;

export default PermissionsTab;
