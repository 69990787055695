import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { deleteClient } from "../../../api/index.js";
import Modal from "../../../Monolith-UI/Modal/Modal.js";

export const DeleteContactModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  contactInfo,
}) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    deleteClient(contactInfo.client_id).then(() => {
      navigate("/contacts");
      enqueueSnackbar(`Contact Deleted.`, {
        variant: "success",
      });
    });
    onSubmit();
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      title={`Delete Contact ${contactInfo.name}`}
      submitText="Delete Contact"
      submitColor="error"
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      width="400px"
    >
      <div
        style={{ margin: "20px 0px", display: "flex", flexDirection: "column" }}
      >
        {`Are you sure you want to delete "${contactInfo.name}"?`}
        <div>{`This action cannot be undone.`}</div>
      </div>
    </Modal>
  );
};
