import { Typography } from "@mui/material";
import { usePermissions } from "../../hooks/usePermissions";
import { Helmet } from "react-helmet-async";

import styled from "styled-components";
import { NavLink, Outlet } from "react-router-dom";
import { MONOLITH_PERMISSIONS } from "../../constants.js";

const tabs = [
  {
    label: "Users",
    value: "users",
    permission: MONOLITH_PERMISSIONS.USERS_MANAGE,
  },
  {
    label: "Groups",
    value: "groups",
    permission: MONOLITH_PERMISSIONS.USER_GROUPS_MANAGE,
  },
  {
    label: "Roles",
    value: "roles",
    permission: MONOLITH_PERMISSIONS.USER_ROLES_MANAGE,
  },
];

const Tabs = styled(({ className }) => {
  const { hasPermission } = usePermissions();
  return (
    <div className={className}>
      <div className="tabs-container">
        {tabs
          .filter((tab) => hasPermission(tab.permission))
          .map((tab) => (
            <NavLink
              to={`${tab.value}`}
              key={tab.value}
              activeClassName="active"
            >
              <div className="tab">{tab.label}</div>
            </NavLink>
          ))}
      </div>
      <div className="border-overlay"></div>
    </div>
  );
})`
  margin-top: 5px;
  margin-bottom: 10px;

  .border-overlay {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }

  .tabs-container {
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .tab {
    padding: 4px 8px;
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
  }

  .tab:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  a.active .tab {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const TabSection = styled(({ className }) => {
  return (
    <div className={className}>
      <Tabs />
      <div className="tab-content">
        <Outlet />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .tab-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const TeamSection = styled(({ className }) => {
  return (
    <div className={className}>
      <Helmet title="Team Management" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        style={{ margin: 0 }}
      >
        Team Management
      </Typography>
      <TabSection />
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export default TeamSection;
