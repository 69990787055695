import { Box, Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useEffect, useRef, useState } from "react";
import Loader from "../../components/Loader";
import ToolBarItems from "../../components/ToolBarItems";
import { usePermissions } from "../../hooks/usePermissions";
import TimeEntriesAPI from "../../api/TimeEntries/time-entries.js";
import styled from "styled-components";

const CategoryList = styled(
  ({
    className,
    timeCategories,
    setTimeCategories,
    setDeleteSelection,
    deleteCategoryPopup,
  }) => {
    useEffect(() => {
      TimeEntriesAPI.getTimeCategories().then((result) => {
        setTimeCategories(result);
      });
    }, []);

    return (
      <div className={className}>
        {!timeCategories && <Loader />}
        {timeCategories &&
          timeCategories.map((type) => {
            return (
              <CategoryItem
                key={type.category_id}
                data={type}
                timeCategories={timeCategories}
                setTimeCategories={setTimeCategories}
                setDeleteSelection={setDeleteSelection}
                deleteCategoryPopup={deleteCategoryPopup}
              />
            );
          })}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const CategoryItem = ({
  data,
  timeCategories,
  setTimeCategories,
  setDeleteSelection,
  deleteCategoryPopup,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const handleTypeUpdate = (event) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.category_name;
      return;
    }
    if (event.currentTarget.innerText !== data.category_name) {
      TimeEntriesAPI.updateTimeCategory(data.category_id, {
        category_name: event.currentTarget.innerText,
      });
      setTimeCategories(
        timeCategories.map((type) => {
          if (type.category_id === data.category_id) {
            type.category_name = event.currentTarget.innerText;
          }
          return type;
        })
      );
    }
  };

  const handleDelete = (event) => {
    setDeleteSelection(data);
    deleteCategoryPopup.current.instance.show();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 10px",
        cursor: "pointer",
        "&:hover": { backgroundColor: theme.palette.action.hover },
      }}
    >
      <div>
        <Box
          contentEditable={hasPermission()}
          suppressContentEditableWarning
          sx={{
            fontSize: "larger",
            padding: "2px",
            cursor: "text",
            "&:hover": { outline: `1px solid slategray` },
            "&:focus": { outline: `1px solid slategray` },
          }}
          onBlur={handleTypeUpdate}
        >
          {data.category_name}
        </Box>
        <div style={{ color: "slategrey", padding: "2px" }}>
          {data.total} Time Entries
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={!hasPermission()}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </Box>
  );
};

const CreateCategoryPopup = ({
  createCategoryPopup,
  timeCategories,
  setTimeCategories,
}) => {
  const form = useRef(null);

  return (
    <Popup
      ref={createCategoryPopup}
      showTitle={true}
      showCloseButton={true}
      title="Create New Time Category"
      defaultHeight={185}
      defaultWidth={400}
      onHidden={(e) => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form}>
        <SimpleItem
          dataField="category_name"
          label={{ text: "Time Category" }}
          isRequired={true}
        ></SimpleItem>
      </Form>
      <ToolBarItems
        submitText="Create Time Category"
        onSubmit={() => {
          if (form.current.instance.validate().isValid) {
            let values = Object.assign(
              {},
              form.current.instance.option("formData")
            );

            TimeEntriesAPI.createTimeCategory(values).then((result) => {
              setTimeCategories([
                ...timeCategories,
                {
                  category_id: result.category_id,
                  category_name: values.category_name,
                  total: 0,
                },
              ]);
            });

            createCategoryPopup.current.instance.hide();
          }
        }}
        onCancel={() => {
          createCategoryPopup.current.instance.hide().then(() => {
            form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const DeleteCategoryPopup = ({
  deleteCategoryPopup,
  timeCategories,
  setTimeCategories,
  deleteSelection,
  setDeleteSelection,
}) => {
  const [items, setItems] = useState(timeCategories);
  const form = useRef(null);

  useEffect(() => {
    deleteSelection &&
      setItems(
        timeCategories.filter(
          (item) => item.category_id !== deleteSelection.category_id
        )
      );
  }, [deleteSelection]);

  const handleSubmit = () => {
    if (form.current) {
      if (form.current.instance.validate().isValid) {
        let values = Object.assign(
          {},
          form.current.instance.option("formData")
        );
        TimeEntriesAPI.deleteTimeCategory(deleteSelection.category_id, {
          replace: values.category_name.category_id,
        });
        setTimeCategories(
          items.map((item) => {
            if (item.category_id === values.category_name.category_id) {
              item.total += deleteSelection.total;
            }
            return item;
          })
        );
        deleteCategoryPopup.current.instance.hide();
      }
    } else {
      deleteCategoryPopup.current.instance.hide();
      TimeEntriesAPI.deleteTimeCategory(deleteSelection.category_id);
      setTimeCategories(items);
    }
  };

  const handleCancel = () => {
    deleteCategoryPopup.current.instance.hide().then(() => {
      if (form.current) form.current.instance.resetValues();
    });
  };

  return (
    <Popup
      ref={deleteCategoryPopup}
      showTitle={true}
      showCloseButton={true}
      title={`Delete Time Category ${deleteSelection?.category_name || ""}`}
      defaultHeight={250}
      defaultWidth={450}
      onHidden={(e) => {
        if (form.current) form.current.instance.resetValues();
      }}
    >
      {deleteSelection?.total === 0 && (
        <div style={{ margin: "15px 0px", minHeight: 115 }}>
          Are you sure you want to delete this time category?
        </div>
      )}
      {deleteSelection?.total > 0 && (
        <div style={{ minHeight: 115 }}>
          <div style={{ margin: "15px 0px" }}>
            Select the time category that should replace this one when deleted:
          </div>
          <Form ref={form}>
            <SimpleItem
              dataField="category_name"
              label={{ text: "Time Category" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: items,
                displayExpr: "category_name",
              }}
            ></SimpleItem>
          </Form>
        </div>
      )}
      <ToolBarItems
        submitText="Delete Time Category"
        submitColor="error"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </Popup>
  );
};

const TimeCategorySettings = styled(({ className }) => {
  const [timeCategories, setTimeCategories] = useState(null);
  const [deleteSelection, setDeleteSelection] = useState(null);
  const createCategoryPopup = useRef(null);
  const deleteCategoryPopup = useRef(null);

  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      <div>
        Create, delete, and edit time entry categories. Use these categories to
        help define the type of work conducted when recording billable time
        entries.
      </div>
      <div style={{ margin: "25px 0px" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            createCategoryPopup.current.instance.show();
          }}
        >
          + Create Category
        </Button>
      </div>
      <CategoryList
        timeCategories={timeCategories}
        setTimeCategories={setTimeCategories}
        setDeleteSelection={setDeleteSelection}
        deleteCategoryPopup={deleteCategoryPopup}
      />
      <CreateCategoryPopup
        createCategoryPopup={createCategoryPopup}
        timeCategories={timeCategories}
        setTimeCategories={setTimeCategories}
      />
      {timeCategories && (
        <DeleteCategoryPopup
          deleteCategoryPopup={deleteCategoryPopup}
          timeCategories={timeCategories}
          setTimeCategories={setTimeCategories}
          deleteSelection={deleteSelection}
          setDeleteSelection={setDeleteSelection}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default TimeCategorySettings;
