import styled, { useTheme } from "styled-components";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import TimeEntriesAPI from "../../api/TimeEntries/time-entries.js";
import { usePermissions } from "../../hooks/usePermissions";
import { useDebouncedCallback } from "use-debounce";
import TimeEntriesColumnDefs from "../TimeEntries/TimeEntriesColumnDefs.js";
import { Menu } from "./components/Menu.js";
import { Button } from "@mui/material";
import ViewSelector from "./components/ViewSelector.js";
import TimeEntriesFilter, {
  FilterList,
} from "../TimeEntries/TimeEntriesFilter.js";
import { useEffect, useState } from "react";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import { Columns3Icon, TimerIcon, DownloadIcon } from "lucide-react";
import AddCustomTimeModal from "./components/AddCustomTimeModal.js";
import moment from "moment";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout.js";
import { getDateFormat, monolithMoment } from "../../utils/date-format.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { nanoid } from "nanoid";
import Loader from "../Loader.js";
import EditTimeEntryModal from "./components/EditTimeEntryModal.js";
import DeleteTimeEntryModal from "./components/DeleteTimeEntryModal.js";
import { useSnackbar } from "notistack";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import DeleteTimeEntriesModal from "./components/DeleteTimeEntriesModal.js";
import InvoiceTimeEntriesModal from "./components/InvoiceTimeEntriesModal.js";
import UnInvoiceTimeEntriesModal from "./components/UnInvoiceTimeEntriesModal.js";
import { ItemTotal } from "../../pages/Cases/CaseEvidence/index";
import { Input } from "@monolith-forensics/monolith-ui";

const PageSize = 100;

const StyledButton = styled(Button)`
  font-size: 0.75rem;
  padding: 3px 5px;
  line-height: normal;
  margin-right: 5px;
  height: 24px;
`;

const ItemFlyout = styled(
  ({
    setIsFlyoutVisible,
    setFlyoutData,
    flyoutData,
    handleGetNextItem = () => {},
    handleGetPrevItem = () => {},
    onEdit = () => {},
    onDelete = () => {},
  }) => {
    const theme = useTheme();
    return (
      <Flyout
        onClose={() => {
          setIsFlyoutVisible(false);
          setFlyoutData(null);
        }}
      >
        <FlyoutHeader>
          <div style={{ width: "100%" }}>
            <h4
              className="flyout-title"
              style={{
                margin: "5px 0px",
                color: theme.palette.text.primary,
              }}
            >
              {`${flyoutData.full_name} - ${monolithMoment({
                timestamp: flyoutData.entry_date,
              })}` || "..."}
            </h4>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                }}
              >
                Time Entry Details
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TaskButton
                  onClick={() => handleGetPrevItem(flyoutData.time_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
                <TaskButton
                  onClick={() => handleGetNextItem(flyoutData.time_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowRightOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
              </div>
            </div>
          </div>
        </FlyoutHeader>
        <FlyoutContent
          defaultInfo={flyoutData}
          onEdit={onEdit}
          onDelete={() => {
            onDelete();
          }}
        />
      </Flyout>
    );
  }
)``;

const FlyoutContent = styled(({ className, defaultInfo, onEdit, onDelete }) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const { data } = useQuery({
    queryKey: [
      "time-entries",
      "list",
      { time_id: parseInt(defaultInfo.time_id, 10) },
    ],
    queryFn: () =>
      TimeEntriesAPI.getTimeEntries({
        time_id: defaultInfo.time_id,
      }),
  });

  const itemData = data?.data?.[0] || defaultInfo;

  const title = document.querySelector(".flyout-title");
  if (title)
    title.innerText = `${itemData?.full_name} - ${monolithMoment({
      timestamp: itemData?.entry_date,
    })}`;

  const dataFieldIgnoreList = ["description"];

  const detail = (
    <div className="detail-container">
      {TimeEntriesColumnDefs.filter(
        (c) => !dataFieldIgnoreList.includes(c.dataField)
      ).map((c) => {
        return (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{c.caption}</div>
            {c.render ? (
              c.render(itemData)
            ) : c.dataType === "date" ? (
              !!itemData[c.dataField] ? (
                <>
                  {monolithMoment({
                    timestamp: itemData[c.dataField],
                    includeTime: true,
                  })}
                </>
              ) : null
            ) : (
              <div className="detail-value">{itemData[c.dataField]}</div>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={className}>
      {!!itemData && (
        <>
          <div className="action-menu">
            {hasPermission() && (
              <>
                <div className="action-menu-item" onClick={onEdit}>
                  <EditOutlinedIcon
                    style={{ color: theme.palette.primary.main }}
                  />
                  <div className="action-menu-label">Edit</div>
                </div>
                <div className="action-menu-item" onClick={onDelete}>
                  <DeleteOutlineOutlinedIcon
                    style={{ color: theme.palette.error.main }}
                  />
                  <div className="action-menu-label">Delete</div>
                </div>
              </>
            )}
          </div>
          {detail}
          <div className="detail-label" style={{ marginTop: 30 }}>
            Description
          </div>
          <div style={{ position: "relative" }}>
            <div className="item-description">{itemData.description}</div>
          </div>
        </>
      )}
      {!itemData && <Loader message={"Loading..."} />}
    </div>
  );
})`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const ActionsMenu = styled(({ className, onActionSelect }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const MenuItems = [
    {
      label: "Invoice Selected Entries",
      value: "invoice",
      onClick: () => onActionSelect("invoice"),
      permission: MONOLITH_PERMISSIONS.TIME_ENTRIES_INVOICE,
    },
    {
      label: "Un-Invoice Selected Entries",
      value: "un-invoice",
      onClick: () => onActionSelect("un-invoice"),
      permission: MONOLITH_PERMISSIONS.TIME_ENTRIES_INVOICE,
    },
    {
      label: "Delete Selected Entries",
      value: "delete",
      onClick: () => onActionSelect("delete"),
    },
  ].filter((p) => hasPermission(p.permission));

  return (
    <DropdownMenu menuItems={MenuItems} arrow>
      Actions
    </DropdownMenu>
  );
})``;

const TimeEntriesTable = styled(({ className, setCurrentView, caseInfo }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser, hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const table = useTable();
  const [selectionState, setSelectionState] = useState({});
  const [showFlyout, setShowFlyout] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [showCreateTimeEntryModal, setShowCreateTimeEntryModal] =
    useState(false);
  const [showEditTimeEntryModal, setShowEditTimeEntryModal] = useState(false);
  const [showDeleteTimeEntryModal, setShowDeleteTimeEntryModal] =
    useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showUnInvoiceModal, setShowUnInvoiceModal] = useState(false);
  const [showDeleteItemsModal, setShowDeleteItemsModal] = useState(false);

  const [timeEntriesState, setTimeEntriesState] = useState({
    filter: [],
    allTimeEntries: hasPermission(MONOLITH_PERMISSIONS.CASES_READ_ALL)
      ? true
      : false,
    search: "",
    columnState: TimeEntriesColumnDefs,
  });

  const [query, setQuery] = useState(null);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["time-entries", "inf-list", query],
      queryFn: ({ pageParam }) =>
        TimeEntriesAPI.getTimeEntries(
          { ...query, pageSize: PageSize, page: pageParam } || {}
        ),
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage, pages) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  const handleUpdateFilter = (filterUpdates) => {
    setTimeEntriesState((prev) => {
      const newState = { ...prev };

      const { field, value, label, operator } = filterUpdates;
      let newFilter = [...newState.filter];

      const currentFieldIndex = newFilter.findIndex((f) => f.field === field);

      // Field Condition Exists
      if (currentFieldIndex > -1) {
        if (!value || value.length === 0) {
          newFilter = newFilter.filter((f) => f.field !== field);
        } else {
          newFilter[currentFieldIndex] = {
            field,
            value,
            label,
            operator,
          };
        }
      } else {
        newFilter.push({
          field,
          value,
          label,
          operator,
        });
      }

      return {
        ...newState,
        filter: newFilter,
      };
    });
  };

  const handleRemoveFilter = (field) => {
    setTimeEntriesState((prev) => {
      const newState = { ...prev };
      const newFilter = [...newState.filter].filter((f) => f.field !== field);
      return {
        ...newState,
        filter: newFilter,
      };
    });
  };

  const handleColumnVisibility = (column, visible) => {
    setTimeEntriesState((ts) => {
      const cs = ts.columnState;
      return {
        ...ts,
        columnState: cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        }),
      };
    });
  };
  const handleGetNextItem = (time_id) => {
    const current = records?.findIndex((i) => i.time_id === time_id);
    const nextItem = records[current + 1] || records[0];

    setSelectedEntry(nextItem);
  };
  const handleGetPrevItem = (time_id) => {
    const current = records?.findIndex((i) => i.time_id === time_id);
    const prevItem = records[current - 1] || records[records.length - 1];

    setSelectedEntry(prevItem);
  };
  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  const handleSort = (field) => {
    const savedColumn =
      timeEntriesState.columnState?.find((svc) => field === svc.dataField) ||
      {};
    const dir = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.sort?.field !== field) {
      newOrder = {
        field,
        dir: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (dir) {
        case "asc":
          newOrder = {
            field,
            dir: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            dir: "asc",
          };
      }
    }

    let newCols = timeEntriesState.columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: newOrder
            ? {
                active: newOrder ? true : false,
                direction: newOrder?.dir,
              }
            : null,
        };
      }
      delete c.sorting;
      return c;
    });

    setTimeEntriesState((ts) => {
      return {
        ...ts,
        columnState: newCols,
      };
    });
  };

  const handleEdit = (data) => {
    setShowEditTimeEntryModal(true);
  };

  const handleDelete = (data) => {
    setShowDeleteTimeEntryModal(true);
  };

  const handleActionButtonClick = (rowData) => {
    setSelectedEntry(rowData);
    setShowFlyout(true);
  };

  const handleActionSelect = (action) => {
    const selectedRows = table?.getVirtualSelectionTotal() || 0;
    if (selectedRows === 0) {
      enqueueSnackbar("Please select at least one item.", {
        variant: "warning",
      });
      return;
    }

    setSelectionState({
      total: table.getVirtualSelectionTotal(),
      options: {
        selectionState: table?.getSelectionState() || null,
        included: table?.getSelectedRows().map((r) => r.time_id) || [],
        excluded: table?.getExcludedRows().map((r) => r.time_id) || [],
        ...query,
      },
    });

    switch (action) {
      case "invoice":
        setShowInvoiceModal(true);
        break;
      case "un-invoice":
        setShowUnInvoiceModal(true);
        break;
      case "delete":
        setShowDeleteItemsModal(true);
        break;
      default:
        break;
    }
  };

  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    TimeEntriesAPI.exportTimeEntries({
      query,
      type: "xlsx",
      columns: timeEntriesState.columnState
        .filter((c) => c.visible !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat(true, true),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      custom_date_format: getDateFormat(true, false),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      el.remove();
    });
  };

  useEffect(() => {
    setQuery((prev) => {
      return {
        user_id: timeEntriesState.allTimeEntries ? null : currentUser.user_id,
        case_id: caseInfo?.case_id,
        search: timeEntriesState.search,
        sort: timeEntriesState.columnState.reduce((acc, c) => {
          if (c.sorting?.active) {
            acc = {
              field: c.dataField,
              dir: c.sorting?.direction,
            };
          }
          return acc;
        }, null),
        ...(timeEntriesState.filter.reduce((acc, f) => {
          if (f.field === "category") {
            acc.category_id = f.value;
          }
          if (f.field === "user_id") {
            acc.user_id = f.value;
          }
          return acc;
        }, {}) || {}),
      };
    });
  }, [timeEntriesState, currentUser]);

  useEffect(() => {
    setSelectedEntry(
      records?.find((r) => r.time_id === selectedEntry?.time_id)
    );
  }, [records, selectedEntry]);

  return (
    <div className={className}>
      {showFlyout && (
        <ItemFlyout
          setIsFlyoutVisible={setShowFlyout}
          setFlyoutData={setSelectedEntry}
          flyoutData={selectedEntry}
          handleGetNextItem={handleGetNextItem}
          handleGetPrevItem={handleGetPrevItem}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
      {showCreateTimeEntryModal && (
        <AddCustomTimeModal
          open={showCreateTimeEntryModal}
          onClose={() => setShowCreateTimeEntryModal(false)}
          onSubmit={(formData) => {
            refetch();
          }}
          defaultFormData={{
            description: "",
            case_id: caseInfo.case_id,
            case_number: caseInfo.case_number,
            task_id: null,
            user_id: currentUser.user_id,
            duration: 3600,
            billed: 0,
            category_id: null,
            start_time: moment().toISOString(),
            end_time: moment().add(1, "h").toISOString(),
          }}
        />
      )}
      {showEditTimeEntryModal && (
        <EditTimeEntryModal
          open={showEditTimeEntryModal}
          onClose={() => setShowEditTimeEntryModal(false)}
          onSubmit={(formData) => {
            refetch();
          }}
          defaultFormData={{
            time_id: selectedEntry?.time_id,
            task_id: selectedEntry?.task_id,
            task_name: selectedEntry?.task_name,
            category_id: selectedEntry?.category_id,
            category_name: selectedEntry?.category_name,
            description: selectedEntry?.description,
            case_id: selectedEntry?.case_id,
            case_number: selectedEntry?.case_number,
            start_time: selectedEntry?.start_time,
            end_time: selectedEntry?.end_time,
            duration: selectedEntry?.duration,
          }}
        />
      )}
      {showDeleteTimeEntryModal && (
        <DeleteTimeEntryModal
          open={showDeleteTimeEntryModal}
          onClose={() => setShowDeleteTimeEntryModal(false)}
          timeEntry={selectedEntry}
          onDelete={() => {
            setShowFlyout(false);
            refetch();
          }}
        />
      )}
      {showDeleteItemsModal && (
        <DeleteTimeEntriesModal
          open={showDeleteItemsModal}
          onClose={() => setShowDeleteItemsModal(false)}
          timeIds={table.getSelectedRows().map((r) => r.time_id)}
          onDelete={() => {
            setShowFlyout(false);
            table.clearSelections();
            refetch();
          }}
        />
      )}
      {showInvoiceModal && (
        <InvoiceTimeEntriesModal
          open={showInvoiceModal}
          onClose={() => setShowInvoiceModal(false)}
          timeIds={table.getSelectedRows().map((r) => r.time_id)}
          onSubmit={() => {
            setShowFlyout(false);
            table.clearSelections();
            refetch();
          }}
        />
      )}
      {showUnInvoiceModal && (
        <UnInvoiceTimeEntriesModal
          open={showUnInvoiceModal}
          onClose={() => setShowUnInvoiceModal(false)}
          timeIds={table.getSelectedRows().map((r) => r.time_id)}
          onSubmit={() => {
            setShowFlyout(false);
            table.clearSelections();
            refetch();
          }}
        />
      )}
      <Menu>
        <StyledButton
          variant="contained"
          onClick={() => setShowCreateTimeEntryModal(true)}
        >
          + New Time Entry
        </StyledButton>
        <TimeEntriesFilter
          caseInfo={caseInfo}
          filter={timeEntriesState.filter}
          onUpdateFilter={handleUpdateFilter}
          onRemoveFilter={handleRemoveFilter}
        />
        {hasPermission(MONOLITH_PERMISSIONS.CASES_READ_ALL) && (
          <DropdownMenu
            menuItems={[
              {
                label: "My Entries",
                value: "my-entries",
                onClick: () =>
                  setTimeEntriesState((prev) => {
                    return {
                      ...prev,
                      allTimeEntries: false,
                    };
                  }),
              },
              {
                label: "All Entries",
                value: "all-entries",
                onClick: () =>
                  setTimeEntriesState((prev) => {
                    return {
                      ...prev,
                      allTimeEntries: true,
                    };
                  }),
              },
            ]}
            title={"Admin Status"}
            arrow
          >
            {timeEntriesState.allTimeEntries ? "All Entries" : "My Entries"}
          </DropdownMenu>
        )}
        <ActionsMenu onActionSelect={handleActionSelect} />
        <ItemTotal total={totalRecords || 0} Icon={TimerIcon} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            marginLeft: "auto",
          }}
        >
          <ComboButton
            type="multi-select"
            data={timeEntriesState.columnState.filter(
              (c) => c.showInColumnChooser !== false
            )}
            displayField="caption"
            idField={"dataField"}
            selectedItems={timeEntriesState.columnState.filter(
              (c) => c.visible !== false
            )}
            variant="outlined"
            closeOnSelect={false}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
            textColor={theme.palette.text.primary}
            title={"Select Columns"}
          >
            <Columns3Icon size={14} />
          </ComboButton>
          <TaskButton
            variant="outlined"
            style={{
              fontSize: 11,
              color: theme.palette.text.primary,
              padding: "3px 5px",
            }}
            onClick={handleExportTable}
          >
            <DownloadIcon size={14} />
          </TaskButton>
          <ViewSelector currentView={"time"} setCurrentView={setCurrentView} />
          <Input
            defaultValue={timeEntriesState.search}
            placeholder="Search Time Entries"
            style={{
              height: 25,
              width: 150,
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.target.value === "") {
                setTimeEntriesState((prev) => {
                  return {
                    ...prev,
                    search: e.target.value,
                  };
                });
              }
            }}
            onKeyUp={(e) => {
              if (e.target.value === "") {
                setTimeEntriesState((prev) => {
                  return {
                    ...prev,
                    search: e.target.value,
                  };
                });
              }
            }}
          />
        </div>
      </Menu>
      <FilterList
        filter={timeEntriesState.filter}
        onUpdateFilter={handleUpdateFilter}
        onRemoveFilter={handleRemoveFilter}
      />
      <Table
        data={records || []}
        totalRecords={totalRecords || 0}
        keyValue="time_id"
        tableInstance={table}
        onHeaderClick={(col) =>
          col?.sorting?.enabled === false ? null : handleSort(col.dataField)
        }
        onScroll={handleScroll}
        showActionColumn={true}
        onActionButtonClick={handleActionButtonClick}
        focusedRow={selectedEntry}
        showSelection={true}
      >
        {timeEntriesState.columnState.map((col) => {
          return <Column key={col.dataField} {...col} />;
        })}
      </Table>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
  height: 100%;
`;

export default TimeEntriesTable;
