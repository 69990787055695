import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";
import { getSoftwareNames } from "../software/index.js";

export async function getAcquisitions(
  options = { evidence_id: null, case_id: null, acq_id: null }
) {
  return (await httpGet(`/acquisitions`, options)).data;
}

const query = async (query) => {
  return await httpPost("/acquisitions/query", query);
};

const exportAcquisitionList = async (query) => {
  return await httpPost("/acquisitions/export", query);
};

export async function createAcquisition(values) {
  return await httpPost("/acquisitions", values);
}

export async function updateAcquisition(id, values) {
  return await httpPatch(`/acquisitions/${id}`, values);
}

export async function deleteAcquisitions(options) {
  if (typeof options.acq_id !== "undefined") {
    return await httpDelete(`/acquisitions`, options);
  } else if (typeof options.case_id !== "undefined") {
    return await httpDelete(`/acquisitions?case_id=${options.case_id}`);
  } else if (typeof options.evidence_id !== "undefined") {
    return await httpDelete(`/acquisitions?evidence_id=${options.evidence_id}`);
  }
}

async function getFormats() {
  return Promise.resolve([
    { format: "AD1", format_id: 7 },
    { format: "AFF4", format_id: 10 },
    { format: "BIN", format_id: 9 },
    { format: "DD", format_id: 4 },
    { format: "DMG", format_id: 11 },
    { format: "E01", format_id: 0 },
    { format: "ISO", format_id: 16 },
    { format: "L01", format_id: 8 },
    { format: "PST", format_id: 17 },
    { format: "RAW", format_id: 2 },
    { format: "TAR", format_id: 6 },
    { format: "UFD", format_id: 5 },
    { format: "VHDX", format_id: 13 },
    { format: "VMDK", format_id: 14 },
    { format: "XRY", format_id: 15 },
    { format: "ZIP", format_id: 12 },
  ]);
}

async function getTypes() {
  return Promise.resolve([
    { type: "Advanced Logical", type_id: 2 },
    { type: "After First Unlock", type_id: 6 },
    { type: "Before First Unlock", type_id: 5 },
    { type: "Chip-off", type_id: 7 },
    { type: "Cloud", type_id: 10 },
    { type: "File System", type_id: 3 },
    { type: "Full File System", type_id: 4 },
    { type: "JTAG", type_id: 8 },
    { type: "Logical", type_id: 1 },
    { type: "Manual", type_id: 9 },
    { type: "Memory", type_id: 11 },
    { type: "Network", type_id: 12 },
    { type: "Other", type_id: 13 },
    { type: "Physical", type_id: 0 },
  ]);
}

async function getStatuses() {
  return Promise.resolve([
    { status: "Active", status_id: 1, color: "#32CD32" }, // limegreen
    { status: "Deleted", status_id: 2, color: "#ff4500" }, // orangered
  ]);
}

async function getHashTypes() {
  return Promise.resolve([
    { hash_type: "MD5", hash_type_id: 0 },
    { hash_type: "SHA1", hash_type_id: 1 },
    { hash_type: "SHA256", hash_type_id: 2 },
    { hash_type: "SHA512", hash_type_id: 3 },
    { hash_type: "Checksum-32", hash_type_id: 9 },
    { hash_type: "Checksum-64", hash_type_id: 10 },
  ]);
}

// CUSTOM FIELDS
export async function getCustomAttributes(options = { fieldsOnly: false }) {
  return httpGet(`/acquisitions/attributes?fieldsOnly=${options.fieldsOnly}`);
}

export async function createAttributeField(
  values = { field_name: null, is_required: null, type_id: null }
) {
  return await httpPost(`/acquisitions/attributes/field`, values);
}

export async function updateAttributeField(id, values = {}) {
  return await httpPost(`/acquisitions/attributes/field/${id}`, values);
}

export async function deleteAttributeField(id) {
  return httpDelete(`/acquisitions/attributes/field/${id}`);
}

const AcquisitionsAPI = {
  getAcquisitions,
  query,
  createAcquisition,
  updateAcquisition,
  deleteAcquisitions,
  getFormats,
  getTypes,
  getStatuses,
  getHashTypes,
  getSoftwareNames,
  exportAcquisitionList,
  getCustomAttributes,
  createAttributeField,
  updateAttributeField,
  deleteAttributeField,
};

export default AcquisitionsAPI;
