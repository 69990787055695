import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import InquiriesAPI from "../../../api/inquiries/index.js";
import { Button, Modal, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import Table, { Column } from "../../../Monolith-UI/Table/Table.js";
import { usePermissions } from "../../../hooks/usePermissions";
import { nanoid } from "nanoid";
import { getDateFormat, monolithMoment } from "../../../utils/date-format.js";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { v4 as uuidv4 } from "uuid";

import Flyout, { FlyoutHeader } from "../../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../../Monolith-UI/TaskButton.js";
import EvidenceAPI from "../../../api/evidence/index.js";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form";

import { useSnackbar } from "notistack";
import ToolBarItems from "../../../components/ToolBarItems.js";
import Loader from "../../../components/Loader.js";
import { DevexEditors } from "../../../utils/devex-editors.js";

const ColumnDefs = [
  { dataField: "evidence_number", caption: "Evidence Number" },
  { dataField: "evidence_type", caption: "Type" },
  { dataField: "manufacturer", caption: "Provider/Make" },
  { dataField: "model_number", caption: "Item Name" },
  { dataField: "serial_number", caption: "Unique ID" },
  {
    dataField: "linked_contact",
    caption: "Linked Contact",
    displayValue: "person_name",
  },
  { dataField: "description", caption: "Description" },
];

const EvidenceItemsContent = styled(
  ({ className, defaultInquiryInfo, readOnly }) => {
    const theme = useTheme();
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);
    const queryClient = useQueryClient();
    const { data } = useQuery({
      queryKey: [
        "inquiries:list",
        { inquiry_id: defaultInquiryInfo.inquiry_id },
      ],
      queryFn: () =>
        InquiriesAPI.getInquiries({
          inquiry_id: defaultInquiryInfo.inquiry_id,
        }),
      select: (data) => data[0],
    });

    const evidenceItems = (data?.evidence || []).map((e) => ({
      ...e,
      uuid: e.item_id,
      item_type: e.evidence_type,
      manufacturer: e.make,
      custom_attributes: e.custom_fields,
    }));

    const onCreate = () => {
      queryClient.refetchQueries({
        queryKey: [
          "inquiries:list",
          { inquiry_id: defaultInquiryInfo.inquiry_id },
        ],
      });
    };

    const handleActionButtonClick = (rowData) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };

    const handleGetNextItem = (uuid) => {
      const current = evidenceItems?.findIndex((i) => i.uuid === uuid);
      const nextItem = evidenceItems[current + 1] || evidenceItems[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (uuid) => {
      const current = evidenceItems?.findIndex((i) => i.uuid === uuid);
      const prevItem =
        evidenceItems[current - 1] || evidenceItems[evidenceItems.length - 1];

      setFlyoutData(prevItem);
    };

    useEffect(() => {
      if (isFlyoutVisible) {
        setFlyoutData(evidenceItems.find((e) => e.uuid === flyoutData.uuid));
      }
    }, [defaultInquiryInfo]);

    return (
      <div className={className}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setShowCreateModal(true)}
          style={{
            width: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
            marginBottom: 10,
          }}
          disabled={
            !hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_UPDATE) || readOnly
          }
        >
          + New Evidence
        </Button>
        {evidenceItems.length === 0 ? (
          <div style={{ marginBottom: 20 }}>No evidence items.</div>
        ) : (
          <Table
            data={evidenceItems ? evidenceItems : []}
            keyValue="uuid"
            columnProps={{ minWidth: 150, width: 150 }}
            showActionColumn={true}
            onActionButtonClick={handleActionButtonClick}
            focusedRow={flyoutData}
          >
            <Column dataField="evidence_number" caption="Evidence Number" />
            <Column dataField="evidence_type" caption="Type" />
            <Column dataField="manufacturer" caption="Provider/Make" />
            <Column dataField="model_number" caption="Item Name" />
            <Column dataField="description" caption="Description" />
          </Table>
        )}
        <CreateEvidenceModal
          open={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          onSubmit={() => onCreate()}
        />
        {isFlyoutVisible && (
          <Flyout
            onClose={() => {
              setIsFlyoutVisible(false);
              setFlyoutData(null);
            }}
          >
            <FlyoutHeader>
              <div style={{ width: "100%" }}>
                <h4
                  style={{
                    margin: "5px 0px",
                    color: theme.palette.text.primary,
                  }}
                >
                  {flyoutData.evidence_number || "No Evidence Number"}
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                    }}
                  >
                    Evidence Details
                  </div>
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TaskButton
                      onClick={() => handleGetPrevItem(flyoutData.uuid)}
                      style={{
                        fontSize: 10,
                      }}
                      variant="outlined"
                    >
                      <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
                    </TaskButton>
                    <TaskButton
                      onClick={() => handleGetNextItem(flyoutData.uuid)}
                      style={{
                        fontSize: 10,
                      }}
                      variant="outlined"
                    >
                      <KeyboardArrowRightOutlinedIcon
                        style={{ fontSize: 17 }}
                      />
                    </TaskButton>
                  </div>
                </div>
              </div>
            </FlyoutHeader>
            <EvidenceFlyoutContent
              defaultEvidenceInfo={flyoutData}
              setIsFlyoutVisible={setIsFlyoutVisible}
              readOnly={readOnly}
              handleClose={() => {
                setIsFlyoutVisible(false);
                setFlyoutData(null);
              }}
            />
          </Flyout>
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .child-items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
  }
`;

const EvidenceFlyoutContent = styled(
  ({
    className,
    defaultEvidenceInfo,
    handleClose = () => {},
    readOnly = true,
  }) => {
    const { inquiry_id } = useParams();
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const theme = useTheme();
    const queryClient = useQueryClient();
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const { data: customFields } = useQuery({
      queryKey: ["evidence:customFields", { fieldsOnly: true }],
      queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
    });

    let columnDefs = ColumnDefs;
    const evidenceInfo = defaultEvidenceInfo;

    // update column defs with custom fields
    columnDefs = useMemo(() => {
      if (customFields)
        return addCustomFieldsToColumnDefs(customFields, columnDefs);
      return columnDefs;
    }, [customFields]);

    const handleEditEvidence = () => setShowEditModal(true);

    const handleDeleteEvidence = () => setShowDeleteModal(true);

    const onEdit = (data) => {
      queryClient.refetchQueries({
        queryKey: ["inquiries:list", { inquiry_id: parseInt(inquiry_id) }],
      });
    };

    const onDelete = () => {
      handleClose();
      queryClient.refetchQueries({
        queryKey: ["inquiries:list", { inquiry_id: parseInt(inquiry_id) }],
      });
    };

    const dataFieldIgnoreList = ["description"];

    const data = {
      ...evidenceInfo,
      ...(evidenceInfo?.custom_attributes?.reduce((acc, attr) => {
        acc[`custom_field_${attr.field_id}`] = attr.value;
        return acc;
      }, {}) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
          .filter((c) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data)
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">
                    {c?.displayValue
                      ? data[c.dataField]?.[c.displayValue]
                      : data[c.dataField]}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        <div className="action-menu">
          {!readOnly && hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_UPDATE) && (
            <div className="action-menu-item" onClick={handleEditEvidence}>
              <EditOutlinedIcon style={{ color: theme.palette.primary.main }} />
              <div className="action-menu-label">Edit</div>
            </div>
          )}
          {!readOnly && hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_DELETE) && (
            <div className="action-menu-item" onClick={handleDeleteEvidence}>
              <DeleteOutlineOutlinedIcon
                style={{ color: theme.palette.error.main }}
              />
              <div className="action-menu-label">Delete</div>
            </div>
          )}
        </div>
        {detail}
        <div className="detail-label" style={{ marginTop: 30 }}>
          Description
        </div>
        <div style={{ position: "relative" }}>
          <div className="description">{evidenceInfo.description}</div>
        </div>
        <EditDetailsModal
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
          onSubmit={() => onEdit()}
          defaultInfo={defaultEvidenceInfo}
        />
        <DeleteModal
          open={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          onSubmit={() => onDelete()}
          defaultInfo={defaultEvidenceInfo}
        />
      </div>
    );
  }
)`
  padding: 20px;

  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .detail-container {
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 25px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    transition: border 0.2s ease-in-out;
    outline: none;
  }
  & .description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const EditDetailsModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { inquiry_id } = useParams();
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef(null);

  const [itemType, setItemType] = useState(defaultInfo?.evi_type_id || null);

  const result = useQueries({
    queries: [
      {
        queryKey: ["evidence:types"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
        enabled: open,
      },
      {
        queryKey: ["evidence:providers"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
        enabled: open,
      },
      {
        queryKey: ["evidence:customFields", { fieldsOnly: true }],
        queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
        enabled: open,
      },
    ],
  });

  const isDone = result.every((x) => x.isFetched);

  const fieldData = {
    evidenceTypes: result[0]?.data || [],
    evidenceProviders: result[1]?.data || [],
  };

  const customFieldData =
    defaultInfo?.custom_fields?.reduce((acc, field) => {
      acc[`custom_field_${field.field_id}`] = field.value;
      return acc;
    }, {}) || {};

  const defaultFormData = {
    evidence_number: defaultInfo.evidence_number,
    evidence_type: defaultInfo.item_type,
    make: defaultInfo.manufacturer,
    model_number: defaultInfo.model_number,
    serial_number: defaultInfo.serial_number,
    description: defaultInfo.description,
    ...customFieldData,
  };

  const customFields = isDone
    ? result[2]?.data
        ?.filter((field) => field.external_enabled)
        .filter(
          (r) =>
            r?.type_ids?.length === 0 ||
            (itemType && r?.type_ids?.includes?.(itemType))
        )
    : [];

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      let formData = Object.assign(
        {},
        form.current.instance.option("formData")
      );

      // remove empty values from formData
      Object.keys(formData).forEach(
        (key) => !formData[key] && delete formData[key]
      );

      const custom_fields = Object.keys(formData)
        .filter((key) => key.includes("custom_field_") && formData[key]) // filter out empty values
        .map((key) => {
          const id = parseInt(key.replace("custom_field_", ""));
          return {
            ...(customFields.find((field) => field.field_id === id) || {}),
            value: formData[key],
          };
        });

      InquiriesAPI.updateInquiryEvidence(inquiry_id, {
        ...formData,
        item_id: defaultInfo.item_id,
        custom_fields,
      }).then((res) => {
        enqueueSnackbar("Evidence updated successfully", {
          variant: "success",
        });
        onSubmit(formData);
      });
    }

    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 650,
            maxHeight: "90vh",
            overflowY: "auto",
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Evidence Details
          </div>
          {isDone && (
            <>
              <Form ref={form} colCount={2} defaultFormData={defaultFormData}>
                <SimpleItem
                  dataField="evidence_number"
                  label={{ text: "Evidence Number" }}
                  editorOptions={{
                    placeholder:
                      "Enter a unique value that identifies this evidence.",
                  }}
                />
                <SimpleItem
                  dataField="evidence_type"
                  label={{ text: "Evidence Type" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.evidenceTypes,
                    placeholder: "Select Evidence Type...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "evi_type",
                    valueExpr: "evi_type",
                    searchEnabled: true,
                    onItemClick: (e) => {
                      setTimeout(() => {
                        setItemType(e.itemData.evi_type_id);
                      }, 25);
                    },
                  }}
                />
                <SimpleItem
                  dataField="make"
                  label={{ text: "Make/Provider" }}
                  editorType="dxAutocomplete"
                  editorOptions={{
                    dataSource: fieldData.evidenceProviders,
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    placeholder: "Apple, Google, Samsung...",
                    displayExpr: "manufacturer",
                    valueExpr: "manufacturer",
                    searchEnabled: true,
                    acceptCustomValue: true,
                  }}
                />
                <SimpleItem
                  dataField="model_number"
                  label={{ text: "Model Number or Service" }}
                  editorOptions={{
                    placeholder: "A1234, WD2500, GMail...",
                    hint: "Enter the model number for this item.",
                  }}
                />
                <SimpleItem
                  dataField="serial_number"
                  label={{ text: "Unique Identifier" }}
                  editorOptions={{
                    placeholder: "first.last@email.com, serial number...",
                    hint: "Enter a unique identifier for this item.",
                  }}
                />
                {customFields &&
                  customFields.map((field) => {
                    return (
                      <SimpleItem
                        key={`custom_field_${field.field_id}`}
                        dataField={`custom_field_${field.field_id}`}
                        editorType={DevexEditors[field.editor_type]}
                        label={{ text: field.field_name }}
                        isRequired={field.is_required === 1}
                        editorOptions={{
                          items: JSON.parse(field.options || "[]"),
                          dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                          useMaskBehavior: true,
                          showClearButton: field.editor_type !== "textBox",
                          pickerType: "calendar",
                          showDropDownButton: true,
                          multiline: false,
                          showSelectionControls:
                            field.editor_type === "tagBox" ? true : false,
                        }}
                      ></SimpleItem>
                    );
                  })}
                {!(customFields.length % 2) && <EmptyItem />}
                <SimpleItem
                  dataField="description"
                  label={{ text: "Description" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 135,
                    placeholder:
                      "Enter a brief description of this item and any important details.",
                  }}
                />
              </Form>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <ToolBarItems
                  submitText="Edit Evidence"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
          {!isDone && <Loader />}
        </div>
      </Modal>
    </div>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { inquiry_id } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    InquiriesAPI.deleteInquiryEvidence(inquiry_id, {
      item_id: defaultInfo.item_id,
    }).then(() => {
      enqueueSnackbar(`Evidence Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-35%, -20%)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Evidence Item
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this Evidence Item?
        </div>
        <div style={{ margin: "10px 0px" }}>
          "{defaultInfo.evidence_number}"
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Evidence"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

const CreateEvidenceModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const [itemType, setItemType] = useState(null);
  const { inquiry_id } = useParams();
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef(null);

  const result = useQueries({
    queries: [
      {
        queryKey: ["evidence:types"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
        enabled: open,
      },
      {
        queryKey: ["evidence:providers"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
        enabled: open,
      },
      {
        queryKey: ["evidence:customFields", { fieldsOnly: true }],
        queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
        enabled: open,
      },
    ],
  });

  const handleSubmit = (event) => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option("formData") };

      handleClose();
      const uuid = uuidv4();

      formData.custom_fields = Object.keys(formData)
        .filter((key) => key.startsWith("custom_field_"))
        .map((key) => {
          const fieldMeta = customFields.find(
            (field) =>
              field.field_id === parseInt(key.replace("custom_field_", ""))
          );

          return {
            value: formData[key],
            ...fieldMeta,
          };
        });

      InquiriesAPI.createInquiryEvidence(inquiry_id, {
        ...formData,
        item_id: uuid,
      }).then((res) => {
        enqueueSnackbar(`Evidence Item Created.`, {
          variant: "success",
        });
        onSubmit();
      });
    }
  };

  const handleCancel = () => handleClose();

  const isDone = result.every((x) => x.isFetched);

  const fieldData = {
    evidenceTypes: result[0]?.data || [],
    evidenceProviders: result[1]?.data || [],
  };

  const customFields = isDone
    ? result[2]?.data?.filter((field) => field.external_enabled)
    : [];

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 650,
            maxHeight: "90vh",
            overflowY: "auto",
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Evidence
          </div>
          {isDone && (
            <>
              <Form ref={form} colCount={2}>
                <SimpleItem
                  dataField="evidence_number"
                  label={{ text: "Evidence Number" }}
                  editorOptions={{
                    placeholder:
                      "Enter a unique value that identifies this evidence.",
                  }}
                />
                <SimpleItem
                  dataField="evidence_type"
                  label={{ text: "Evidence Type" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.evidenceTypes,
                    placeholder: "Select Evidence Type...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "evi_type",
                    valueExpr: "evi_type",
                    searchEnabled: true,
                  }}
                />
                <SimpleItem
                  dataField="make"
                  label={{ text: "Make/Provider" }}
                  editorType="dxAutocomplete"
                  editorOptions={{
                    dataSource: fieldData.evidenceProviders,
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    placeholder: "Apple, Google, Samsung...",
                    displayExpr: "manufacturer",
                    valueExpr: "manufacturer",
                    searchEnabled: true,
                    acceptCustomValue: true,
                  }}
                />
                <SimpleItem
                  dataField="model_number"
                  label={{ text: "Model Number or Service" }}
                  editorOptions={{
                    placeholder: "A1234, WD2500, GMail...",
                    hint: "Enter the model number for this item.",
                  }}
                />
                <SimpleItem
                  dataField="serial_number"
                  label={{ text: "Unique Identifier" }}
                  editorOptions={{
                    placeholder: "first.last@email.com, serial number...",
                    hint: "Enter a unique identifier for this item.",
                  }}
                />
                {customFields &&
                  customFields.map((field) => {
                    return (
                      <SimpleItem
                        key={`custom_field_${field.field_id}`}
                        dataField={`custom_field_${field.field_id}`}
                        editorType={DevexEditors[field.editor_type]}
                        label={{ text: field.field_name }}
                        isRequired={field.is_required === 1}
                        editorOptions={{
                          items: JSON.parse(field.options || "[]"),
                          dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                          useMaskBehavior: true,
                          showClearButton: field.editor_type !== "textBox",
                          pickerType: "calendar",
                          showDropDownButton: true,
                          multiline: false,
                          showSelectionControls:
                            field.editor_type === "tagBox" ? true : false,
                        }}
                      ></SimpleItem>
                    );
                  })}
                {!(customFields.length % 2) && <EmptyItem />}
                <SimpleItem
                  dataField="description"
                  label={{ text: "Description" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 135,
                    placeholder:
                      "Enter a brief description of this item and any important details.",
                  }}
                />
              </Form>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <ToolBarItems
                  submitText="Create Evidence"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
          {!isDone && <Loader />}
        </div>
      </Modal>
    </div>
  );
};

const addCustomFieldsToColumnDefs = (customFields, columnDefs) => {
  return [
    ...columnDefs.filter((cd) => !cd.dataField.includes("custom_field_")),
    ...customFields.map((field) => ({
      dataField: `custom_field_${field.field_id}`,
      caption: field.field_name,
      visible: false,
      dataType: field.editor_type === "dateBox" ? "date" : "string",
      format: {
        type: getDateFormat(true, false),
      },
    })),
  ];
};

export default EvidenceItemsContent;
