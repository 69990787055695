import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import CasesApi from "../../api/cases/index.js";
import { usePermissions } from "../../hooks/usePermissions";
import { db_timestamp } from "../../utils/date-format.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";

const StatusSelector = ({ rowData, onChange = () => {} }) => {
  const { hasPermission } = usePermissions();
  const [current, setCurrent] = useState(rowData.case_status);
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["cases:statuses"]);
  const containerRef = useRef(null);

  const { data: items } = useQuery({
    queryKey: ["cases:statuses"],
    queryFn: () => CasesApi.getCaseStatuses(),
    enabled: !cachedData,
    placeholderData: (data) => data || [],
  });

  const currentStatus = items?.find((p) => p.status_name === current) || {};

  const handleItemSelect = async (item) => {
    setCurrent(item.status_name);
    containerRef.current.classList.remove("custom-action-button");
    await CasesApi.updateCase(rowData.case_id, {
      case_status: item.status_name,
      case_closed_date: item.status_name === "Closed" ? db_timestamp() : null,
    });

    onChange({
      case_status: item.status_name,
      case_status_id: item.case_status_id,
      case_closed_date: item.status_name === "Closed" ? db_timestamp() : null,
    });
  };

  const menuItems = [
    {
      radioGroup: true,
      value: currentStatus?.case_status_id,
      onSelectionChanged: (data) => {
        handleItemSelect(items.find((p) => p.case_status_id === data.value));
      },
      items: items.map((p) => ({
        label: p.status_name,
        value: p.case_status_id,
      })),
    },
  ];

  return (
    <div
      ref={containerRef}
      className={`${currentStatus ? "" : "asdaction-button"}`}
    >
      <DropdownMenu
        menuItems={menuItems}
        disabled={!hasPermission()}
        title={"Select Status"}
        variant={"contained"}
        textColor={current ? "primary" : "secondary"}
        buttonProps={{
          style: {
            fontSize: 12,
          },
        }}
      >
        {current ? <div>{current}</div> : <div>Select...</div>}
      </DropdownMenu>
    </div>
  );
};

export default StatusSelector;
