const diffFormData = (defaultFormData, data) => {
  const diff = {};
  for (const key in data) {
    if (defaultFormData[key] !== data[key]) {
      if (Array.isArray(data[key])) {
        if (data[key].length !== defaultFormData?.[key]?.length) {
          diff[key] = data[key];
        } else {
          for (let i = 0; i < data[key].length; i++) {
            if (data[key][i] !== defaultFormData[key][i]) {
              diff[key] = data[key];
              break;
            }
          }
        }
      } else {
        diff[key] = data[key];
      }
    }
  }
  return diff;
};

export default diffFormData;
