import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  getCaseNumberFormat,
  getEvidenceNumberFormat,
  getStorageNumberFormat,
  logActivity,
  updateCaseNumberFormat,
  updateEvidenceNumberFormat,
  updateStorageNumberFormat,
} from "../../api";
import { useSnackbar } from "notistack";

import { TextBox } from "devextreme-react/ui/text-box";
import { NumberBox } from "devextreme-react/ui/number-box";
import { SelectBox } from "devextreme-react/ui/select-box";
import { useAuth } from "../../contexts/AuthContext";
import { Divider, Button } from "@mui/material";
import Loader from "../../components/Loader";
import styled from "styled-components";

const buttonStyle = {
  margin: "10px 0px",
  marginRight: "10px",
};

const CaseNumberFormat = () => {
  const { currentUser } = useAuth();
  const [example, setExample] = useState("");
  const [currentFormat, setCurrentFormat] = useState(null);
  const [fieldError, setFieldError] = useState(false);
  const savedFormat = useRef(null);
  const isNumberUsed = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getCaseNumberFormat().then((result) => {
      isNumberUsed.current = result.find((r) => {
        return r.format_section === "Number";
      });
      savedFormat.current = result;
      setExample(getExample(result));
      setCurrentFormat(result);
    });
  }, []);

  const addText = () => {
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Text",
      },
    ]);
  };

  const addNumber = () => {
    if (!isNumberUsed.current) {
      isNumberUsed.current = true;
      setFieldError(false);
      setExample(
        getExample([
          ...currentFormat,
          {
            format_section: "Number",
            format_mod: 1,
            iterator: 1,
          },
        ])
      );
      setCurrentFormat([
        ...currentFormat,
        {
          format_section: "Number",
          format_mod: 1,
          iterator: 1,
        },
      ]);
    }
  };

  const addYear = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Year",
          format_mod: "YY",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Year",
        format_mod: "YY",
      },
    ]);
  };

  const addMonth = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Month",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Month",
      },
    ]);
  };

  const addDay = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Day",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Day",
      },
    ]);
  };

  const getExample = (formatData) => {
    let example = "";

    formatData.forEach((item) => {
      switch (item.format_section) {
        case "Text":
          example = `${example}${item.initial_text}`;
          break;
        case "Number":
          example = `${example}${String(item.iterator).padStart(
            item.format_mod,
            "0"
          )}`;
          break;
        case "Year":
          example = `${example}${moment().format(item.format_mod)}`;
          break;
        case "Month":
          example = `${example}${moment().format("MM")}`;
          break;
        case "Day":
          example = `${example}${moment().format("DD")}`;
          break;
        default:
          break;
      }
    });

    return example;
  };

  const handleUpdate = () => {
    //check that number field exists
    if (!isNumberUsed.current) {
      setFieldError(true);
      return;
    }

    //submit update
    let values = [];

    for (const selection of currentFormat) {
      if (selection.format_section === "Text") {
        values.push({
          type: "Text",
          value: selection.initial_text,
        });
      }

      if (selection.format_section === "Number") {
        values.push({
          type: "Number",
          value: [selection.format_mod, selection.iterator, selection.iterator],
        });
      }

      if (selection.format_section === "Year") {
        values.push({
          type: "Year",
          value: selection.format_mod,
        });
      }

      if (selection.format_section === "Month") {
        values.push({
          type: "Month",
          value: "",
        });
      }

      if (selection.format_section === "Day") {
        values.push({
          type: "Day",
          value: "",
        });
      }
    }

    updateCaseNumberFormat(values).then(() => {
      enqueueSnackbar(`Case Number Format Updated.`, {
        variant: "success",
      });
    });

    logActivity("Admin", currentUser.user_id, "Updated Case Number Format");
  };

  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Case Number Format
      </div>
      <div>
        {currentFormat && (
          <>
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
              <div>
                Use the options below to create a custom case number that will
                be automatically generated when you create a new case. The
                "Iterator" option must be used.
              </div>
            </div>
            <div
              style={{ textAlign: "left", paddingLeft: "5px", width: "95%" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addText"
                  onClick={addText}
                >
                  <i className="fa fa-plus"></i> + Text
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addNumber"
                  onClick={addNumber}
                >
                  <i className="fa fa-plus"></i> + Iterator
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addYear"
                  onClick={addYear}
                >
                  <i className="fa fa-plus"></i> + Year
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addMonth"
                  onClick={addMonth}
                >
                  <i className="fa fa-plus"></i> + Month
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addDay"
                  onClick={addDay}
                >
                  <i className="fa fa-plus"></i> + Day
                </Button>
              </div>
              {fieldError && (
                <div style={{ color: "orangered", margin: "10px 0px" }}>
                  Please add a number iterator field.
                </div>
              )}
              <div style={{ margin: "10px 0px" }}>EXAMPLE: {example}</div>
              <div style={{ margin: "20px 5px 10px 5px", minHeight: "250px" }}>
                {currentFormat.map((item, index) => {
                  switch (item.format_section) {
                    case "Text":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Text: </div>
                            <TextBox
                              defaultValue={item.initial_text}
                              maxLength={100}
                              width="200px"
                              stylingMode="filled"
                              placeholder="Enter Text..."
                              onInput={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i)
                                    cf.initial_text = e.event.target.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Number":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Iterator: </div>
                            <NumberBox
                              defaultValue={parseInt(item.format_mod)}
                              style={{ marginRight: "10px" }}
                              width="60px"
                              min={1}
                              max={5}
                              showSpinButtons={true}
                              stylingMode="filled"
                              hint="Select number size."
                              onValueChanged={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i) cf.format_mod = e.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                            <NumberBox
                              defaultValue={parseInt(item.iterator)}
                              min={0}
                              width="130px"
                              stylingMode="filled"
                              placeholder="Initial Count Value"
                              onInput={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i)
                                    cf.iterator = e.event.target.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Year":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Year: </div>
                            <SelectBox
                              defaultValue={item.format_mod}
                              width="200px"
                              stylingMode="filled"
                              items={["YY", "YYYY"]}
                              placeholder="Select year format..."
                              onValueChanged={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i) cf.format_mod = e.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Month":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Month: </div>
                            <TextBox
                              maxLength={100}
                              width="200px"
                              stylingMode="filled"
                              defaultValue="MM"
                              disabled={true}
                            />
                          </div>
                        </div>
                      );
                    case "Day":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Day: </div>
                            <TextBox
                              maxLength={100}
                              width="200px"
                              stylingMode="filled"
                              defaultValue="DD"
                              disabled={true}
                            />
                          </div>
                        </div>
                      );
                    default:
                      return <></>;
                  }
                })}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={buttonStyle}
                className="summaryButton resetBtn"
                onClick={() => {
                  isNumberUsed.current = false;
                  setCurrentFormat([]);
                  setExample([]);
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={buttonStyle}
                className="summaryButton defaultBtn"
                onClick={() => {
                  setExample(getExample(savedFormat.current));
                  setCurrentFormat(savedFormat.current);
                }}
              >
                Default
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={buttonStyle}
                className="summaryButton applyBtn"
                onClick={handleUpdate}
              >
                Apply
              </Button>
            </div>
          </>
        )}
        {!currentFormat && (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

const EvidenceNumberFormat = () => {
  const { currentUser } = useAuth();

  const [example, setExample] = useState("");
  const [currentFormat, setCurrentFormat] = useState(null);
  const [fieldError, setFieldError] = useState(false);
  const savedFormat = useRef(null);
  const isNumberUsed = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getEvidenceNumberFormat().then((result) => {
      isNumberUsed.current = result.find((r) => {
        return r.format_section === "Number";
      });
      savedFormat.current = result;
      setExample(getExample(result));
      setCurrentFormat(result);
    });
  }, []);

  const addText = () => {
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Text",
      },
    ]);
  };

  const addNumber = () => {
    if (!isNumberUsed.current) {
      isNumberUsed.current = true;
      setFieldError(false);
      setExample(
        getExample([
          ...currentFormat,
          {
            format_section: "Number",
            format_mod: 1,
            iterator: 1,
          },
        ])
      );
      setCurrentFormat([
        ...currentFormat,
        {
          format_section: "Number",
          format_mod: 1,
          iterator: 1,
        },
      ]);
    }
  };

  const addYear = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Year",
          format_mod: "YY",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Year",
        format_mod: "YY",
      },
    ]);
  };

  const addMonth = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Month",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Month",
      },
    ]);
  };

  const addDay = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Day",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Day",
      },
    ]);
  };

  const getExample = (formatData) => {
    let example = "";

    formatData.forEach((item) => {
      switch (item.format_section) {
        case "Text":
          example = `${example}${item.initial_text}`;
          break;
        case "Number":
          example = `${example}${String(item.iterator).padStart(
            item.format_mod,
            "0"
          )}`;
          break;
        case "Year":
          example = `${example}${moment().format(item.format_mod)}`;
          break;
        case "Month":
          example = `${example}${moment().format("MM")}`;
          break;
        case "Day":
          example = `${example}${moment().format("DD")}`;
          break;
        default:
          break;
      }
    });

    return example;
  };

  const handleUpdate = () => {
    //check that number field exists
    if (!isNumberUsed.current) {
      setFieldError(true);
      return;
    }

    //submit update
    let values = [];

    for (const selection of currentFormat) {
      if (selection.format_section === "Text") {
        values.push({
          type: "Text",
          value: selection.initial_text,
        });
      }

      if (selection.format_section === "Number") {
        values.push({
          type: "Number",
          value: [selection.format_mod, selection.iterator, selection.iterator],
        });
      }

      if (selection.format_section === "Year") {
        values.push({
          type: "Year",
          value: selection.format_mod,
        });
      }

      if (selection.format_section === "Month") {
        values.push({
          type: "Month",
          value: "",
        });
      }

      if (selection.format_section === "Day") {
        values.push({
          type: "Day",
          value: "",
        });
      }
    }

    updateEvidenceNumberFormat(values).then(() => {
      enqueueSnackbar(`Evidence Number Format Updated.`, {
        variant: "success",
      });
    });

    logActivity("Admin", currentUser.user_id, "Updated Evidence Number Format");
  };

  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Evidence Number Format
      </div>
      <div>
        {currentFormat && (
          <>
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
              <div>
                Use the options below to create a custom evidence number that
                will be automatically generated when you create a new evidence.
                The "Iterator" option must be used.
              </div>
            </div>
            <div
              style={{ textAlign: "left", paddingLeft: "5px", width: "95%" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addText"
                  onClick={addText}
                >
                  <i className="fa fa-plus"></i> + Text
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addNumber"
                  onClick={addNumber}
                >
                  <i className="fa fa-plus"></i> + Iterator
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addYear"
                  onClick={addYear}
                >
                  <i className="fa fa-plus"></i> + Year
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addMonth"
                  onClick={addMonth}
                >
                  <i className="fa fa-plus"></i> + Month
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addDay"
                  onClick={addDay}
                >
                  <i className="fa fa-plus"></i> + Day
                </Button>
              </div>
              {fieldError && (
                <div style={{ color: "orangered", margin: "10px 0px" }}>
                  Please add a number iterator field.
                </div>
              )}
              <div style={{ margin: "10px 0px" }}>EXAMPLE: {example}</div>
              <div style={{ margin: "20px 5px 10px 5px", minHeight: "250px" }}>
                {currentFormat.map((item, index) => {
                  switch (item.format_section) {
                    case "Text":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Text: </div>
                            <TextBox
                              maxLength={100}
                              defaultValue={item.initial_text}
                              width="200px"
                              stylingMode="filled"
                              placeholder="Enter Text..."
                              onInput={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i)
                                    cf.initial_text = e.event.target.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Number":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Iterator: </div>
                            <NumberBox
                              defaultValue={parseInt(item.format_mod)}
                              style={{ marginRight: "10px" }}
                              width="60px"
                              min={1}
                              max={5}
                              showSpinButtons={true}
                              stylingMode="filled"
                              hint="Select number size."
                              onValueChanged={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i) cf.format_mod = e.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                            <NumberBox
                              defaultValue={parseInt(item.iterator)}
                              min={0}
                              width="130px"
                              stylingMode="filled"
                              placeholder="Initial Count Value"
                              onInput={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i)
                                    cf.iterator = e.event.target.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Year":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Year: </div>
                            <SelectBox
                              defaultValue={item.format_mod}
                              width="200px"
                              stylingMode="filled"
                              items={["YY", "YYYY"]}
                              placeholder="Select year format..."
                              onValueChanged={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i) cf.format_mod = e.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Month":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Month: </div>
                            <TextBox
                              maxLength={100}
                              width="200px"
                              stylingMode="filled"
                              defaultValue="MM"
                              disabled={true}
                            />
                          </div>
                        </div>
                      );
                    case "Day":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Day: </div>
                            <TextBox
                              maxLength={100}
                              width="200px"
                              stylingMode="filled"
                              defaultValue="DD"
                              disabled={true}
                            />
                          </div>
                        </div>
                      );
                    default:
                      return <></>;
                  }
                })}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={buttonStyle}
                className="summaryButton resetBtn"
                onClick={() => {
                  isNumberUsed.current = false;
                  setCurrentFormat([]);
                  setExample([]);
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={buttonStyle}
                className="summaryButton defaultBtn"
                onClick={() => {
                  setExample(getExample(savedFormat.current));
                  setCurrentFormat(savedFormat.current);
                }}
              >
                Default
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={buttonStyle}
                className="summaryButton applyBtn"
                onClick={handleUpdate}
              >
                Apply
              </Button>
            </div>
          </>
        )}
        {!currentFormat && (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

const StorageNumberFormat = () => {
  const { currentUser } = useAuth();
  const [example, setExample] = useState("");
  const [currentFormat, setCurrentFormat] = useState(null);
  const [fieldError, setFieldError] = useState(false);
  const savedFormat = useRef(null);
  const isNumberUsed = useRef(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getStorageNumberFormat().then((result) => {
      isNumberUsed.current = result.find((r) => {
        return r.format_section === "Number";
      });
      savedFormat.current = result;
      setExample(getExample(result));
      setCurrentFormat(result);
    });
  }, []);

  const addText = () => {
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Text",
      },
    ]);
  };

  const addNumber = () => {
    if (!isNumberUsed.current) {
      isNumberUsed.current = true;
      setFieldError(false);
      setExample(
        getExample([
          ...currentFormat,
          {
            format_section: "Number",
            format_mod: 1,
            iterator: 1,
          },
        ])
      );
      setCurrentFormat([
        ...currentFormat,
        {
          format_section: "Number",
          format_mod: 1,
          iterator: 1,
        },
      ]);
    }
  };

  const addYear = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Year",
          format_mod: "YY",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Year",
        format_mod: "YY",
      },
    ]);
  };

  const addMonth = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Month",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Month",
      },
    ]);
  };

  const addDay = () => {
    setExample(
      getExample([
        ...currentFormat,
        {
          format_section: "Day",
        },
      ])
    );
    setCurrentFormat([
      ...currentFormat,
      {
        format_section: "Day",
      },
    ]);
  };

  const getExample = (formatData) => {
    let example = "";

    formatData.forEach((item) => {
      switch (item.format_section) {
        case "Text":
          example = `${example}${item.initial_text}`;
          break;
        case "Number":
          example = `${example}${String(item.iterator).padStart(
            item.format_mod,
            "0"
          )}`;
          break;
        case "Year":
          example = `${example}${moment().format(item.format_mod)}`;
          break;
        case "Month":
          example = `${example}${moment().format("MM")}`;
          break;
        case "Day":
          example = `${example}${moment().format("DD")}`;
          break;
        default:
          break;
      }
    });

    return example;
  };

  const handleUpdate = () => {
    //check that number field exists
    if (!isNumberUsed.current) {
      setFieldError(true);
      return;
    }

    //submit update
    let values = [];

    for (const selection of currentFormat) {
      if (selection.format_section === "Text") {
        values.push({
          type: "Text",
          value: selection.initial_text,
        });
      }

      if (selection.format_section === "Number") {
        values.push({
          type: "Number",
          value: [selection.format_mod, selection.iterator, selection.iterator],
        });
      }

      if (selection.format_section === "Year") {
        values.push({
          type: "Year",
          value: selection.format_mod,
        });
      }

      if (selection.format_section === "Month") {
        values.push({
          type: "Month",
          value: "",
        });
      }

      if (selection.format_section === "Day") {
        values.push({
          type: "Day",
          value: "",
        });
      }
    }

    updateStorageNumberFormat(values).then(() => {
      enqueueSnackbar(`Storage Number Format Updated.`, {
        variant: "success",
      });
    });

    logActivity("Admin", currentUser.user_id, "Updated Storage Number Format");
  };

  return (
    <>
      <div style={{ fontSize: "large", marginBottom: "20px" }}>
        Storage Number Format
      </div>
      <div>
        {currentFormat && (
          <>
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
              <div>
                Use the options below to create a custom storage number that
                will be automatically generated when you create a new storage.
                The "Iterator" option must be used.
              </div>
            </div>
            <div
              style={{ textAlign: "left", paddingLeft: "5px", width: "95%" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addText"
                  onClick={addText}
                >
                  <i className="fa fa-plus"></i> + Text
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addNumber"
                  onClick={addNumber}
                >
                  <i className="fa fa-plus"></i> + Iterator
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addYear"
                  onClick={addYear}
                >
                  <i className="fa fa-plus"></i> + Year
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addMonth"
                  onClick={addMonth}
                >
                  <i className="fa fa-plus"></i> + Month
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={buttonStyle}
                  className="summaryButton addDay"
                  onClick={addDay}
                >
                  <i className="fa fa-plus"></i> + Day
                </Button>
              </div>
              {fieldError && (
                <div style={{ color: "orangered", margin: "10px 0px" }}>
                  Please add a number iterator field.
                </div>
              )}
              <div style={{ margin: "10px 0px" }}>EXAMPLE: {example}</div>
              <div style={{ margin: "20px 5px 10px 5px", minHeight: "250px" }}>
                {currentFormat.map((item, index) => {
                  switch (item.format_section) {
                    case "Text":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Text: </div>
                            <TextBox
                              maxLength={100}
                              defaultValue={item.initial_text}
                              width="200px"
                              stylingMode="filled"
                              placeholder="Enter Text..."
                              onInput={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i)
                                    cf.initial_text = e.event.target.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Number":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Iterator: </div>
                            <NumberBox
                              defaultValue={parseInt(item.format_mod)}
                              style={{ marginRight: "10px" }}
                              width="60px"
                              min={1}
                              max={5}
                              showSpinButtons={true}
                              stylingMode="filled"
                              hint="Select number size."
                              onValueChanged={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i) cf.format_mod = e.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                            <NumberBox
                              defaultValue={parseInt(item.iterator)}
                              min={0}
                              width="130px"
                              stylingMode="filled"
                              placeholder="Initial Count Value"
                              onInput={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i)
                                    cf.iterator = e.event.target.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Year":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Year: </div>
                            <SelectBox
                              defaultValue={item.format_mod}
                              width="200px"
                              stylingMode="filled"
                              items={["YY", "YYYY"]}
                              placeholder="Select year format..."
                              onValueChanged={(e) => {
                                let temp = currentFormat.map((cf, i) => {
                                  if (index === i) cf.format_mod = e.value;
                                  return cf;
                                });

                                setExample(getExample(temp));
                                setCurrentFormat(temp);
                              }}
                            />
                          </div>
                        </div>
                      );
                    case "Month":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Month: </div>
                            <TextBox
                              maxLength={100}
                              width="200px"
                              stylingMode="filled"
                              defaultValue="MM"
                              disabled={true}
                            />
                          </div>
                        </div>
                      );
                    case "Day":
                      return (
                        <div key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <div style={{ minWidth: "100px" }}>Day: </div>
                            <TextBox
                              maxLength={100}
                              width="200px"
                              stylingMode="filled"
                              defaultValue="DD"
                              disabled={true}
                            />
                          </div>
                        </div>
                      );
                    default:
                      return <></>;
                  }
                })}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={buttonStyle}
                className="summaryButton resetBtn"
                onClick={() => {
                  isNumberUsed.current = false;
                  setCurrentFormat([]);
                  setExample([]);
                }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={buttonStyle}
                className="summaryButton defaultBtn"
                onClick={() => {
                  setExample(getExample(savedFormat.current));
                  setCurrentFormat(savedFormat.current);
                }}
              >
                Default
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={buttonStyle}
                className="summaryButton applyBtn"
                onClick={handleUpdate}
              >
                Apply
              </Button>
            </div>
          </>
        )}
        {!currentFormat && (
          <div>
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

const ItemNumberFormatSettings = styled(({ className }) => {
  return (
    <div className={className} style={{ maxWidth: 750, padding: "0px 30px" }}>
      <CaseNumberFormat />
      <Divider style={{ margin: "20px 0px" }} />
      <EvidenceNumberFormat />
      <Divider style={{ margin: "20px 0px" }} />
      <StorageNumberFormat />
      <Divider style={{ margin: "20px 0px" }} />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default ItemNumberFormatSettings;
