import { useState, useRef, useMemo, useEffect } from "react";
import { Button, Modal, Typography, useTheme } from "@mui/material";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import ForensicSoftwareColumnDefs from "../../components/ForensicSoftware/ForensicSoftwareColumnDefs.js";
import { usePermissions } from "../../hooks/usePermissions";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import ForensicSoftwareAPI from "../../api/software/index.js";
import {
  db_timestamp,
  getDateFormat,
  monolithMoment,
  validateTimestamp,
} from "../../utils/date-format.js";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import ForensicSoftwareFilterDimensions from "../../components/ForensicSoftware/ForensicSoftwareFilterDimensions.js";
import { Helmet } from "react-helmet-async";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";

import Loader from "../../components/Loader.js";
import styled from "styled-components";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import { nanoid } from "nanoid";
import ToolBarItems from "../../components/ToolBarItems.js";
import { Form, EmptyItem, SimpleItem } from "devextreme-react/ui/form.js";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import { useDebouncedCallback, useDebounce } from "use-debounce";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import { SaveIcon } from "lucide-react";
import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import { Input } from "@monolith-forensics/monolith-ui";

const columnDefs = ForensicSoftwareColumnDefs.filter(
  (col) => col.enabled !== false
);

const ItemFlyout = styled(
  ({
    setIsFlyoutVisible,
    setFlyoutData,
    flyoutData,
    handleGetNextItem = () => {},
    handleGetPrevItem = () => {},
    onEdit = () => {},
    onDelete = () => {},
  }) => {
    const theme = useTheme();
    return (
      <Flyout
        onClose={() => {
          setIsFlyoutVisible(false);
          setFlyoutData(null);
        }}
      >
        <FlyoutHeader>
          <div style={{ width: "100%" }}>
            <h4
              className="flyout-title"
              style={{
                margin: "5px 0px",
                color: theme.palette.text.primary,
              }}
            >
              {flyoutData.software_name || "..."}
            </h4>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                }}
              >
                Software Details
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TaskButton
                  onClick={() => handleGetPrevItem(flyoutData.license_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
                <TaskButton
                  onClick={() => handleGetNextItem(flyoutData.license_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowRightOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
              </div>
            </div>
          </div>
        </FlyoutHeader>
        <FlyoutContent
          defaultInfo={flyoutData}
          onEdit={onEdit}
          onDelete={() => {
            setIsFlyoutVisible(false);
            onDelete();
          }}
        />
      </Flyout>
    );
  }
)``;

const FlyoutContent = styled(({ className, defaultInfo, onEdit, onDelete }) => {
  const theme = useTheme();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const queryClient = useQueryClient();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { data: itemData } = useQuery({
    queryKey: ["software:list", { license_id: defaultInfo.license_id }],
    queryFn: () =>
      ForensicSoftwareAPI.getSoftwareItems({
        license_id: defaultInfo.license_id,
      }),
    initialData: [defaultInfo],
    select: (res) => {
      return res[0];
    },
  });

  const title = document.querySelector(".flyout-title");
  if (title) {
    title.innerText =
      itemData?.software_name || defaultInfo.software_name || "";
  }

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const dataFieldIgnoreList = ["description"];

  const detail = (
    <div className="detail-container">
      {columnDefs
        .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
        .map((c) => {
          return (
            <div className="detail-item" key={nanoid()}>
              <div className="detail-label">{c.caption}</div>
              {c.render ? (
                c.render(itemData)
              ) : c.dataType === "date" ? (
                !!itemData[c.dataField] ? (
                  <>
                    {monolithMoment({
                      timestamp: itemData[c.dataField],
                      includeTime: false,
                    })}
                  </>
                ) : null
              ) : (
                <div className="detail-value">{itemData[c.dataField]}</div>
              )}
            </div>
          );
        })}
    </div>
  );

  return (
    <div className={className}>
      {!!itemData && (
        <>
          <div className="action-menu">
            <>
              {hasPermission(MONOLITH_PERMISSIONS.SOFTWARE_UPDATE) && (
                <div className="action-menu-item" onClick={handleEdit}>
                  <EditOutlinedIcon
                    style={{ color: theme.palette.primary.main }}
                  />
                  <div className="action-menu-label">Edit</div>
                </div>
              )}
              {hasPermission(MONOLITH_PERMISSIONS.SOFTWARE_DELETE) && (
                <div className="action-menu-item" onClick={handleDelete}>
                  <DeleteOutlineOutlinedIcon
                    style={{ color: theme.palette.error.main }}
                  />
                  <div className="action-menu-label">Delete</div>
                </div>
              )}
            </>
          </div>
          {detail}
          <div className="detail-label" style={{ marginTop: 30 }}>
            Description
          </div>
          <div style={{ position: "relative" }}>
            <div className="item-description">{itemData.description}</div>
          </div>
          <DeleteModal
            onSubmit={() => onDelete()}
            open={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            defaultInfo={itemData}
          />
          <EditModal
            onSubmit={() => {
              queryClient.refetchQueries({
                queryKey: [
                  "software:list",
                  { license_id: itemData.license_id },
                ],
              });
              onEdit();
            }}
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            defaultInfo={itemData}
          />
        </>
      )}
      {!itemData && <Loader message={"Loading..."} />}
    </div>
  );
})`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const CreateModal = ({ open, handleClose = () => {}, onSubmit = () => {} }) => {
  const theme = useTheme();
  const form = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: ["software:names"],
        queryFn: () => ForensicSoftwareAPI.getSoftwareNames(),
        enabled: open,
      },
      {
        queryKey: ["software:vendors"],
        queryFn: () => ForensicSoftwareAPI.getSoftwareVendors(),
        enabled: open,
      },
      {
        queryKey: ["software:locations"],
        queryFn: () => ForensicSoftwareAPI.getSoftwareLocations(),
        enabled: open,
      },
    ],
  });

  const handleSubmit = (event) => {
    if (form.current.instance.validate().isValid) {
      let formData = { ...form.current.instance.option().formData };

      handleClose();

      if (formData.purchase_date)
        formData.purchase_date = db_timestamp(formData.purchase_date);
      if (formData.expire_date)
        formData.expire_date = db_timestamp(formData.expire_date);

      ForensicSoftwareAPI.createSoftwareItem(formData).then((result) => {
        enqueueSnackbar(`Software Added.`, {
          variant: "success",
        });

        onSubmit();
      });
    }
  };

  const isDone = result.every((item) => item.isFetched);

  const fieldData = {
    names: isDone ? result[0].data : [],
    vendors: isDone ? result[1].data : [],
    locations: isDone ? result[2].data : [],
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Create Software
          </div>
          {isDone && (
            <>
              <Form ref={form} colCount={2}>
                <SimpleItem
                  dataField="software_name"
                  label={{ text: "Software" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.names,
                    placeholder: "Select or enter a software...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "software_name",
                    valueExpr: "software_name",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { software_name: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                    onOpened: async (e) => {
                      e.component.option("dataSource", fieldData.names);
                    },
                  }}
                />
                <SimpleItem
                  dataField="vendor"
                  label={{ text: "Vendor" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.vendors,
                    placeholder: "Select or enter a vendor...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "vendor",
                    valueExpr: "vendor",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { vendor: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                    onOpened: async (e) => {
                      e.component.option("dataSource", fieldData.vendors);
                    },
                  }}
                />
                <SimpleItem dataField="name" label={{ text: "License Name" }} />
                <SimpleItem dataField="edition" label={{ text: "Edition" }} />
                <SimpleItem
                  dataField="purchase_date"
                  label={{ text: "Purchase Date" }}
                  editorType="dxDateBox"
                  editorOptions={{
                    type: "date",
                    useMaskBehavior: true,
                    displayFormat: getDateFormat(false, false),
                  }}
                  validationRules={[
                    {
                      type: "custom",
                      message: "Invalid Date",
                      validationCallback: validateTimestamp,
                    },
                  ]}
                />
                <SimpleItem
                  dataField="expire_date"
                  label={{ text: "Expire Date" }}
                  editorType="dxDateBox"
                  editorOptions={{
                    type: "date",
                    useMaskBehavior: true,
                    displayFormat: getDateFormat(false, false),
                  }}
                  validationRules={[
                    {
                      type: "custom",
                      message: "Invalid Date",
                      validationCallback: validateTimestamp,
                    },
                  ]}
                />
                <SimpleItem
                  dataField="cost"
                  label={{ text: "Cost" }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: `#,##0.##`,
                  }}
                />
                <SimpleItem
                  dataField="sms"
                  label={{ text: "SMS" }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: `#,##0.##`,
                  }}
                />
                <SimpleItem
                  dataField="license_key"
                  label={{ text: "License Key" }}
                />
                <SimpleItem
                  dataField="dongle_sn"
                  label={{ text: "Dongle S/N" }}
                />
                <SimpleItem
                  dataField="location"
                  label={{ text: "Location" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.locations,
                    placeholder: "Select or enter a location...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "location",
                    valueExpr: "location",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { location: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                    onOpened: async (e) => {
                      e.component.option("dataSource", fieldData.locations);
                    },
                  }}
                />
                <EmptyItem />
                <SimpleItem
                  dataField="description"
                  label={{ text: "Description" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 135,
                  }}
                />
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: 5,
                  marginTop: 30,
                }}
              >
                <Button
                  variant="text"
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" size="small" onClick={handleSubmit}>
                  Create Software
                </Button>
              </div>
            </>
          )}
          {!isDone && <Loader />}
        </div>
      </Modal>
    </>
  );
};

const EditModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const form = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: ["software:names"],
        queryFn: () => ForensicSoftwareAPI.getSoftwareNames(),
        enabled: open,
      },
      {
        queryKey: ["software:vendors"],
        queryFn: () => ForensicSoftwareAPI.getSoftwareVendors(),
        enabled: open,
      },
      {
        queryKey: ["software:locations"],
        queryFn: () => ForensicSoftwareAPI.getSoftwareLocations(),
        enabled: open,
      },
    ],
  });

  const handleSubmit = (event) => {
    if (form.current.instance.validate().isValid) {
      let newData = { ...form.current.instance.option().formData };

      let modeledData = {};
      let oldData = { ...defaultInfo };

      handleClose();

      for (const key of Object.keys(oldData)) {
        if (oldData[key] !== newData[key]) {
          modeledData[key] = newData[key];
        }
      }

      if (modeledData.purchase_date)
        modeledData.purchase_date = db_timestamp(modeledData.purchase_date);
      if (modeledData.expire_date)
        modeledData.expire_date = db_timestamp(modeledData.expire_date);

      ForensicSoftwareAPI.updateSoftwareItem(
        newData.license_id,
        modeledData
      ).then((result) => {
        enqueueSnackbar(`Software Updated.`, {
          variant: "success",
        });

        onSubmit();
      });
    }
  };

  const isDone = result.every((item) => item.isFetched);

  const fieldData = {
    names: isDone ? result[0].data : [],
    vendors: isDone ? result[1].data : [],
    locations: isDone ? result[2].data : [],
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        disableEnforceFocus={true}
        style={{
          userSelect: "none",
          zIndex: 1400,
        }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 700,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 350px)",
            overflowY: "auto",
            padding: 20,
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Software
          </div>
          {isDone && (
            <>
              <Form
                ref={form}
                colCount={2}
                defaultFormData={{ ...defaultInfo }}
              >
                <SimpleItem
                  dataField="software_name"
                  label={{ text: "Software" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.names,
                    placeholder: "Select or enter a software...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "software_name",
                    valueExpr: "software_name",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { software_name: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                    onOpened: async (e) => {
                      e.component.option("dataSource", fieldData.names);
                    },
                  }}
                />
                <SimpleItem
                  dataField="vendor"
                  label={{ text: "Vendor" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.vendors,
                    placeholder: "Select or enter a vendor...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "vendor",
                    valueExpr: "vendor",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { vendor: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                    onOpened: async (e) => {
                      e.component.option("dataSource", fieldData.vendors);
                    },
                  }}
                />
                <SimpleItem dataField="name" label={{ text: "License Name" }} />
                <SimpleItem dataField="edition" label={{ text: "Edition" }} />
                <SimpleItem
                  dataField="purchase_date"
                  label={{ text: "Purchase Date" }}
                  editorType="dxDateBox"
                  editorOptions={{
                    type: "date",
                    useMaskBehavior: true,
                    displayFormat: getDateFormat(false, false),
                  }}
                  validationRules={[
                    {
                      type: "custom",
                      message: "Invalid Date",
                      validationCallback: validateTimestamp,
                    },
                  ]}
                />
                <SimpleItem
                  dataField="expire_date"
                  label={{ text: "Expire Date" }}
                  editorType="dxDateBox"
                  editorOptions={{
                    type: "date",
                    useMaskBehavior: true,
                    displayFormat: getDateFormat(false, false),
                  }}
                  validationRules={[
                    {
                      type: "custom",
                      message: "Invalid Date",
                      validationCallback: validateTimestamp,
                    },
                  ]}
                />
                <SimpleItem
                  dataField="cost"
                  label={{ text: "Cost" }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: `#,##0.##`,
                  }}
                />
                <SimpleItem
                  dataField="sms"
                  label={{ text: "SMS" }}
                  editorType="dxNumberBox"
                  editorOptions={{
                    format: `#,##0.##`,
                  }}
                />
                <SimpleItem
                  dataField="license_key"
                  label={{ text: "License Key" }}
                />
                <SimpleItem
                  dataField="dongle_sn"
                  label={{ text: "Dongle S/N" }}
                />
                <SimpleItem
                  dataField="location"
                  label={{ text: "Location" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.locations,
                    placeholder: "Select or enter a location...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "location",
                    valueExpr: "location",
                    searchEnabled: true,
                    acceptCustomValue: true,
                    onCustomItemCreating: (e) => {
                      if (e.text != "") {
                        e.customItem = { location: e.text };
                      } else {
                        e.customItem = null;
                      }
                    },
                    onOpened: async (e) => {
                      e.component.option("dataSource", fieldData.locations);
                    },
                  }}
                />
                <EmptyItem />
                <SimpleItem
                  dataField="description"
                  label={{ text: "Description" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 135,
                  }}
                />
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: 5,
                  marginTop: 30,
                }}
              >
                <Button
                  variant="text"
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" size="small" onClick={handleSubmit}>
                  Edit Software
                </Button>
              </div>
            </>
          )}
          {!isDone && <Loader />}
        </div>
      </Modal>
    </>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    ForensicSoftwareAPI.deleteSoftwareItem(defaultInfo.license_id).then(() => {
      enqueueSnackbar(`Software Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 200px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Software
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this software license?
        </div>
        <div
          style={{ margin: "10px 0px", color: theme.palette.text.secondary }}
        >
          "{defaultInfo.vendor ? defaultInfo.vendor + " | " : ""}
          {defaultInfo.software_name}"
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Software"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

const SoftwareSectionContent = styled(
  ({
    className,
    stateStoreKey = "software:view",
    defaultQueryKey = "software:list",
  }) => {
    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const theme = useTheme();
    const queryFilter = useRef(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [pageSize, setPageSize] = useState(100);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);

    const table = useTable();

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const [query, setQuery] = useState(null);

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          ForensicSoftwareAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage, pages) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage, pages) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };

    const handleFilter = (newFilter) => {
      setQuery((q) => {
        return { query: { ...q?.query, ...newFilter, page: 1 } };
      });
    };

    const handleRefresh = () => {
      queryClient.refetchQueries({
        queryKey: [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
        exact: true,
      });
    };

    const handleClearFilters = () => {
      queryFilter.current.clear();
    };

    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      ForensicSoftwareAPI.exportSoftwareList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat(true, true),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();
        // remove snackbar
      });
    };

    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };

    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };

    const handleActionButtonClick = (rowData) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };

    const handleGetNextItem = (id) => {
      const current = records?.findIndex((i) => i.license_id === id);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (id) => {
      const current = records?.findIndex((i) => i.license_id === id);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    const handleRefetchQuery = () => {
      refetch();
    };
    const onCreate = () => handleRefetchQuery();
    const onEdit = () => handleRefetchQuery();
    const onDelete = () => handleRefetchQuery();
    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    const { queryButton, conditions } = useQueryFilter({
      dimensions: ForensicSoftwareFilterDimensions,
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    return (
      <div className={className}>
        <Helmet title="Forensic Software" />
        <Typography
          variant="h3"
          gutterBottom
          display="inline"
          style={{ margin: 0 }}
        >
          Forensic Software
        </Typography>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission(MONOLITH_PERMISSIONS.SOFTWARE_CREATE)}
            onClick={(e) => {
              setShowCreateModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Software
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={SaveIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <Input
              placeholder="Search Software"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e) => {
                const currentValue = e.target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records}
              totalRecords={totalRecords}
              keyValue="license_id"
              tableInstance={table}
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              showActionColumn={true}
              onActionButtonClick={handleActionButtonClick}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
          </>
        )}
        {!data && <Loader message={"Retrieving Items..."} />}
        {isFlyoutVisible && (
          <ItemFlyout
            setIsFlyoutVisible={setIsFlyoutVisible}
            setFlyoutData={setFlyoutData}
            flyoutData={flyoutData}
            handleGetNextItem={handleGetNextItem}
            handleGetPrevItem={handleGetPrevItem}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )}
        <CreateModal
          onSubmit={onCreate}
          open={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
        />
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SoftwareSection = () => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.SOFTWARE_READ_ALL)) {
    return <AccessDeniedMessage />;
  }

  return <SoftwareSectionContent />;
};

export default SoftwareSection;
