import { useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { usePermissions } from "../../../../hooks/usePermissions";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import synchronizeColumnState from "../../../../utils/synchronize-column-state.js";
import QaReviewColumnDefs from "../../../../components/QualityAssurance/QaReviewColumnDefs.js";
import Table, {
  Column,
  useTable,
} from "../../../../Monolith-UI/Table/Table.js";
import { useInfiniteQuery, useQueries } from "@tanstack/react-query";
import QualityAssuranceAPI from "../../../../api/quality_assurance/index.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { useQueryFilter } from "../../../../Monolith-UI/QueryFilter/QueryFilter.js";
import QaReviewFilterDimensions from "../../../../components/QualityAssurance/QaReviewFilterDimensions.js";
import { getDateFormat } from "../../../../utils/date-format.js";
import { Button, Modal } from "@mui/material";
import { ItemTotal } from "../../CaseEvidence/index";

//Icons
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ComboButton from "../../../../Monolith-UI/ComboButton/ComboButton.js";
import TextBox from "devextreme-react/ui/text-box.js";
import Loader from "../../../../components/Loader.js";
import UserApi from "../../../../api/users/users.js";
import { Template } from "devextreme-react/core/template";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import UserSelectBoxItem from "../../../../components/SelectBoxItems/UserSelectBoxItem.js";
import ToolBarItems from "../../../../components/ToolBarItems.js";
import { Input } from "@monolith-forensics/monolith-ui";

const CreateQAReviewPopup = ({
  open,
  onSubmit = () => {},
  handleClose = () => {},
}) => {
  const { case_id } = useParams();
  const theme = useTheme();
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = usePermissions();

  const result = useQueries({
    queries: [
      {
        queryKey: [
          "users:list",
          { includeObservers: false, includeInactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({ includeObservers: false, includeInactive: false }),
        enabled: open,
      },
      {
        queryKey: ["quality-assurance:checklists"],
        queryFn: () => QualityAssuranceAPI.getQAChecklists({ enabled: 1 }),
        enabled: open,
      },
    ],
  });

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      let values = Object.assign({}, form.current.instance.option("formData"));

      QualityAssuranceAPI.createQAReview({
        ...values,
        case_id,
        created_by_id: currentUser.user_id,
      }).then((result) => {
        enqueueSnackbar(`QA Review Created.`, {
          variant: "success",
        });
        onSubmit();
      });
      handleClose();
    }
  };

  const handleCancel = () => handleClose();

  const isDone = result.every((x) => x.isFetched);

  const users = result[0]?.data || [];
  const qa_checklists = result[1]?.data || [];

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 600,
          maxHeight: "90vh",
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 300px)",
          overflowY: "auto",
          padding: 20,
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Create QA Review
        </div>
        {!isDone && <Loader />}
        {isDone && (
          <>
            <Form ref={form} colCount={2}>
              <SimpleItem
                dataField="name"
                label={{ text: "QA Name" }}
                isRequired={true}
              />
              <SimpleItem
                dataField="type"
                label={{ text: "QA Type" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: [
                    "General Review",
                    "Technical Review",
                    "Admin Review",
                  ],
                  placeholder: "Select QA Type...",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                }}
              />
              <SimpleItem
                dataField="checklist_id"
                label={{ text: "Checklist" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: qa_checklists,
                  displayExpr: "checklist_name",
                  valueExpr: "checklist_id",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                }}
              />
              <SimpleItem
                dataField="assigned_to_id"
                label={{ text: "Assigned To" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: users,
                  displayExpr: "full_name",
                  valueExpr: "user_id",
                  searchEnabled: true,
                  searchExpr: "full_name",
                  placeholder: "Select Examiner...",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  itemTemplate: "assigned_to_id",
                }}
              />
              <Template
                name="assigned_to_id"
                render={(data) => <UserSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="description"
                label={{ text: "QA Description" }}
                editorType="dxTextArea"
                colSpan={2}
                editorOptions={{
                  height: 75,
                }}
              />
            </Form>
            <ToolBarItems
              submitText="Create QA Review"
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              style={{ marginTop: 15 }}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const QaReviews = styled(({ stateStoreKey = "case:qa:view" }) => {
  const { case_id, review_id } = useParams();
  const theme = useTheme();
  const { hasPermission } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const columnDefs = QaReviewColumnDefs;
  const [columnState, setColumnState] = useState(() => {
    return synchronizeColumnState(
      columnDefs,
      JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
    );
  });

  const [pageSize, setPageSize] = useState(100);
  const [query, setQuery] = useState(null);
  const [searchText, setSearchText] = useState(
    localStorage.getItem(`${stateStoreKey}:searchText`)
  );
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const table = useTable();

  const currentSort = useMemo(() => {
    let [sort] = columnState
      ?.filter((c) => c.sorting?.active)
      .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

    return sort;
  }, [columnState]);

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "cases:qa-reviews",
        {
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            case_id,
          },
        },
      ],
      queryFn: ({ pageParam }) =>
        QualityAssuranceAPI.query({
          query: {
            ...query?.query,
            order: query?.query?.order || currentSort,
            pageSize,
            page: pageParam,
            case_id,
          },
        }),
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      enabled: !!query,
      placeholderData: (data) => data,
    });

  const records = data?.pages?.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  const totalRecords = data?.pages?.[0]?.total || 0;

  const debouncedFetchNextPage = useDebouncedCallback((e) => {
    fetchNextPage();
  }, 50);

  const { queryButton, queryFilter, conditions } = useQueryFilter({
    dimensions: QaReviewFilterDimensions,
    onQuerySet: (newFilter) =>
      setQuery((q) => {
        return { query: { ...q?.query, ...newFilter, page: 1, case_id } };
      }),
    stateStoring: {
      enabled: true,
      type: "localStorage",
      storageKey: stateStoreKey,
    },
  });

  const handleSort = (field) => {
    const savedColumn =
      columnState?.find((svc) => field === svc.dataField) || {};
    const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

    let newOrder = null;

    // if sorted on a different column, reset the sort
    if (query?.query?.order?.field !== field) {
      newOrder = {
        field,
        sort: "asc",
      };
    }

    // otherwise rotate the sort options on the current column
    else {
      switch (order) {
        case "asc":
          newOrder = {
            field,
            sort: "desc",
          };
          break;
        case "desc":
          newOrder = null;
          break;
        default:
          newOrder = {
            field,
            sort: "asc",
          };
      }
    }

    let newCols = columnState?.map((c) => {
      if (c.dataField === field) {
        return {
          ...c,
          sorting: {
            active: newOrder ? true : false,
            direction: newOrder?.sort,
          },
        };
      }
      delete c.sorting;
      return c;
    });

    let newQuery = {
      query: {
        ...query.query,
        order: newOrder,
      },
    };

    setColumnState(newCols);

    setQuery(newQuery);
  };
  const handleExportTable = () => {
    // show snackbar
    enqueueSnackbar("Exporting table...", {
      variant: "info",
    });

    QualityAssuranceAPI.exportQaReviewsList({
      query: {
        ...query?.query,
        order: query?.query?.order || currentSort,
        pageSize,
      },
      type: "xlsx",
      columns: columnState
        .filter((c) => c.visible !== false && c.showInColumnChooser !== false)
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return { dataField: c.dataField, header: c.caption, ...c };
        }),
      date_format: getDateFormat(true, true),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
    });
  };
  const handleColumnReorder = (newOrder) => {
    setColumnState((cs) => {
      return newOrder.map((o) => {
        return {
          ...cs.find((c) => c.dataField === o.column),
          order: o.order,
        };
      });
    });
  };
  const handleColumnVisibility = (column, visible) => {
    setColumnState((cs) => {
      return cs.map((c) => {
        if (c.dataField === column.dataField) {
          return {
            ...c,
            visible,
          };
        }
        return c;
      });
    });
  };
  const handleClearFilters = () => queryFilter.clear();
  const handleQueryRefetch = () => refetch();
  // Detect scroll to bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * data?.pages?.length) {
      if (hasNextPage && !isFetchingNextPage) {
        debouncedFetchNextPage();
      }
    }
  };

  // Persist column state to local storage
  useEffect(() => {
    let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
    localStorage.setItem(
      stateStoreKey,
      JSON.stringify({
        ...oldState,
        cols: columnState,
      })
    );
  }, [columnState]);

  useEffect(() => {
    setQuery((q) => ({
      query: {
        ...q?.query,
        search: debouncedSearchText ? debouncedSearchText : null,
        page: 1,
      },
    }));
  }, [debouncedSearchText]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!hasPermission}
          onClick={(e) => {
            setShowCreateModal(true);
          }}
          style={{
            minWidth: "fit-content",
            fontSize: 11,
            padding: "3px 6px",
          }}
        >
          + New QA Review
        </Button>
        <div style={{ marginLeft: 10 }}>{queryButton}</div>
        <ItemTotal total={totalRecords} Icon={InventoryOutlinedIcon} />
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            minWidth: "fit-content",
          }}
        >
          <ComboButton
            type="multi-select"
            data={columnState}
            displayField="caption"
            idField={"dataField"}
            selectedItems={columnState.filter((c) => c.visible !== false)}
            variant="outlined"
            closeOnSelect={false}
            textColor={theme.palette.text.secondary}
            title={"Select Columns"}
            showSearch={true}
            dropDownTitle={() => {
              return (
                <div
                  style={{
                    margin: "5px 0px",
                    padding: 3,
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                  }}
                >
                  Select Columns
                </div>
              );
            }}
            onItemDeSelect={(item) => {
              handleColumnVisibility(item, false);
            }}
            onItemSelect={(item) => {
              handleColumnVisibility(item, true);
            }}
          >
            <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            // onItemSelect={handleAddCondition}
            textColor={theme.palette.text.secondary}
            title={"Export Table"}
            onClick={handleExportTable}
          >
            <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={"Clear Filters"}
            onClick={handleClearFilters}
          >
            <FilterListOffIcon style={{ fontSize: 18 }} />
          </ComboButton>
          <ComboButton
            type="button"
            variant="outlined"
            textColor={theme.palette.text.secondary}
            title={table.isCompact ? "Zoom In" : "Zoom Out"}
            onClick={() => table.toggleCompact()}
          >
            {table.isCompact ? (
              <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
            ) : (
              <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
            )}
          </ComboButton>
          <ComboButton
            type="button"
            variant={"outlined"}
            textColor={
              table.isStriped
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            }
            title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
            onClick={() => table.toggleStripes()}
          >
            <ReorderOutlinedIcon style={{ fontSize: 18 }} />
          </ComboButton>
          {/* SEARCH */}
          <Input
            placeholder="Search QA Reviews"
            variant="outlined"
            height={30}
            defaultValue={searchText}
            onKeyUp={(e) => {
              const currentValue = e.target.value.trim();
              setSearchText(currentValue);

              localStorage.setItem(`${stateStoreKey}:searchText`, currentValue);
            }}
            style={{
              marginLeft: "10px",
              border: searchText
                ? `1px solid ${theme.palette.primary.main}`
                : "",
            }}
          />
        </div>
      </div>
      {data && (
        <>
          {<div>{conditions}</div>}
          <Table
            data={records}
            totalRecords={totalRecords}
            tableInstance={table}
            keyValue="qa_id"
            columnProps={{ minWidth: 150, width: 150 }}
            onHeaderClick={(col) =>
              col?.sorting?.enabled === false ? null : handleSort(col.dataField)
            }
            onScroll={handleScroll}
            onColumnReorder={handleColumnReorder}
            // onActionButtonClick={handleActionButtonClick}
            // showActionColumn={true}
            // focusedRow={flyoutData}
            onRowUpdated={() => {
              refetch();
            }}
            showSelection={true}
          >
            {columnState
              .filter((col) =>
                col.dataField === "actions" ? hasPermission : true
              )
              .map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
          </Table>
        </>
      )}
      {!data && <Loader message={"Retrieving Items..."} />}
      <CreateQAReviewPopup
        open={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        onSubmit={() => handleQueryRefetch()}
      />
    </>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default QaReviews;
