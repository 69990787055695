import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import {
  Form,
  EmptyItem,
  GroupItem,
  SimpleItem,
} from "devextreme-react/ui/form";
import DataSource from "devextreme/data/data_source";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ToolBarItems from "../../components/ToolBarItems";
import {
  db_timestamp,
  getDateFormat,
  monolithMoment,
  validateTimestamp,
} from "../../utils/date-format";
import { usePermissions } from "../../hooks/usePermissions";
import {
  logActivity,
  getEvidence,
  getSoftwareNames,
  getStorage,
  getContacts,
  createContact,
} from "../../api";
import { updateAcquisition } from "../../api/acquisitions";
import { useAuth } from "../../contexts/AuthContext";
import { Template } from "devextreme-react/core/template";
import EvidenceSelectBoxItem from "../../components/SelectBoxItems/EvidenceSelectBoxItem.js";
import StorageSelectBoxItem from "../../components/SelectBoxItems/StorageSelectBoxItem.js";

const AcquisitionOverview = ({ acquisitionInfo, handleAcquisitionUpdate }) => {
  const editAcquisitionPopup = useRef(null);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AcquisitionDescription acquisitionInfo={acquisitionInfo} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AcquisitionData
            acquisitionInfo={acquisitionInfo}
            handleAcquisitionUpdate={handleAcquisitionUpdate}
            editAcquisitionPopup={editAcquisitionPopup}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}></Grid>
      </Grid>
      <EditAcquisitionPopup
        acquisitionInfo={acquisitionInfo}
        handleAcquisitionUpdate={handleAcquisitionUpdate}
        editAcquisitionPopup={editAcquisitionPopup}
      />
    </>
  );
};

const AcquisitionDescription = ({ acquisitionInfo }) => {
  return (
    <Card sx={{ p: "15px", minHeight: "175px", height: "100%" }}>
      <Typography color="textSecondary" variant="overline" fontSize={13}>
        Description
      </Typography>
      <Divider sx={{ mb: "10px" }} />
      <Box
        sx={{
          mb: 2,
          whiteSpace: "pre-line",
          maxHeight: "300px",
          overflowY: "auto",
        }}
      >
        {acquisitionInfo.acq_notes}
      </Box>
    </Card>
  );
};

const AcquisitionData = ({ acquisitionInfo, editAcquisitionPopup }) => {
  const { hasPermission } = usePermissions();

  const handleEdit = () => {
    editAcquisitionPopup.current.instance.show();
  };

  const calculateDuration = (data) => {
    return (data.duration_hours || "00") + ":" + (data.duration_mins || "00");
  };

  //build details object
  const acquisitionDetails = {
    Name: acquisitionInfo.acq_name || "",
    "": "",
    "Evidence Item": acquisitionInfo.evidence_number || "",
    Format: acquisitionInfo.format || "",
    Size: acquisitionInfo.size
      ? `${acquisitionInfo.size || 0} ${acquisitionInfo.size_unit || ""}`
      : "",
    Method: acquisitionInfo.acq_type || "",
    "Acquire Date":
      monolithMoment({
        timestamp: acquisitionInfo.acquire_date,
        includeTime: false,
      }) || "",
    Software: acquisitionInfo.acq_tool
      ? `${acquisitionInfo.acq_tool} ${acquisitionInfo.tool_version || ""}`
      : "",
    "Linked Contact": acquisitionInfo.contact,
  };

  acquisitionDetails["Duration"] = calculateDuration(acquisitionInfo);

  return (
    <Card sx={{ p: "15px", minHeight: "175px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography color="textSecondary" variant="overline" fontSize={13}>
          Details
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <Button
            variant="text"
            color="primary"
            size="small"
            disabled={!hasPermission()}
            style={{ height: 20, width: 45 }}
            onClick={handleEdit}
          >
            Edit
          </Button>
        </div>
      </div>
      <Divider sx={{ mb: "10px" }} />
      <div style={{ maxWidth: 800, margin: "auto" }}>
        <AcquisitionDetailsTable
          data={acquisitionDetails}
          acquisitionInfo={acquisitionInfo}
        />
        {acquisitionInfo.hash_1 && (
          <div style={{ padding: 4 }}>
            {acquisitionInfo.hash_1_type}: {acquisitionInfo.hash_1}
          </div>
        )}
        {acquisitionInfo.hash_2 && (
          <div style={{ padding: 4 }}>
            {acquisitionInfo.hash_2_type}: {acquisitionInfo.hash_2}
          </div>
        )}
      </div>
    </Card>
  );
};

const AcquisitionDetailsTable = ({ data, acquisitionInfo }) => {
  const theme = useTheme();
  return (
    <table style={{ width: "100%", maxWidth: 800, margin: "auto" }}>
      <tbody>
        <tr>
          <td
            style={{
              textAlign: "left",
              paddingRight: 15,
              verticalAlign: "top",
            }}
          >
            <div>
              {Object.keys(data)
                .map((key, index) => {
                  if ((index + 1) & 1) {
                    return (
                      <div key={key} style={{ minHeight: "25px" }}>
                        {key !== "" ? `${key}:` : ""}
                      </div>
                    );
                  } else return null;
                })
                .filter((r) => r !== null)}
            </div>
          </td>
          <td
            style={{
              textAlign: "left",
              verticalAlign: "top",
            }}
          >
            <div>
              {Object.values(data)
                .map((value, index, arr) => {
                  if ((index + 1) & 1) {
                    if (Object.keys(data)[index] === "Evidence Item") {
                      return (
                        <Link
                          style={{ color: theme.palette.primary.main }}
                          onMouseEnter={(event) => {
                            event.target.style.textDecoration = "underline";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.textDecoration = "none";
                          }}
                          to={`/evidence/${acquisitionInfo.uuid}/overview`}
                        >
                          <div style={{ minHeight: "25px" }}>{value || ""}</div>
                        </Link>
                      );
                    }
                    return (
                      <div key={value + index} style={{ minHeight: "25px" }}>
                        {value || ""}
                      </div>
                    );
                  } else return null;
                })
                .filter((r) => r !== null)}
            </div>
          </td>
          <td
            style={{
              textAlign: "left",
              paddingRight: 15,
              verticalAlign: "top",
            }}
          >
            <div>
              {Object.keys(data)
                .map((key, index) => {
                  if (!((index + 1) & 1)) {
                    return (
                      <div key={key} style={{ minHeight: "25px" }}>
                        {key !== "" ? `${key}:` : ""}
                      </div>
                    );
                  } else return null;
                })
                .filter((r) => r !== null)}
            </div>
          </td>
          <td
            style={{
              textAlign: "left",
              verticalAlign: "top",
            }}
          >
            <div>
              {Object.values(data)
                .map((value, index, arr) => {
                  if (!((index + 1) & 1)) {
                    if (Object.keys(data)[index] === "Evidence Item") {
                      return (
                        <Link
                          style={{
                            color: theme.palette.primary.main,
                          }}
                          onMouseEnter={(event) => {
                            event.target.style.textDecoration = "underline";
                          }}
                          onMouseLeave={(event) => {
                            event.target.style.textDecoration = "none";
                          }}
                          to={`/evidence/${acquisitionInfo.uuid}/overview`}
                        >
                          <div style={{ minHeight: "25px" }}>{value || ""}</div>
                        </Link>
                      );
                    }
                    return (
                      <div key={value + index} style={{ minHeight: "25px" }}>
                        {value || ""}
                      </div>
                    );
                  } else return null;
                })
                .filter((r) => r !== null)}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const EditAcquisitionPopup = ({
  handleAcquisitionUpdate,
  editAcquisitionPopup,
  acquisitionInfo,
}) => {
  const form = useRef(null);
  const [fieldData, setFieldData] = useState({
    storage: [],
    evidence: [],
    software: [],
  });
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const currentData = { ...acquisitionInfo };

  useEffect(() => {
    Promise.all([
      getStorage({
        case_id: acquisitionInfo.case_id,
        active_only: true,
        include_general_storage: true,
      }),
      getEvidence({ case_id: acquisitionInfo.case_id }),
      getSoftwareNames(),
      getContacts({ case_id: acquisitionInfo.case_id }),
    ]).then((result) => {
      setFieldData({
        storage: result[0],
        evidence: result[1],
        software: result[2],
        contacts: result[3],
      });
    });
  }, []);

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option().formData };

      editAcquisitionPopup.current.instance.hide();

      let updateData = {};

      //Determine new values
      for (const key of Object.keys(formData)) {
        if (currentData[key] !== formData[key]) {
          updateData[key] = formData[key];
        }
      }

      //Set new Acquire date if applicable
      if (updateData.acquire_date)
        updateData.acquire_date = db_timestamp(updateData.acquire_date);

      //Get Updated Linked Contact info
      if (updateData.contact && updateData.contact.client_id === null) {
        let newContact = await createContact({
          name: updateData.contact.name,
          case_id: acquisitionInfo.case_id,
        });

        updateData.person_id = newContact.client_id;
        delete updateData.contact;
      } else if (updateData.contact) {
        updateData.person_id = updateData.contact.client_id;
        delete updateData.contact;
      }

      if (Object.keys(updateData).length > 0) {
        updateAcquisition(currentData.acq_id, updateData);
        handleAcquisitionUpdate(updateData);

        enqueueSnackbar(`Acquisition Edited.`, {
          variant: "success",
        });

        logActivity(
          acquisitionInfo.case_id,
          currentUser.user_id,
          `Acquisition Edited (Evidence: ${currentData.evidence_number}) - ${currentData.acq_name}`
        );
      } else {
        enqueueSnackbar(`No new data to update.`, {
          variant: "warning",
        });
      }
    }
  };

  return (
    <>
      <Popup
        ref={editAcquisitionPopup}
        title="Edit Acquisition"
        showTitle={true}
        showCloseButton={true}
        defaultHeight={900}
        defaultWidth={800}
        resizeEnabled={true}
        position={{
          offset: "100 0",
        }}
      >
        <Form ref={form} colCount={2} defaultFormData={{ ...acquisitionInfo }}>
          <SimpleItem
            dataField="evidence_id"
            label={{ text: "Evidence" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: new DataSource({
                store: fieldData.evidence,
                paginate: true,
                pageSize: 10,
              }),
              hint: "Select an evidence item to acquire.",
              noDataText: "No evidence items are in this case.",
              searchEnabled: true,
              valueExpr: "evidence_id",
              displayExpr: "evidence_number",
              placeholder: "Select...",
              itemTemplate: "evidence_id",
            }}
          />
          <Template
            name="evidence_id"
            render={(data) => <EvidenceSelectBoxItem data={data} />}
          />
          <EmptyItem />
          <SimpleItem
            dataField="acq_name"
            label={{ text: "Acquisition Name" }}
            isRequired={true}
          />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="format"
              label={{ text: "Format" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  "E01",
                  "DD",
                  "UFD",
                  "TAR",
                  "AD1",
                  "L01",
                  "BIN",
                  "AFF4",
                  "DMG",
                  "ZIP",
                  "VHDX",
                  "VMDK",
                  "XRY",
                ],
                placeholder: "Select...",
                dropDownOptions: {
                  height: "200px",
                },
                acceptCustomValue: true,
                onCustomItemCreating: (e) => {
                  if (e.text != "") {
                    e.customItem = e.text;
                    e.component.getDataSource().store().insert(e.customItem);
                    e.component.getDataSource().reload();
                  } else {
                    e.customItem = null;
                  }
                },
              }}
            />
            <SimpleItem
              dataField="acq_type"
              label={{ text: "Type" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: [
                  "Physical",
                  "Logical",
                  "Advanced Logical",
                  "File System",
                  "Full File System",
                  "Before First Unlock",
                  "After First Unlock",
                  "Chip-off",
                  "JTAG",
                  "Manual",
                ],
                placeholder: "Select...",
                acceptCustomValue: true,
                onCustomItemCreating: (e) => {
                  if (e.text != "") {
                    e.customItem = e.text;
                    e.component.getDataSource().store().insert(e.customItem);
                    e.component.getDataSource().reload();
                  } else {
                    e.customItem = null;
                  }
                },
              }}
            />
          </GroupItem>
          <SimpleItem
            dataField="acq_tool"
            label={{ text: "Forensic Software" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: new DataSource({
                store: fieldData.software,
                paginate: true,
                pageSize: 10,
              }),
              displayExpr: "software_name",
              valueExpr: "software_name",
              placeholder: "Select...",
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text != "") {
                  e.customItem = { software_name: e.text };
                  e.component.getDataSource().store().insert(e.customItem);
                  e.component.getDataSource().reload();
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
          <SimpleItem
            dataField="tool_version"
            label={{ text: "Software Version" }}
          />
          <SimpleItem
            dataField="storage_id"
            label={{ text: "Storage Items" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: new DataSource({
                store: fieldData.storage,
                paginate: true,
                pageSize: 10,
                key: "storage_id",
                group: "is_general_storage",
                sort: [
                  "Position",
                  { selector: "is_general_storage", desc: true },
                ],
              }),
              hint: "Select storage items that this acquisition is stored on.",
              noDataText:
                "Assign storage to the case to choose a storage item.",
              searchEnabled: true,
              displayExpr: "storage_number",
              valueExpr: "storage_id",
              showDropDownButton: true,
              grouped: true,
              dropDownOptions: {
                height: "350px",
              },
              groupTemplate: (data) => {
                return `<div>${
                  data.key === 1 ? "General Storage" : "Assigned Storage"
                }</div>`;
              },
              itemTemplate: "storage_id",
            }}
          />
          <Template
            name="storage_id"
            render={(data) => <StorageSelectBoxItem data={data} />}
          />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="size"
              label={{ text: "Size" }}
              editorType="dxNumberBox"
              editorOptions={{
                min: 0,
                max: 999,
                showSpinButtons: true,
              }}
            />
            <SimpleItem
              dataField="size_unit"
              label={{ text: "Unit" }}
              editorType="dxSelectBox"
              editorOptions={{
                items: ["KB", "MB", "GB", "TB"],
                value: "GB",
              }}
            />
          </GroupItem>
          <SimpleItem dataField="hash_1" label={{ text: "Hash 1" }} />
          <SimpleItem
            dataField="hash_1_type"
            label={{ text: "Algorithm" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: ["MD5", "SHA-1", "SHA256", "Checksum-64"],
              placeholder: "Select...",
            }}
          />
          <SimpleItem dataField="hash_2" label={{ text: "Hash 2" }} />
          <SimpleItem
            dataField="hash_2_type"
            label={{ text: "Algorithm" }}
            editorType="dxSelectBox"
            editorOptions={{
              items: ["MD5", "SHA-1", "SHA256", "Checksum-64"],
              placeholder: "Select...",
            }}
          />
          <SimpleItem
            dataField="acquire_date"
            label={{ text: "Acquire Date" }}
            editorType="dxDateBox"
            editorOptions={{
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, true),
            }}
            validationRules={[
              {
                type: "custom",
                message: "Invalid Date",
                validationCallback: validateTimestamp,
              },
            ]}
          />
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="duration_hours"
              label={{ text: "Duration Hours" }}
              editorType="dxNumberBox"
              editorOptions={{
                min: 0,
                showSpinButtons: true,
              }}
            />
            <SimpleItem
              dataField="duration_mins"
              label={{ text: "Duration Mins" }}
              editorType="dxNumberBox"
              editorOptions={{
                max: 59,
                min: 0,
                showSpinButtons: true,
              }}
            />
          </GroupItem>
          <EmptyItem />
          <SimpleItem
            dataField="contact"
            label={{ text: "Linked Contact" }}
            editorType="dxSelectBox"
            editorOptions={{
              dataSource: fieldData.contacts,
              dropDownOptions: {
                maxHeight: 250,
              },
              placeholder: "Select or Enter New Contact",
              displayExpr: "name",
              searchEnabled: true,
              hint: "Select a person that this acquisition is linked to.",
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                if (e.text != "") {
                  e.customItem = { name: e.text, client_id: null };
                  e.component.getDataSource().store().insert(e.customItem);
                  e.component.getDataSource().reload();
                } else {
                  e.customItem = null;
                }
              },
            }}
          />
          <SimpleItem
            dataField="acq_notes"
            label={{ text: "Notes" }}
            editorType="dxTextArea"
            colSpan={2}
            editorOptions={{ height: 125 }}
          />
        </Form>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Edit Acquisition"
            onSubmit={handleSubmit}
            onCancel={() => {
              editAcquisitionPopup.current.instance.hide();
            }}
          />
        </div>
      </Popup>
    </>
  );
};

export default AcquisitionOverview;
