import { Button, Typography, useTheme } from "@mui/material";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { nanoid } from "nanoid";

import PeopleIcon from "@mui/icons-material/People";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";

import ContactsColumnDefs from "../../components/Contacts/ContactsColumnDefs.js";
import { usePermissions } from "../../hooks/usePermissions";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import ContactsAPI from "../../api/contacts/index.js";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import ContactsFilterDimensions from "../../components/Contacts/ContactsFilterDimensions.js";
import { getDateFormat, monolithMoment } from "../../utils/date-format.js";
import { ItemTotal } from "../Cases/CaseEvidence/index";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import Loader from "../../components/Loader.js";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import { Helmet } from "react-helmet-async";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import CreateContactsModal from "./components/CreateContactModal.js";
import { Input } from "@monolith-forensics/monolith-ui";

import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";
import { MONOLITH_PERMISSIONS } from "../../constants.js";

const actions = [
  { value: 0, text: "Assign Contacts", permission: null },
  {
    value: 1,
    text: "Import from CSV",
    permission: MONOLITH_PERMISSIONS.CONTACTS_BULK_IMPORT,
  },
];

const columnDefs = ContactsColumnDefs;

const ContactFlyout = ({
  setIsFlyoutVisible,
  setFlyoutData,
  flyoutData,
  handleGetNextItem,
  handleGetPrevItem,
  handleContactsRefetch,
}) => {
  const theme = useTheme();

  return (
    <Flyout
      onClose={() => {
        setIsFlyoutVisible(false);
        setFlyoutData(null);
      }}
    >
      <FlyoutHeader>
        <div style={{ width: "100%" }}>
          <h4
            className="flyout-title"
            style={{
              margin: "5px 0px",
              color: theme.palette.text.primary,
            }}
          >
            {flyoutData.name || "..."}
          </h4>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: theme.palette.text.secondary,
                fontSize: 12,
              }}
            >
              Contact Details
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <TaskButton
                onClick={() => handleGetPrevItem(flyoutData.client_id)}
                style={{
                  fontSize: 10,
                }}
                variant="outlined"
              >
                <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
              </TaskButton>
              <TaskButton
                onClick={() => handleGetNextItem(flyoutData.client_id)}
                style={{
                  fontSize: 10,
                }}
                variant="outlined"
              >
                <KeyboardArrowRightOutlinedIcon style={{ fontSize: 17 }} />
              </TaskButton>
            </div>
          </div>
        </div>
      </FlyoutHeader>
      <FlyoutContent
        defaultInfo={flyoutData}
        setIsFlyoutVisible={setIsFlyoutVisible}
        setFlyoutData={setFlyoutData}
        handleContactsRefetch={handleContactsRefetch}
      />
    </Flyout>
  );
};

const FlyoutContent = styled(({ className, defaultInfo, queryKey }) => {
  const { hasPermission } = usePermissions();

  const { data: itemData } = useQuery({
    queryKey: ["contact:item", { client_id: defaultInfo.client_id }],
    queryFn: () =>
      ContactsAPI.getContacts({ client_id: defaultInfo.client_id }),
    initialData: [defaultInfo],
    select: (res) => {
      return res[0];
    },
  });

  const title = document.querySelector(".flyout-title");
  if (title) title.innerText = itemData?.name || defaultInfo?.name || "";

  const dataFieldIgnoreList = ["notes"];

  const detail = (
    <div className="detail-container">
      {columnDefs
        .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
        .map((c) => {
          return (
            <div className="detail-item" key={nanoid()}>
              <div className="detail-label">{c.caption}</div>
              {c.render ? (
                c.render(itemData, {
                  queryKey,
                  ignoreRender: !hasPermission(),
                })
              ) : c.dataType === "date" ? (
                !!itemData[c.dataField] ? (
                  <>
                    {monolithMoment({
                      timestamp: itemData[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : null
              ) : (
                <div className="detail-value">{itemData[c.dataField]}</div>
              )}
            </div>
          );
        })}
    </div>
  );

  return (
    <div className={className}>
      {!!itemData && (
        <>
          <div className="action-menu"></div>
          {detail}
          <div className="detail-label" style={{ marginTop: 30 }}>
            Description
          </div>
          <div style={{ position: "relative" }}>
            <div className="item-description">{itemData.notes}</div>
          </div>
        </>
      )}
      {!itemData && <Loader message={"Loading..."} />}
    </div>
  );
})`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const CaseContactsSectionContent = styled(
  ({
    className,
    stateStoreKey = "contacts:view",
    defaultQueryKey = "contacts:list",
    caseInfo,
  }) => {
    const theme = useTheme();
    const { hasPermission } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });
    const [pageSize, setPageSize] = useState(100);
    const [query, setQuery] = useState(null);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const navigate = useNavigate();

    const table = useTable();

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          defaultQueryKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              case_id: caseInfo?.case_id,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          ContactsAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              case_id: caseInfo?.case_id,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    const { queryButton, queryFilter, conditions } = useQueryFilter({
      dimensions: ContactsFilterDimensions,
      onQuerySet: (newFilter) =>
        setQuery((q) => {
          return { query: { ...q?.query, ...newFilter, page: 1 } };
        }),
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };

    const handleExportTable = () => {
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      ContactsAPI.exportContactsList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat(true, true),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();
      });
    };
    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData) => {
      setFlyoutData(rowData);
      setIsFlyoutVisible(true);
    };
    const handleActionSelect = (action) => {
      switch (action.value) {
        case 0:
          setShowAssignModal(true);
          break;
        case 1:
          navigate(`/cases/${caseInfo.case_id}/contacts?v=import`);
          break;
        default:
          break;
      }
    };
    const handleClearFilters = () => queryFilter.clear();
    const handleContactsRefetch = () => {
      refetch();
    };
    const handleGetNextItem = (client_id) => {
      const current = records?.findIndex((i) => i.client_id === client_id);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (client_id) => {
      const current = records?.findIndex((i) => i.client_id === client_id);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    // Persist column state to local storage
    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState, stateStoreKey]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    return (
      <div className={className}>
        {!caseInfo && (
          <>
            <Helmet title="Contacts" />
            <Typography variant="h3" gutterBottom display="inline">
              Contacts
            </Typography>
          </>
        )}
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasPermission()}
            onClick={(e) => {
              setShowCreateModal(true);
            }}
            style={{
              minWidth: "fit-content",
              fontSize: 11,
              padding: "3px 6px",
            }}
          >
            + New Contact
          </Button>
          <div style={{ marginLeft: 10 }}>{queryButton}</div>
          <ItemTotal total={totalRecords || 0} Icon={PeopleIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            {hasPermission() && caseInfo && (
              <ComboButton
                type="dropDown"
                data={actions.filter(
                  (a) => !a.permission || hasPermission(a.permission)
                )}
                title={"More Actions"}
                showDropdownIcon={true}
                displayField="text"
                variant="outlined"
                useSelectMode={false}
                onItemSelect={(item) => handleActionSelect(item)}
                textColor={theme.palette.text.primary}
              >
                Actions
              </ComboButton>
            )}
            <ComboButton
              type="multi-select"
              data={columnState}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact ? (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              ) : (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <Input
              placeholder="Search Contacts"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e) => {
                const currentValue = e.target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records}
              totalRecords={totalRecords || 0}
              tableInstance={table}
              keyValue="client_id"
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              onActionButtonClick={handleActionButtonClick}
              showActionColumn={true}
              focusedRow={flyoutData}
              showSelection={false}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
          </>
        )}
        {!data && <Loader message={"Retrieving Items..."} />}
        {isFlyoutVisible && (
          <ContactFlyout
            flyoutData={flyoutData}
            setFlyoutData={setFlyoutData}
            setIsFlyoutVisible={setIsFlyoutVisible}
            handleGetNextItem={handleGetNextItem}
            handleGetPrevItem={handleGetPrevItem}
            handleContactsRefetch={handleContactsRefetch}
          />
        )}
        <CreateContactsModal
          isOpen={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          onSubmit={handleContactsRefetch}
        />
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CaseContacts = (props) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  if (!hasPermission(MONOLITH_PERMISSIONS.CONTACTS_READ_ALL)) {
    return <AccessDeniedMessage />;
  }

  return <CaseContactsSectionContent {...props} />;
};

export default CaseContacts;
