import { useParams } from "react-router-dom";
import styled from "styled-components";
import shortUUID from "short-uuid";
import moment from "moment";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { usePermissions } from "../../../hooks/usePermissions";
import UserApi from "../../../api/users/users.js";
import TaskStatuses from "../../../components/TaskBoard/components/TaskStatuses.js";
import TaskPriorities from "../../../components/TaskBoard/components/TaskPriorities.js";
import TaskViewer from "../../../components/TaskBoard/components/TaskViewer.js";
import TaskBoard from "../../../components/TaskBoard/TaskBoard.js";
import AddTaskModal from "../../../components/TaskBoard/components/AddTaskModal.js";
import IndexedDBService from "../../../IndexedDB/IndexedDBService.js";
import CasesApi from "../../../api/cases/index.js";

const CaseTasks = styled(({ className }) => {
  const { item_id, case_id } = useParams();
  const { currentUser } = usePermissions();
  const queryClient = useQueryClient();
  const taskBoardInstance = useRef(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [defaultFormData, setDefaultFormData] = useState({});

  const { data: caseInfo } = useQuery({
    queryKey: [
      "cases",
      "list",
      {
        case_id: parseInt(case_id),
      },
    ],
    queryFn: () =>
      CasesApi.getCases({
        case_id: parseInt(case_id),
      }),
    select: (data) => data?.[0] || {},
  });

  const { data: caseUsers } = useQuery({
    queryKey: [
      "users",
      "list",
      {
        include_observers: false,
        include_inactive: false,
        case_id: caseInfo.case_id,
      },
    ],
    queryFn: () =>
      UserApi.getUsers({
        include_observers: false,
        include_inactive: false,
        case_id: caseInfo.case_id,
      }),
  });

  const { data: savedTaskState, isFetched } = useQuery({
    queryKey: ["tasks", "state", caseInfo.uuid],
    queryFn: () =>
      IndexedDBService.TaskState.getTaskState({ uuid: caseInfo.uuid }),
    // don't cache this query
    gcTime: 0,
  });

  const query = {
    include_archived: false,
    case_id: caseInfo.case_id,
  };

  const handleAddItem = useCallback(
    (data) => {
      setDefaultFormData({
        case_id: caseInfo?.case_id || null,
        case_number: caseInfo?.case_number || null,
        case_uuid: caseInfo?.uuid || null,
        task_name: null,
        description: null,
        uuid: shortUUID.generate(),
        object_id: caseInfo?.uuid || null,
        object_type: caseInfo?.uuid ? "case" : null,
        priority_id: data?.taskValue === "priority" ? data.id : 2,
        status_id: data?.taskValue === "status" ? data.id : 4,
        sort_value: data?.tasks?.[0]?.sort_value - 1 || Math.random() * 1000000,
      });
      setShowAddModal(true);
    },
    [currentUser, caseInfo.case_id]
  );

  const onCreateTask = useCallback(
    (newTaskData) => {
      if (newTaskData.sub_tasks && newTaskData.sub_tasks.length > 0) {
        taskBoardInstance.current?.reload();
        return;
      }
      queryClient.setQueryData(
        taskBoardInstance.current.taskQueryKey,
        (oldData) => {
          const prevTasks = [...(oldData?.data || [])];
          const newTask = {
            due_date: null,
            status_id: null,
            priority_id: null,
            task_name: null,
            description: null,
            object_id: null,
            object_type: null,
            uuid: null,
            parent_id: null,
            sort_value: null,
            subtasks: [],
            case_id: caseInfo?.case_id || null,
            case_uuid: caseInfo?.uuid || null,
            task_id: null,
            time_category: null,
            time_category_id: null,
            case_number: caseInfo?.case_number || null,
            case_ref: caseInfo?.case_ref || null,
            object_name: caseInfo?.case_number || null,
            ...newTaskData, // override defaults listed above - these are values that may potentailly come from task creation
            assignees:
              caseUsers?.filter((u) =>
                newTaskData.assignees?.includes(u.user_id)
              ) || [],
            complete_date: null,
            completed_by: null,
            completed_by_id: null,
            created_by: {
              full_name: currentUser.full_name,
              user_id: 1,
              email: currentUser.email,
            },
            created_by_id: currentUser.user_id,
            created_on: moment().format("YYYY-MM-DD HH:mm:ss"),
            duration: null,
            is_complete: 0,
            status_name: TaskStatuses.find(
              (status) => status.status_id === newTaskData.status_id
            ).status_name,
            priority_name: TaskPriorities.find(
              (priority) => priority.priority_id === newTaskData.priority_id
            ).priority_name,
          };

          prevTasks.push(newTask);
          return {
            ...oldData,
            data: prevTasks,
          };
        }
      );
    },
    [caseUsers]
  );

  const handleTaskStateChange = useCallback(({ key, state }) => {
    queryClient.setQueryData(["tasks", "state", key], state);

    IndexedDBService.TaskState.putTaskState(key, state);
  }, []);

  return (
    <div className={className}>
      {useMemo(() => {
        if (!isFetched) {
          return null;
        }
        return (
          <>
            {item_id ? (
              <TaskViewer
                task={{ uuid: item_id, case_id: parseInt(case_id, 10) }}
                itemHref={(item) => `/cases/${case_id}/tasks/v/${item.uuid}`}
              />
            ) : (
              <TaskBoard
                taskBoardInstance={taskBoardInstance}
                caseInfo={caseInfo}
                handleAddItem={handleAddItem}
                taskQuery={query}
                defaultTaskState={savedTaskState}
                onTaskStateChange={handleTaskStateChange}
                itemHref={(item) => `/cases/${case_id}/tasks/v/${item.uuid}`}
              />
            )}
          </>
        );
      }, [item_id, isFetched])}
      {showAddModal && (
        <AddTaskModal
          open={showAddModal}
          onClose={() => setShowAddModal(false)}
          defaultFormData={defaultFormData}
          onSubmit={onCreateTask}
        />
      )}
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  h1 {
    margin: 0;
    padding: 0;
  }
`;

export default CaseTasks;
