import ForensicSoftwareAPI from "../../api/software/index.js";

const ForensicSoftwareFilterDimensions = [
  {
    name: "Vendor",
    field: "vendor",
    id: "vendor",
    type: "string",
    mode: "multi-select",
    key: ["software:vendors"],
    selectionDisplayName: "vendor",
    selectionIdField: "vendor",
    queryFn: (params) => ForensicSoftwareAPI.getSoftwareVendors(),
  },
  {
    name: "Software",
    field: "software_name",
    id: "software_name",
    type: "string",
    mode: "multi-select",
    key: ["software:names"],
    selectionDisplayName: "software_name",
    selectionIdField: "software_name",
    queryFn: (params) => ForensicSoftwareAPI.getSoftwareNames(),
  },
  {
    name: "Purchase Date",
    field: "purchase_date",
    id: "purchase_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Expire Date",
    field: "expire_date",
    id: "expire_date",
    type: "date",
    mode: "date",
  },
  {
    name: "Location",
    field: "location",
    id: "location",
    type: "string",
    mode: "multi-select",
    key: ["software:locations"],
    selectionDisplayName: "location",
    selectionIdField: "location",
    queryFn: (params) => ForensicSoftwareAPI.getSoftwareLocations(),
  },
];

export default ForensicSoftwareFilterDimensions;
