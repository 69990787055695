import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { AnalysisAPI } from "../../../../../api/index.js";
import { monolithMoment } from "../../../../../utils/date-format.js";
import { Button, Pill } from "@monolith-forensics/monolith-ui";
import { CreateTraceIndicatorModal } from "../../../../../components/Modals";
import { useState } from "react";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { TraceAttributeTypes } from "../../../../../components/ColumnDefinitions";

const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailLabel = styled.div`
  font-weight: 500;
  margin-right: 8px;
  min-width: 125px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const DetailValue = styled.div`
  white-space: pre-wrap;
  max-height: 150px;
  overflow-y: auto;
`;

const IndicatorItem = styled(({ className, data, onClick }) => {
  data.trace_attributes.sort((a, b) => {
    if (b.type.toUpperCase() > a.type.toUpperCase()) return -1;
    if (b.type.toUpperCase() < a.type.toUpperCase()) return 1;
    return 0;
  });

  return (
    <div
      className={className + " timeline-notes-list-item"}
      onClick={() => onClick?.(data)}
    >
      <div className="indicator-title">{data.name}</div>
      <div className="indicator-subtitle timestamp">
        {monolithMoment({
          timestamp: data.created_on,
          includeTime: true,
          includeZone: true,
        })}
      </div>
      <div className="indicator-subtitle description">
        <DetailItem>
          {/* <DetailLabel>Description</DetailLabel> */}
          <DetailValue>{data.description}</DetailValue>
        </DetailItem>
      </div>
      <div className="trace-attributes-list">
        {data.trace_attributes.map((ta) => (
          <Pill size="xs" key={ta.uuid} showRemoveIcon={false}>
            <span className="attribute-type">
              {
                TraceAttributeTypes.find((type) => type.value === ta.type)
                  ?.label
              }
            </span>
            <span> : </span>
            <span className="attribute-value">{ta.value}</span>
          </Pill>
        ))}
      </div>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.input.border};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .view-note-alert {
      display: flex;
    }
  }

  h4 {
    margin: 0;
  }
  .attribute-type {
    font-weight: 550;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .indicator-title {
    font-size: 18px;
    font-weight: 500;
    width: fit-content;
  }

  .indicator-subtitle {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.secondary};
    width: fit-content;
  }

  .indicator-subtitle.timestamp {
    font-size: 12px;
  }

  .indicator-subtitle.description {
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .trace-attributes-list {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }
`;

const FlyoutTraceIndicatorsTab = styled(({ className, defaultEventData }) => {
  const { currentUser } = usePermissions();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "timeline-events",
      "trace-indicators",
      "list",
      { uuid: defaultEventData.uuid, include_attributes: true },
    ],
    queryFn: ({ queryKey }) => {
      const [_, __, ___, query] = queryKey;
      return AnalysisAPI.TimelineEvents.getTraceIndicators(query);
    },
    enabled: !!defaultEventData.uuid,
  });

  const onCreateTraceIndicator = (data, diff) => {
    refetch();
    setShowCreateModal(false);
  };

  const traceIndicators = data?.data || [];

  return (
    <div className={className}>
      <div
        style={{
          display: "flex",
          flex: "initial",
          flexDirection: "row",
          gap: 5,
          alignContent: "center",
          alignItems: "center",
          marginBottom: 10,
          marginTop: 10,
          width: "100%",
        }}
      >
        <Button
          size="xxs"
          variant="contained"
          color="primary"
          onClick={() => setShowCreateModal(true)}
        >
          + New Indicator
        </Button>
        <CreateTraceIndicatorModal
          show={showCreateModal}
          defaultFormData={{
            created_by_id: currentUser.user_id,
            case_uuid: defaultEventData?.case?.uuid,
            timeline_event_uuid: defaultEventData?.uuid,
            evidence_uuid: defaultEventData?.source_evidence?.uuid,
          }}
          onClose={() => setShowCreateModal(false)}
          onCancel={() => setShowCreateModal(false)}
          onSubmit={onCreateTraceIndicator}
        />
      </div>
      <div className="ti-list">
        {traceIndicators.map((ti) => {
          return <IndicatorItem key={ti.uuid} data={ti} />;
        })}
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  .ti-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 0px;

    padding-right: 8px;
    gap: 15px;

    overflow-y: auto;
  }
`;

export default FlyoutTraceIndicatorsTab;
