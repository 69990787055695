import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getOffices(options = { office_id: null }) {
  if (options.office_id == null) return httpGet("/offices");
  else return httpGet(`/offices?office_id=${options.office_id}`);
}

export async function createOffice(values) {
  return await httpPost(`/offices`, values);
}

export async function updateOffice(office_id, values) {
  return await httpPatch(`/offices/${office_id}`, values);
}

export async function deleteOffice(office_id) {
  return await httpDelete(`/offices/${office_id}`);
}

const OfficesApi = {
  getOffices,
  createOffice,
  updateOffice,
  deleteOffice,
};

export default OfficesApi;
