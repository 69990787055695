import { useState } from "react";
import { Button, Modal } from "@monolith-forensics/monolith-ui";
import ButtonMenu from "../Forms/components/ButtonMenu.js";
import { usePermissions } from "../../hooks/usePermissions";
import DocumentTemplatesAPI from "../../api/DocumentTemplates/DocumentTemplates.js";
import LoggingAPI from "../../api/logging/index.js";
import { DocumentTemplateDefaultInfo } from "./DocumentTemplate.interface";

const DeleteDocumentTemplateModal = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  buttonProps,
  defaultInfo,
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: (defaultInfo: any) => void;
  onCancel: () => void;
  buttonProps?: { cancelText?: string; submitText?: string };
  defaultInfo?: DocumentTemplateDefaultInfo;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = usePermissions();

  const handleSubmit = async () => {
    setSubmitting(true);

    await DocumentTemplatesAPI.remove({ uuid: defaultInfo?.uuid });

    LoggingAPI.createLog({
      user_id: currentUser.user_id,
      message: `Deleted document template: ${defaultInfo?.name}`,
      event: "delete_document_template",
      object_type: "document_template",
      object_uuid: defaultInfo?.uuid,
    });

    onSubmit?.(defaultInfo);
    setSubmitting(false);
    onClose?.();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Modal.Title>Delete Document Template</Modal.Title>
      <div style={{ fontWeight: 600, margin: "20px 0px" }}>
        Are you sure you want to delete the template{" "}
        <span style={{ fontWeight: 600 }}>"{defaultInfo?.name}"</span>?
      </div>
      <ButtonMenu>
        <Button
          size="xs"
          variant="subtle"
          onClick={handleCancel}
          disabled={submitting}
        >
          {buttonProps?.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={submitting}
          loading={submitting}
        >
          {buttonProps?.submitText || "Delete Template"}
        </Button>
      </ButtonMenu>
    </Modal>
  );
};

export default DeleteDocumentTemplateModal;
