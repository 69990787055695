import { httpGet, httpPost } from "../connection.js";

const get = async (params = {}) => {
  return await httpGet("/audits", params);
};

const getAuditReport = async (params = {}) => {
  return await httpGet(`/audits/${params.uuid}/audit-report`, params);
};

const getAuditItems = async (params = {}) => {
  return await httpGet(`/audits/${params.uuid}/audit-items`, params);
};

const updateAuditItem = async (params = {}) => {
  return await httpPost(`/audits/${params.uuid}/audit-items`, params);
};

const getAuditLogs = async (params = {}) => {
  return await httpGet(`/audits/audit-logs`, params);
};

const createAuditLog = async (params = {}) => {
  return await httpPost(`/audits/audit-logs`, params);
};

const completeAudit = async (params = {}) => {
  return await httpPost(`/audits/${params.uuid}/complete-audit`, params);
};

const create = async (params = {}) => {
  return await httpPost("/audits", params);
};

const exportAuditItemsList = async (params = {}) => {
  return await httpPost("/audits/export-audit-items", params);
};

const exportAuditLogsList = async (params = {}) => {
  return await httpPost("/audits/export-audit-logs", params);
};

const AuditsAPI = {
  get,
  getAuditItems,
  getAuditReport,
  updateAuditItem,
  getAuditLogs,
  createAuditLog,
  create,
  completeAudit,
  exportAuditItemsList,
  exportAuditLogsList,
};

export default AuditsAPI;
