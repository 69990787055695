import { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useRef } from "react";
import { useQueries } from "@tanstack/react-query";
import CaseStorageAPI from "../../../api/storage/index.js";
import { Button, Modal } from "@mui/material";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../utils/date-format.js";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader.js";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import { Template } from "devextreme-react/core/template.js";
import UserApi from "../../../api/users/users.js";
import { getEvidenceSubmitters, getIpAddress } from "../../../api/index.js";
import UserSelectBoxItem from "../../../components/SelectBoxItems/UserSelectBoxItem.js";
import SignatureField from "../../../components/SignatureField/SignatureField.js";
import moment from "moment";

export const ReleaseItemsPopup = ({
  caseInfo,
  onSubmit = () => {},
  open,
  handleClose,
  table,
  query,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const releasedBySig = useRef(null);
  const releasedToSig = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: [
          "case:leads",
          { includeObservers: false, includeInactive: false },
        ],
        queryFn: (params) =>
          UserApi.getUsers({ includeObservers: false, includeInactive: false }),
        enabled: open,
      },
      {
        queryKey: ["evidence:submitters"],
        queryFn: () => getEvidenceSubmitters(),
        enabled: open,
      },
    ],
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      const data = { ...form.current.instance.option().formData };

      const moveInfo = {
        total: table?.getVirtualSelectionTotal() || 0,
        caseInfo: {
          case_id: caseInfo.case_id,
          case_number: caseInfo.case_number,
          case_ref: caseInfo.case_ref,
        },
        options: {
          selectionState: table?.getSelectionState() || null,
          included:
            table
              ?.getSelectedRows()
              .filter((r) => r.location_id !== 1) //Ignore items that are released
              .map((r) => r.uuid) || [],
          excluded: table?.getExcludedRows().map((r) => r.uuid) || [],
          ...query,
        },
      };

      const formData = {
        location_name: data.released_by.full_name,
        new_location_id: 1,
        new_location_name: data.released_to,
        timestamp: db_timestamp(data.timestamp, true),
        reason: data.reason || "Storage Item Released",
        entered_by: currentUser.full_name,
        entered_by_id: currentUser.user_id,
        coc_type: "Release",
      };

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = releasedToSig.current.isEmpty()
        ? null
        : {
            signature: releasedToSig.current.getSignature(),
            signer: data.released_to,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = releasedBySig.current.isEmpty()
        ? null
        : {
            signature: releasedBySig.current.getSignature(),
            signer: data.released_by.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      formData.signature = JSON.stringify(signature);

      if (moveInfo.total > 0) {
        CaseStorageAPI.moveStorageItems({
          formData,
          options: moveInfo.options,
          caseInfo: moveInfo.caseInfo,
        }).then((res) => {
          onSubmit();
          enqueueSnackbar(`Storage Items Released to ${data.released_to}`, {
            variant: "success",
          });
        });
      } else {
        enqueueSnackbar("No storage items selected.", {
          variant: "warning",
        });
      }

      handleClose();
    }
  };

  // check if the query is loading
  const isDone = result.every((item) => item.isSuccess);

  const fieldData = {
    users: result[0].data,
    submitters: result[1].data,
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Release Storage Items
        </div>
        {!isDone && <Loader />}
        {isDone && (
          <>
            <Form ref={form} colCount={1}>
              <SimpleItem
                dataField="released_by"
                label={{ text: "Released By" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  dataSource: fieldData.users,
                  searchEnabled: true,
                  displayExpr: "full_name",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  itemTemplate: "released_by",
                }}
              />
              <Template
                name="released_by"
                render={(data) => <UserSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="released_to"
                label={{ text: "Released To" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  dataSource: fieldData.submitters,
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  placeholder: "Select or add new...",
                  searchEnabled: true,
                  searchExpr: "custody_from",
                  displayExpr: "custody_from",
                  valueExpr: "custody_from",
                  acceptCustomValue: true,
                  onCustomItemCreating: (e) => {
                    if (e.text != "") {
                      e.customItem = { custody_from: e.text };
                      e.component.getDataSource().store().insert(e.customItem);
                      e.component.getDataSource().reload();
                    } else {
                      e.customItem = null;
                    }
                  },
                }}
              />
              <SimpleItem
                dataField="timestamp"
                label={{ text: "Timestamp" }}
                editorType="dxDateBox"
                isRequired={true}
                editorOptions={{
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  value: new Date(),
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              />
              <SimpleItem
                dataField={"reason"}
                label={{ text: "Release Notes" }}
                editorType="dxTextArea"
              />
              <SimpleItem
                dataField="custody_from_sig"
                label={{ text: "Released By Signature" }}
              >
                <SignatureField
                  sigFieldRef={releasedBySig}
                  title="Released By Signature"
                />
              </SimpleItem>
              <SimpleItem
                dataField="custody_to_sig"
                label={{ text: "Released To Signature" }}
              >
                <SignatureField
                  sigFieldRef={releasedToSig}
                  title="Released To Signature"
                />
              </SimpleItem>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: 5,
                marginTop: 30,
              }}
            >
              <Button
                variant="text"
                size="small"
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" size="small" onClick={handleSubmit}>
                Release Items
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
