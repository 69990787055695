import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DropDownSelector = ({
  items = [],
  tooltipText = null,
  buttonIcon: Icon,
  displayExpr = null,
  valueExpr = null,
  value,
  onSelect = () => {},
  useSelectMode = false,
  selectedItem = null,
  variant = "text",
  color = "secondary",
  style,
  disabled = false,
  showDropDownArrow = true,
  buttonStyle = {},
  title = null,
  itemRef = null,
  size = null,
}) => {
  const [currentItem, setCurrentItem] = useState(selectedItem || items[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!disabled) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUpdate = (item) => {
    if (useSelectMode) setCurrentItem(item);
    onSelect(item);
    handleClose();
  };

  useEffect(() => {
    if (useSelectMode) setCurrentItem(selectedItem || items[0]);
  }, [value]);

  useEffect(() => {
    setCurrentItem(selectedItem || items[0]);
  }, [items]);

  return (
    <div style={style}>
      <Tooltip
        disableHoverListener={!tooltipText}
        arrow
        title={tooltipText}
        enterDelay={500}
        sx={{ display: tooltipText ? "" : "none" }}
      >
        <Button
          variant={variant}
          size="small"
          color={color}
          disabled={disabled}
          onClick={handleClick}
          sx={{ px: 2, ...buttonStyle }}
          style={{
            fontSize: size === "compact" ? 10 : "inherit",
            padding: size === "compact" ? "0px 5px" : "4px 10px",
          }}
        >
          {Icon && (
            <div style={{ marginRight: 5, display: "flex" }}>
              <Icon style={{ fontSize: 14 }} />
            </div>
          )}
          {useSelectMode
            ? displayExpr
              ? currentItem[displayExpr]
              : currentItem
            : value}
          {showDropDownArrow && (
            <ArrowDropDownIcon fontSize="small" style={{ marginLeft: 2 }} />
          )}
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          style: {
            maxHeight: "250px",
          },
        }}
      >
        {title && (
          <>
            <div
              style={{
                padding: "5px 15px",
                userSelect: "none",
                fontWeight: "bold",
                color: theme.palette.primary.main,
              }}
            >
              {title}
            </div>
            <Divider />
          </>
        )}
        {items &&
          items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleUpdate(valueExpr ? item[valueExpr] : item);
                }}
                style={{
                  fontSize: size === "compact" ? 10 : "inherit",
                  padding: size === "compact" ? "5px 5px" : "6px 16px",
                }}
              >
                {item.icon && (
                  <span style={{ marginRight: 5, fontSize: "16px" }}>
                    <item.icon style={{ fontSize: 14 }} />
                  </span>
                )}
                {displayExpr ? item[displayExpr] : item}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default DropDownSelector;
