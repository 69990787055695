import NotificationSettingsService from "../../api/notification-settings";
import Loader from "../../components/Loader";
import { usePermissions } from "../../hooks/usePermissions";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { Switch } from "@monolith-forensics/monolith-ui";
import { MONOLITH_PERMISSIONS } from "../../constants.js";

const notificationOptions = [
  {
    event: "cases:assigned_to_me",
    description: "Notify me when I am assigned to a case",
  },
  {
    event: "tasks:assigned_to_me",
    description: "Notify me when I am assigned to a task",
  },
  {
    event: "software:upcoming_expiration",
    description: "Notify me when software is about to expire",
    permission: MONOLITH_PERMISSIONS.SOFTWARE_READ_ALL,
  },
];

const NotificationSettings = styled(({ className }) => {
  const { currentUser, hasPermission } = usePermissions();

  const { data: notificationSettings, isLoading } = useQuery({
    queryKey: ["notification-settings", currentUser.user_id],
    queryFn: () =>
      NotificationSettingsService.getNotificationSettings({
        user_id: currentUser.user_id,
      }),
  });

  const handleOptionChange = (option, value) => {
    NotificationSettingsService.updateNotificationSettings({
      notification_id: option.notification_id || Date.now(),
      user_id: currentUser.user_id,
      event: option.event,
      enabled: value,
    });
  };

  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      <div style={{ fontSize: "large", fontWeight: "bold", marginBottom: 20 }}>
        Email Notifications
      </div>
      <div className="notification-options">
        {notificationOptions.map((option) => {
          const userSetting = notificationSettings.find(
            (setting) => setting.event === option.event
          );
          return (
            <div className="notification-option">
              <Switch
                size="xs"
                defaultValue={userSetting?.enabled}
                onChange={(value) => handleOptionChange(option, value)}
                disabled={!hasPermission(option.permission)}
              />
              <div data-disabled={!hasPermission(option.permission)}>
                {option.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;

  .notification-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 20px;

    &[data-disabled="true"] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .notification-option {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.secondary};
    user-select: none;
  }
`;

export default NotificationSettings;
