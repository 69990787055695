import { Button, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useFileUploader } from "../../../contexts/FileUploader.js";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import shortUUID from "short-uuid";
import Menu, { MenuItem } from "../../../Monolith-UI/Menu.js";

const UploadButton = ({
  caseInfo,
  onClick = () => {},
  onSelect = () => {},
  onClose = () => {},
}) => {
  const theme = useTheme();
  const { case_id, folder_id } = useParams();
  const { addFiles } = useFileUploader();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);

  const handleToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const handleUpload = (e) => {
    onSelect(e.target.files);
    handleClose();
  };

  return (
    <>
      <div ref={anchorRef} className="action-menu-item" onClick={handleToggle}>
        <UploadOutlinedIcon style={{ color: theme.palette.primary.main }} />
        <div className="action-menu-label">Upload</div>
        <ArrowDropDownIcon fontSize="small" style={{ marginLeft: 5 }} />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        dropDownTitle={""}
      >
        <MenuItem
          onClick={handleClose}
          data={{ text: "My Cases", value: 0 }}
          style={{ minWidth: 200 }}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <UploadFileOutlinedIcon
              style={{
                marginRight: 10,
                color: theme.palette.primary.main,
                fontSize: 22,
              }}
            />
            File
            <input
              type="file"
              onChange={handleUpload}
              multiple
              style={{ opacity: 0, position: "absolute", left: "-9999px" }}
            />
          </label>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          data={{ text: "All Cases", value: 1 }}
          style={{ minWidth: 200 }}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <DriveFolderUploadOutlinedIcon
              style={{
                marginRight: 10,
                color: "orange",
                fontSize: 22,
              }}
            />
            Folder
            <input
              type="file"
              webkitdirectory="true"
              directory="true"
              multiple
              onChange={handleUpload}
              style={{ opacity: 0, position: "absolute", left: "-9999px" }}
            />
          </label>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UploadButton;
