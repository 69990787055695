import { Link } from "react-router-dom";
import styled from "styled-components";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { getDateFormat } from "../../utils/date-format.js";
import ToolBarItems from "../ToolBarItems.js";
import { Modal, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { useState, useRef } from "react";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CaseReportsAPI from "../../api/cases/reports.js";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import CaseArchivesAPI from "../../api/cases/archives.js";
import DropdownMenu from "../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import { EditIcon, TrashIcon } from "lucide-react";

const DefaultQueryKey = "cases:archives:list";

const CaseArchivesColumnDefs = [
  {
    dataField: "actions",
    caption: "",
    width: 55,
    allowSorting: false,
    allowReorder: false,
    showInColumnChooser: false,
    render: (rowData) => <ActionCell rowData={rowData} />,
  },
  {
    dataField: "name",
    caption: "Name",
    render: (rowData) => <CaseArchiveNameComponent rowData={rowData} />,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    dataType: "date",
    format: {
      type: getDateFormat(true, false),
    },
  },
  {
    dataField: "created_by",
    caption: "Created By",
  },
  {
    dataField: "is_ready",
    caption: "Status",
    render: (rowData) => {
      return rowData.is_ready ? "Ready" : "Archiving";
    },
  },
];

export default CaseArchivesColumnDefs.slice();

const CaseArchiveNameComponent = styled(({ className, rowData, color }) => {
  return (
    <div className={className} title="Download Archive">
      {rowData.is_ready ? (
        <a className={"item-link"} href={rowData.link} download={rowData.name}>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div>{!!rowData.name ? rowData.name : "View Report"}</div>
            <DownloadOutlinedIcon
              className="action-button"
              style={{ fontSize: 13, marginLeft: 5 }}
            />
          </div>
        </a>
      ) : (
        <>{rowData.name}</>
      )}
    </div>
  );
})`
  & {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & .item-link {
    color: ${({ color, theme }) => color || theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActionCell = ({ rowData }) => {
  const { hasPermission } = usePermissions();
  const queryClient = useQueryClient();

  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const handleSubmit = () => {
    queryClient.refetchQueries({
      queryKey: [DefaultQueryKey],
      type: "active",
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <DropdownMenu
          menuItems={[
            {
              label: "Delete",
              icon: TrashIcon,
              iconColor: "orangered",
              onClick: () => setShowDeleteModel(true),
            },
          ]}
          title={"Actions"}
          variant="outlined"
          // arrow
          disabled={!hasPermission()}
        >
          <MoreHorizOutlinedIcon style={{ fontSize: 13 }} />
        </DropdownMenu>
      </div>
      <DeleteModal
        open={showDeleteModel}
        handleClose={() => setShowDeleteModel(false)}
        onSubmit={() => handleSubmit()}
        defaultInfo={rowData}
      />
    </>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    CaseArchivesAPI.deleteCaseArchive({
      archive_id: defaultInfo.archive_id,
    }).then(() => {
      enqueueSnackbar(`Case Archive Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 400,
            backgroundColor: theme.palette.background.default,
            position: "absolute",
            left: "50%",
            top: "20%",
            transform: "translate(-35%, -20%)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Delete Case Archive
          </div>
          <div style={{ margin: "10px 0px" }}>
            Are you sure you want to delete this Case Archive?
          </div>
          <div style={{ margin: "10px 0px" }}>"{defaultInfo.name}"</div>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Delete Case Archive"
              submitColor="error"
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
