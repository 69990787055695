import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import Table, {
  Column,
  useTable,
} from "../../../../../Monolith-UI/Table/Table.js";
import { useDebouncedCallback } from "use-debounce";
import synchronizeColumnState from "../../../../../utils/synchronize-column-state.js";
import { AnalysisAPI } from "../../../../../api/index.js";
import EvidenceAPI from "../../../../../api/evidence/index.js";
import { Helmet } from "react-helmet-async";
import { CreateTimelineEventModal } from "../../../../../components/Modals";

import {
  Button,
  DropDownMenu,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import { Columns3Icon, DownloadIcon } from "lucide-react";
import TimelineEventFlyout from "./TimelineEventFlyout.js";
import { TimelineEventDefs } from "../../../../../components/ColumnDefinitions";
import { getDateFormat } from "../../../../../utils/date-format.js";
import { TableColumn } from "@/Monolith-UI/Table/types/Table";

interface TimelineEvent {
  account: {
    name: string;
    uuid: string;
    account_id: number;
  };
  artifact: {
    name: string;
    uuid: string;
    artifact_id: number;
  };
  case: {
    uuid: string;
    case_id: number;
    case_name: string;
    case_number: string;
  };
  created_by: {
    email: string;
    user_id: number;
    full_name: string;
    last_name: string;
    first_name: string;
  };
  created_on: string;
  description: string;
  event_id: number;
  event_name: string;
  event_type: {
    name: string;
    uuid: string;
    event_type_id: number;
  };
  source_evidence: {
    uuid: string;
    item_name: string;
    evidence_id: number;
    evidence_number: string;
  };
  target_evidence: {
    uuid: string;
    item_name: string;
    evidence_id: number;
    evidence_number: string;
  };
  timestamp: string;
  uuid: string;
}

interface TimelineEventsTableQuery {
  case_id?: number;
  order?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
}

interface TimelineTableMethods {
  refresh: () => void;
}

const queryKeyPrefix = ["timeline-events", "list"];
const pageSize = 50;
const stateStoreKey = "caseTimeline";

const TimelineEventsTable = styled(
  ({
    className,
    case_id,
    selectedRow,
    onLoaded,
    onAddEvent,
    onRowAction,
    componentRef,
  }) => {
    const [query, setQuery] = useState<TimelineEventsTableQuery | null>({
      case_id,
      pageSize,
      page: 1,
    });

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        TimelineEventDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const table = useTable();

    const {
      data,
      refetch,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      isFetched,
    } = useInfiniteQuery({
      queryKey: [queryKeyPrefix, query],
      queryFn: ({ pageParam }) =>
        AnalysisAPI.TimelineEvents.get({
          ...query,
          page: pageParam,
        }),
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage;
      },
      getPreviousPageParam: (firstPage) => {
        if (firstPage.page - 1 === 0) return null;
        return firstPage.page - 1;
      },
      initialPageParam: 1,
      placeholderData: (data) => data,
    });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    //   const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback(() => {
      fetchNextPage();
    }, 50);

    // Detect scroll to bottom
    const handleScroll = (e: React.MouseEvent) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      const pageLength = data?.pages?.length ? data?.pages?.length : 0;
      if (scrollHeight - scrollTop <= clientHeight + 100 * pageLength) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };

    const handleSearch = (e: React.KeyboardEvent) => {
      const target = e.target as HTMLInputElement;
      let searchText = target.value;
      if (e.code === "Enter" || e.code === "NumpadEnter" || searchText === "") {
        setQuery((q) => ({
          ...q,
          search: searchText === "" ? null : searchText,
        }));
      }
    };

    const handleSort = (dataField: string) => {
      setQuery((prev) => {
        return {
          ...prev,
          order: dataField,
          sort: prev?.sort === "asc" ? "desc" : "asc",
        };
      });
    };

    const handleActionButtonClick = (rowData: TimelineEvent) => {
      onRowAction?.(rowData);
    };

    const handleColumnVisibility = (
      columns: {
        label: string;
        value: string;
      }[]
    ) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (columns.find((col) => col.value === c.dataField)) {
            return {
              ...c,
              visible: true,
            };
          }
          return {
            ...c,
            visible: false,
          };
        });
      });
    };

    const handleExportTable = (option: string) => {
      // show snackbar

      let columns = columnState.map((c) => {
        return { dataField: c.dataField, header: c.caption, ...c };
      });
      if (option === "export:visible") {
        columns = columns.filter((c) => c.visible !== false);
      }

      AnalysisAPI.TimelineEvents.exportList({
        query,
        type: "xlsx",
        columns,
        date_format: getDateFormat(true, true),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = filename;
        el.click();
        // remove snackbar
        el.remove();
      });
    };

    useEffect(() => {
      if (isFetched) {
        onLoaded?.();
      }
    }, [isFetched]);

    useEffect(() => {
      if (componentRef) {
        componentRef.current = {
          refresh: refetch,
        };
      }
    }, []);

    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            gap: 5,
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="xxs"
            onClick={() => onAddEvent?.()}
          >
            + New Event
          </Button>
          <div
            style={{
              display: "flex",
              flex: "initial",
              flexDirection: "row",
              gap: 5,
              alignContent: "center",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            <DropDownMenu
              data={columnState.map((col) => ({
                label: col.caption,
                value: col.dataField,
              }))}
              defaultValue={columnState
                .filter((col) => col.visible !== false)
                .map((col) => ({
                  label: col.caption,
                  value: col.dataField,
                }))}
              variant="outlined"
              multiselect
              searchable
              buttonProps={{
                title: "Show/Hide Columns",
                size: "xxs",
                style: { padding: "0px 4px" },
              }}
              onChange={handleColumnVisibility}
              dropDownProps={{
                style: { width: 175, maxWidth: 400 },
              }}
            >
              <Columns3Icon size={14} />
            </DropDownMenu>
            <DropDownMenu
              variant="outlined"
              data={[
                { label: "Export Visible Columns", value: "export:visible" },
                { label: "Export All Columns", value: "export:all" },
              ]}
              buttonProps={{
                title: "Export List to XLSX",
                size: "xxs",
                style: { padding: "0px 4px" },
              }}
              onItemSelect={(item) => handleExportTable(item.value)}
              dropDownProps={{
                style: { width: 175, maxWidth: 400 },
              }}
            >
              <DownloadIcon size={14} />
            </DropDownMenu>
            <TextInput
              stylingMode="outlined"
              placeholder="Search Timeline"
              height="100%"
              width={200}
              onKeyUp={handleSearch}
            />
          </div>
        </div>
        <Table
          data={records || []}
          keyValue="uuid"
          columnProps={{ minWidth: 150, width: 150 }}
          onScroll={handleScroll}
          onHeaderClick={(col: TableColumn<TimelineEvent>) =>
            col?.sorting?.enabled === false ? null : handleSort(col.dataField)
          }
          onActionButtonClick={handleActionButtonClick}
          showActionColumn={true}
          focusedRow={selectedRow}
        >
          {columnState.map((col) => {
            return <Column key={col.dataField} {...col} />;
          })}
        </Table>
      </>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const TimelineEvents = styled(({ className }) => {
  const { case_id: caseID, event_uuid } = useParams();
  const case_id = caseID ? parseInt(caseID) : null;
  const navigate = useNavigate();

  const { currentUser } = usePermissions();
  const queryClient = useQueryClient();
  const timelineTableRef = useRef<TimelineTableMethods>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<null | {
    uuid: string | undefined;
  }>({
    uuid: event_uuid,
  });
  const [showFlyout, setShowFlyout] = useState(event_uuid ? true : false);

  const prefetchData = () => {
    // Prefetch queries
    queryClient.prefetchQuery({
      queryKey: ["timeline", "accounts", { case_id }],
      queryFn: () => AnalysisAPI.Accounts.get({ case_id }),
    });

    queryClient.prefetchQuery({
      queryKey: ["timeline", "event-types"],
      queryFn: () => AnalysisAPI.EventTypes.get(),
    });

    queryClient.prefetchQuery({
      queryKey: ["timeline", "artifact-types"],
      queryFn: () => AnalysisAPI.Artifacts.get(),
    });

    queryClient.prefetchQuery({
      queryKey: ["evidence", "list", { case_id }],
      queryFn: () => EvidenceAPI.getEvidence({ case_id }),
    });
  };

  const handleShowFlyout = (rowData: TimelineEvent) => {
    setSelectedEvent(rowData);
    setShowFlyout(true);
    navigate(`/cases/${case_id}/analysis/timeline/${rowData.uuid}`);
  };

  const handleCloseFlyout = () => {
    // remove event_uuid from URL
    const newUrl = `/cases/${case_id}/analysis/timeline`;
    navigate(newUrl, { replace: true });
    setSelectedEvent(null);
    setShowFlyout(false);
  };

  // keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      // open modal on ctrl + n
      if (ev.ctrlKey && ev.key === "n") {
        ev.preventDefault();
        setShowCreateModal(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={className}>
      <Helmet title="Case Timeline" />
      <CreateTimelineEventModal
        show={showCreateModal}
        defaultFormData={{
          created_by_id: currentUser.user_id,
          case_id,
        }}
        onClose={() => setShowCreateModal(false)}
        onCancel={() => setShowCreateModal(false)}
        onSubmit={(data: TimelineEvent) => {
          timelineTableRef?.current?.refresh();
          setShowCreateModal(false);
          setSelectedEvent({ uuid: data.uuid });
          setShowFlyout(true);
        }}
      />
      <TimelineEventFlyout
        open={showFlyout}
        onClose={handleCloseFlyout}
        event_uuid={selectedEvent?.uuid ? selectedEvent?.uuid : ""}
        defaultEventData={selectedEvent}
        onEdit={() => {
          timelineTableRef?.current?.refresh();
        }}
        onDelete={() => {
          timelineTableRef?.current?.refresh();
          setSelectedEvent(null);
          setShowFlyout(false);
        }}
      />
      <TimelineEventsTable
        componentRef={timelineTableRef}
        case_id={case_id}
        onLoaded={() => prefetchData()}
        onAddEvent={() => setShowCreateModal(true)}
        onRowAction={handleShowFlyout}
        selectedRow={selectedEvent}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default TimelineEvents;
