import { useParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";

import { useTheme, Modal, Button } from "@mui/material";
import { getEvidence } from "../../api";

import { useAuth } from "../../contexts/AuthContext";
import { addRecentEvidence } from "../../api/evidence/recentEvidence";
import EvidenceOverview from "./EvidenceOverview";
import Loader from "../../components/Loader";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import PhonelinkOutlinedIcon from "@mui/icons-material/PhonelinkOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import ItemChip from "../../components/ItemChip";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { usePermissions } from "../../hooks/usePermissions";
import ToolBarItems from "../../components/ToolBarItems.js";
import { useSnackbar } from "notistack";
import EvidenceAPI from "../../api/evidence/index.js";
import Tabs from "../../Monolith-UI/Tabs/Tabs";
import EvidenceNotes from "./sections/EvidenceNotes.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import LoggingAPI from "../../api/logging/index.js";

const BackLink = ({ message }) => {
  const theme = useTheme();

  return (
    <Link to={-1}>
      <TaskButton
        style={{ outline: `1px solid ${theme.palette.divider}` }}
        title={message}
      >
        <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
      </TaskButton>
    </Link>
  );
};

const EvidenceMeta = ({ evidenceInfo }) => {
  const theme = useTheme();

  return (
    <>
      <ItemChip
        text={evidenceInfo.evidence_number}
        Icon={PhonelinkOutlinedIcon}
        iconStyle={{ color: theme.palette.primary.main, fontSize: 12 }}
        title="Evidence Number"
      />
    </>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const { currentUser } = usePermissions();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    EvidenceAPI.deleteEvidence({
      evidence_id: defaultInfo.evidence_id,
    }).then(() => {
      LoggingAPI.createLog({
        case_id: parseInt(defaultInfo.case_id),
        user_id: currentUser.user_id,
        message: `Deleted evidence item: ${defaultInfo.evidence_number}`,
        event: "evidence:delete",
        object_type: "evidence",
        object_uuid: defaultInfo.uuid,
      });
      enqueueSnackbar(`Evidence Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 200px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Evidence Item
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this Evidence Item?
        </div>
        <div style={{ margin: "10px 0px" }}>
          "{defaultInfo.evidence_number}"
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Evidence"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

const EvidenceDetails = styled(({ className }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { uuid, active_tab } = useParams();
  const { currentUser } = useAuth();

  const {
    isLoading,
    data: evidenceInfo,
    isFetched,
  } = useQuery({
    queryKey: ["evidence:list", { uuid, include_children: true }],
    queryFn: () => getEvidence({ uuid, include_children: true }),
    select: (data) => data[0],
  });

  useEffect(() => {
    if (isFetched) {
      addRecentEvidence({
        evidence_id: evidenceInfo?.evidence_id,
        user_id: currentUser.user_id,
      });
    }
  }, [isFetched]);

  const handleEvidenceUpdate = (newValues) => {};

  const onDelete = async (values) => {
    navigate(-1);
  };

  if (isLoading) return <Loader />;

  return (
    <div className={className}>
      {evidenceInfo && (
        <>
          <Helmet>
            <title>Evidence Details | {evidenceInfo.evidence_number}</title>
          </Helmet>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "10px 5px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
              }}
            >
              <BackLink
                message={"Back to Evidence Items"}
                evidenceInfo={evidenceInfo}
              />
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Evidence Details
              </div>
            </div>
            <EvidenceMeta evidenceInfo={evidenceInfo} />
            {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_DELETE) && (
              <div
                className="action-menu-item"
                style={{ marginLeft: "auto" }}
                onClick={() => setShowDeleteModel(true)}
              >
                <DeleteOutlineOutlinedIcon
                  style={{ color: theme.palette.error.main }}
                />
                <div className="action-menu-label">Delete</div>
              </div>
            )}
          </div>
          <div style={{ marginTop: 0 }}>
            <Tabs value={active_tab}>
              <Tabs.List>
                <Link to={`/evidence/${evidenceInfo.uuid}/overview`}>
                  <Tabs.Tab value="overview" onClick={() => {}}>
                    Overview
                  </Tabs.Tab>
                </Link>
                <Link to={`/evidence/${evidenceInfo.uuid}/notes`}>
                  <Tabs.Tab value="notes" onClick={() => {}}>
                    Notes
                  </Tabs.Tab>
                </Link>
              </Tabs.List>
            </Tabs>
          </div>
          {active_tab === "overview" && (
            <EvidenceOverview
              evidenceInfo={evidenceInfo}
              handleEvidenceUpdate={handleEvidenceUpdate}
            />
          )}
          {active_tab === "notes" && (
            <EvidenceNotes evidenceInfo={evidenceInfo} />
          )}
          <DeleteModal
            open={showDeleteModel}
            handleClose={() => setShowDeleteModel(false)}
            onSubmit={() => onDelete()}
            defaultInfo={evidenceInfo}
          />
        </>
      )}
      {!isLoading && !evidenceInfo && (
        <>
          <Helmet>
            <title>Evidence Details | Access Denied</title>
          </Helmet>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
              padding: 15,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 5,
              width: "fit-content",
              margin: "auto",
            }}
          >
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              You do not have access to this evidence.
            </div>
            <div style={{ color: theme.palette.text.secondary, fontSize: 18 }}>
              Request access from the case lead or Monolith admin.
            </div>
            <Link to="/evidence">
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                size="small"
              >
                Return To Evidence List
              </Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding-right: 10px;

  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
`;

export default EvidenceDetails;
