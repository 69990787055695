import { Card, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getEvidenceReportMetrics } from "../../../../api";

const EvidenceReportMetrics = ({ reportInfo }) => {
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    getEvidenceReportMetrics({
      ...reportInfo.report_parameters,
      dimension: "stats",
    }).then((result) => {
      setMetrics(result);
    });
  }, []);

  return (
    <Card
      style={{
        padding: "20px",
        textAlign: "left",
        flexGrow: 1,
        flexBasis: "10px",
      }}
    >
      <div>
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Metrics
        </div>
        <Divider sx={{ my: 2 }} />
        <div
          style={{
            minWidth: "300px",
          }}
        >
          {metrics && (
            <>
              {typeof metrics !== "undefined" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Evidence Items:</div>
                    <div>{metrics?.evidence_count || 0}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Acquired Data:</div>
                    <div>
                      {metrics.acquired_data_gb
                        ? metrics.acquired_data_gb.toFixed(2) + " GB"
                        : "0.00 GB"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "5px",
                    }}
                  >
                    <div>Acquisition Rate:</div>
                    <div>
                      {metrics.avg_acq_rate
                        ? metrics.avg_acq_rate.toFixed(2) + " Days"
                        : "N/A"}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default EvidenceReportMetrics;
