// React & 3rd party libraries
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import styled from "styled-components";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { useDebounce, useDebouncedCallback } from "use-debounce";

// Icons
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SquareStackIcon } from "lucide-react";

// APIs, custom hooks, utilities
import AcquisitionsAPI from "../../../../api/acquisitions/index.js";
import {
  getContacts,
  getEvidence,
  getSoftwareNames,
  getStorage,
  userApi,
} from "../../../../api/index.js";
import { usePermissions } from "../../../../hooks/usePermissions";
import {
  getDateFormat,
  monolithMoment,
} from "../../../../utils/date-format.js";
import { useQueryFilter } from "../../../../Monolith-UI/QueryFilter/QueryFilter.js";
import synchronizeColumnState from "../../../../utils/synchronize-column-state.js";

// Custom components
import { ItemTotal } from "../../../Cases/CaseEvidence/index";
import DeleteAcquisitionPopup from "../../../Cases/CaseAcquisitions/components/DeleteAcquisitionPopup.js";
import EditAcquisitionPopup from "../../../Cases/CaseAcquisitions/components/EditAcquisitionPopup.js";
import AcqColumnDefs from "../../../../components/Acquisitions/AcqColumnDefs.js";
import Table, {
  Column,
  useTable,
} from "../../../../Monolith-UI/Table/Table.js";
import ComboButton from "../../../../Monolith-UI/ComboButton/ComboButton.js";
import Loader from "../../../../components/Loader.js";
import AcquisitionDimensions from "../../../../components/Acquisitions/AcquisitionDimensions.js";
import Flyout, { FlyoutHeader } from "../../../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../../../Monolith-UI/TaskButton.js";
import { addCustomFieldsToColumnDefs } from "../../../../components/Evidence/CustomFieldCell.js";
import { UserToolTip } from "../UserToolTip.js";
import CreateAcquisitionPopup from "../../../Cases/CaseAcquisitions/components/CreateAcquisitionPopup.js";
import { Input } from "@monolith-forensics/monolith-ui";

let columnDefs = AcqColumnDefs;

const userTooltipMessage = {
  header: "My Case Acquisitions",
  body: "Below are only the acquisitions from cases that are assigned to you and associated with this contact. There may be other acquisitions associated with this contact that are not visible here",
};

const adminTooltipMessage = {
  header: "All Case Acquisitions",
  body: "Below are all acquisitions from cases associated with this contact.",
};

const FlyoutContent = styled(
  ({
    className,
    defaultInfo,
    setFlyoutData,
    setShowCreatePopup,
    setShowEditPopup,
    setShowDeletePopup,
    reloadFn,
    columnDefs,
  }) => {
    const { hasPermission } = usePermissions();
    const theme = useTheme();
    const queryClient = useQueryClient();

    const { data: itemData } = useQuery({
      queryKey: ["acquisition", { acq_id: parseInt(defaultInfo.acq_id, 10) }],
      queryFn: () =>
        AcquisitionsAPI.getAcquisitions({
          acq_id: parseInt(defaultInfo.acq_id, 10),
        }),
      initialData: [defaultInfo],
      select: (data) => data[0],
    });

    const title = document.querySelector(".flyout-title");
    if (title) title.innerText = itemData.acq_name || defaultInfo.acq_name;

    const handleCopy = () => {
      setShowCreatePopup(true);
    };

    const handleEdit = () => {
      setShowEditPopup(true);
    };

    const handleDelete = () => {
      setShowDeletePopup(true);
    };

    const handleChange = (data) => {
      reloadFn();
      queryClient.setQueryData(
        ["acquisition", { acq_id: defaultInfo.acq_id }],
        (oldData) => {
          const dataObj = oldData[0] || {};
          return [{ ...dataObj, ...data }];
        }
      );
    };

    const dataFieldIgnoreList = ["acq_notes"];

    const data = {
      ...defaultInfo,
      ...(defaultInfo?.custom_attributes?.reduce((acc, attr) => {
        acc[`custom_field_${attr.field_id}`] = attr.value;
        return acc;
      }, {}) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
          .filter((c) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data, { onChange: handleChange })
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        {!!itemData.acq_name && (
          <>
            <div className="action-menu">
              {hasPermission() && (
                <>
                  <div className="action-menu-item" onClick={handleCopy}>
                    <ContentCopyIcon
                      style={{ color: theme.palette.primary.main }}
                    />
                    <div className="action-menu-label">Copy</div>
                  </div>
                  <div className="action-menu-item" onClick={handleEdit}>
                    <EditOutlinedIcon
                      style={{ color: theme.palette.primary.main }}
                    />
                    <div className="action-menu-label">Edit</div>
                  </div>
                  <div className="action-menu-item" onClick={handleDelete}>
                    <DeleteOutlineOutlinedIcon
                      style={{ color: theme.palette.error.main }}
                    />
                    <div className="action-menu-label">Delete</div>
                  </div>
                </>
              )}
            </div>
            {detail}
            <div className="detail-label" style={{ marginTop: 30 }}>
              Description
            </div>
            <div style={{ position: "relative" }}>
              <div className="item-description">{itemData.acq_notes}</div>
            </div>
          </>
        )}
        {!itemData.acq_name && <Loader message={"Loading..."} />}
      </div>
    );
  }
)`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    padding: 3px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

const AcquisitionsTable = styled(
  ({
    className,
    infoData,
    stateStoreKey = "acquisitions:contacts:list",
    client_id = null,
    contact_id = null,
    currentUser = { user_id: null },
    viewData = [
      { text: "My Acquisitions", value: "user-view" },
      { text: "All Acquisitions", value: "admin-view" },
    ],
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const queryFilter = useRef(null);

    const pageSize = 20;

    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);

    const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

    const table = useTable();

    // Determines scope of cases displayed in Client Table
    const [currentView, setCurrentView] = useState(() => {
      const savedViewState = JSON.parse(
        localStorage.getItem("contact:view:acq")
      );
      // If the user is an admin and has no view selected, default the view to "All evidence"
      if (hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_READ_ALL)) {
        if (!savedViewState) {
          localStorage.setItem("contact:view:acq", JSON.stringify(viewData[1]));
        }
        return savedViewState || viewData[1];
      } else {
        // For regular users, keep "My evidence" as the default view
        // Set to "My Acquisitions" or the default view for regular users
        localStorage.setItem("contact:view:acq", JSON.stringify(viewData[0]));
        return viewData[0];
      }
    });

    const userID =
      currentView.value === "user-view" ? currentUser.user_id : undefined;

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        columnDefs,
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const handleActionButtonClick = (rowData) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };
    const handleFilter = (newFilter) => {
      setQuery((q) => {
        return { query: { ...q?.query, ...newFilter } };
      });
    };
    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };
    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnResize = (e) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          const col = e.columns.find((col) => col.dataField === c.dataField);
          if (col) {
            return {
              ...c,
              width: parseInt(col.width.replace(/px/g, "")),
            };
          }
          return c;
        });
      });
    };
    const handleExportTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting evidence table...", {
        variant: "info",
      });

      AcquisitionsAPI.exportAcquisitionList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
          pageSize,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat(true, true),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        custom_date_format: getDateFormat(true, false),
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );

        el.download = filename;
        el.click();

        // remove element
        el.remove();

        // remove snackbar
      });
    };
    const handleGetNextItem = (acq_id) => {
      const current = records?.data?.findIndex((i) => i.acq_id === acq_id);
      const nextItem = records?.data[current + 1] || records?.data[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (acq_id) => {
      const current = records?.data?.findIndex((i) => i.acq_id === acq_id);
      const prevItem =
        records?.data[current - 1] || records?.data[records?.data.length - 1];

      setFlyoutData(prevItem);
    };
    const handleRefresh = () => {
      refetch();
    };
    const handleReload = () => {
      refetch();
    };
    const handleClearFilters = () => {
      queryFilter.current.clear();
    };
    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);
      setQuery(newQuery);
    };
    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };
    const onAcquisitionCreated = (data) => {
      queryClient.refetchQueries({
        queryKey: [
          "acquisitions:contacts:list",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
      });
    };

    const onAcquisitionEdited = (data) => {
      queryClient.refetchQueries({
        queryKey: [
          "acquisitions:contacts:list",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              contact_id,
            },
          },
        ],
      });

      queryClient
        .refetchQueries({
          queryKey: ["acquisition", { acq_id: parseInt(data.acq_id, 10) }],
        })
        .then((res) => {
          const acqData = queryClient.getQueryData([
            "acquisition",
            { acq_id: data.acq_id },
          ]);
          setFlyoutData(acqData[0]);
        });
    };

    const onAcquisitionDeleted = (data) => {
      setIsFlyoutVisible(false);
      setFlyoutData(null);
      queryClient.refetchQueries({
        queryKey: [
          "acquisitions:contacts:list",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
            },
          },
        ],
      });
    };

    const handleViewChange = (item) => {
      setCurrentView(item);
      localStorage.setItem("contact:view:acq", JSON.stringify(item));
    };

    const { data: customFields } = useQuery({
      queryKey: ["acquisitions", "customFields", "list"],
      queryFn: () => AcquisitionsAPI.getCustomAttributes({ fieldsOnly: true }),
    });

    let filterDimensions = AcquisitionDimensions;

    // update filter dimensions with custom fields
    filterDimensions = useMemo(() => {
      if (customFields) {
        //update filter dimensions
        return [
          ...AcquisitionDimensions,
          ...customFields.map((field) => ({
            name: field.field_name,
            field: `custom_field_${field.field_id}`,
            id: `custom_field_${field.field_id}`,
            type:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "string"
                : "text",
            mode:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "multi-select"
                : "text",
            options: field.options
              ? JSON.parse(field.options).map((o) => ({
                  option: o,
                }))
              : null,
            selectionDisplayName: field.options ? "option" : null,
            selectionIdField: field.options ? "option" : null,
          })),
        ];
      }
      return AcquisitionDimensions;
    }, [customFields]);

    const { queryButton, conditions, resolvedQuery } = useQueryFilter({
      dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    const [query, setQuery] = useState({
      query: {
        conditions: resolvedQuery?.conditions,
        case_id: infoData?.evidence_id ? null : infoData?.case_id,
        evidence_id: infoData?.evidence_id,
      },
    });

    const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          "acquisitions",
          "list",
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
              person_id: client_id,
              contact_id,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          AcquisitionsAPI.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              user_id: userID,
              person_id: client_id,
              contact_id,
              page: pageParam,
            },
          }),
        getNextPageParam: (lastPage, pages) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage, pages) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    useQueries({
      queries: [
        {
          queryKey: [
            "storage:list",
            {
              case_id: infoData.case_id,
              active_only: true,
              include_general_storage: true,
            },
          ],
          queryFn: () =>
            getStorage({
              case_id: infoData.case_id,
              active_only: true,
              include_general_storage: true,
            }),
          enabled: !!data,
        },
        {
          queryKey: ["acquisition:software:list"],
          queryFn: () => getSoftwareNames(),
          enabled: !!data,
        },
        {
          queryKey: ["acquisitions:formats:list"],
          queryFn: () => AcquisitionsAPI.getFormats(),
          enabled: !!data,
        },
        {
          queryKey: ["acquisitions:type:list"],
          queryFn: () => AcquisitionsAPI.getTypes(),
          enabled: !!data,
        },
        {
          queryKey: ["people:contacts", { case_id: infoData.case_id }],
          queryFn: () =>
            getContacts({
              case_id: infoData.case_id,
            }),
          enabled: !!data,
        },
        {
          queryKey: ["cases:leads"],
          queryFn: () =>
            userApi.getUsers({
              includeInactive: false,
              includeObservers: false,
            }),
          enabled: !!data,
        },
        !infoData.evidence_id && {
          queryKey: [
            "evidence:list",
            {
              case_id: infoData.case_id,
            },
          ],
          queryFn: () =>
            getEvidence({
              case_id: infoData.case_id,
            }),
          enabled: !!data,
        },
      ],
    });

    useEffect(() => {
      const acqID = searchParams.get("acq_id");

      if (acqID) {
        setFlyoutData({ acq_id: acqID });
        setIsFlyoutVisible(true);
      }
    }, [searchParams]);

    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    useEffect(() => {
      if (!customFields) return;
      // Add custom fields to column defs
      setColumnState((cs) => {
        return addCustomFieldsToColumnDefs(customFields, cs);
      });
    }, [customFields]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    return (
      <div className={className}>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          {hasPermission(MONOLITH_PERMISSIONS.CASES_READ_ALL) ? (
            <>
              <UserToolTip
                info={
                  currentView.value === "admin-view"
                    ? adminTooltipMessage
                    : userTooltipMessage
                }
              />
              <ComboButton
                type="dropDown"
                data={viewData}
                value={currentView}
                showDropdownIcon={true}
                displayField="text"
                variant="outlined"
                useSelectMode={true}
                onItemSelect={(e) => handleViewChange(e)}
                textColor={theme.palette.text.primary}
              />
            </>
          ) : (
            <UserToolTip info={userTooltipMessage} />
          )}
          <div>{queryButton}</div>
          <ItemTotal total={totalRecords} Icon={SquareStackIcon} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Refresh Table"}
              onClick={handleRefresh}
            >
              <SyncOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            {/* SEARCH */}
            <Input
              placeholder="Search Acquisitions"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e) => {
                const currentValue = e.target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records || []}
              totalRecords={totalRecords || 0}
              reloadFn={handleReload}
              tableInstance={table}
              keyValue="acq_id"
              columnProps={{ minWidth: 150, width: 150 }}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              onColumnResize={handleColumnResize}
              onActionButtonClick={handleActionButtonClick}
              showActionColumn={true}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
          </>
        )}
        {!data && <Loader message={"Retrieving Acquisitions..."} />}
        {isFlyoutVisible && (
          <Flyout
            onClose={() => {
              setIsFlyoutVisible(false);
              setFlyoutData(null);
            }}
          >
            <FlyoutHeader>
              <div style={{ width: "100%" }}>
                <h4
                  className="flyout-title"
                  style={{
                    margin: "5px 0px",
                    color: theme.palette.text.primary,
                  }}
                >
                  {flyoutData.acq_name || "..."}
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 12,
                    }}
                  >
                    Acquisition Details
                  </div>
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TaskButton
                      onClick={() => handleGetPrevItem(flyoutData.acq_id)}
                      style={{
                        fontSize: 10,
                      }}
                      variant="outlined"
                    >
                      <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
                    </TaskButton>
                    <TaskButton
                      onClick={() => handleGetNextItem(flyoutData.acq_id)}
                      style={{
                        fontSize: 10,
                      }}
                      variant="outlined"
                    >
                      <KeyboardArrowRightOutlinedIcon
                        style={{ fontSize: 17 }}
                      />
                    </TaskButton>
                  </div>
                </div>
              </div>
            </FlyoutHeader>
            <FlyoutContent
              defaultInfo={flyoutData}
              setShowCreatePopup={setShowCreatePopup}
              setShowEditPopup={setShowEditPopup}
              setShowDeletePopup={setShowDeletePopup}
              setIsFlyoutVisible={setIsFlyoutVisible}
              setFlyoutData={setFlyoutData}
              reloadFn={handleReload}
              columnDefs={columnState}
            />
          </Flyout>
        )}
        <CreateAcquisitionPopup
          caseInfo={
            infoData?.evidence_id ? { case_id: infoData?.case_id } : infoData
          }
          evidenceInfo={infoData?.evidence_id ? infoData : null}
          onSubmit={onAcquisitionCreated}
          open={showCreatePopup}
          handleClose={() => setShowCreatePopup(false)}
          flyoutData={flyoutData}
        />
        <DeleteAcquisitionPopup
          onDelete={onAcquisitionDeleted}
          open={showDeletePopup}
          handleClose={() => setShowDeletePopup(false)}
          details={flyoutData}
        />
        <EditAcquisitionPopup
          caseInfo={
            infoData?.evidence_id ? { case_id: infoData.case_id } : infoData
          }
          onEdit={onAcquisitionEdited}
          open={showEditPopup}
          handleClose={() => setShowEditPopup(false)}
          defaultInfo={flyoutData}
        />
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export default AcquisitionsTable;
