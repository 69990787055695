import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";

import { ChevronRightIcon, Check } from "lucide-react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { useState } from "react";
import Loader from "../../components/Loader.js";
import { useQuery } from "@tanstack/react-query";
import { StyledInput } from "../Input.js";

const StyledButton = styled.button`
  font-size: 0.75rem;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  height: max-content;
  padding: 3px 5px;
  color: ${({ theme, textColor }) =>
    textColor === "secondary"
      ? theme.palette.text.secondary
      : theme.palette.text.primary};
  transition: all 0.15s ease-in-out;
  border: 1px solid
    ${(props) => {
      if (props.error) return props.theme.palette.error.main;
      if (props.variant === "outlined") return props.theme.palette.divider;
      if (props.variant === "text") return "transparent";
      if (props.variant === "contained") return "transparent";
      return "transparent";
    }};
  background-color: ${(props) => {
    if (props.variant === "contained")
      return props.theme.palette.background.secondary;
    if (props.variant === "outlined") return "transparent";
    if (props.variant === "text") return "transparent";
    return "transparent";
  }};
  ${(props) =>
    props.enabled === undefined || props.enabled
      ? `&:hover {
    border: 1px solid ${props.theme.palette.primary.main};
  }`
      : ""}
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledMenuContent = styled(RadixDropdownMenu.Content)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: fit-content;
  max-height: 300px;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  overflow: hidden;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.85),
    0px 10px 20px -15px rgba(22, 23, 24, 0.65);

  z-index: 100000;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }
  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const StyledSubMenuContent = styled(RadixDropdownMenu.SubContent)`
  min-width: 175px;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 100000;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: slideDownAndFade;
  }
  &[data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  &[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  &[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const StyledMenuItem = styled(RadixDropdownMenu.Item)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledSubTrigger = styled(RadixDropdownMenu.SubTrigger)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  position: relative;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }

  .RightSlot {
    margin-left: auto;
    padding-left: 20px;
    color: ${(props) => props.theme.palette.text.primary};
  }
  [data-highlighted] > .RightSlot {
    color: ${(props) => props.theme.palette.text.primary};
  }
  [data-disabled] .RightSlot {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

const StyledSeparator = styled(RadixDropdownMenu.Separator)`
  height: 2px;
  background-color: ${(props) => props.theme.palette.divider};
  margin: 5px;
`;

const StyledLabel = styled(RadixDropdownMenu.Label)`
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.secondary};
  padding: 5px;
`;

const StyledRadioItem = styled(RadixDropdownMenu.RadioItem)`
  position: relative;
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  padding-right: 25px;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledCheckboxItem = styled(RadixDropdownMenu.CheckboxItem)`
  position: relative;
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 25px;
  padding: 0 5px;
  padding-right: 25px;
  user-select: none;
  outline: none;

  &[data-disabled] {
    color: ${(props) => props.theme.palette.text.secondary};
    pointer-events: "none";
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.palette.action.hover};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledItemIndicator = styled(RadixDropdownMenu.ItemIndicator)`
  position: absolute;
  right: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const EmptyPlaceholder = styled.div`
  user-select: none;
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 0.75rem;
  font-weight: 600;
  padding: 5px;
  text-align: center;
`;

const Label = ({ item, placeholder }) => {
  if (item.component) {
    const Component = item.component;
    return <Component data={item} />;
  }
  if (item.render) {
    return item.render(item);
  }
  return <>{item?.label || placeholder}</>;
};

const CheckboxGroup = ({ items = [], value, onSelect, queryKey = [] }) => {
  const [selected, setSelected] = useState(value || []);

  const { data: asyncItems, isFetched } = useQuery({
    queryKey,
    queryFn: items,
    enabled: items instanceof Function,
  });

  // Update local state when selection changes
  const handleSelect = (item) => {
    setSelected((prev) => {
      let newSelected = null;
      if (prev.includes(item.value)) {
        newSelected = prev.filter((v) => v !== item.value);
      } else {
        newSelected = [...prev, item.value];
      }

      onSelect?.(items.filter((i) => newSelected.includes(i.value)));
      return newSelected;
    });
  };

  if (items instanceof Function && !isFetched)
    return <Loader iconProps={{ size: 25 }} />;

  const data = asyncItems?.length > 0 ? asyncItems : items;

  return (
    <>
      {data?.map((checkboxItem) => {
        const Icon = checkboxItem?.icon;
        return (
          <StyledCheckboxItem
            key={checkboxItem?.value}
            checked={selected.includes(checkboxItem?.value)}
            onSelect={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSelect(checkboxItem);
            }}
          >
            {checkboxItem?.icon && (
              <Icon
                color={checkboxItem?.iconColor}
                size={14}
                style={{ marginRight: 10 }}
              />
            )}
            <Label item={checkboxItem} placeholder={"Menu Item"} />
            <StyledItemIndicator>
              <Check size={14} />
            </StyledItemIndicator>
          </StyledCheckboxItem>
        );
      })}
    </>
  );
};

const RadioGroup = ({ items = [], value, onSelect }) => {
  return (
    <RadixDropdownMenu.RadioGroup value={value}>
      {items.map((radioItem, idx) => {
        const Icon = radioItem?.icon;
        if (radioItem.separator) {
          return <StyledSeparator key={idx} />;
        }
        return (
          <StyledRadioItem
            key={idx}
            value={radioItem?.value}
            onSelect={(e) => {
              e.stopPropagation();
              if (radioItem.closeOnSelect) e.preventDefault();
              if (radioItem?.value === value) onSelect?.(null);
              else onSelect?.(radioItem);
            }}
          >
            {radioItem?.icon && (
              <Icon
                color={radioItem?.iconColor}
                size={14}
                style={{ marginRight: 10 }}
              />
            )}
            <Label item={radioItem} placeholder={"Menu Item"} />
            <StyledItemIndicator>
              <Check size={14} />
            </StyledItemIndicator>
          </StyledRadioItem>
        );
      })}
    </RadixDropdownMenu.RadioGroup>
  );
};

const RenderItems = ({ items = [], emptyPlaceholder, onChange }) => {
  const handleSelect = (item) => {
    item?.onClick?.(item);
    onChange?.(item?.value, item);
  };

  return (
    <>
      {items.length === 0 && (
        <EmptyPlaceholder>{emptyPlaceholder}</EmptyPlaceholder>
      )}
      {items.map((item, idx) => {
        const Icon = item?.icon;

        if (item?.content) {
          return (
            <RadixDropdownMenu.Sub key={idx}>
              <StyledSubTrigger>
                {item?.icon && (
                  <Icon
                    color={item?.iconColor}
                    size={14}
                    style={{ marginRight: 10 }}
                  />
                )}
                <Label item={item} placeholder={"Menu Item"} />
                <div className="RightSlot">
                  <ChevronRightIcon size={16} />
                </div>
              </StyledSubTrigger>
              <RadixDropdownMenu.Portal>
                <StyledSubMenuContent sideOffset={2} alignOffset={-5}>
                  {item?.content}
                </StyledSubMenuContent>
              </RadixDropdownMenu.Portal>
            </RadixDropdownMenu.Sub>
          );
        }
        if (item.separator) {
          return <StyledSeparator key={idx} />;
        }
        if (item.header) {
          return (
            <StyledLabel key={idx}>
              {item?.label || "Section Header"}
            </StyledLabel>
          );
        }
        if (item.checkboxGroup) {
          if (item?.items?.length === 0)
            return <EmptyPlaceholder>{emptyPlaceholder}</EmptyPlaceholder>;
          return (
            <CheckboxGroup
              key={idx}
              items={item?.items}
              value={item?.value}
              onSelect={(data) => item?.onSelectionChanged?.(data)}
            />
          );
        }
        if (item.radioGroup) {
          if (item?.items?.length === 0)
            return <EmptyPlaceholder>{emptyPlaceholder}</EmptyPlaceholder>;
          return (
            <RadioGroup
              key={idx}
              items={item?.items}
              value={item?.value}
              onSelect={(data) => {
                item?.onSelectionChanged?.(data);
                data?.onClick?.(data);
              }}
            />
          );
        }
        if (item.items) {
          return (
            <RadixDropdownMenu.Sub key={idx}>
              <StyledSubTrigger>
                {item?.icon && (
                  <Icon
                    color={item?.iconColor}
                    size={14}
                    style={{ marginRight: 10 }}
                  />
                )}
                <Label item={item} placeholder={"Menu Item"} />
                <div className="RightSlot">
                  <ChevronRightIcon size={16} />
                </div>
              </StyledSubTrigger>
              <RadixDropdownMenu.Portal>
                <StyledSubMenuContent sideOffset={2} alignOffset={-5}>
                  {item?.items?.length === 0 ? (
                    <EmptyPlaceholder>{emptyPlaceholder}</EmptyPlaceholder>
                  ) : (
                    <RenderItems
                      items={item?.items}
                      handleSelect={handleSelect}
                      emptyPlaceholder={emptyPlaceholder}
                    />
                  )}
                </StyledSubMenuContent>
              </RadixDropdownMenu.Portal>
            </RadixDropdownMenu.Sub>
          );
        }
        return (
          <StyledMenuItem
            key={idx}
            onSelect={(e) => {
              e.stopPropagation();
              handleSelect(item);
            }}
            {...(item?.menuItemProps || {})}
          >
            {item?.icon && (
              <Icon
                color={item?.iconColor}
                size={14}
                style={{ marginRight: 7 }}
              />
            )}
            <Label item={item} placeholder={"Menu Item"} />
          </StyledMenuItem>
        );
      })}
    </>
  );
};

const DropdownMenu = ({
  children,
  menuItems,
  defaultValue,
  arrow = false,
  variant = "outlined",
  emptyPlaceholder = "No Items",
  textColor = "primary",
  style = {},
  buttonProps = {},
  contentProps = {},
  dropdownContent,
  disabled = false,
  onScrollEnd = () => {},
  onOpen = () => {},
  onChange = () => {},
  loading = false,
  searchEnabled = false,
  onSearch = () => {},
  error = false,
}) => {
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 100 * menuItems?.length) {
      onScrollEnd();
    }
  };

  return (
    <RadixDropdownMenu.Root
      onOpenChange={(open) => {
        if (open) onOpen();
      }}
    >
      <RadixDropdownMenu.Trigger asChild disabled={disabled}>
        <StyledButton
          variant={variant}
          textColor={textColor}
          {...buttonProps}
          error={error}
        >
          {defaultValue || children || "Menu"}
          {arrow && (
            <ArrowDropDownOutlinedIcon
              style={{ fontSize: 16, marginLeft: "auto", width: 20 }}
            />
          )}
        </StyledButton>
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal>
        <StyledMenuContent
          sideOffset={2}
          align="start"
          style={style}
          onClick={(e) => e.stopPropagation()}
          collisionPadding={20}
          {...contentProps}
        >
          {loading ? (
            <Loader iconProps={{ size: 25 }} />
          ) : dropdownContent ? (
            dropdownContent
          ) : (
            <>
              {searchEnabled && (
                <StyledInput
                  placeholder="Search"
                  variant="contained"
                  style={{ marginBottom: 5, marginTop: 5 }}
                  onChange={(e) => onSearch(e.target.value)}
                />
              )}
              <div
                style={{ height: "100%", overflowY: "auto" }}
                onScroll={handleScroll}
              >
                <RenderItems
                  items={menuItems}
                  emptyPlaceholder={emptyPlaceholder}
                  onChange={onChange}
                />
              </div>
            </>
          )}
        </StyledMenuContent>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

export const MenuItem = StyledMenuItem;

export default DropdownMenu;
