import { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useRef } from "react";
import { useQueries } from "@tanstack/react-query";
import CaseStorageAPI from "../../../api/storage/index.js";
import { Modal } from "@mui/material";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../utils/date-format.js";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader.js";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import LocationSelectBoxItem from "../../../components/SelectBoxItems/LocationSelectBoxItem.js";
import { Template } from "devextreme-react/core/template.js";
import LocationsAPI from "../../../api/locations/index.js";
import DataSource from "devextreme/data/data_source";
import { getIpAddress } from "../../../api/index.js";
import ToolBarItems from "../../../components/ToolBarItems.js";
import SignatureField from "../../../components/SignatureField/SignatureField.js";
import moment from "moment";

export const MoveItemsPopup = ({
  caseInfo,
  onSubmit = () => {},
  open,
  handleClose,
  table,
  query,
}) => {
  const form = useRef(null);
  const receivedFromSig = useRef(null);
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: ["evidence:locations", { include_groups: false }],
        queryFn: (params) =>
          LocationsAPI.getLocations({}, { include_groups: false }),
        enabled: open,
      },
      {
        queryKey: ["storage:move-reasons"],
        queryFn: () => CaseStorageAPI.getStorageCocMoveReasons(),
        enabled: open,
      },
    ],
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      const moveInfo = {
        total: table?.getVirtualSelectionTotal() || 0,
        caseInfo: {
          case_id: caseInfo.case_id,
          case_number: caseInfo.case_number,
          case_ref: caseInfo.case_ref,
        },
        options: {
          selectionState: table?.getSelectionState() || null,
          included:
            table
              ?.getSelectedRows()
              .filter((r) => r.location_id !== 1) //Ignore items that are released
              .map((r) => r.uuid) || [],
          excluded: table?.getExcludedRows().map((r) => r.uuid) || [],
          ...query,
        },
      };

      const formData = {
        new_location_id: data.new_location.location_id,
        new_location_name: data.new_location.location_name,
        reason: data.reason,
        timestamp: db_timestamp(data.timestamp, true),
        entered_by: currentUser.full_name,
        entered_by_id: currentUser.user_id,
        coc_type: "Move",
      };

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: currentUser.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      formData.signature = JSON.stringify(signature);

      if (moveInfo.total > 0) {
        CaseStorageAPI.moveStorageItems({
          formData,
          options: moveInfo.options,
          caseInfo: moveInfo.caseInfo,
          total: moveInfo.total,
        }).then((res) => {
          onSubmit();
          enqueueSnackbar(
            `Storage Items Moved to ${data.new_location.location_name}`,
            {
              variant: "success",
            }
          );
        });
      } else {
        enqueueSnackbar("No storage items selected.", {
          variant: "warning",
        });
      }

      handleClose();
    }
  };

  // check if the query is loading
  const isDone = result.every((item) => item.isSuccess);

  const fieldData = {
    locations: result[0].data,
    reasons: result[1].data,
  };
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Move Storage Items
        </div>
        {!isDone && <Loader />}
        {isDone && caseInfo.case_id ? (
          <>
            <Form ref={form} colCount={1}>
              <SimpleItem
                dataField="new_location"
                label={{ text: "New Location" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  hint: "Select the new location for this storage item.",
                  searchEnabled: true,
                  dataSource: new DataSource({
                    store: fieldData.locations,
                    key: "location_id",
                    group: "office_name",
                  }),
                  displayExpr: "location_name",
                  grouped: true,
                  dropDownOptions: {
                    height: "350px",
                  },
                  itemTemplate: "new_location",
                }}
              />
              <Template
                name="new_location"
                render={(data) => <LocationSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Move Reason" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  dataSource: fieldData.reasons,
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  placeholder: "Select or add new...",
                  searchEnabled: true,
                  searchExpr: "reason",
                  displayExpr: "reason",
                  valueExpr: "reason",
                  acceptCustomValue: true,
                  onCustomItemCreating: (e) => {
                    e.customItem = { reason: e.text };
                    // Adds the entry to the data source
                    e.component.getDataSource().store().insert(e.customItem);
                    // Reloads the data source
                    e.component.getDataSource().reload();
                  },
                }}
              />
              <SimpleItem
                dataField="timestamp"
                label={{ text: "Timestamp" }}
                editorType="dxDateBox"
                isRequired={true}
                editorOptions={{
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  value: new Date(),
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              />
              <SimpleItem
                dataField="custody_from_sig"
                label={{ text: "Moved By Signature" }}
              >
                <SignatureField
                  sigFieldRef={receivedFromSig}
                  title="Moved By Signature"
                />
              </SimpleItem>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: 5,
                marginTop: 20,
              }}
            >
              <ToolBarItems
                onSubmit={handleSubmit}
                onCancel={handleClose}
                submitText="Move Items"
              />
            </div>
          </>
        ) : (
          <>
            <Form ref={form} colCount={1}>
              <SimpleItem
                dataField="new_location"
                label={{ text: "New Location" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  hint: "Select the new location for this storage item.",
                  searchEnabled: true,
                  dataSource: new DataSource({
                    store: fieldData.locations,
                    key: "location_id",
                    group: "office_name",
                  }),
                  displayExpr: "location_name",
                  grouped: true,
                  dropDownOptions: {
                    height: "350px",
                  },
                  itemTemplate: "new_location",
                }}
              />
              <Template
                name="new_location"
                render={(data) => <LocationSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Move Reason" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  dataSource: fieldData.reasons,
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  placeholder: "Select or add new...",
                  searchEnabled: true,
                  searchExpr: "reason",
                  displayExpr: "reason",
                  valueExpr: "reason",
                  acceptCustomValue: true,
                  onCustomItemCreating: (e) => {
                    e.customItem = { reason: e.text };
                    // Adds the entry to the data source
                    e.component.getDataSource().store().insert(e.customItem);
                    // Reloads the data source
                    e.component.getDataSource().reload();
                  },
                }}
              />
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: 5,
                marginTop: 20,
              }}
            >
              <ToolBarItems
                onSubmit={handleSubmit}
                onCancel={handleClose}
                submitText="Move Items"
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
