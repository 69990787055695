import { Box, Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useEffect, useRef, useState } from "react";
import {
  createCaseStatus,
  deleteCaseStatus,
  getCaseStatuses,
  updateCaseStatus,
} from "../../api";
import Loader from "../../components/Loader";
import ToolBarItems from "../../components/ToolBarItems";
import { usePermissions } from "../../hooks/usePermissions";
import styled from "styled-components";

const CaseStatussList = styled(
  ({
    className,
    caseStatuses,
    setCaseStatuses,
    setDeleteSelection,
    deleteCaseStatusPopup,
  }) => {
    useEffect(() => {
      getCaseStatuses().then((result) => {
        setCaseStatuses(result);
      });
    }, []);

    return (
      <div className={className}>
        {!caseStatuses && <Loader />}
        {caseStatuses &&
          caseStatuses.map((status) => {
            return (
              <CaseStatusItem
                key={status.case_status_id}
                data={status}
                caseStatuses={caseStatuses}
                setCaseStatuses={setCaseStatuses}
                setDeleteSelection={setDeleteSelection}
                deleteCaseStatusPopup={deleteCaseStatusPopup}
                allowEdit={
                  status.status_name !== "Active" &&
                  status.status_name !== "Closed"
                }
              />
            );
          })}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const CaseStatusItem = ({
  data,
  caseStatuses,
  setCaseStatuses,
  setDeleteSelection,
  deleteCaseStatusPopup,
  allowEdit,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const handleStatusUpdate = (event) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.status_name;
      return;
    }
    if (event.currentTarget.innerText !== data.status_name) {
      updateCaseStatus(data.case_status_id, {
        status_name: event.currentTarget.innerText,
      });
      setCaseStatuses(
        caseStatuses.map((status) => {
          if (status.case_status_id === data.case_status_id) {
            status.status_name = event.currentTarget.innerText;
          }
          return status;
        })
      );
    }
  };

  const handleDelete = (event) => {
    setDeleteSelection(data);
    deleteCaseStatusPopup.current.instance.show();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 10px",
        cursor: "pointer",
        "&:hover": { backgroundColor: theme.palette.action.hover },
      }}
    >
      <div>
        <Box
          contentEditable={allowEdit}
          suppressContentEditableWarning
          sx={{
            fontSize: "larger",
            padding: "2px",
            cursor: "text",
            "&:hover": { outline: allowEdit ? `1px solid slategray` : "none" },
            "&:focus": { outline: allowEdit ? `1px solid slategray` : "none" },
          }}
          onBlur={handleStatusUpdate}
        >
          {data.status_name}
        </Box>
        <div style={{ color: "slategrey", padding: "2px" }}>
          {data.total} Cases
        </div>
      </div>
      {allowEdit && (
        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="text"
            color="error"
            size="small"
            disabled={!hasPermission()}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      )}
    </Box>
  );
};

const CreateCaseStatusPopup = ({
  createCaseStatusPopup,
  caseStatuses,
  setCaseStatuses,
}) => {
  const form = useRef(null);

  return (
    <Popup
      ref={createCaseStatusPopup}
      showTitle={true}
      showCloseButton={true}
      title="Create New Case Status"
      defaultHeight={185}
      defaultWidth={400}
      onHidden={(e) => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form}>
        <SimpleItem
          dataField="status_name"
          label={{ text: "Case Status" }}
          isRequired={true}
        ></SimpleItem>
      </Form>
      <ToolBarItems
        submitText="Create Case Status"
        onSubmit={() => {
          if (form.current.instance.validate().isValid) {
            let values = Object.assign(
              {},
              form.current.instance.option("formData")
            );

            createCaseStatus(values).then((result) => {
              setCaseStatuses([
                ...caseStatuses,
                {
                  case_status_id: result.case_status_id,
                  status_name: values.status_name,
                  total: 0,
                },
              ]);
            });

            createCaseStatusPopup.current.instance.hide();
          }
        }}
        onCancel={() => {
          createCaseStatusPopup.current.instance.hide().then(() => {
            form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const DeleteCaseStatusPopup = ({
  deleteCaseStatusPopup,
  caseStatuses,
  setCaseStatuses,
  deleteSelection,
}) => {
  const [items, setItems] = useState(caseStatuses);
  const form = useRef(null);

  useEffect(() => {
    deleteSelection &&
      setItems(
        caseStatuses.filter(
          (item) => item.case_status_id !== deleteSelection.case_status_id
        )
      );
  }, [deleteSelection]);

  return (
    <Popup
      ref={deleteCaseStatusPopup}
      showTitle={true}
      showCloseButton={true}
      title={`Delete Case Status ${deleteSelection?.status_name || ""}`}
      defaultHeight={250}
      defaultWidth={450}
      onHidden={(e) => {
        if (form.current) form.current.instance.resetValues();
      }}
    >
      {deleteSelection?.total === 0 && (
        <div style={{ margin: "15px 0px", minHeight: 115 }}>
          Are you sure you want to delete this case status?
        </div>
      )}
      {deleteSelection?.total > 0 && (
        <div style={{ minHeight: 115 }}>
          <div style={{ margin: "15px 0px" }}>
            Select the case status that should replace this one when deleted:
          </div>
          <Form ref={form}>
            <SimpleItem
              dataField="status_name"
              label={{ text: "Case Status" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: items,
                displayExpr: "status_name",
              }}
            ></SimpleItem>
          </Form>
        </div>
      )}
      <ToolBarItems
        submitText="Delete Case Status"
        submitColor="error"
        onSubmit={() => {
          if (form.current) {
            if (form.current.instance.validate().isValid) {
              let values = Object.assign(
                {},
                form.current.instance.option("formData")
              );
              deleteCaseStatus(deleteSelection.case_status_id, {
                replace: values.status_name.status_name,
              });
              setCaseStatuses(
                items.map((item) => {
                  if (
                    item.case_status_id === values.status_name.case_status_id
                  ) {
                    item.total += deleteSelection.total;
                  }
                  return item;
                })
              );
              deleteCaseStatusPopup.current.instance.hide();
            }
          } else {
            deleteCaseStatusPopup.current.instance.hide();
            deleteCaseStatus(deleteSelection.case_status_id);
            setCaseStatuses(items);
          }
        }}
        onCancel={() => {
          deleteCaseStatusPopup.current.instance.hide().then(() => {
            if (form.current) form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const CaseStatusSettings = styled(({ className }) => {
  const [caseStatuses, setCaseStatuses] = useState(null);
  const [deleteSelection, setDeleteSelection] = useState(null);
  const createCaseStatusPopup = useRef(null);
  const deleteCaseStatusPopup = useRef(null);

  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      <div>
        Create, delete, and edit case statuses. Create case statuses that will
        help identify the current state of a case. The "Active" and "Closed"
        options are permanent and cannot be changed.
      </div>
      <div style={{ margin: "25px 0px" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            createCaseStatusPopup.current.instance.show();
          }}
        >
          + Create Case Status
        </Button>
      </div>
      <CaseStatussList
        caseStatuses={caseStatuses}
        setCaseStatuses={setCaseStatuses}
        setDeleteSelection={setDeleteSelection}
        deleteCaseStatusPopup={deleteCaseStatusPopup}
      />
      <CreateCaseStatusPopup
        createCaseStatusPopup={createCaseStatusPopup}
        caseStatuses={caseStatuses}
        setCaseStatuses={setCaseStatuses}
      />
      {caseStatuses && (
        <DeleteCaseStatusPopup
          deleteCaseStatusPopup={deleteCaseStatusPopup}
          caseStatuses={caseStatuses}
          setCaseStatuses={setCaseStatuses}
          deleteSelection={deleteSelection}
          setDeleteSelection={setDeleteSelection}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default CaseStatusSettings;
