import { useQueries } from "@tanstack/react-query";
import { z } from "zod";
import { useForm } from "@mantine/form";

import {
  FormSection,
  Grid,
  SelectBox,
  TextInput,
  Button,
  DateInput,
  TextAreaInput,
} from "@monolith-forensics/monolith-ui";
import ClientListItem from "./components/ClientListItem.js";
import UserListItem from "./components/UserListItem.js";
import ButtonMenu from "./components/ButtonMenu.js";

import ClientsAPI from "../../api/clients/index.js";
import CasesApi from "../../api/cases/index.js";
import UserApi from "../../api/users/users.js";

import { getDateFormat } from "../../utils/date-format.js";
import resolveCustomFieldValidations from "./utils/resolveCustomFieldValidations.js";
import convertCustomFieldsToArray from "./utils/convertCustomFieldsToArray.js";
import validateAsync from "./utils/zod/validateAsync.js";
import CustomFieldRender from "./components/CustomFieldRender.js";
import diffFormData from "./utils/diffFormData.js";

const DefaultCaseForm = ({
  defaultFormData = {},
  buttonProps = {},
  onSubmit,
  onCancel,
}) => {
  const results = useQueries({
    queries: [
      {
        queryKey: ["clients", "list"],
        queryFn: () => ClientsAPI.getClients(),
      },
      {
        queryKey: ["case-types", "list"],
        queryFn: () => CasesApi.getCaseTypes(),
      },
      {
        queryKey: ["case-status", "list"],
        queryFn: () => CasesApi.getCaseStatuses(),
      },
      {
        queryKey: [
          "users",
          "list",
          { include_observers: false, include_inactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({
            include_observers: false,
            include_inactive: false,
          }),
      },
      {
        queryKey: ["cases", "custom-fields", "list", { fieldsOnly: true }],
        queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
      },
    ],
  });

  const clients = results[0].data || [];
  const caseTypes = results[1].data || [];
  const caseStatuses = results[2].data || [];
  const caseLeads = results[3].data || [];
  const customFields = results?.[4]?.data || []; // filter out disabled fields

  // Validation schema
  const schema = z.object({
    case_number: z
      .string()
      .regex(/^[^<>:"/\\|?*]+$/, 'Case number cannot contain: <>:"/\\|?*')
      // async validation
      .refine(
        async (value) => {
          if (!value) return true;
          const caseNumbers = await CasesApi.getCaseNumbers({
            case_number: value,
          });
          return caseNumbers.length === 0;
        },
        { message: "Case number already exists" }
      )
      .optional(),
    case_name: z.string().min(1),
    case_open_date: z.string().date().optional(),
    ...resolveCustomFieldValidations(customFields),
  });

  const form = useForm({
    mode: "uncontrolled",
    initialValues: defaultFormData,
  });

  const modelFormData = (formData) => {
    const modeledFormData = {
      case_number: formData.case_number || null,
      case_name: formData.case_name || null,
      client_id: formData?.client_id || null,
      case_type: formData?.case_type || null,
      case_status: formData?.case_status || null,
      case_lead_id: formData?.case_lead_id || null,
      case_open_date: formData.case_open_date || null,
      case_synopsis: formData.case_synopsis || null,
      custom_attributes: convertCustomFieldsToArray(customFields, formData),
    };

    return modeledFormData;
  };

  const handleSubmit = async () => {
    const submitData = form.getValues();
    const validationResult = await validateAsync(schema)(submitData);

    if (validationResult.hasErrors) {
      console.error("Validation Errors", validationResult.errors);
      form.setErrors(validationResult.errors);
      return;
    }

    const modeledFormData = modelFormData(submitData);
    const modeledDiffData = diffFormData(defaultFormData, submitData);

    // convert any custom fields in diff to an array
    if (
      Object.keys(modeledDiffData).some((key) =>
        key.startsWith("custom_attribute_")
      )
    ) {
      modeledDiffData.custom_attributes = convertCustomFieldsToArray(
        customFields,
        modeledDiffData
      );

      // remove custom fields from diff
      Object.keys(modeledDiffData).forEach((key) => {
        if (key.startsWith("custom_attribute_")) {
          delete modeledDiffData[key];
        }
      });
    }

    // call onSubmit with modeled data and diff data
    // diff data is used when the form is in edit context
    // diff may be used when we only want to update modified values
    onSubmit?.(modeledFormData, modeledDiffData);
  };

  const handleCancel = () => {
    form.reset();
    onCancel?.();
  };

  return (
    <>
      <FormSection title="Basic Information">
        <Grid col={2}>
          <TextInput
            size="sm"
            variant="outlined"
            label="Case Number"
            placeholder="Leave blank for auto-generation"
            description={
              "This is the unique identifier for the case - Monolith will ensure that a globally unique value is used.\n\nIf left blank, a unique identifier will be generated automatically."
            }
            key={form.key("case_number")}
            {...form.getInputProps("case_number")}
          />
          <TextInput
            size="sm"
            variant="outlined"
            label="Case Name"
            required
            placeholder="Provide a short and memorable name"
            description="This is the name of the case that will be displayed in the case list. Great case names are short and memorable."
            key={form.key("case_name")}
            {...form.getInputProps("case_name")}
          />
          <SelectBox
            size="sm"
            variant="outlined"
            label="Client"
            description={
              "This is the person that the case is being conducted for.  Typically, this person requested the work or will receive your case results."
            }
            searchable
            clearable
            data={clients?.map((client) => ({
              label: client.name,
              value: client.client_id,
              data: client,
            }))}
            key={form.key("client_id")}
            {...form.getInputProps("client_id")}
            renderOption={(option) => {
              return <ClientListItem client={option.data} />;
            }}
            actionComponent={
              <Button
                size="xs"
                variant="subtle"
                color="primary"
                fullWidth
                style={{ marginBottom: 5 }}
              >
                + New Client
              </Button>
            }
          />
          <SelectBox
            size="sm"
            variant="outlined"
            label="Case Type"
            description={
              "This is the type of case that you are working on.  This is used to categorize your case and can be used to filter your case list.\n\nThis list can also be fully customized in the Monolith settings menu."
            }
            clearable
            searchable
            data={caseTypes?.map((caseType) => ({
              label: caseType.case_type,
              value: caseType.case_type,
              data: caseType,
            }))}
            key={form.key("case_type")}
            {...form.getInputProps("case_type")}
          />
          <SelectBox
            size="sm"
            variant="outlined"
            label="Case Status"
            description={
              "This is the current status of the case.  This is used to track the progress of the case and can be used to filter your case list.\n\nThis list can be customized in the Monolith settings menu."
            }
            clearable
            searchable
            data={caseStatuses?.map((caseStatus) => ({
              label: caseStatus.status_name,
              value: caseStatus.status_name,
              data: caseStatus,
            }))}
            key={form.key("case_status")}
            {...form.getInputProps("case_status")}
          />
          <SelectBox
            size="sm"
            variant="outlined"
            label="Case Lead"
            description={
              "This is the person that is responsible for the case.  This person will be the primary examiner/analyst and will be responsible for the case from start to finish.\n\nThis list is populated with all active users that are not observers."
            }
            clearable
            searchable
            data={caseLeads?.map((user) => ({
              label: `${user.first_name} ${user.last_name}`,
              value: user.user_id,
              data: user,
            }))}
            key={form.key("case_lead_id")}
            {...form.getInputProps("case_lead_id")}
            renderOption={(option) => {
              return <UserListItem caseLead={option.data} />;
            }}
          />
          <DateInput
            label="Case Open Date"
            description="This is the date that the case was opened.  This is typically the date that the case was created in Monolith, but can also have a date prior to that."
            variant="outlined"
            arrow
            size="sm"
            clearable
            max={new Date()}
            enableCalendar={true}
            defaultValue={new Date()}
            format={getDateFormat(true, false)}
            key={form.key("case_open_date")}
            {...form.getInputProps("case_open_date")}
          />
          <TextAreaInput
            size="sm"
            variant="outlined"
            label="Description"
            description="This is a basic description of the case.  This can include information about the case, the subject, evidence, and any other relevant information."
            placeholder="Enter multi-line text..."
            colSpan={2}
            minRows={6}
            maxRows={24}
            key={form.key("case_synopsis")}
            {...form.getInputProps("case_synopsis")}
          />
        </Grid>
      </FormSection>
      <FormSection title="Custom Fields" defaultOpen={false}>
        <Grid col={2}>
          {customFields
            ?.filter((c) => c.enabled === 1) // filter out disabled fields
            ?.map((field) => (
              <CustomFieldRender
                key={form.key(`custom_attribute_${field.field_id}`)}
                field={field}
                inputProps={form.getInputProps(
                  `custom_attribute_${field.field_id}`
                )}
              />
            ))}
        </Grid>
      </FormSection>
      <ButtonMenu>
        <Button size="xs" variant="subtle" onClick={handleCancel}>
          {buttonProps.cancelText || "Cancel"}
        </Button>
        <Button
          size="xs"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {buttonProps.submitText || "Submit"}
        </Button>
      </ButtonMenu>
    </>
  );
};

export default DefaultCaseForm;
