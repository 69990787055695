import React, { useEffect, useState } from "react";
import { getMonolithReports } from "../../api";
import StorageReport from "./components/Storage/StorageReport";
import CasesReport from "./components/Cases/CaseReport";
import EvidenceReport from "./components/Evidence/EvidenceReport";
import QaEntryReport from "./components/QA/Report";
import AcquisitionsReport from "./components/Acquisitions/AcquisitionsReport";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { CATEGORY_MAP } from "./CategoryMap";
import { FprReport } from "./components/FPR/FprReport";
import { TimeEntryReport } from "./components/TimeEntry/TimeEntryReport";
import styled from "styled-components";

//Metrics to add to report
// Evidence Intake during period
// Evidence Items Released
// Acquisitions created during period
// total acquisition data (stored)
// Acquisition tool composition
// Distinct acquisition total
// Cases Opened During Period
// Cases Closed During Period
// Case Progress Composition
// Case Status Composition
// Case Type Composition
// Case Documents uploaded
// Case Notes Created
// Evidence Type Composition
// Evidence Progress Composition
// Total Storage items assigned
// Storage items assigned during period
// Storage items released during period
// User activity Composition
// Inquiries received during period
// Inquiries converted during period
// Inquiries merged during period
// Custom Attribute Stats

const ReportDetails = styled(({ className }) => {
  const [reportInfo, setReportInfo] = useState(null);
  const { report_id: reportID } = useParams();
  const theme = useTheme();
  const [formUpdated, setFormUpdated] = useState(false);

  useEffect(() => {
    setReportInfo(null);
    getMonolithReports({ report_id: reportID }).then((result) => {
      setReportInfo(result[0]);
    });
  }, [formUpdated]);

  if (!reportInfo) return <Loader />;

  return (
    <div className={className}>
      <Helmet title="Monolith Reports" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3">{reportInfo.name}</Typography>
          <div style={{ color: "slategray", fontSize: "medium" }}>
            Monolith Report
          </div>
        </Grid>
        <div
          style={{
            fontSize: "smaller",
            marginLeft: "auto",
            display: "flex",
            alignItems: "end",
          }}
        >
          <Link to={`/reports`}>
            <span
              style={{
                cursor: "pointer",
                color: theme.palette.primary.main,
              }}
              onMouseEnter={(event) => {
                event.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(event) => {
                event.target.style.textDecoration = "none";
              }}
            >
              {"< Back to Reports"}
            </span>
          </Link>
        </div>
      </Grid>
      <Divider sx={{ mb: 4, mt: 1 }} />
      <div>
        {reportInfo && (
          <>
            {reportInfo.category_id === CATEGORY_MAP.CASES && (
              <CasesReport
                reportInfo={reportInfo}
                setFormUpdated={setFormUpdated}
              />
            )}
            {reportInfo.category_id === CATEGORY_MAP.EVIDENCE && (
              <EvidenceReport
                reportInfo={reportInfo}
                setFormUpdated={setFormUpdated}
              />
            )}
            {reportInfo.category_id === CATEGORY_MAP.STORAGE && (
              <StorageReport
                reportInfo={reportInfo}
                setFormUpdated={setFormUpdated}
              />
            )}
            {reportInfo.category_id === CATEGORY_MAP.ACQUISITIONS && (
              <AcquisitionsReport
                reportInfo={reportInfo}
                setFormUpdated={setFormUpdated}
              />
            )}
            {reportInfo.category_id === CATEGORY_MAP.QA_ENTRIES && (
              <QaEntryReport
                reportInfo={reportInfo}
                setFormUpdated={setFormUpdated}
              />
            )}
            {reportInfo.category_id === CATEGORY_MAP.FPR && (
              <FprReport
                reportInfo={reportInfo}
                setFormUpdated={setFormUpdated}
              />
            )}
            {reportInfo.category_id === CATEGORY_MAP.TIME_ENTRIES && (
              <TimeEntryReport
                reportInfo={reportInfo}
                setFormUpdated={setFormUpdated}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
})`
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;
  padding-right: 10px;
`;

export default ReportDetails;
