import styled from "styled-components";
import { monolithMoment } from "../../utils/date-format.js";
import moment from "moment";

const LineItem = styled(({ className, log }) => {
  return (
    <div className={className}>
      <div key={log.log_id} className="log-item">
        <div
          className="log-item-detail user"
          title={`${log.user.full_name}\n${log.user.email}`}
        >
          {log.user.first_name}
        </div>
        <div className="log-item-detail description" title={log.activity}>
          {log.activity}
        </div>
        <div
          className="log-item-detail timestamp"
          title={monolithMoment({
            timestamp: log.timestamp,
            includeTime: true,
            includeZone: true,
          })}
        >
          {moment.utc(log.timestamp).fromNow()}
        </div>
      </div>
    </div>
  );
})`
  .log-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .log-item-detail {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .log-item-detail.user {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: bold;
  }

  .log-item-detail.description {
    color: ${({ theme }) => theme.palette.text.primary};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .log-item-detail.timestamp {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-left: auto;
    min-width: 100px;
    text-align: right;
  }
`;

const CardItem = styled(({ className, log }) => {
  let appMeta = [];
  if (log.app) {
    appMeta.push(log.app);
  }
  if (log.client_ip) {
    appMeta.push(log.client_ip);
  }
  if (log.user_agent) {
    appMeta.push(log.user_agent);
  }

  return (
    <div className={className}>
      <div className="title">{log.activity}</div>
      <div className="subtitle">
        {log.user.full_name}
        {` (${log.user.email})`}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          justifyContent: "space-between",
          marginTop: 5,
        }}
      >
        <div
          className="subtitle timestamp"
          title={monolithMoment({
            timestamp: log.timestamp,
            includeTime: true,
            includeZone: true,
          })}
        >
          {moment.utc(log.timestamp).fromNow()}
        </div>
        <div className="subtitle timestamp" title={appMeta.join(" | ")}>
          {appMeta.join(" | ")}
        </div>
      </div>
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.input.border};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .view-note-alert {
      display: flex;
    }
  }

  .timestamp {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .title {
    font-weight: 500;
    width: fit-content;
  }

  .subtitle {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.secondary};
    width: fit-content;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActivityFeed = styled(({ className, logs, variant = "line" }) => {
  return (
    <div className={className}>
      <div className="logs-list">
        {logs.map((log) =>
          variant === "line" ? <LineItem log={log} /> : <CardItem log={log} />
        )}
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;

  font-size: 12px;

  h3 {
    margin: 0px;
    margin-bottom: 10px;
  }

  .logs-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    flex: 1 1 auto;

    overflow-y: auto;

    padding-right: 8px;
  }
`;

export default ActivityFeed;
