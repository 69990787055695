import { Box, Button, Popover, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Loader from "../../components/Loader";
import { usePermissions } from "../../hooks/usePermissions";
import styled from "styled-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TasksAPI from "../../api/tasks/tasks.js";
import DeleteTaskTemplateModal from "./DeleteTaskTemplateModal.js";

const TaskListPopover = ({ tasks, target }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    target.current.addEventListener("mouseenter", handlePopoverOpen);
    target.current.addEventListener("mouseleave", handlePopoverClose);

    return () => {
      if (target.current) {
        target.current.removeEventListener("mouseenter", handlePopoverOpen);
        target.current.removeEventListener("mouseleave", handlePopoverClose);
      }
    };
  }, []);

  return (
    <>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2, maxWidth: 200 }}>
          {tasks.map((task) => {
            return (
              <div style={{ marginBottom: 10 }}>
                <div>{task.task_name}</div>
                <div style={{ color: "slategray" }}>{task.task_notes}</div>
              </div>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

const TaskTemplateItem = ({ data, onDelete, onUpdate }) => {
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const taskCountRef = useRef(null);
  const { hasPermission } = usePermissions();

  const handleUpdate = async (event) => {
    const newTemplateName = event.currentTarget.innerText;
    if (newTemplateName === "") {
      event.currentTarget.innerText = data.template_name;
      return;
    }
    if (newTemplateName !== data.template_name) {
      await TasksAPI.updateTaskTemplate(data.template_id, {
        template_name: newTemplateName,
      });

      onUpdate?.({
        template_id: data.template_id,
        template_name: newTemplateName,
      });
    }
  };

  const handleDelete = (event) => {
    onDelete?.(data);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px 10px",
          cursor: "pointer",
          "&:hover": { backgroundColor: theme.palette.action.hover },
        }}
      >
        <div>
          <Box
            contentEditable={hasPermission()}
            suppressContentEditableWarning
            sx={{
              fontSize: "larger",
              padding: "2px",
              cursor: "text",
              "&:hover": { outline: `1px solid slategray` },
              "&:focus": { outline: `1px solid slategray` },
            }}
            onBlur={handleUpdate}
          >
            {data.template_name}
          </Box>
          <div
            ref={taskCountRef}
            style={{ color: "slategrey", padding: "2px", width: "fit-content" }}
          >
            {data?.sub_tasks?.length || 0} Tasks
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            variant="text"
            color="error"
            size="small"
            disabled={!hasPermission()}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        </div>
      </Box>
      <TaskListPopover tasks={data?.sub_tasks || []} target={taskCountRef} />
      {showDeleteModal && (
        <DeleteTaskTemplateModal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onDelete={() => handleDelete()}
          template={data}
        />
      )}
    </>
  );
};

const TaskTemplatesList = styled(({ className }) => {
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery({
    queryKey: ["tasks", "templates"],
    queryFn: () => TasksAPI.getTaskTemplates(),
  });

  const handleUpdate = (updateData) => {
    queryClient.setQueryData(["tasks", "templates"], (oldData) => {
      const templates = oldData.data.map((template) => {
        if (template.template_id === updateData.template_id) {
          return {
            ...template,
            ...updateData,
          };
        }
        return template;
      });
      return {
        ...oldData,
        data: templates,
      };
    });
  };

  if (!data) return <Loader />;

  const templates = data?.data || [];

  return (
    <div className={className}>
      <>
        {templates?.map((template) => {
          return (
            <TaskTemplateItem
              key={template.template_id}
              data={template}
              taskTemplates={templates}
              onDelete={() => refetch()}
              onUpdate={handleUpdate}
            />
          );
        }) || <></>}
      </>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const TaskTemplateSettings = styled(({ className }) => {
  const [taskTemplates, setTaskTemplates] = useState(null);
  const deleteTemplatePopup = useRef(null);

  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      <div>
        Delete task templates or edit thier names. The tasks in a template can
        be edited by creating a new task list based on a current template. Make
        the edits, then save as a new template.
      </div>
      <div style={{ margin: "25px 0px" }}></div>
      <TaskTemplatesList
        taskTemplates={taskTemplates}
        setTaskTemplates={setTaskTemplates}
        deleteTemplatePopup={deleteTemplatePopup}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

export default TaskTemplateSettings;
