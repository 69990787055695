import { Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteOffice, updateOffice } from "../../api";
import ToolBarItems from "../../components/ToolBarItems";
import { usePermissions } from "../../hooks/usePermissions";
import { Template } from "devextreme-react/core/template";
import UserSelectBoxItem from "../../components/SelectBoxItems/UserSelectBoxItem";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import OfficesApi from "../../api/offices/index.js";
import UserApi from "../../api/users/users.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import AccessDeniedMessage from "../../components/AccessDeniedMessage.js";

const OfficeDetails = ({}) => {
  const { office_id } = useParams();
  const theme = useTheme();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const form = useRef(null);
  const confirmOfficeDeletePopup = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const hasReadPermission = hasPermission(
    MONOLITH_PERMISSIONS.OFFICES_READ_ALL
  );

  const result = useQueries({
    queries: [
      {
        queryKey: ["offices:list", { office_id }],
        queryFn: () => OfficesApi.getOffices({ office_id }),
        enabled: hasReadPermission,
      },
      {
        queryKey: [
          "users:list",
          { includeObservers: true, includeInactive: false },
        ],
        queryFn: () =>
          UserApi.getUsers({ includeObservers: true, includeInactive: false }),
        enabled: hasReadPermission,
      },
    ],
  });

  const handleUpdate = async (e) => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option().formData };

      //Collect Changed Fields
      let newValues = {};
      for (const key of Object.keys(formData)) {
        if (office[key] !== formData[key]) {
          newValues[key] = formData[key];
        }
      }

      updateOffice(office.office_id, newValues).then((res) => {
        queryClient.setQueryData(["offices:list", { office_id }], (old) => {
          return [
            {
              ...old[0],
              ...newValues,
            },
          ];
        });
        enqueueSnackbar("Office Updated", {
          variant: "success",
        });
      });
    }
  };

  const isDone = result.every((query) => query.isFetched);

  const [office] = result[0]?.data || [];
  const users = result[1]?.data || [];

  if (!hasReadPermission) {
    return <AccessDeniedMessage />;
  }

  return (
    <>
      <Helmet title="Office Details" />
      {isDone && (
        <>
          <div
            className="pageHeader"
            style={{
              padding: "10px 5px",
              margin: "0px",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Link to="/offices">
              <TaskButton
                style={{ outline: `1px solid ${theme.palette.divider}` }}
                title="Back to Offices"
              >
                <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
              </TaskButton>
            </Link>

            <div style={{ fontSize: "x-large", marginLeft: 10 }}>
              {office.office_name}
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: "small",
                }}
              >
                {office.office_city || "City"},{" "}
                {office.office_region || "State/Province"}
              </div>
            </div>
          </div>
          <div style={{ boxShadow: "none" }}>
            <div
              style={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  padding: "40px",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: 750,
                  }}
                >
                  <div>
                    <div style={{ fontSize: "large" }}>Office Details</div>
                    <div style={{ color: theme.palette.text.secondary }}></div>
                  </div>
                  <div>
                    <Form
                      width={500}
                      ref={form}
                      colCount={2}
                      defaultFormData={{
                        office_name: office.office_name,
                        office_address: office.office_address,
                        office_city: office.office_city,
                        office_region: office.office_region,
                        office_postal_code: office.office_postal_code,
                        office_phone: office.office_phone,
                        manager_id: office.manager_id,
                      }}
                      readOnly={
                        !hasPermission(MONOLITH_PERMISSIONS.OFFICES_UPDATE)
                      }
                    >
                      <SimpleItem
                        dataField="office_name"
                        label={{ text: "Office Name" }}
                        isRequired={true}
                      />
                      <SimpleItem
                        dataField="office_address"
                        label={{ text: "Address" }}
                      />
                      <SimpleItem
                        dataField="office_city"
                        label={{ text: "City" }}
                      />
                      <SimpleItem
                        dataField="office_region"
                        label={{ text: "State/Province" }}
                      />
                      <SimpleItem
                        dataField="office_postal_code"
                        label={{ text: "Postal Code" }}
                      />
                      <SimpleItem
                        dataField="office_phone"
                        label={{ text: "Phone" }}
                      />
                      <SimpleItem
                        dataField="manager_id"
                        label={{ text: "Manager" }}
                        editorType="dxSelectBox"
                        editorOptions={{
                          items: users,
                          displayExpr: "full_name",
                          valueExpr: "user_id",
                          itemTemplate: "manager_id",
                        }}
                      />
                      <Template
                        name="manager_id"
                        render={(data) => <UserSelectBoxItem data={data} />}
                      />
                    </Form>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "35px",
                    width: 750,
                  }}
                >
                  {hasPermission() && (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={handleUpdate}
                      disabled={
                        !hasPermission(MONOLITH_PERMISSIONS.OFFICES_UPDATE)
                      }
                    >
                      Update Office
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {hasPermission() && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "40px",
                  marginTop: "25px",
                  textAlign: "left",
                  alignItems: "center",
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 5,
                }}
              >
                <div>
                  <div style={{ fontSize: "large" }}>Delete Office</div>
                  <div style={{ color: theme.palette.text.secondary }}>
                    Delete this office from Monolith.
                  </div>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={(e) => {
                      confirmOfficeDeletePopup.current.instance.show();
                    }}
                    disabled={
                      !hasPermission(MONOLITH_PERMISSIONS.OFFICES_UPDATE)
                    }
                  >
                    Delete Office
                  </Button>
                </div>
              </div>
            )}
          </div>
          <ConfirmOfficeDeletePopup
            confirmOfficeDeletePopup={confirmOfficeDeletePopup}
            office={office}
          />
        </>
      )}
    </>
  );
};

const ConfirmOfficeDeletePopup = ({ confirmOfficeDeletePopup, office }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    confirmOfficeDeletePopup.current.instance.hide();
    deleteOffice(office.office_id);
    enqueueSnackbar("Office Deleted.", {
      variant: "success",
    });
    navigate(`/offices`);
  };

  const handleCancel = (event) => {
    confirmOfficeDeletePopup.current.instance.hide();
  };

  return (
    <Popup
      ref={confirmOfficeDeletePopup}
      title="Delete Office"
      showTitle={true}
      showCloseButton={true}
      width={450}
      height={175}
      position={{
        offset: "100 0",
      }}
    >
      <div>Are you sure you want to delete office "{office.office_name}"?</div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <ToolBarItems
          submitText="Delete Office"
          submitColor="error"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Popup>
  );
};

export default OfficeDetails;
