import { Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import InquiriesAPI from "../../api/inquiries";
import Loader from "../../components/Loader";
import InquiryOverview from "./InquiryOverview";
import ConvertInquiryPopup from "./components/ConvertInquiryPopup";
import MergeInquiryPopup from "./components/MergeInquiryPopup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import { usePermissions } from "../../hooks/usePermissions";

export const InquiryStatusOptions = [
  "New",
  "Contacted",
  "Converted",
  "Merged",
  "Transferred",
  "Accepted",
  "Declined",
];

const InquiryDetails = () => {
  const { inquiry_id } = useParams();
  const queryClient = useQueryClient();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const theme = useTheme();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const { data: inquiryInfo } = useQuery({
    queryKey: ["inquiries:list", { inquiry_id: parseInt(inquiry_id) }],
    queryFn: () => InquiriesAPI.getInquiries({ inquiry_id }),
    select: (data) => data[0],
    refetchOnWindowFocus: true,
  });

  const handleInquiryUpdate = () => {
    queryClient.refetchQueries({
      queryKey: ["inquiries:list", { inquiry_id: parseInt(inquiry_id) }],
    });
  };

  const handleStatusUpdate = (newStatus) => {
    queryClient.setQueryData(
      ["inquiries:list", { inquiry_id: inquiryInfo.inquiry_id }],
      (oldData) => {
        if (oldData[0]) return [{ ...oldData[0], inquiry_status: newStatus }];
      }
    );
    InquiriesAPI.updateInquiry(inquiry_id, { inquiry_status: newStatus });
  };

  return (
    <>
      {!inquiryInfo && <Loader />}
      {inquiryInfo && (
        <>
          <Helmet>
            <title>Inquiry Details | {String(inquiryInfo.inquiry_id)}</title>
          </Helmet>
          <div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Link to={-1}>
                <TaskButton
                  style={{
                    outline: `1px solid ${theme.palette.divider}`,
                    marginRight: 10,
                  }}
                  title={"Back to Inquiries"}
                >
                  <KeyboardReturnOutlinedIcon style={{ fontSize: 20 }} />
                </TaskButton>
              </Link>
              <div>
                <Typography variant="h3" display="inline">
                  Inquiry Details
                </Typography>
                <div style={{ color: theme.palette.text.secondary }}>
                  ID {inquiryInfo.inquiry_id}
                  {inquiryInfo.request_name
                    ? ` | ${inquiryInfo.request_name}`
                    : ""}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0px",
              }}
            >
              {!inquiryInfo.case_id && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                      minWidth: "fit-content",
                      fontSize: 11,
                      padding: "3px 6px",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setShowCreateModal(true);
                    }}
                    disabled={
                      !hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_UPDATE)
                    }
                  >
                    + Create Case
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{
                      minWidth: "fit-content",
                      fontSize: 11,
                      padding: "3px 6px",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setShowMergeModal(true);
                    }}
                    disabled={
                      !hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_UPDATE)
                    }
                  >
                    {"# Merge"}
                  </Button>
                </>
              )}
              <ComboButton
                type="dropDown"
                data={InquiryStatusOptions}
                enabled={hasPermission(MONOLITH_PERMISSIONS.INQUIRIES_UPDATE)}
                value={inquiryInfo.inquiry_status}
                title={"Set Inquiry Status"}
                showDropdownIcon={true}
                variant="outlined"
                useSelectMode={true}
                onItemSelect={(e) => handleStatusUpdate(e)}
                dropDownTitle={() => {
                  return (
                    <div
                      style={{
                        margin: "5px 0px",
                        padding: 3,
                        color: theme.palette.text.secondary,
                        display: "flex",
                        alignItems: "center",
                        minWidth: 200,
                      }}
                    >
                      Set Inquiry Status
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <InquiryOverview details={inquiryInfo} />
          <ConvertInquiryPopup
            open={showCreateModal}
            handleClose={() => setShowCreateModal(false)}
            onSubmit={() => handleInquiryUpdate()}
            defaultInfo={inquiryInfo}
          />
          <MergeInquiryPopup
            open={showMergeModal}
            handleClose={() => setShowMergeModal(false)}
            onSubmit={() => handleInquiryUpdate()}
            defaultInfo={inquiryInfo}
          />
        </>
      )}
    </>
  );
};

export default InquiryDetails;
