import React, { useState, useEffect } from "react";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { getIssueTypes, userApi } from "../../../api";
import CollapseSection from "../../../components/CollaspeSection";
import { getDateFormat } from "../../../utils/date-format";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "@mui/material";
import { Template } from "devextreme-react/core/template";
import UserSelectBoxItem from "../../../components/SelectBoxItems/UserSelectBoxItem.js";

const QaEntryParamsForm = ({ paramsForm, reportInfo }) => {
  const theme = useTheme();
  const [editorVisiblity, setEditorVisbility] = useState({
    startDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
    endDate:
      reportInfo?.report_parameters?.timestamp_interval === "Custom"
        ? true
        : false,
  });

  let startDateRef = null;
  let endDateRef = null;

  const { data: users } = useQuery({
    queryKey: ["users", { includeObservers: false }],
    queryFn: () => userApi.getUsers({ includeObservers: false }),
    initialData: [],
    select: (data) =>
      data.map((user) => ({
        user_id: user.user_id,
        email: user.email,
        full_name: user.full_name,
        title: user.title,
      })),
    refetchOnWindowFocus: false,
  });

  const { data: issueTypes } = useQuery({
    queryKey: ["qa:issueTypes"],
    queryFn: () => getIssueTypes(),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const status = [
    { value: "Yes", text: "Resolved" },
    { value: "No", text: "Not Resolved" },
  ];

  return (
    <>
      <CollapseSection
        title={"Report Parameters | QA Entries - Overview"}
        allowCollapse={false}
      >
        <Form
          ref={paramsForm}
          colCount={2}
          defaultFormData={reportInfo || null}
        >
          <SimpleItem
            dataField="report_parameters.time_interval"
            label={{ text: "Time Interval", location: "top" }}
            colSpan={1}
            editorType="dxSelectBox"
            editorOptions={{
              items: [
                "Custom",
                "Today",
                "Yesterday",
                "This Week",
                "Last Week",
                "This Month",
                "Last Month",
                "This Year",
                "Last Year",
                "All Time",
              ],
              dropDownOptions: {
                maxHeight: 250,
              },
              onValueChanged: (e) => {
                setEditorVisbility({
                  ...editorVisiblity,
                  startDate: e.value === "Custom",
                  endDate: e.value === "Custom",
                });
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.start_date"
            label={{ text: "Start Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.startDate}
            editorOptions={{
              onInitialized: (e) => {
                startDateRef = e.component;
              },
              onValueChanged: (e) => {
                endDateRef.option("min", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, false),
            }}
          />
          <SimpleItem
            dataField="report_parameters.end_date"
            label={{ text: "End Date", location: "top" }}
            editorType="dxDateBox"
            colSpan={1}
            isRequired={true}
            visible={editorVisiblity.endDate}
            editorOptions={{
              onInitialized: (e) => {
                endDateRef = e.component;
              },
              onValueChanged: (e) => {
                startDateRef.option("max", e.value);
              },
              type: "date",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, false),
            }}
          />
          <SimpleItem
            dataField="report_parameters.examiner"
            label={{ text: "Examiner" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              displayExpr: "full_name",
              items: users,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
              itemTemplate: "report_parameters.examiner",
            }}
          />
          <Template
            name="report_parameters.examiner"
            render={(data) => <UserSelectBoxItem data={data} />}
          />
          <SimpleItem
            dataField="report_parameters.reviewer"
            label={{ text: "Reviewer" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              displayExpr: "full_name",
              items: users,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
              itemTemplate: "report_parameters.reviewer",
            }}
          />
          <Template
            name="report_parameters.reviewer"
            render={(data) => <UserSelectBoxItem data={data} />}
          />
          <SimpleItem
            dataField="report_parameters.issue_type"
            label={{ text: "Issue Type" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "issue_type",
              displayExpr: "issue_type",
              items: issueTypes,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
          <SimpleItem
            dataField="report_parameters.status"
            label={{ text: "Status" }}
            colSpan={1}
            editorType="dxTagBox"
            editorOptions={{
              valueExpr: "value",
              displayExpr: "text",
              items: status,
              showClearButton: false,
              showDropDownButton: true,
              multiline: false,
              showSelectionControls: true,
              selectAllMode: "allPages",
              dropDownOptions: {
                maxHeight: 250,
              },
            }}
          />
        </Form>
      </CollapseSection>
    </>
  );
};

export default QaEntryParamsForm;
