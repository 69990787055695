import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useRef, useState } from "react";
import styled from "styled-components";
import RelayAPI from "../../../api/RelayAPI/index.js";
import Loader from "../../../components/Loader.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import BasicDetails from "./BasicDetails.js";
import CustomFieldOptions from "./CustomFieldOptions.js";
import Instructions from "./Instructions.js";
import UserManagement from "./UserManagement.js";

const buttonStyle = {
  minWidth: "150px",
  padding: "2px 5px",
};

const RelaySettings = styled(({ className }) => {
  const { currentUser } = useAuth();

  const tenant = currentUser.licenseInfo.tenant;

  const { isLoading, data } = useQuery({
    queryKey: [
      "relayInfo",
      {
        cloud_tenant_id: tenant,
        include_logo: true,
        include_instructions: true,
      },
    ],
    queryFn: () =>
      RelayAPI.getRelayInfo({
        cloud_tenant_id: tenant,
        include_logo: true,
        include_instructions: true,
      }),
  });

  const [group, setGroup] = useState("basic-details");

  const handleChange = (event, newGroup) => {
    if (newGroup !== null) {
      setGroup(newGroup);
    }
  };

  const control = {
    value: group,
    onChange: handleChange,
    exclusive: true,
  };

  if (isLoading) return <Loader />;

  const [relayInfo] = data || [];

  return (
    <div className={className}>
      {!relayInfo && <RelaySetup />}
      {relayInfo && (
        <>
          <div style={{ marginBottom: 15, display: "flex" }}>
            <ToggleButtonGroup
              size="small"
              {...control}
              style={{ margin: "auto" }}
            >
              <ToggleButton
                value="basic-details"
                key="basic-details"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>Basic Details</>
              </ToggleButton>
              <ToggleButton
                value="user-management"
                key="user-management"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>User Management</>
              </ToggleButton>
              <ToggleButton
                value="instructions"
                key="instructions"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>Instructions</>
              </ToggleButton>
              <ToggleButton
                value="custom-field-options"
                key="custom-field-options"
                size="small"
                disableRipple={true}
                style={buttonStyle}
              >
                <>Custom Field Options</>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {group === "basic-details" && <BasicDetails relayInfo={relayInfo} />}
          {group === "instructions" && <Instructions relayInfo={relayInfo} />}
          {group === "user-management" && (
            <UserManagement relayInfo={relayInfo} />
          )}
          {group === "custom-field-options" && (
            <CustomFieldOptions relayInfo={relayInfo} />
          )}
        </>
      )}
    </div>
  );
})`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0px;
`;

const RelaySetup = styled(({ className }) => {
  const form = useRef(null);
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();

  const handleComplete = async () => {
    const formData = form.current.instance.option("formData");
    RelayAPI.createRelayTenant(formData).then((res) => {
      queryClient.refetchQueries({ queryKey: ["relayInfo"] });
    });
  };

  const handleSubmit = () => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r) => {
        if (r.isValid) {
          handleComplete();
        }
      });
    } else {
      if (res.isValid) {
        handleComplete();
      }
    }
  };

  const urlSlug = currentUser.licenseInfo.organization
    .toLowerCase()
    .replace(/\s/g, "-");

  const tenantEmail = currentUser.email;
  const tenantName = currentUser.licenseInfo.organization;

  return (
    <div className={className}>
      <Typography variant="h3" display="inline">
        Create Your Relay Tenant
      </Typography>
      <div className="message">
        Create a Relay tenant for Monolith. Relay is for users outside of
        Monolith and gives them a platform to submit requests to your forensics
        lab. Those requests enter into Monolith as inquiries. Users can use
        Relay to manage and view updates to thier requests.
      </div>
      <div style={{ width: 500, marginTop: 20, padding: 20 }}>
        <Form ref={form}>
          <SimpleItem
            dataField="tenant_slug"
            editorType="dxTextBox"
            label={{ text: "Your Relay URL", visible: true }}
            editorOptions={{
              placeholder: "Enter a new URL slug",
              value: urlSlug,
            }}
            validationRules={[
              {
                type: "required",
                message: "This field is required",
              },
              {
                type: "pattern",
                pattern: "^[a-z0-9-]+$",
                message:
                  "Only lowercase letters, numbers, and dashes are allowed",
              },
              {
                type: "stringLength",
                min: 3,
                max: 30,
                message: "Must be between 3 and 30 characters",
              },
              {
                type: "async",
                message: "This URL slug is already in use",
                validationCallback: async (e) => {
                  return RelayAPI.verifySlug({ tenant_slug: e.value }).then(
                    (res) => {
                      return res.success;
                    }
                  );
                },
              },
            ]}
          />
          <SimpleItem
            dataField="tenant_email"
            editorType="dxTextBox"
            label={{ text: "Your Relay Email", visible: true }}
            editorOptions={{
              placeholder: "Enter a new Relay tenant email",
              mode: "email",
              value: tenantEmail,
            }}
            validationRules={[
              {
                type: "required",
                message: "This field is required",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          />
          <SimpleItem
            dataField="tenant_org"
            editorType="dxTextBox"
            label={{ text: "Your Relay Org Name", visible: true }}
            editorOptions={{
              placeholder: "Enter a new Relay tenant name",
              value: tenantName,
            }}
            validationRules={[
              {
                type: "required",
                message: "This field is required",
              },
              {
                type: "stringLength",
                min: 3,
                max: 30,
                message: "Must be between 3 and 30 characters",
              },
              {
                type: "pattern",
                pattern: "^[a-zA-Z0-9 ]+$",
                message: "Only letters, numbers, and spaces are allowed",
              },
            ]}
          />
          <SimpleItem />
        </Form>
        <div style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            Create Relay Tenant
          </Button>
        </div>
      </div>
    </div>
  );
})`
  .message {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

export default RelaySettings;
