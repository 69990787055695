import { monolithMoment } from "../../utils/date-format.js";

const TraceIndicatorDefs = [
  {
    dataField: "name",
    caption: "Name",
  },
  {
    dataField: "description",
    caption: "Description",
  },
  {
    dataField: "created_by",
    caption: "Created By",
    render: (data) => data?.created_by?.full_name,
  },
  {
    dataField: "created_on",
    caption: "Created On",
    render: (data) =>
      monolithMoment({
        timestamp: data.created_on,
        includeTime: true,
        includeZone: true,
      }),
  },
  {
    dataField: "updated_on",
    caption: "Updated On",
    visible: false,
    render: (data) =>
      monolithMoment({
        timestamp: data.updated_on,
        includeTime: true,
        includeZone: true,
      }),
  },
];

export default TraceIndicatorDefs;
