import { useEffect, useMemo, useRef, useState } from "react";
import { getClients, caseNumberExists, logActivity } from "../../../api";
import {
  EmptyItem,
  Form,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/ui/form";
import DataSource from "devextreme/data/data_source";
import { db_timestamp, getDateFormat } from "../../../utils/date-format";
import { useAuth } from "../../../contexts/AuthContext";
import ClientSelectBoxItem from "../../../components/SelectBoxItems/ClientSelectBoxItem.js";
import { Template } from "devextreme-react/core/template";
import CasesApi from "../../../api/cases";
import { useQueries } from "@tanstack/react-query";
import UserApi from "../../../api/users/users";
import ClientsAPI from "../../../api/clients";
import Modal from "../../../Monolith-UI/Modal/Modal";
import CollapseSection from "../../../components/CollaspeSection";
import { useSnackbar } from "notistack";
import { DevexEditors } from "../../../utils/devex-editors";
import Loader from "../../../components/Loader.js";

const EditCaseModal = ({
  caseInfo,
  open = false,
  onSubmit = (data) => {},
  handleClose = () => {},
}) => {
  const [caseStatus, setCaseStatus] = useState(caseInfo?.case_status);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [clients, setClients] = useState([]);

  const form = useRef(null);
  const customFieldForm = useRef(null);

  const handleSubmit = () => {
    const res = form.current.instance.validate();
    if (res.status === "pending") {
      res.complete.then((r) => {
        handleCompleteSubmit({ isValid: r.isValid });
      });
    } else {
      handleCompleteSubmit({ isValid: res.isValid });
    }
  };

  const handleCompleteSubmit = async ({ isValid }) => {
    if (!isValid) return;
    if (
      form.current.instance.validate().isValid &&
      (customFieldForm?.current?.instance?.validate().isValid || true)
    ) {
      const formData = {
        ...form.current.instance.option().formData,
        ...(customFieldForm?.current?.instance?.option()?.formData || {}),
      };
      handleClose();
      let updateData = {};

      //Determine new values
      for (const key of Object.keys(formData)) {
        if (caseInfo[key] !== formData[key]) {
          updateData[key] = formData[key];
        }
      }

      let customAttributes = [];

      for (let key of Object.keys(updateData)) {
        if (key.indexOf("custom_attribute_") !== -1) {
          let field_id = parseInt(key.replace("custom_attribute_", ""));
          let currentAttr = caseInfo.custom_attributes.find((attribute) => {
            return attribute.field_id === field_id;
          });

          //evidence has attribute & it hasnt changed
          if (currentAttr && currentAttr.value === updateData[key]) {
            //do nothing
          } else {
            const baseAttr = fieldData.customFields.find(
              (attr) => attr.field_id === field_id
            );
            customAttributes.push({
              field_id: field_id,
              field_name: baseAttr.field_name,
              item_id: caseInfo.case_id,
              value: updateData[key],
            });
          }
          delete updateData[key];
        }
      }

      if (customAttributes?.length > 0)
        updateData.custom_attributes = customAttributes;

      if (Object.keys(updateData)?.length > 0) {
        //Model Data
        if (updateData.case_number) {
          updateData.case_number.trim();
        }
        if (updateData.case_ref) {
          updateData.case_ref.trim();
        }
        if (updateData.case_open_date) {
          updateData.case_open_date = db_timestamp(updateData.case_open_date);
        }
        if (updateData.case_closed_date) {
          updateData.case_closed_date = db_timestamp(
            updateData.case_closed_date
          );
        }

        await CasesApi.updateCase(caseInfo.case_id, updateData);

        onSubmit?.({ case_id: caseInfo.case_id, ...updateData });

        enqueueSnackbar(`Case Edited.`, {
          variant: "success",
        });

        let message = ``;
        let comma = " ";

        for (const key of Object.keys(updateData)) {
          switch (key) {
            case "case_number":
              message += comma + "Case Number changed to " + updateData[key];
              break;
            case "case_ref":
              message += comma + "Case Name changed to " + updateData[key];
              break;
            case "client_id":
              message +=
                comma +
                "Client changed to " +
                clients.find((c) => c.client_id === updateData[key]).name;
              break;

            case "synopsis":
              message += comma + "Description changed to " + updateData[key];
              break;
            case "case_open_date":
              message +=
                comma +
                "Case open date changed to " +
                updateData.case_open_date +
                " UTC";
              break;
            case "case_closed_date":
              message +=
                comma +
                "Case closed date changed to " +
                updateData.case_closed_date +
                " UTC";
              break;
            default:
              break;
          }

          comma = ", ";
        }

        logActivity(
          caseInfo.case_id,
          currentUser.user_id,
          `Case Edited: ${message}`
        );
      } else {
        enqueueSnackbar(`No new data to update.`, {
          variant: "warning",
        });
      }
    }
  };
  const handleCancel = () => {
    handleClose();
  };

  const result = useQueries({
    queries: [
      {
        queryKey: ["cases:types"],
        queryFn: () => CasesApi.getCaseTypes(),
        enabled: open,
      },
      {
        queryKey: ["cases:statuses"],
        queryFn: () => CasesApi.getCaseStatuses(),
        enabled: open,
      },
      {
        queryKey: [
          "cases:leads",
          {
            includeObservers: false,
            includeInactive: false,
          },
        ],
        queryFn: () =>
          UserApi.getUsers({
            includeObservers: false,
            includeInactive: false,
          }),
        enabled: open,
      },
      {
        queryKey: ["clients:list"],
        queryFn: () => ClientsAPI.getClients(),
        enabled: open,
      },
      {
        queryKey: ["cases:custom-fields:list"],
        queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
        enabled: open,
        select: (data) => data.filter((c) => c.enabled === 1),
      },
    ],
  });

  const isDone = result.every((x) => x.isFetched);

  let defaultDetailData = {};
  // Create convenience objects to access defaultInfo fields for main and advanced details
  if (caseInfo) {
    defaultDetailData = {
      case_id: caseInfo.case_id || null,
      case_number: caseInfo.case_number || null,
      case_ref: caseInfo.case_ref || null,
      case_type: caseInfo.case_type || null,
      case_status: caseInfo.case_status || null,
      client_id: caseInfo.client_id || null,
      case_open_date: caseInfo.case_open_date || null,
      case_closed_date: caseInfo.case_closed_date || null,
      case_synopsis: caseInfo.case_synopsis || null,
    };
  }

  const fieldData = useMemo(
    () => ({
      Casetypes: result[0].data,
      caseStatus: result[1].data,
      users: result[2].data,
      clients: result[3].data,
      customFields: result[4].data,
    }),
    [result]
  );

  const caseTypes = fieldData?.Casetypes || [];
  const caseStatuses = fieldData?.caseStatus || [];

  useEffect(() => {
    getClients({ isContact: 0 }).then((clients) => {
      setClients(clients);
    });
  }, []);

  const MainDetailsForm = useMemo(() => {
    return (
      <Form ref={form} colCount={2} defaultFormData={defaultDetailData}>
        <SimpleItem
          dataField={"case_number"}
          label={{ text: "Case Number" }}
          editorOptions={{
            hint: "Enter a unique value for your case.",
            placeholder: "Leave blank to auto-generate...",
          }}
          validationRules={[
            {
              type: "required",
              message: "Case Number is required",
            },
            {
              type: "pattern",
              message: 'Contains invalid characters: <>:"/\\|?*',
              pattern: /^[^<>:"/\\|?*]+$/,
            },
            {
              type: "async",
              message: "Case Number already exists",
              ignoreEmptyValue: true,
              validationCallback: (e) => {
                if (e.value === caseInfo.case_number)
                  return new Promise((resolve) => resolve(true));

                return caseNumberExists({ case_number: e.value }).then(
                  (res) => {
                    return !res;
                  }
                );
              },
            },
          ]}
        />
        <SimpleItem
          dataField={"case_ref"}
          label={{ text: "Case Name" }}
          editorOptions={{
            hint: "Enter a short phrase or term to help identify this case (Cause No., Suspect, Court Case Citation, etc.).",
          }}
          validationRules={[
            {
              type: "required",
            },
            {
              type: "pattern",
              message: 'Contains invalid characters: <>:"/\\|?*',
              pattern: /^[^<>:"/\\|?*]+$/,
            },
          ]}
        />
        <SimpleItem
          dataField={"client_id"}
          label={{ text: "Client" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: new DataSource({
              store: fieldData.clients,
              paginate: true,
              pageSize: 10,
            }),
            placeholder: "Select Client...",
            dropDownOptions: {
              maxHeight: 300,
            },
            displayExpr: "name",
            valueExpr: "client_id",
            searchEnabled: true,
            showClearButton: true,
            openOnFieldClick: false,
            onFocusIn: (e) => {
              e.component.open();
            },
            itemTemplate: "client_id",
          }}
        />
        <Template
          name="client_id"
          render={(data) => <ClientSelectBoxItem data={data} />}
        />
        <SimpleItem
          dataField={"case_type"}
          label={{ text: "Case Type" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: caseTypes,
            displayExpr: "case_type",
            valueExpr: "case_type",
            searchEnabled: true,
            searchExpr: "case_type",
            searchMode: "startsWith",
            placeholder: "Select Case Type...",
            dropDownOptions: {
              maxHeight: 250,
            },
          }}
        />
        <SimpleItem
          dataField={"case_status"}
          label={{ text: "Case Status" }}
          editorType="dxSelectBox"
          editorOptions={{
            dataSource: caseStatuses,
            displayExpr: "status_name",
            valueExpr: "status_name",
            searchEnabled: true,
            searchExpr: "status_name",
            searchMode: "startsWith",
            placeholder: "Select Case Status...",
            dropDownOptions: {
              maxHeight: 250,
            },
            onSelectionChanged: (e) => {
              setTimeout(() => {
                setCaseStatus(e.selectedItem.status_name);
              }, 25);
            },
          }}
        />
        <SimpleItem
          dataField={"case_open_date"}
          label={{ text: "Case Open Date" }}
          isRequired={true}
          editorType="dxDateBox"
          editorOptions={{
            type: "datetime",
            useMaskBehavior: true,
            displayFormat: getDateFormat(false, false),
          }}
        />
        <SimpleItem
          dataField={"case_closed_date"}
          label={{ text: "Case Close Date" }}
          editorType="dxDateBox"
          visible={caseStatus === "Closed"}
          isRequired={caseStatus === "Closed"}
          editorOptions={{
            type: "datetime",
            useMaskBehavior: true,
            displayFormat: getDateFormat(false, false),
          }}
        />
        {caseStatus === "Closed" && <EmptyItem />}
        <SimpleItem
          dataField={"case_synopsis"}
          label={{ text: "Description" }}
          editorType="dxTextArea"
          colSpan={2}
          editorOptions={{
            height: 150,
          }}
        />
      </Form>
    );
  }, [
    open,
    caseInfo,
    fieldData,
    caseStatus,
    defaultDetailData,
    clients,
    caseTypes,
    caseStatuses,
  ]);

  const CustomFieldFormSection = useMemo(() => {
    const defaultFormData = {};

    caseInfo?.custom_attributes?.forEach((attr) => {
      defaultFormData[`custom_attribute_${attr.field_id}`] =
        attr.editor_type === "tagBox"
          ? typeof attr.value === "object"
            ? attr.value
            : JSON.parse(attr.value)
          : attr.value;
    });

    return (
      <Form
        ref={customFieldForm}
        colCount={2}
        style={{ padding: 10 }}
        defaultFormData={defaultFormData}
      >
        {fieldData?.customFields?.map((attribute) => (
          <SimpleItem
            key={`custom_attribute_${attribute.field_id}`}
            dataField={`custom_attribute_${attribute.field_id}`}
            editorType={DevexEditors[attribute.editor_type]}
            label={{ text: attribute.field_name }}
            editorOptions={{
              placeholder: attribute.description || "",
              hint: attribute.description || "",
              items: JSON.parse(attribute.options || "[]"),
              dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, false),
              showClearButton: attribute.editor_type !== "textBox",
              pickerType: "calendar",
              showDropDownButton: true,
              multiline: false,
              showSelectionControls:
                attribute.editor_type === "tagBox" ? true : false,
            }}
          >
            {attribute.is_required === 1 && <RequiredRule message="" />}
          </SimpleItem>
        ))}
      </Form>
    );
  }, [caseInfo, fieldData]);

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        title="Edit Case"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        submitText="Edit Case"
        showToolbar={isDone}
        showCloseButton={false}
      >
        {isDone ? (
          <>
            <CollapseSection title="Case Details" visible={true}>
              {MainDetailsForm}
            </CollapseSection>
            {fieldData?.customFields?.length === 0 ||
            fieldData?.customFields === undefined ? (
              <></>
            ) : (
              <CollapseSection title="Custom Fields">
                {CustomFieldFormSection}
              </CollapseSection>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

export default EditCaseModal;
