import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getDateFormat, monolithMoment } from "../../utils/date-format.js";
import { nanoid } from "nanoid";
import TableColumnDefs from "../../components/Evidence/TableColumnDefs.js";
import {
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { usePermissions } from "../../hooks/usePermissions";

import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSnackbar } from "notistack";
import ToolBarItems from "../../components/ToolBarItems.js";
import { Template } from "devextreme-react/core/template";
import {
  EmptyItem,
  Form,
  GroupItem,
  SimpleItem,
} from "devextreme-react/ui/form";
import ContactSelectBoxItem from "../../components/SelectBoxItems/ContactSelectBoxItem.js";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import EvidenceAPI from "../../api/evidence/index.js";
import ContactsAPI from "../../api/contacts/index.js";
import Loader from "../../components/Loader.js";
import { DevexEditors } from "../../utils/devex-editors.js";
import { logActivity } from "../../api/index.js";
import CocContent from "./CocContent.js";
import PhotosContent from "./PhotosContent.js";
import ChildItemsContent from "./ChildItemsContent.js";
import ProgressBar from "../../Monolith-UI/ProgressBar/ProgressBar.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import OverviewContent from "./OverviewContent.js";
import AcquisitionView from "../Acquisitions/AcquisitionView.js";
import AuditContent from "./AuditContent.js";
import { safeParseJSON } from "../../utils/File-parsers/safeParseJSON.js";

const EvidenceOverview = styled(({ className, evidenceInfo }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();

  const { data: evidenceProgress } = useQuery({
    queryKey: ["evidence:progress"],
    queryFn: () => EvidenceAPI.getEvidenceProgress(),
  });

  const handleProgressUpdate = (item) => {
    EvidenceAPI.updateEvidence(evidenceInfo.evidence_id, {
      progress: item.progress_name,
    });
    queryClient.setQueryData(
      ["evidence:list", { uuid: evidenceInfo.uuid, include_children: true }],
      (oldData) => {
        if (oldData[0])
          return [{ ...oldData[0], progress: item.progress_name }];
      }
    );
  };

  return (
    <div className={className}>
      <div>
        <div
          style={{
            margin: "20px 0px",
            padding: "20px",
            paddingTop: "30px",
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 5,
          }}
        >
          <ProgressBar
            defaultValue={evidenceInfo.progress}
            progressItems={evidenceProgress || []}
            onSelect={handleProgressUpdate}
            enabled={hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE)}
          />
        </div>
        <Details details={evidenceInfo} />
      </div>
    </div>
  );
})`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  height: 0px;
  padding-right: 10px;
`;

const Details = styled(({ className, details }) => {
  return (
    <div className={className}>
      <SideContent details={details} />
      <MainContent details={details} />
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  min-height: calc(100vh - 200px);
`;

const SideContent = styled(({ className, details }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(true);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditCustomFieldsModal, setShowEditCustomFieldsModal] =
    useState(false);

  const dataFieldIgnoreList = ["description", "case_ref", "case_number"];

  const evidenceInfo = {
    ...details,
  };

  delete evidenceInfo.custom_attributes;
  delete evidenceInfo.case_ref;
  delete evidenceInfo.case_number;

  const caseInfo = {
    case_number: details?.case_number,
    case_ref: details?.case_ref,
  };

  const evidenceDetails = (
    <div className="detail-container">
      {TableColumnDefs.filter(
        (c) => !dataFieldIgnoreList.includes(c.dataField)
      ).map((c) => {
        return (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{c.caption}</div>
            {c.render ? (
              c.render(evidenceInfo, {
                onRowUpdated:
                  c.dataField === "progress"
                    ? (item) => {
                        onEdit();
                      }
                    : null,
              })
            ) : c.dataType === "date" ? (
              <>
                {!!evidenceInfo[c.dataField] &&
                  monolithMoment({
                    timestamp: evidenceInfo[c.dataField],
                    includeTime: true,
                  })}
              </>
            ) : (
              <div className="detail-value">{evidenceInfo[c.dataField]}</div>
            )}
          </div>
        );
      })}
    </div>
  );

  const caseDetails = (
    <div className="detail-container">
      {TableColumnDefs.map((c) => {
        if (!caseInfo[c.dataField]) return null;
        return (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{c.caption}</div>
            {c.render ? (
              c.render(details)
            ) : c.dataType === "date" ? (
              <>
                {monolithMoment({
                  timestamp: caseInfo[c.dataField],
                  includeTime: true,
                })}
              </>
            ) : (
              <div className="detail-value">{caseInfo[c.dataField]}</div>
            )}
          </div>
        );
      })}
    </div>
  );

  const customFieldData =
    details?.custom_attributes?.filter((c) =>
      c.value === null || c.value === "" || c.value === "[]" ? false : true
    ) || [];

  const customDetails = (
    <div className="detail-container">
      {customFieldData.map((c) => {
        if (c.value === null) return null;
        if (c.value === "") return null;
        if (c.value === "[]") return null;

        const parsedValue = safeParseJSON(c.value);

        return (
          <div className="detail-item" key={nanoid()}>
            <div className="detail-label">{c.field_name}</div>
            {c.editor_type === "dateBox" ? (
              <>
                {monolithMoment({
                  timestamp: c.value,
                  includeTime: false,
                })}
              </>
            ) : c.editor_type === "tagBox" ? (
              <div className="detail-value">
                {Array.isArray(parsedValue)
                  ? parsedValue.map((v) => v).join(", ")
                  : parsedValue}
              </div>
            ) : (
              <div className="detail-value">{parsedValue}</div>
            )}
          </div>
        );
      }) || []}
      {customFieldData.length === 0 && (
        <div className="detail-item">
          <div className="detail-label">No custom data</div>
        </div>
      )}
    </div>
  );

  const onEdit = (newData) => {
    queryClient.refetchQueries(["evidence:list", { uuid: evidenceInfo.uuid }]);
  };

  return (
    <div className={className + (open ? " expanded" : " collapsed")}>
      <div
        className={
          open
            ? "collapse-btn side-content-expanded"
            : "collapse-btn side-content-collapsed"
        }
      >
        {open && <div className="header">Case Info</div>}
        <TaskButton variant="outlined" onClick={() => setOpen(!open)}>
          <KeyboardTabOutlinedIcon style={{ fontSize: 16 }} />
        </TaskButton>
      </div>
      {open && (
        <>
          {caseDetails}
          <div className="header-container">
            <div className="header" style={{ marginRight: 15 }}>
              Evidence Details
            </div>
            {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE) && (
              <div
                className="action-menu-item"
                onClick={() => setShowEditModal(true)}
              >
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
            )}
          </div>
          {evidenceDetails}
          <div className="header-container">
            <div className="header" style={{ marginRight: 15 }}>
              Custom Fields
            </div>
            {hasPermission(MONOLITH_PERMISSIONS.EVIDENCE_UPDATE) && (
              <div
                className="action-menu-item"
                onClick={() => setShowEditCustomFieldsModal(true)}
              >
                <EditOutlinedIcon
                  style={{ color: theme.palette.primary.main }}
                />
                <div className="action-menu-label">Edit</div>
              </div>
            )}
          </div>
          {customDetails}
          <EditDetailsModal
            open={showEditModal}
            handleClose={() => setShowEditModal(false)}
            onSubmit={onEdit}
            defaultInfo={details}
          />
          <EditCustomFieldsModal
            open={showEditCustomFieldsModal}
            handleClose={() => setShowEditCustomFieldsModal(false)}
            onSubmit={onEdit}
            defaultInfo={details}
          />
        </>
      )}
    </div>
  );
})`
  max-width: 425px;
  min-width: 425px;
  min-height: 350px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.1s ease-in-out;

  &.collapsed {
    min-width: 50px;
    max-width: 50px;
  }

  & .collapse-btn {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .side-content-expanded {
    svg {
      transform: rotate(180deg);
    }
  }

  & .side-content-collapsed {
    justify-content: center;
  }

  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
    cursor: default;
  }
  & .header-container {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 20px 0px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    margin-left: auto;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: baseline;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
    width: 100%;
    white-space: pre-wrap;
  }
`;

const MainContent = styled(({ className, details }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const { uuid } = useParams();
  const [currentTab, setCurrentTab] = useState(0);

  const buttonStyle = {
    minWidth: "150px",
    padding: "2px 5px",
  };

  const handleChange = (event, newGroup) => {
    if (newGroup !== null) {
      setCurrentTab(newGroup);
    }
  };

  const control = {
    value: currentTab,
    onChange: handleChange,
    exclusive: true,
  };

  useEffect(() => {
    setCurrentTab(0);
  }, [uuid]);

  return (
    <div className={className}>
      <div className="toggle-menu">
        <ToggleButtonGroup size="small" {...control} style={{ margin: "auto" }}>
          <ToggleButton
            value={0}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            Overview
          </ToggleButton>
          <ToggleButton
            value={1}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            Chain of Custody
          </ToggleButton>
          <ToggleButton
            value={2}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Acquisitions</>
          </ToggleButton>
          {details.is_parent === 1 && (
            <ToggleButton
              value={3}
              size="small"
              disableRipple={true}
              style={buttonStyle}
            >
              <>Child Items</>
            </ToggleButton>
          )}
          <ToggleButton
            value={4}
            size="small"
            disableRipple={true}
            style={buttonStyle}
          >
            <>Photos</>
          </ToggleButton>
          {hasPermission(MONOLITH_PERMISSIONS.AUDITS_READ) && (
            <ToggleButton
              value={5}
              size="small"
              disableRipple={true}
              style={buttonStyle}
            >
              <>Audit Logs</>
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </div>
      {currentTab === 0 && <OverviewContent evidenceInfo={details} />}
      {currentTab === 1 && <CocContent evidenceInfo={details} />}
      {currentTab === 2 && <AcquisitionView infoData={details} />}
      {currentTab === 3 && <ChildItemsContent evidenceInfo={details} />}
      {currentTab === 4 && <PhotosContent evidenceInfo={details} />}
      {hasPermission(MONOLITH_PERMISSIONS.AUDITS_READ) && currentTab === 5 && (
        <AuditContent evidenceInfo={details} />
      )}
    </div>
  );
})`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0px;

  & .toggle-menu {
    margin-bottom: 10px;
    display: flex;
    overflow-x: auto;
  }
  & .header {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 1.1rem;
    font-weight: 500;
  }
  & .description {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 12px;
    margin-top: 10px;
  }
`;

function convertToBytes(size, unit) {
  switch (unit) {
    case "KB":
      return size * 1024;
    case "MB":
      return size * 1024 * 1024;
    case "GB":
      return size * 1024 * 1024 * 1024;
    case "TB":
      return size * 1024 * 1024 * 1024 * 1024;
    default:
      return size;
  }
}

const EditDetailsModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef(null);

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      const formData = Object.assign(
        {},
        form.current.instance.option("formData")
      );

      handleClose();

      const updateData = {};

      Object.keys(formData).forEach((key) => {
        if (formData[key] !== defaultInfo[key]) {
          updateData[key] = formData[key];
        }
      });

      EvidenceAPI.updateEvidence(defaultInfo.evidence_id, updateData).then(
        (res) => {
          enqueueSnackbar("Evidence updated successfully", {
            variant: "success",
          });
          logActivity(
            defaultInfo.case_id,
            currentUser.user_id,
            `Edited Evidence Details ${defaultInfo.evidence_number}`
          );

          if (updateData.capacity_unit) {
            updateData.capacity_bytes = convertToBytes(
              defaultInfo.capacity,
              updateData.capacity_unit
            );
          }
          if (updateData.capacity) {
            updateData.capacity_bytes = convertToBytes(
              updateData.capacity,
              defaultInfo.capacity_unit
            );
          }
          onSubmit(updateData);
        }
      );
    }
  };

  const handleCancel = () => handleClose();

  const result = useQueries({
    queries: [
      {
        queryKey: ["evidence:types"],
        queryFn: () => EvidenceAPI.getEvidenceTypes(),
        enabled: open,
      },
      {
        queryKey: ["evidence:providers"],
        queryFn: () => EvidenceAPI.getEvidenceBrands(),
        enabled: open,
      },
      {
        queryKey: ["people:contacts", { case_id: defaultInfo.case_id }],
        queryFn: () =>
          ContactsAPI.getContacts({
            case_id: defaultInfo.case_id,
          }),
        enabled: open,
      },
    ],
  });

  const isDone = result.every((x) => x.isFetched);

  const fieldData = {
    evidenceTypes: result[0]?.data || [],
    evidenceProviders: result[1]?.data || [],
    contacts: result[2]?.data || [],
  };

  const defaultFormData = {
    evidence_number: defaultInfo.evidence_number,
    item_type: defaultInfo.item_type,
    manufacturer: defaultInfo.manufacturer,
    model_name: defaultInfo.model_name,
    model_number: defaultInfo.model_number,
    serial_number: defaultInfo.serial_number,
    capacity: defaultInfo.capacity,
    capacity_unit: defaultInfo.capacity_unit,
    location_received: defaultInfo.location_received,
    description: defaultInfo.description,
    contact_id: defaultInfo.contact_id,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 800,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 400px)",
            overflowY: "auto",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Evidence Details
          </div>
          {isDone && (
            <>
              <Form ref={form} colCount={2} defaultFormData={defaultFormData}>
                <SimpleItem
                  dataField="evidence_number"
                  label={{ text: "Evidence Number" }}
                  isRequired={true}
                  editorOptions={{
                    hint: "Enter a unique ID that identifies this evidence.",
                    placeholder: "Leave blank to auto-generate evidence number",
                  }}
                />
                <SimpleItem
                  dataField="item_type"
                  label={{ text: "Evidence Type" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.evidenceTypes,
                    placeholder: "Select Evidence Type...",
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    displayExpr: "evi_type",
                    valueExpr: "evi_type",
                    searchEnabled: true,
                  }}
                />
                <SimpleItem
                  dataField="manufacturer"
                  label={{ text: "Item Brand" }}
                  editorType="dxAutocomplete"
                  editorOptions={{
                    dataSource: fieldData.evidenceProviders,
                    dropDownOptions: {
                      maxHeight: 250,
                    },
                    placeholder: "Apple, Google, Samsung...",
                    displayExpr: "manufacturer",
                    valueExpr: "manufacturer",
                    searchEnabled: true,
                    acceptCustomValue: true,
                  }}
                />
                <SimpleItem
                  dataField="model_name"
                  label={{ text: "Item Name" }}
                  editorOptions={{
                    placeholder: "iPhone Xs, WINSRV003, Smith Email Account...",
                    hint: "Enter the model name of the device.",
                  }}
                />
                <SimpleItem
                  dataField="model_number"
                  label={{ text: "Model Number or Service" }}
                  editorOptions={{
                    placeholder: "A1234, WD2500, GMail...",
                    hint: "Enter the model number for this item.",
                  }}
                />
                <SimpleItem
                  dataField="serial_number"
                  label={{ text: "Unique Identifier" }}
                  editorOptions={{
                    placeholder: "first.last@email.com, serial number...",
                    hint: "Enter a unique identifier for this item.",
                  }}
                />
                <GroupItem colCount={2} cssClass="dxform-group-no-padding">
                  <SimpleItem
                    dataField="capacity"
                    label={{ text: "Size" }}
                    editorType="dxNumberBox"
                    editorOptions={{
                      min: 0,
                      max: 999,
                      showSpinButtons: true,
                    }}
                  />
                  <SimpleItem
                    dataField="capacity_unit"
                    label={{ text: "Size Unit" }}
                    editorType="dxSelectBox"
                    editorOptions={{
                      items: ["KB", "MB", "GB", "TB"],
                    }}
                  />
                </GroupItem>
                <SimpleItem
                  dataField="contact_id"
                  label={{ text: "Linked Contact" }}
                  editorType="dxSelectBox"
                  editorOptions={{
                    dataSource: fieldData.contacts,
                    placeholder: "Select Contact...",
                    dropDownOptions: {
                      maxHeight: 300,
                    },
                    displayExpr: "name",
                    valueExpr: "client_id",
                    searchEnabled: true,
                    showClearButton: true,
                    openOnFieldClick: false,
                    onFocusIn: (e) => {
                      e.component.open();
                    },
                    itemTemplate: "contact_id",
                  }}
                />
                <Template
                  name="contact_id"
                  render={(data) => <ContactSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="location_received"
                  label={{ text: "Location Recieved" }}
                  editorOptions={{
                    placeholder: "Address, Front Office, etc...",
                    hint: "Location at time of intake.",
                  }}
                />
                <EmptyItem />
                <SimpleItem
                  dataField="description"
                  label={{ text: "Description" }}
                  colSpan={2}
                  editorType="dxTextArea"
                  editorOptions={{
                    height: 135,
                    placeholder:
                      "Enter a brief description of this item and any important details.",
                  }}
                />
              </Form>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <ToolBarItems
                  submitText="Edit Evidence"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
          {!isDone && <Loader />}
        </div>
      </Modal>
    </div>
  );
};

const EditCustomFieldsModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef(null);

  const defaultFormData = {};

  defaultInfo.custom_attributes?.forEach((attr) => {
    defaultFormData[`custom_attribute_${attr.field_id}`] = safeParseJSON(
      attr.value
    );
  });

  const { data: customFields } = useQuery({
    queryKey: ["evidence:customFields"],
    queryFn: () => EvidenceAPI.getCustomFields({ fieldsOnly: true }),
    select: (data) => data?.filter((f) => f.enabled === 1),
  });

  const handleSubmit = () => {
    if (form.current.instance.validate().isValid) {
      const formData = Object.assign(
        {},
        form.current.instance.option("formData")
      );

      handleClose();

      const updateData = {};

      Object.keys(formData).forEach((key) => {
        if (
          typeof defaultFormData[key] === "undefined" ||
          (formData[key] ? formData[key].toString() : formData[key]) !==
            (defaultFormData[key]
              ? defaultFormData[key].toString()
              : defaultFormData[key])
        ) {
          updateData[key] = formData[key];
        }
      });

      //Get custom attributes from updateData object
      //Compile them into a single object array with related attrubite values
      //remove original form items from form data
      const customAttributes = [];
      for (const key of Object.keys(updateData)) {
        const field_id = parseInt(key.replace("custom_attribute_", ""));
        if (!field_id) continue;

        const currentAttr = defaultInfo.custom_attributes.find((attribute) => {
          return attribute.field_id === field_id;
        });

        //evidence has attribute & it hasnt changed
        if (currentAttr && currentAttr.value === updateData[key]) continue;

        const baseAttr = customFields.find(
          (attr) => attr.field_id === field_id
        );

        customAttributes.push({
          attribute_id: currentAttr ? currentAttr.attribute_id : null,
          field_id: field_id,
          field_name: baseAttr.field_name,
          item_id: defaultInfo.evidence_id,
          value: updateData[key],
          editor_type: baseAttr.editor_type,
          description: baseAttr.description,
          options: baseAttr.options || null,
        });

        delete updateData[key];
      }

      EvidenceAPI.updateEvidence(defaultInfo.evidence_id, {
        custom_attributes: customAttributes,
      }).then((res) => {
        enqueueSnackbar("Evidence updated successfully.", {
          variant: "success",
        });

        logActivity(
          defaultInfo.case_id,
          currentUser.user_id,
          `Edited Evidence Custom Fields ${defaultInfo.evidence_number}`
        );

        onSubmit({
          custom_attributes: [
            ...defaultInfo.custom_attributes.filter((ca) => {
              return !customAttributes.find(
                (ca2) => ca2.field_id === ca.field_id
              );
            }),
            ...customAttributes.map((ca) => {
              return {
                ...ca,
                value:
                  ca.editor_type === "tagBox"
                    ? JSON.stringify(ca.value)
                    : ca.value,
              };
            }),
          ],
        });
      });
    }
  };

  const handleCancel = () => handleClose();

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 800,
            maxHeight: "90vh",
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 400px)",
            overflowY: "auto",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Edit Evidence Custom Fields
          </div>
          {customFields && (
            <>
              <Form
                ref={form}
                colCount={2}
                defaultFormData={{ ...defaultFormData }}
              >
                {customFields &&
                  customFields
                    .filter(
                      (r) =>
                        r?.type_ids?.length === 0 ||
                        (defaultInfo.evi_type_id &&
                          r?.type_ids?.includes?.(defaultInfo.evi_type_id))
                    )
                    .map((attribute) => {
                      return (
                        <SimpleItem
                          key={`custom_attribute_${attribute.field_id}`}
                          dataField={`custom_attribute_${attribute.field_id}`}
                          editorType={DevexEditors[attribute.editor_type]}
                          isRequired={attribute.is_required === 1}
                          label={{ text: attribute.field_name }}
                          editorOptions={{
                            placeholder: attribute.description || "",
                            hint: attribute.description || "",
                            items: JSON.parse(attribute.options || "[]"),
                            dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                            useMaskBehavior: true,
                            displayFormat: getDateFormat(false, false),
                            showClearButton:
                              attribute.editor_type !== "textBox",
                            pickerType: "calendar",
                            showDropDownButton: true,
                            multiline: false,
                            showSelectionControls:
                              attribute.editor_type === "tagBox" ? true : false,
                          }}
                        />
                      );
                    })}
              </Form>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <ToolBarItems
                  submitText="Edit Evidence"
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                />
              </div>
            </>
          )}
          {!customFields && <Loader />}
        </div>
      </Modal>
    </div>
  );
};

export default EvidenceOverview;
