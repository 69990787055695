import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { Popup } from "devextreme-react/ui/popup";
import { LoadIndicator } from "devextreme-react/ui/load-indicator";
import { Tabs } from "devextreme-react/ui/tabs";
import { DataGrid, Column } from "devextreme-react/ui/data-grid";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { Helmet } from "react-helmet-async";
import { useTheme, IconButton, Button, Tooltip } from "@mui/material";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";

import {
  deleteLocation,
  getEvidence,
  getLocations,
  getStorage,
  updateLocation,
} from "../../api";

import { getDateFormat } from "../../utils/date-format";
import { usePermissions } from "../../hooks/usePermissions";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import styled from "styled-components";

const LocationDetails = styled(({ className }) => {
  const { location_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const [locationData, setLocationData] = useState(null);
  const [locationGroups, setLocationGroups] = useState([]);
  const form = useRef(null);
  const confirmLocationDeletePopup = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getLocations({ office_id: searchParams.get("office_id") }).then(
      (result) => {
        setLocationData(result.find((l) => l.location_id == location_id));
        setLocationGroups(
          result.filter((l) => l.is_parent === 1 && l.location_id !== 2)
        );
      }
    );
  }, []);

  if (!hasPermission(MONOLITH_PERMISSIONS.LOCATIONS_READ_ALL)) {
    return (
      <div className={className}>
        <Helmet title="Location Details" />
        <div>
          <h1>Permission Denied</h1>
          <p>
            You do not have permission to view this page. Please contact your
            administrator if you believe this is an error.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <Helmet title="Location Details" />
      {locationData && (
        <>
          <div
            className="pageHeader"
            style={{
              padding: "10px 5px",
              margin: "0px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <div style={{ fontSize: "x-large" }}>
              {locationData.location_name}
              <div style={{ color: "slategray", fontSize: "small" }}>
                Office: {locationData.office_name}
              </div>
            </div>
            <div style={{ fontSize: "small", marginLeft: "auto" }}>
              <span
                style={{
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                }}
                onMouseEnter={(event) => {
                  event.target.style.textDecoration = "underline";
                }}
                onMouseLeave={(event) => {
                  event.target.style.textDecoration = "none";
                }}
                onClick={(event) => {
                  navigate(`/locations`);
                }}
              >
                {"< Back to Locations"}
              </span>
            </div>
          </div>
          <div style={{ boxShadow: "none" }}>
            <div className="dx-card">
              <div
                style={{
                  padding: "40px",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: 750,
                  }}
                >
                  <div>
                    <div style={{ fontSize: "large" }}>Location Details</div>
                    <div style={{ color: "slategrey" }}></div>
                  </div>
                  <div>
                    <Form
                      ref={form}
                      defaultFormData={{
                        location_name: locationData.location_name,
                        location_type: locationData.location_type,
                        parent_id: locationData.parent_id,
                      }}
                      width={450}
                      colCount={1}
                      readOnly={
                        !hasPermission(MONOLITH_PERMISSIONS.LOCATIONS_UPDATE) ||
                        locationData.parent_id === 2
                      }
                    >
                      <SimpleItem
                        dataField="location_name"
                        label={{ text: "Location Name" }}
                        isRequired={true}
                      />
                      <SimpleItem
                        dataField="location_type"
                        label={{ text: "Type" }}
                        isRequired={false}
                      />
                      <SimpleItem
                        dataField="parent_id"
                        label={{ text: "Location Group" }}
                        isRequired={true}
                        editorType="dxSelectBox"
                        editorOptions={{
                          items: locationGroups,
                          valueExpr: "location_id",
                          displayExpr: "location_name",
                        }}
                      />
                    </Form>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "35px",
                    width: 750,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={
                      !hasPermission(MONOLITH_PERMISSIONS.LOCATIONS_UPDATE) ||
                      locationData.parent_id === 2
                    }
                    onClick={async (e) => {
                      if (form.current.instance.validate().isValid) {
                        let formData = form.current.instance.option().formData;

                        //Collect Changed Fields
                        let newValues = {};
                        for (const key of Object.keys(formData)) {
                          if (locationData[key] !== formData[key]) {
                            newValues[key] = formData[key];
                          }
                        }

                        updateLocation(locationData.location_id, newValues);
                        enqueueSnackbar("Location Updated", {
                          variant: "success",
                        });
                        setLocationData({
                          ...locationData,
                          ...{ ...newValues },
                        });
                      }
                    }}
                  >
                    Update Location
                  </Button>
                </div>
              </div>
            </div>
            <TabSection locationInfo={locationData} />
            {hasPermission() && locationData.parent_id !== 2 && (
              <div
                className="dx-card"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "40px",
                  marginTop: "25px",
                  textAlign: "left",
                  alignItems: "center",
                }}
              >
                <div>
                  <div style={{ fontSize: "large" }}>Delete Location</div>
                  <div style={{ color: "slategrey" }}>
                    Delete this location from Monolith.
                  </div>
                </div>
                <div>
                  {" "}
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={(e) => {
                      confirmLocationDeletePopup.current.instance.show();
                    }}
                  >
                    Delete Location
                  </Button>
                </div>
              </div>
            )}
          </div>
          <ConfirmLocationDeletePopup
            confirmLocationDeletePopup={confirmLocationDeletePopup}
            locationData={locationData}
            setLocationData={setLocationData}
          />
        </>
      )}
    </div>
  );
})`
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;
  padding-right: 10px;
`;

const ConfirmLocationDeletePopup = ({
  confirmLocationDeletePopup,
  locationData,
  setLocationData,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Popup
      ref={confirmLocationDeletePopup}
      title="Delete Location"
      showTitle={true}
      showCloseButton={true}
      width={450}
      height={175}
      position={{
        offset: "100 0",
      }}
    >
      {locationData.evidence_count + locationData.storage_count > 0 && (
        <div>
          To delete this location, move its evidence and storage items to
          another location.
        </div>
      )}
      {locationData.evidence_count + locationData.storage_count === 0 && (
        <>
          <div>
            Are you sure you want to delete location "
            {locationData.location_name}"?
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: 5,
          marginTop: 30,
          color: "white",
        }}
      >
        <Button
          variant="text"
          size="small"
          style={{ marginRight: 10 }}
          onClick={(e) => {
            confirmLocationDeletePopup.current.instance.hide();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          disabled={
            locationData.evidence_count + locationData.storage_count > 0
          }
          onClick={async (e) => {
            confirmLocationDeletePopup.current.instance.hide();
            deleteLocation(locationData.location_id);
            enqueueSnackbar("Location Deleted.", {
              variant: "success",
            });
            navigate(`/locations`);
          }}
        >
          Delete Location
        </Button>
      </div>
    </Popup>
  );
};

const TabSection = ({ locationInfo }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div style={{ minHeight: "450px", marginTop: 25 }}>
      <Tabs
        items={["Evidence Items", "Storage Items"]}
        selectedIndex={selectedTab}
        focusStateEnabled={false}
        hoverStateEnabled={false}
        activeStateEnabled={false}
        width={250}
        onItemClick={(e) => {
          e.event.preventDefault();
          e.event.stopPropagation();
          setSelectedTab(e.itemIndex);
        }}
      />
      {selectedTab === 0 && <EvidenceItemGrid locationInfo={locationInfo} />}
      {selectedTab === 1 && <StorageItemGrid locationInfo={locationInfo} />}
    </div>
  );
};

const EvidenceItemGrid = ({ locationInfo }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [evidenceItems, setEvidenceItems] = useState(null);
  const evidenceReportGrid = useRef(null);

  useEffect(() => {
    getEvidence({ location_id: locationInfo.location_id }).then((result) => {
      setEvidenceItems(result);
    });
  }, [locationInfo]);

  return (
    <div
      className="dx-card"
      style={{
        padding: "20px",
        marginBottom: "20px",
        height: 450,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Evidence currently at {locationInfo.location_name}
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Tooltip arrow title="Export To Excel">
            <IconButton
              color="primary"
              size="small"
              disableRipple={false}
              onClick={(e) => {
                evidenceReportGrid.current.instance.exportToExcel();
              }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Select Columns">
            <IconButton
              color="primary"
              size="small"
              disableRipple={false}
              onClick={(e) => {
                evidenceReportGrid.current.instance.showColumnChooser();
              }}
            >
              <ViewColumnOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {!evidenceItems && (
        <div style={{ margin: "auto", marginTop: 50 }}>
          <LoadIndicator />
        </div>
      )}
      {evidenceItems && (
        <DataGrid
          ref={evidenceReportGrid}
          dataSource={evidenceItems}
          height="350px"
          showRowLines={true}
          columnAutoWidth={false}
          columnMinWidth={150}
          width="100%"
          columnResizingMode="widget"
          allowColumnReordering={true}
          allowColumnResizing={true}
          onToolbarPreparing={(e) => {
            e.toolbarOptions.visible = false;
          }}
          paging={{
            pageSize: 20,
          }}
          scrolling={{
            mode: "virtual",
            rowRenderingMode: "virtual",
            useNative: false,
          }}
          headerFilter={{
            visible: true,
            allowSearch: true,
          }}
          hoverStateEnabled={true}
          columnChooser={{
            enabled: true,
            mode: "select",
            title: "Filter Columns",
            height: "350",
            width: "325",
          }}
        >
          <Column
            dataField="evidence_number"
            caption="Evidence Number"
            allowReordering={false}
            showInColumnChooser={false}
            visible={true}
          />
          <Column
            dataField="case_number"
            caption="Case Number"
            allowReordering={false}
            showInColumnChooser={false}
            visible={true}
            cellRender={(e) => {
              return (
                <div>
                  <div>
                    <span
                      style={{
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                      }}
                      onMouseEnter={(event) => {
                        event.target.style.textDecoration = "underline";
                      }}
                      onMouseLeave={(event) => {
                        event.target.style.textDecoration = "none";
                      }}
                      onClick={(event) => {
                        navigate(`/cases/${e.data.case_id}/overview`);
                      }}
                    >
                      {e.data.case_number}
                    </span>
                  </div>
                </div>
              );
            }}
          />
          <Column
            dataField="case_ref"
            caption="Case Name"
            allowReordering={false}
            showInColumnChooser={false}
            visible={true}
          />
          <Column
            dataField="coc_intake_timestamp"
            caption="Intake Date"
            dataType="date"
            format={{
              type: getDateFormat(false, false),
            }}
          />
          <Column dataField="item_type" caption="Type" />
          <Column dataField="manufacturer" caption="Brand" />
          <Column dataField="model_name" caption="Item Name" />
          <Column
            visible={false}
            dataField="model_number"
            caption="Model Number"
          />
          <Column
            visible={false}
            dataField="serial_number"
            caption="Serial Number"
          />
          <Column
            visible={true}
            dataField="capacity_bytes"
            caption="Size"
            alignment="left"
            headerFilter={{ groupInterval: 100 }}
            calculateSortValue={(rowData) => {
              return rowData.capacity_bytes;
            }}
            cellTemplate={(container, options) => {
              if (
                options.data.capacity != null &&
                options.data.capacity_unit != null
              ) {
                container.innerHTML =
                  options.data.capacity + " " + options.data.capacity_unit;
              }
            }}
          />
          <Column
            visible={false}
            dataField="capacity"
            caption="Size (no unit)"
            alignment="left"
            headerFilter={{ groupInterval: 100 }}
            calculateSortValue={(rowData) => {
              return rowData.capacity_bytes;
            }}
            cellTemplate={(container, options) => {
              if (
                options.data.capacity != null &&
                options.data.capacity_unit != null
              ) {
                container.innerHTML = options.data.capacity;
              }
            }}
          />
          <Column
            visible={false}
            allowSearch={false}
            dataField="capacity_unit"
            caption="Size Unit"
          />
          <Column
            visible={false}
            dataField="client_name"
            caption="Client Name"
          />
          <Column
            visible={false}
            dataField="organization"
            caption="Organization"
          />
          <Column visible={false} dataField="office_name" caption="Office" />
          <Column
            visible={false}
            dataField="location_name"
            caption="Location"
          />
          <Column
            visible={false}
            dataField="description"
            width={150}
            caption="Description"
          />
        </DataGrid>
      )}
    </div>
  );
};

const StorageItemGrid = ({ locationInfo }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [storageItems, setStorageItems] = useState(null);
  const storageReportGrid = useRef(null);

  useEffect(() => {
    getStorage({ location_id: locationInfo.location_id }).then((result) => {
      setStorageItems(result);
    });
  }, [locationInfo]);
  return (
    <div
      className="dx-card"
      style={{
        padding: "20px",
        marginBottom: "20px",
        height: 450,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            fontSize: "larger",
          }}
        >
          Storage currently at {locationInfo.location_name}
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Tooltip arrow title="Export To Excel">
            <IconButton
              color="primary"
              size="small"
              disableRipple={false}
              onClick={(e) => {
                storageReportGrid.current.instance.exportToExcel();
              }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Select Columns">
            <IconButton
              color="primary"
              size="small"
              disableRipple={false}
              onClick={(e) => {
                storageReportGrid.current.instance.showColumnChooser();
              }}
            >
              <ViewColumnOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {!storageItems && (
        <div style={{ margin: "auto", marginTop: 50 }}>
          <LoadIndicator />
        </div>
      )}
      {storageItems && (
        <DataGrid
          ref={storageReportGrid}
          dataSource={storageItems}
          height="350px"
          showRowLines={true}
          columnAutoWidth={false}
          columnMinWidth={150}
          width="100%"
          columnResizingMode="widget"
          allowColumnReordering={true}
          allowColumnResizing={true}
          onToolbarPreparing={(e) => {
            e.toolbarOptions.visible = false;
          }}
          paging={{
            pageSize: 20,
          }}
          stateStoring={{
            enabled: false,
            type: "localStorage",
            storageKey: "reportDetails-grid",
            savingTimeout: 10,
          }}
          scrolling={{
            mode: "virtual",
            rowRenderingMode: "virtual",
            useNative: false,
          }}
          headerFilter={{
            visible: true,
            allowSearch: true,
          }}
          hoverStateEnabled={true}
          columnChooser={{
            enabled: true,
            mode: "select",
            title: "Filter Columns",
            height: "350",
            width: "325",
          }}
        >
          <Column dataField="storage_number" caption="Storage Number" />
          <Column
            dataField="case_number"
            caption="Assigned Case"
            allowReordering={false}
            visible={true}
            cellRender={(e) => {
              return (
                <div>
                  <div>
                    <span
                      style={{
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                      }}
                      onMouseEnter={(event) => {
                        event.target.style.textDecoration = "underline";
                      }}
                      onMouseLeave={(event) => {
                        event.target.style.textDecoration = "none";
                      }}
                      onClick={(event) => {
                        navigate(`/cases/${e.data.case_id}/overview`);
                      }}
                    >
                      {e.data.case_number}
                    </span>
                  </div>
                </div>
              );
            }}
          />
          <Column
            dataField="date_added"
            caption="Date Created"
            dataType="date"
            format={{ type: getDateFormat(false, false) }}
          />
          <Column
            dataField="date_assigned"
            caption="Date Assigned"
            dataType="date"
            visible={false}
            format={{ type: getDateFormat(false, false) }}
          />
          <Column
            dataField="is_general_storage"
            caption="General Storage"
            visible={false}
            alignment="left"
            calculateCellValue={(rowData) => {
              return rowData.is_general_storage === 0 ? "No" : "Yes";
            }}
          />
          <Column dataField="type" caption="Type" />
          <Column dataField="make" caption="Brand" />
          <Column dataField="model_name" caption="Model" />
          <Column
            dataField="model_number"
            caption="Model Number"
            visible={false}
          />
          <Column
            dataField="serial_number"
            caption="Serial Number"
            visible={false}
          />
          <Column
            dataField="capacity"
            caption="Size"
            alignment="left"
            headerFilter={{ groupInterval: 100 }}
            calculateSortValue={(rowData) => {
              return rowData.capacity_bytes;
            }}
            cellRender={(e) => {
              if (e.data.capacity && e.data.capacity_unit) {
                return <>{e.data.capacity + " " + e.data.capacity_unit}</>;
              }
            }}
          />
          <Column dataField="capacity_unit" caption="Unit" visible={false} />
          <Column
            dataField="location_name"
            caption="Location"
            visible={false}
          />
          <Column
            dataField="notes"
            width={150}
            caption="Description"
            visible={false}
          />
        </DataGrid>
      )}
    </div>
  );
};

export default LocationDetails;
