import OfficesApi from "../../api/offices/index.js";

const UsersFilterDimensions = [
  {
    name: "Role",
    field: "user_role",
    id: "user_role",
    type: "string",
    mode: "multi-select",
    key: ["users:roles"],
    selectionDisplayName: "text",
    selectionIdField: "value",
    queryFn: (params) =>
      Promise.resolve([
        {
          text: "Super Admin",
          value: "super_admin",
        },
        {
          text: "Admin",
          value: "admin",
        },
        {
          text: "User",
          value: "user",
        },
      ]),
  },
  {
    name: "Status",
    field: "active",
    id: "active",
    type: "string",
    mode: "multi-select",
    key: ["users:statuses"],
    selectionDisplayName: "text",
    selectionIdField: "value",
    queryFn: (params) =>
      Promise.resolve([
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Deactivated",
          value: 0,
        },
      ]),
  },
  {
    name: "Office",
    field: "office_id",
    id: "office_id",
    type: "string",
    mode: "multi-select",
    key: ["offices:list"],
    selectionDisplayName: "office_name",
    selectionIdField: "office_id",
    queryFn: (params) => OfficesApi.getOffices(),
  },
];

export default UsersFilterDimensions;
