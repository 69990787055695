import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePermissions } from "../../../hooks/usePermissions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import MonolithNotesAPI from "../../../api/Notes/index.js";
import moment from "moment";
import { getDateFormat } from "../../../utils/date-format.js";
import NoteSystem from "../../../Monolith-UI/NoteSystem/NoteSystem.js";
import IndexedDBService from "../../../IndexedDB/IndexedDBService.js";
import DropdownMenu from "../../../Monolith-UI/DropdownMenu/DropdownMenu.js";
import EvidenceAPI from "../../../api/evidence/index.js";
import styled from "styled-components";
import * as Tooltip from "@radix-ui/react-tooltip";
import DetailItem from "../../../Monolith-UI/DetailItem.js";
import { useParams } from "react-router-dom";
import LoggingAPI from "../../../api/logging/index.js";
import SaveTemplateModal from "../../../Monolith-UI/NoteSystem/Components/SaveTemplateModal.js";
import { NoteView } from "../../../Monolith-UI/NoteSystem/Enums/NoteView.js";
import { useSnackbar } from "notistack";
import CasesApi from "../../../api/cases/index.js";

const EvidenceItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 0px;
`;

const EvidenceItemTitle = styled.div``;

const EvidenceItemSubtitle = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;
`;

const EvidenceComponent = styled(({ className, item }) => {
  return (
    <Tooltip.Provider delayDuration={400} skipDelayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <EvidenceItem>
            <EvidenceItemTitle>{item.evidence_number}</EvidenceItemTitle>
            <EvidenceItemSubtitle>
              {item.manufacturer} {item.item_type}
            </EvidenceItemSubtitle>
          </EvidenceItem>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className={className}
            side="right"
            sideOffset={15}
            align="start"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="title">Evidence Details</div>
            <DetailItem label="Evidence Number" value={item.evidence_number} />
            <DetailItem label="Item Type" value={item.item_type} />
            <DetailItem label="Item Name" value={item.model_name || "N/A"} />
            <DetailItem label="Provider" value={item.manufacturer || "N/A"} />
            <DetailItem label="Unique ID" value={item.serial_number || "N/A"} />
            <DetailItem
              label="Linked Contact"
              value={item?.linked_contact?.name || "N/A"}
            />
            <DetailItem label="Description" value={""} />
            <div>{item.description || "N/A"}</div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
})`
  pointer-events: none;
  z-index: 99999;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 15px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  font-size: 0.75rem;
  max-width: 350px;
  width: 250px;

  .title {
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;

const NoteRenderer = ({ note, onNoteTitleEdit, onSave, active, templates }) => {
  const { currentUser, hasPermission } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const defaultQuery = [
    "notes:list",
    {
      uuid: note?.uuid || null,
      include_content: true,
      data_only: true,
    },
  ];
  const noteRef = useRef(null);
  const editorRef = useRef(null);
  noteRef.current = note;
  const queryClient = useQueryClient();
  const contentUpdateTimer = useRef(null);

  const readOnly =
    !hasPermission() ||
    noteRef.current?.created_by?.user_id !== currentUser.user_id;

  const { data, refetch, isFetching } = useQuery({
    queryKey: defaultQuery,
    queryFn: () => MonolithNotesAPI.getNotes(defaultQuery[1]),
    enabled: !!noteRef.current,
  });

  if (data?.length === 0 && !isFetching) refetch();

  const handleUpdateContent = useCallback((content, delay = 700) => {
    if (contentUpdateTimer.current) clearTimeout(contentUpdateTimer.current);

    // Do not upload base64 data
    // Image updloads and edit will be handled by the image uploader
    if (content.includes('src="data:image/')) {
      const parseHTML = new DOMParser().parseFromString(content, "text/html");

      const images = parseHTML.querySelectorAll(".monolith-image");

      // clear base64 data
      images.forEach((img) => {
        img.src = "";
        img.classList = "monolith-image";
      });

      // convert to string
      content = parseHTML.body.innerHTML;
    }

    contentUpdateTimer.current = setTimeout(() => {
      setIsSaving(true);
      MonolithNotesAPI.updateNote({
        uuid: noteRef.current.uuid,
        note_data: content,
      }).then((res) => {
        if (res.success === false) {
          enqueueSnackbar("Error saving note, you may be offline.", {
            variant: "error",
          });
          return;
        }

        // Show saving indicator for min amount of time
        // wihout this, the saving indicator will flash too quickly
        setTimeout(() => {
          setIsSaving(false);
          // call this to update note data in case notes list
          onSave?.(noteRef.current);
        }, 125);
      });
      queryClient.setQueryData(defaultQuery, (oldData = []) => {
        if (!oldData?.[0]) return [];
        const newData = [...oldData];
        newData[0].note_data = content;
        newData[0].updated_at = new Date().toISOString();
        newData[0].updated_by = {
          full_name: currentUser.full_name,
          user_id: currentUser.user_id,
          title: currentUser.title,
          email: currentUser.email,
        };
        return newData;
      });

      // Reset queries so that note data is up to date when shown in other UI areas
      // prevents stale note data that was previously loaded into cache from showing in UI
      queryClient.resetQueries({
        queryKey: [
          "case-notes",
          "list",
          { uuid: noteRef.current.uuid, include_content: true },
        ],
        exact: true,
      });
    }, delay);
  }, []);

  const handleImageUpload = async (data) => {
    return new Promise(async (resolve, reject) => {
      const uploadURL = await MonolithNotesAPI.getUploadUrl({
        filename: data.name,
        case_uuid: noteRef.current.linked_case.uuid,
      });

      await MonolithNotesAPI.uploadImage({
        url: uploadURL.uploadUrl,
        file: data.file,
      });

      let image = new Image();
      image.src = uploadURL.downloadUrl;
      image.onload = () => {
        resolve(uploadURL.downloadUrl);
      };

      // Send API request to add attachment record for note and img
      MonolithNotesAPI.addAttachment({
        note_uuid: noteRef.current.uuid,
        uuid: data.id,
        filename: data.name,
        size: data.file.size,
        created_on: moment().toISOString(),
        md5: data.md5,
        sha1: data.sha1,
        sha256: data.sha256,
      });
    });
  };

  const handleExport = (uuid, format) => {
    const font = document.querySelector(".editor-content").dataset.font;
    MonolithNotesAPI.exportNotes({
      uuid: noteRef.current.uuid,
      font: font,
      format,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateFormat: getDateFormat(),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );
      el.download = filename;
      el.click();
      // remove snackbar
    });
  };

  const handleSaveTemplate = ({ note, content }) => {
    setShowSaveTemplateModal(true);
    setTemplateData({ note, content });
  };

  const submitSaveTemplate = ({ name, description, content, is_shared }) => {
    MonolithNotesAPI.createTemplate({
      name,
      description,
      content,
      is_shared,
    }).then((res) => {
      queryClient.refetchQueries({
        queryKey: [
          "notes:templates",
          { data_only: true, include_content: true },
        ],
      });
    });
  };

  // Add save note shortcut
  useEffect(() => {
    if (noteRef.current) {
      // save on ctrl s
      const handleSave = (e) => {
        if (e.ctrlKey && e.key === "s") {
          e.preventDefault();
          const content = editorRef.current.getHTML();
          handleUpdateContent(content, 0);
        }

        // save on cmd s
        if (e.metaKey && e.key === "s") {
          e.preventDefault();
          const content = editorRef.current.getHTML();
          handleUpdateContent(content, 0);
        }
      };
      window.addEventListener("keydown", handleSave);

      return () => {
        window.removeEventListener("keydown", handleSave);
      };
    }
  }, [note, handleUpdateContent]);

  const caseNote = data || [];
  const noteContent = caseNote?.[0]?.note_data || "";

  if (!noteRef.current) return null;

  if (!active) return null;

  return (
    <>
      {data && (
        <NoteSystem.NoteEditor
          readOnly={readOnly}
          noteRef={noteRef}
          noteContent={noteContent}
          handleUpdateContent={handleUpdateContent}
          handleImageUpload={handleImageUpload}
          handleExport={handleExport}
          onNoteTitleEdit={onNoteTitleEdit}
          templates={templates}
          onSaveTemplate={handleSaveTemplate}
          onEditorLoad={(editor) => {
            editorRef.current = editor;
          }}
          isSaving={isSaving}
        />
      )}
      <SaveTemplateModal
        open={showSaveTemplateModal}
        onSubmit={submitSaveTemplate}
        onClose={() => setShowSaveTemplateModal(false)}
        details={templateData}
      />
    </>
  );
};

const CaseNotesWrapper = ({ caseInfo }) => {
  const { item_id: defaultNoteUUID } = useParams();
  const { currentUser, hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const [noteView, setNoteView] = useState(NoteView.TREE);
  const [defaultQuery, setDefaultQuery] = useState([
    "case:notes",
    {
      case_uuid: caseInfo.uuid,
      data_only: true,
      order: { field: "name", sort: "asc" },
      search: null,
      created_by_id: currentUser.user_id,
    },
  ]);

  // Set to read only if the current user is viewing another user's notes
  // Or if license has expired
  // read only is false when viewing all notes
  const readOnly =
    !hasPermission() ||
    (currentUser.user_id !== defaultQuery[1].created_by_id &&
      defaultQuery[1].created_by_id !== null);

  const { data, isFetched } = useQuery({
    queryKey: defaultQuery,
    queryFn: () => MonolithNotesAPI.getNotes(defaultQuery[1]),
    placeholderData: (data) => data,
  });

  const { data: noteUsers } = useQuery({
    queryKey: ["notes:users", { case_uuid: caseInfo.uuid }],
    queryFn: () => MonolithNotesAPI.getNoteUsers({ case_uuid: caseInfo.uuid }),
  });

  const { data: evidence } = useQuery({
    queryKey: ["evidence:list", { case_uuid: caseInfo.uuid }],
    queryFn: () => EvidenceAPI.getEvidence({ case_uuid: caseInfo.uuid }),
    enabled: isFetched,
  });

  const { data: templates } = useQuery({
    queryKey: ["notes:templates", { data_only: true, include_content: true }],
    queryFn: () =>
      MonolithNotesAPI.getTemplates({
        data_only: true,
        include_content: true,
        created_by_id: currentUser.user_id,
        include_shared: true,
      }),
    enabled: isFetched,
  });

  const { data: savedTabs, isFetched: savedTabsFetched } = useQuery({
    queryKey: ["notes:tabs", { uuid: caseInfo.uuid }],
    queryFn: () =>
      IndexedDBService.NoteTabs.getCaseNoteTabs({
        uuid: caseInfo.uuid,
      }),
    enabled: isFetched,
    //disable cache
    gcTime: 0,
  });

  const selectedUser = useMemo(() => {
    return noteUsers?.find(
      (user) => user.user_id === defaultQuery[1].created_by_id
    );
  }, [defaultQuery[1].created_by_id]);

  const onNoteCreated = (newNote) => {
    MonolithNotesAPI.createNote({
      uuid: newNote.uuid,
      case_id: caseInfo.case_id,
      case_uuid: caseInfo.uuid,
      note_tag: newNote.note_tag,
      path: newNote.path || null,
      created_on: newNote.created_on,
      created_by_id: currentUser.user_id,
      parent_id: newNote.parent_id,
      is_folder: newNote.is_folder,
      object_type: newNote.object_type || "case",
      object_id: newNote.object_id || caseInfo.uuid,
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [newNote, ...oldData];
      return newData;
    });

    LoggingAPI.createLog({
      case_id: caseInfo.case_id,
      user_id: currentUser.user_id,
      message: `Created ${newNote.is_folder === 1 ? "note folder" : "note"} "${
        newNote.note_tag
      }", ID ${newNote.uuid}`,
      event: "create",
      object_type: "note",
      object_uuid: newNote.uuid,
    });
  };

  const onNoteDeleted = (deletedNote) => {
    MonolithNotesAPI.deleteNote({
      uuid: deletedNote.uuid,
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === deletedNote.uuid);
      newData.splice(index, 1);
      return newData;
    });

    LoggingAPI.createLog({
      case_id: caseInfo.case_id,
      user_id: currentUser.user_id,
      message: `Deleted ${
        deletedNote.is_folder === 1 ? "note folder" : "note"
      } "${deletedNote.note_tag}", ID ${deletedNote.uuid}`,
      event: "delete",
      object_type: "note",
      object_uuid: deletedNote.uuid,
    });
  };

  const onNoteUpdated = (updatedNote) => {
    // Update note list state with updated note
    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === updatedNote.uuid);
      newData[index].updated_at = updatedNote.updated_at;
      newData[index].updated_by = {
        full_name: currentUser.full_name,
        user_id: currentUser.user_id,
        title: currentUser.title,
        email: currentUser.email,
      };
      return newData;
    });

    LoggingAPI.createLog({
      case_id: caseInfo.case_id,
      user_id: currentUser.user_id,
      message: `Updated note "${updatedNote.note_tag}", ID ${updatedNote.uuid}`,
      event: "update",
      object_type: "note",
      object_uuid: updatedNote.uuid,
    });
  };

  const handleItemRename = ({ item, newName, newPath }) => {
    MonolithNotesAPI.updateNote({
      uuid: item.uuid,
      note_tag: newName,
      path: newPath,
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === item.uuid);
      newData[index].note_tag = newName;
      newData[index].path = newPath;
      newData[index].updated_at = new Date().toISOString();
      newData[index].updated_by = {
        full_name: currentUser.full_name,
        user_id: currentUser.user_id,
        title: currentUser.title,
        email: currentUser.email,
      };
      return newData;
    });

    // Reset queries so that note data is up to date when shown in other UI areas
    // prevents stale note data that was previously loaded into cache from showing in UI
    queryClient.resetQueries({
      queryKey: [
        "case-notes",
        "list",
        { uuid: item.uuid, include_content: true },
      ],
      exact: true,
    });

    LoggingAPI.createLog({
      case_id: caseInfo.case_id,
      user_id: currentUser.user_id,
      message: `Renamed ${item.is_folder === 1 ? "note folder" : "note"} "${
        item.note_tag
      }" → "${newName}", ID ${item.uuid}`,
      event: "update",
      object_type: "note",
      object_uuid: item.uuid,
    });
  };

  const handleNoteMove = ({ source, target, newPath }) => {
    MonolithNotesAPI.updateNote({
      uuid: source.uuid,
      parent_id: target?.uuid || null,
      path: newPath,
      object_id: target?.object_id || caseInfo.uuid,
      object_type: target?.object_type || "case",
    });

    queryClient.setQueryData(defaultQuery, (oldData) => {
      const newData = [...oldData];
      const index = newData.findIndex((n) => n.uuid === source.uuid);
      newData[index].parent_id = target?.uuid;
      newData[index].path = newPath;
      newData[index].object_id = target?.object_id || caseInfo.uuid;
      newData[index].object_type = target?.object_type || "case";
      newData[index].object_name = target?.object_name || null;
      return newData;
    });

    // Reset queries so that note data is up to date when shown in other UI areas
    // prevents stale note data that was previously loaded into cache from showing in UI
    queryClient.resetQueries({
      queryKey: [
        "case-notes",
        "list",
        { uuid: source.uuid, include_content: true },
      ],
      exact: true,
    });

    // Triggers a re-render of the note list
    setDefaultQuery((prev) => {
      return [...prev];
    });

    LoggingAPI.createLog({
      case_id: caseInfo.case_id,
      user_id: currentUser.user_id,
      message: `Moved ${source.is_folder === 1 ? "note folder" : "note"} "${
        source.note_tag
      }", ID ${source.uuid} - "${source.object_name}\\${source.path}" → "${
        target.object_name
      }\\${newPath}"`,
      event: "update",
      object_type: "note",
      object_uuid: source.uuid,
    });
  };

  const handleNoteViewChange = (newView) => {
    // If switching to tree view, ensure that "All users" option is not selected
    if (newView === NoteView.TREE && defaultQuery[1].created_by_id === null) {
      setDefaultQuery((prev) => {
        const newState = [...prev];
        newState[1].created_by_id = currentUser.user_id;
        return newState;
      });
    }

    setNoteView(newView);
  };

  const onToggleSearch = (enabled) => {
    if (!enabled) {
      setDefaultQuery((prev) => {
        const newState = [...prev];
        newState[1].search = null;

        return newState;
      });
    }
  };

  const handleSearch = (searchTerm) => {
    setDefaultQuery((prev) => {
      const newState = [...prev];
      newState[1].search = searchTerm;

      return newState;
    });
  };

  const handleExport = (uuid, format) => {
    // check for valid uuid
    if (!uuid) return;
    if (Array.isArray(uuid) && uuid.length === 0) return;

    const font = document.querySelector(".editor-content")?.dataset?.font;

    MonolithNotesAPI.exportNotes({
      uuid: uuid,
      font: font,
      format,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateFormat: getDateFormat(),
    }).then((res) => {
      const { signedUrl, filename } = res;
      const el = document.createElement("a");
      el.href = signedUrl.replace(
        "http://localhost:3000",
        "http://localhost:3001"
      );

      el.download = filename;
      el.click();
      // remove snackbar
    });
  };

  const onTabsChange = (tabs) => {
    if (!savedTabsFetched) return; // dont sync until tabs are fetched from indexeddb
    // Sync tabs to IndexedDB
    IndexedDBService.NoteTabs.putCaseNoteTabs({
      uuid: caseInfo.uuid,
      tabs,
    }).catch((err) => {
      console.error("Error saving tabs to IndexedDB", err);
    });
  };

  const handleOrder = (options) => {
    setDefaultQuery((prev) => {
      const newState = [...prev];
      newState[1].order = options;
      return newState;
    });
  };

  const userSelectItems = [
    {
      header: true,
      label: "User Notes",
    },
    {
      radioGroup: true,
      value: defaultQuery[1].created_by_id,
      items: [
        {
          label: "My Notes",
          value: currentUser.user_id,
          onClick: () => {
            setDefaultQuery((prev) => {
              const newState = [...prev];
              newState[1].created_by_id = currentUser.user_id;
              return newState;
            });
          },
        },
        ...(noteUsers
          ?.filter((user) => user.user_id !== currentUser.user_id)
          ?.map((user) => ({
            label: user.full_name,
            value: user.user_id,
            onClick: () => {
              setDefaultQuery((prev) => {
                const newState = [...prev];
                newState[1].created_by_id = user.user_id;
                return newState;
              });
            },
          })) || []),
      ],
    },
  ];

  // Add All users Selector
  if (noteView === NoteView.LIST) {
    // Insert item at top of list
    userSelectItems[1].items.unshift({
      label: "All Users",
      value: null,
      onClick: () => {
        setDefaultQuery((prev) => {
          const newState = [...prev];
          newState[1].created_by_id = null;
          return newState;
        });
      },
    });
  }

  const noteObjectIDs = useMemo(
    () => data?.map((note) => note.object_id) || [],
    [data]
  );

  const actionMenuItems = [
    {
      label: "Export to PDF",
      items: [
        {
          label: "Current Tabs",
          title:
            "Export notes that are currently opened in tabs to a PDF document.",
          onSelect: ({ tabs }) => {
            handleExport(
              tabs.map((tab) => tab.note.uuid),
              "pdf"
            );
          },
        },
        {
          label: `${
            defaultQuery[1].created_by_id === currentUser.user_id
              ? "All My Notes"
              : defaultQuery[1].created_by_id === null
              ? "All Notes"
              : `All ${selectedUser?.first_name}'s Notes`
          }`,
          title: "Export all notes for this case to a PDF document.",
          onSelect: () =>
            handleExport(
              data
                .filter((note) => note.is_folder === 0)
                .map((note) => note.uuid),
              "pdf"
            ),
        },
      ],
    },
    {
      label: "Export to MS Word",
      items: [
        {
          label: "Current Tabs",
          title:
            "Export notes that are currently opened in tabs to a Microsoft Word document.",
          onSelect: ({ tabs }) =>
            handleExport(
              tabs.map((tab) => tab.note.uuid),
              "docx"
            ),
        },
        {
          label: `${
            defaultQuery[1].created_by_id === currentUser.user_id
              ? "All My Notes"
              : defaultQuery[1].created_by_id === null
              ? "All Notes"
              : `All ${selectedUser?.first_name}'s Notes`
          }`,
          title: "Export all notes for this case to a Microsoft Word document.",
          onSelect: () =>
            handleExport(
              data
                .filter((note) => note.is_folder === 0)
                .map((note) => note.uuid),
              "docx"
            ),
        },
      ],
    },
  ];

  if (!readOnly) {
    actionMenuItems.push({
      label: "Create Evidence Note",
      items:
        evidence
          ?.filter((e) => !noteObjectIDs.includes(e.uuid))
          ?.map((e) => {
            return {
              label: e.evidence_number,
              render: () => {
                return <EvidenceComponent item={e} />;
              },
              onSelect: ({ handleCreateNote }) =>
                handleCreateNote({
                  object_name: e.evidence_number,
                  object_type: "evidence",
                  object_id: e.uuid,
                  parent_id: null,
                }),
            };
          }) || [],
    });
  }

  if (!savedTabsFetched) return null;

  const notes =
    noteView === NoteView.TREE ? data : data?.filter((n) => n.is_folder === 0); // Filter out folders when in list view

  return (
    <NoteSystem
      notes={notes}
      defaultTabs={savedTabs?.tabs || []}
      defaultNote={data?.find((note) => note.uuid === defaultNoteUUID) || null}
      noteRenderer={NoteRenderer}
      readOnly={readOnly}
      onNoteOrderChange={handleOrder}
      defaultNoteOrder={defaultQuery[1].order}
      onNoteViewChange={handleNoteViewChange}
      defaultNoteView={noteView}
      onNoteCreated={onNoteCreated}
      onNoteDeleted={onNoteDeleted}
      onNoteUpdated={onNoteUpdated}
      onFolderCreated={onNoteCreated}
      onTabsChange={onTabsChange}
      onItemMove={handleNoteMove}
      onItemRename={handleItemRename}
      search={{
        placeholder: !readOnly
          ? "Search My Notes"
          : selectedUser?.first_name
          ? `Search ${selectedUser.first_name}'s Notes`
          : "Search All Notes",
        onToggleSearch,
        onSearch: handleSearch,
      }}
      noteMetadata={{
        linked_case: {
          case_id: caseInfo.case_id,
          uuid: caseInfo.uuid,
          case_number: caseInfo.case_number,
          case_ref: caseInfo.case_ref,
        },
        created_by: {
          full_name: currentUser.full_name,
          user_id: currentUser.user_id,
          title: currentUser.title,
          email: currentUser.email,
        },
      }}
      templates={templates}
      rootObject={{
        object_id: caseInfo.uuid,
        object_type: "case",
        object_name: caseInfo.case_number,
      }}
      headerTitle={{
        badge: "All Notes",
        label: caseInfo.case_number,
        color: "info",
      }}
    >
      <NoteSystem.NoteListMenu actionMenuItems={actionMenuItems}>
        <DropdownMenu
          menuItems={userSelectItems}
          title={"User Notes"}
          variant="outlined"
          arrow
        >
          {defaultQuery[1].created_by_id === null
            ? "All Users"
            : defaultQuery[1].created_by_id === currentUser.user_id
            ? "My Notes"
            : `${selectedUser.first_name}'s Notes`}
        </DropdownMenu>
      </NoteSystem.NoteListMenu>
    </NoteSystem>
  );
};

const CaseNotes = () => {
  const { case_id } = useParams();

  const { data: caseInfo } = useQuery({
    queryKey: [
      "cases",
      "list",
      {
        case_id: parseInt(case_id),
      },
    ],
    queryFn: () =>
      CasesApi.getCases({
        case_id: parseInt(case_id),
      }),
    enabled: !!case_id,
    select: (data) => data?.[0] || {},
  });

  if (!case_id) return <div>Problem loading notes...</div>;

  return <CaseNotesWrapper caseInfo={caseInfo} />;
};

export default CaseNotes;
