import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import EvidenceAPI, {
  createEvidenceCocPDF,
  createEvidenceIntakePDF,
  createEvidenceReleasePDF,
} from "../../api/evidence/index.js";
import moment from "moment";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { useRef, useState } from "react";
import { Modal, useTheme } from "@mui/material";
import { usePermissions } from "../../hooks/usePermissions";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import { getIpAddress, logActivity } from "../../api/index.js";
import { useSnackbar } from "notistack";
import LocationsAPI from "../../api/locations/index.js";
import {
  db_timestamp,
  getDateFormat,
  monolithMoment,
  validateTimestamp,
} from "../../utils/date-format.js";
import ToolBarItems from "../../components/ToolBarItems.js";
import { EmptyItem, Form, SimpleItem } from "devextreme-react/ui/form";
import { Template } from "devextreme-react/core/template";
import DataSource from "devextreme/data/data_source";
import LocationSelectBoxItem from "../../components/SelectBoxItems/LocationSelectBoxItem.js";
import SignatureField from "../../components/SignatureField/SignatureField.js";
import UserApi from "../../api/users/users.js";
import UserSelectBoxItem from "../../components/SelectBoxItems/UserSelectBoxItem.js";
import Loader from "../../components/Loader.js";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const CocContent = styled(({ className, evidenceInfo }) => {
  const { data } = useQuery({
    queryKey: ["evidence:cocData", { evidence_id: evidenceInfo.evidence_id }],
    queryFn: () =>
      EvidenceAPI.getCocRecords({ evidence_id: evidenceInfo.evidence_id }),
  });

  if (!data) return null;

  data &&
    data.sort((a, b) => {
      if (a.timestamp > b.timestamp) return 1;
      if (a.timestamp < b.timestamp) return -1;
      return 0;
    });

  return (
    <div className={className}>
      <ActionMenu evidenceInfo={evidenceInfo} records={data} />
      {data.length === 0 && (
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          No chain of custody records
        </div>
      )}
      {data?.map((record) => {
        return (
          <CocRecord
            key={record.coc_id}
            evidenceInfo={evidenceInfo}
            record={record}
          />
        );
      })}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ActionMenu = styled(({ className, evidenceInfo, records }) => {
  const theme = useTheme();
  const { currentUser, hasPermission } = usePermissions();
  const queryClient = useQueryClient();
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [showDestroyModal, setShowDestroyModal] = useState(false);
  const [showInsertRecordModal, setShowInsertRecordModal] = useState(false);
  const [showCheckoutItemModal, setShowCheckoutItemModal] = useState(false);
  const [showIntakeModal, setShowIntakeModal] = useState(false);
  const [showReturnToParentModal, setShowReturnToParentModal] = useState(false);
  const [showReCollectModal, setShowReCollectModal] = useState(false);

  const intakeExists = records.find((r) => r.type === "Intake");

  const actions = [
    {
      title: "Move Item",
      onClick: () => setShowMoveModal(true),
      visible: records.length !== 0 && evidenceInfo.location_id !== 1,
    },
    {
      title: "Checkout Item",
      onClick: () => setShowCheckoutItemModal(true),
      visible:
        records.length !== 0 &&
        evidenceInfo.location_id !== 1 &&
        evidenceInfo.location_id !== currentUser.location_id,
    },
    {
      title: "Return to Parent",
      onClick: () => setShowReturnToParentModal(true),
      visible:
        records.length !== 0 &&
        evidenceInfo.is_parent !== 1 &&
        evidenceInfo.location_name !== evidenceInfo.parent_name &&
        evidenceInfo.location_id !== 1,
    },
    {
      title: "Release Item",
      onClick: () => setShowReleaseModal(true),
      visible: records.length !== 0 && evidenceInfo.location_id !== 1,
    },
    {
      title: "Destroy Item",
      onClick: () => setShowDestroyModal(true),
      visible: records.length !== 0 && evidenceInfo.location_id !== 1,
    },
    {
      title: "Enter Intake Details",
      onClick: () => setShowIntakeModal(true),
      visible: !intakeExists,
    },
    {
      title: "Re-collect Item",
      onClick: () => setShowReCollectModal(true),
      visible: records.length !== 0 && evidenceInfo.location_id === 1,
    },
    {
      title: "Insert Record",
      onClick: () => setShowInsertRecordModal(true),
      visible: records.length !== 0 && currentUser.role_id === 1,
    },
  ];

  const reportActions = [
    {
      index: 1,
      title: "Intake Form",
      onClick: () => {
        createEvidenceIntakePDF({
          evidence: {
            ...evidenceInfo,
            currenUserFullName: currentUser.full_name,
          },
        }).then(() => {
          logActivity(
            evidenceInfo.case_id,
            currentUser.user_id,
            `Generated PDF Intake Form for evidence item ${evidenceInfo.evidence_number}.`
          );
        });
      },
    },
    {
      index: 2,
      title: "Release Form",
      onClick: () => {
        createEvidenceReleasePDF({
          evidence: {
            ...evidenceInfo,
            currenUserFullName: currentUser.full_name,
          },
        }).then(() => {
          logActivity(
            evidenceInfo.case_id,
            currentUser.user_id,
            `Generated PDF Release Form for evidence item ${evidenceInfo.evidence_number}.`
          );
        });
      },
    },
    {
      index: 3,
      title: "Evidence COC Report",
      onClick: () => {
        createEvidenceCocPDF({
          evidence: {
            ...evidenceInfo,
            currenUserFullName: currentUser.full_name,
          },
          cocData: records,
          options: { suppCoc: false },
        }).then(() => {
          logActivity(
            evidenceInfo.case_id,
            currentUser.user_id,
            `Generated PDF COC Report for evidence item ${evidenceInfo.evidence_number}.`
          );
        });
      },
    },
    {
      index: 4,
      title: "Supplemental COC",
      onClick: () => {
        createEvidenceCocPDF({
          evidence: {
            ...evidenceInfo,
            currenUserFullName: currentUser.full_name,
          },
          cocData: records,
          options: { suppCoc: true },
        }).then(() => {
          logActivity(
            evidenceInfo.case_id,
            currentUser.user_id,
            `Generated PDF COC Report for evidence item ${evidenceInfo.evidence_number}.`
          );
        });
      },
    },
  ];

  const handleAction = (action) => {
    action.onClick();
  };

  const refetchCocData = () => {
    queryClient.refetchQueries({
      queryKey: ["evidence:cocData", { evidence_id: evidenceInfo.evidence_id }],
    });

    queryClient.refetchQueries({
      queryKey: ["evidence:list", { uuid: evidenceInfo.uuid }],
    });
  };

  const onMove = () => refetchCocData();
  const onRelease = () => refetchCocData();
  const onDestroy = () => refetchCocData();
  const onInsertRecord = () => refetchCocData();
  const onCheckoutItem = () => refetchCocData();
  const onIntake = () => refetchCocData();
  const onReturnItemToParent = () => refetchCocData();
  const onReCollect = () => refetchCocData();

  return (
    <div className={className}>
      {hasPermission() && (
        <ComboButton
          type="dropDown"
          data={actions.filter((a) => a.visible)}
          title={"Actions"}
          showDropdownIcon={true}
          displayField="title"
          variant="outlined"
          useSelectMode={false}
          onItemSelect={handleAction}
          textColor={theme.palette.text.primary}
        >
          Actions
        </ComboButton>
      )}
      <ComboButton
        type="dropDown"
        data={reportActions}
        title={"Report Actions"}
        showDropdownIcon={true}
        displayField="title"
        variant="outlined"
        useSelectMode={false}
        onItemSelect={handleAction}
        textColor={theme.palette.text.primary}
      >
        Reports
      </ComboButton>
      <MoveEvidenceModal
        open={showMoveModal}
        handleClose={() => setShowMoveModal(false)}
        onSubmit={onMove}
        defaultInfo={evidenceInfo}
      />
      <ReleaseEvidenceModal
        open={showReleaseModal}
        handleClose={() => setShowReleaseModal(false)}
        onSubmit={onRelease}
        defaultInfo={evidenceInfo}
      />
      <DestroyEvidenceModal
        open={showDestroyModal}
        handleClose={() => setShowDestroyModal(false)}
        onSubmit={onDestroy}
        defaultInfo={evidenceInfo}
      />
      <InsertRecordModal
        open={showInsertRecordModal}
        handleClose={() => setShowInsertRecordModal(false)}
        onSubmit={onInsertRecord}
        defaultInfo={evidenceInfo}
      />
      <CheckoutItemModal
        open={showCheckoutItemModal}
        handleClose={() => setShowCheckoutItemModal(false)}
        onSubmit={onCheckoutItem}
        defaultInfo={evidenceInfo}
      />
      <IntakeItemModal
        open={showIntakeModal}
        handleClose={() => setShowIntakeModal(false)}
        onSubmit={onIntake}
        defaultInfo={evidenceInfo}
      />
      <ReturnToParentModal
        open={showReturnToParentModal}
        handleClose={() => setShowReturnToParentModal(false)}
        onSubmit={onReturnItemToParent}
        defaultInfo={evidenceInfo}
      />
      <ReCollectModal
        open={showReCollectModal}
        handleClose={() => setShowReCollectModal(false)}
        onSubmit={onReCollect}
        defaultInfo={evidenceInfo}
      />
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
`;

const MoveEvidenceModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const receivedFromSig = useRef(null);

  const result = useQueries({
    queries: [
      {
        queryKey: ["evidence:locations", { include_groups: false }],
        queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
        enabled: open,
      },
      {
        queryKey: ["evidence:cocMoveReasons"],
        queryFn: () => EvidenceAPI.getCocMoveReasons(),
        enabled: open,
      },
    ],
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      handleClose();

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: currentUser.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      data.signature = JSON.stringify(signature);

      const requestObj = {
        caseInfo: {
          case_id: defaultInfo.case_id,
          case_number: defaultInfo.case_number,
          case_ref: defaultInfo.case_ref,
        },
        evidenceInfo: {
          evidence_id: defaultInfo.evidence_id,
          uuid: defaultInfo.uuid,
          evidence_number: defaultInfo.evidence_number,
        },
        moveData: {
          entered_by_id: currentUser.user_id,
          entered_by: currentUser.full_name,
          new_location_id: data.new_location.location_id,
          new_location_name: data.new_location.location_name,
          current_location_name: defaultInfo.location_name,
          reason: data.reason || "Evidence item moved.",
          timestamp: db_timestamp(data.timestamp, true),
          signature: data.signature,
          coc_type: "Move",
        },
      };

      await EvidenceAPI.moveEvidence(requestObj);

      onSubmit(requestObj);

      enqueueSnackbar(
        `Evidence Item Moved to ${data.new_location.location_name}`,
        {
          variant: "success",
        }
      );
    }
  };

  const isDone = result.every((r) => r.isFetched);

  const locations = isDone ? result[0].data : [];
  const reasons = isDone ? result[1].data : [];

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          minHeight: 450,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Move Evidence Item
        </div>

        <Form ref={form} colCount={1}>
          <SimpleItem
            dataField="new_location"
            label={{ text: "New Location" }}
            editorType="dxSelectBox"
            isRequired={true}
            editorOptions={{
              hint: "Select the new location for this storage item.",
              searchEnabled: true,
              dataSource: new DataSource({
                store: locations,
                key: "location_id",
                group: "office_name",
              }),
              displayExpr: "location_name",
              grouped: true,
              dropDownOptions: {
                height: "350px",
              },
              itemTemplate: "new_location",
            }}
          />
          <Template
            name="new_location"
            render={(data) => <LocationSelectBoxItem data={data} />}
          />
          <SimpleItem
            dataField="reason"
            label={{ text: "Move Reason" }}
            editorType="dxSelectBox"
            isRequired={true}
            editorOptions={{
              dataSource: reasons,
              dropDownOptions: {
                maxHeight: 250,
              },
              placeholder: "Select or add new...",
              searchEnabled: true,
              searchExpr: "reason",
              displayExpr: "reason",
              valueExpr: "reason",
              acceptCustomValue: true,
              onCustomItemCreating: (e) => {
                e.customItem = { reason: e.text };
                // Adds the entry to the data source
                e.component.getDataSource().store().insert(e.customItem);
                // Reloads the data source
                e.component.getDataSource().reload();
              },
            }}
          />
          <SimpleItem
            dataField="timestamp"
            label={{ text: "Timestamp" }}
            editorType="dxDateBox"
            isRequired={true}
            editorOptions={{
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, true),
              value: new Date(),
            }}
            validationRules={[
              {
                type: "custom",
                message: "Invalid Date",
                validationCallback: validateTimestamp,
              },
            ]}
          />
          <EmptyItem />
          <SimpleItem
            dataField="custody_from_sig"
            label={{ text: "Moved By Signature" }}
          >
            <SignatureField
              sigFieldRef={receivedFromSig}
              title="Moved By Signature"
            />
          </SimpleItem>
        </Form>
        <ToolBarItems
          submitText="Move Evidence"
          onSubmit={handleSubmit}
          onCancel={() => handleClose()}
        />
      </div>
    </Modal>
  );
};

const ReleaseEvidenceModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const releasedToSig = useRef(null);
  const releasedBySig = useRef(null);

  const { data: users } = useQuery({
    enabled: open,
    queryKey: [
      "users:list",
      { includeObservers: false, includeInactive: false },
    ],
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      handleClose();

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = releasedToSig.current.isEmpty()
        ? null
        : {
            signature: releasedToSig.current.getSignature(),
            signer: data.released_to,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = releasedBySig.current.isEmpty()
        ? null
        : {
            signature: releasedBySig.current.getSignature(),
            signer: data.released_by.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      data.signature = JSON.stringify(signature);

      const requestObj = {
        caseInfo: {
          case_id: defaultInfo.case_id,
          case_number: defaultInfo.case_number,
          case_ref: defaultInfo.case_ref,
        },
        evidenceInfo: {
          evidence_id: defaultInfo.evidence_id,
          uuid: defaultInfo.uuid,
          evidence_number: defaultInfo.evidence_number,
        },
        moveData: {
          entered_by_id: currentUser.user_id,
          entered_by: currentUser.full_name,
          new_location_id: 1,
          new_location_name: data.released_to,
          current_location_name: data.released_by.full_name,
          reason: data.reason || "Evidence Released",
          timestamp: db_timestamp(data.timestamp, true),
          signature: data.signature,
          coc_type: "Release",
        },
      };

      await EvidenceAPI.moveEvidence(requestObj);

      onSubmit(requestObj);

      enqueueSnackbar(`Evidence Item Released to ${data.released_to}`, {
        variant: "success",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          minHeight: 350,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Release Evidence Item
        </div>
        {users && (
          <>
            <Form ref={form} colCount={1}>
              <SimpleItem
                dataField="released_by"
                label={{ text: "Released By" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  dataSource: users,
                  searchEnabled: true,
                  displayExpr: "full_name",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  itemTemplate: "released_by",
                }}
              />
              <Template
                name="released_by"
                render={(data) => <UserSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="released_to"
                label={{ text: "Released To" }}
                isRequired={true}
                editorOptions={{
                  placeholder: "Enter Name...",
                }}
              />
              <SimpleItem
                dataField="timestamp"
                label={{ text: "Timestamp" }}
                editorType="dxDateBox"
                isRequired={true}
                editorOptions={{
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  value: new Date(),
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Release Notes" }}
                editorType={"dxTextArea"}
              />
              <SimpleItem
                dataField="custody_from_sig"
                label={{ text: "Released By Signature" }}
              >
                <SignatureField
                  sigFieldRef={releasedBySig}
                  title="Released By Signature"
                />
              </SimpleItem>
              <SimpleItem
                dataField="custody_to_sig"
                label={{ text: "Released To Signature" }}
              >
                <SignatureField
                  sigFieldRef={releasedToSig}
                  title="Released To Signature"
                />
              </SimpleItem>
            </Form>
            <ToolBarItems
              submitText="Release Evidence"
              onSubmit={handleSubmit}
              onCancel={() => handleClose()}
            />
          </>
        )}
        {!users && <Loader />}
      </div>
    </Modal>
  );
};

const DestroyEvidenceModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const destroyedBySig = useRef(null);

  const { data: users } = useQuery({
    enabled: open,
    queryKey: [
      "users:list",
      { includeObservers: false, includeInactive: false },
    ],
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      handleClose();

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_from_sig = destroyedBySig.current.isEmpty()
        ? null
        : {
            signature: destroyedBySig.current.getSignature(),
            signer: data.destroyed_by.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      data.signature = JSON.stringify(signature);

      const requestObj = {
        caseInfo: {
          case_id: defaultInfo.case_id,
          case_number: defaultInfo.case_number,
          case_ref: defaultInfo.case_ref,
        },
        evidenceInfo: {
          evidence_id: defaultInfo.evidence_id,
          uuid: defaultInfo.uuid,
          evidence_number: defaultInfo.evidence_number,
        },
        moveData: {
          entered_by_id: currentUser.user_id,
          entered_by: currentUser.full_name,
          new_location_id: -1,
          new_location_name: "Destroyed",
          current_location_name: data.destroyed_by.full_name,
          reason: data.reason || "Evidence Destroyed",
          timestamp: db_timestamp(data.timestamp, true),
          signature: data.signature,
          coc_type: "Destroy",
        },
      };

      await EvidenceAPI.moveEvidence(requestObj);

      onSubmit(requestObj);

      enqueueSnackbar(`Evidence Item Destroyed`, {
        variant: "success",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          minHeight: 350,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Destroy Evidence Item
        </div>
        {users && (
          <>
            <Form ref={form} colCount={1}>
              <SimpleItem
                dataField="destroyed_by"
                label={{ text: "Destroyed By" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  dataSource: users,
                  searchEnabled: true,
                  displayExpr: "full_name",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  itemTemplate: "destroyed_by",
                }}
              />
              <Template
                name="destroyed_by"
                render={(data) => <UserSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="timestamp"
                label={{ text: "Timestamp" }}
                editorType="dxDateBox"
                isRequired={true}
                editorOptions={{
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  value: new Date(),
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Destroy Notes" }}
                editorType={"dxTextArea"}
              />
              <SimpleItem
                dataField="custody_from_sig"
                label={{ text: "Destroyed By Signature" }}
              >
                <SignatureField
                  sigFieldRef={destroyedBySig}
                  title="Destroyed By Signature"
                />
              </SimpleItem>
            </Form>
            <ToolBarItems
              submitText="Destroy Evidence"
              onSubmit={handleSubmit}
              onCancel={() => handleClose()}
            />
          </>
        )}
        {!users && <Loader />}
      </div>
    </Modal>
  );
};

const ReCollectModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const receivedBySig = useRef(null);
  const receivedFromSig = useRef(null);

  const { data: users } = useQuery({
    enabled: open,
    queryKey: [
      "users:list",
      { includeObservers: false, includeInactive: false },
    ],
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = receivedBySig.current.isEmpty()
        ? null
        : {
            signature: receivedBySig.current.getSignature(),
            signer: data.received_by.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: data.received_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      data.signature = JSON.stringify(signature);

      handleClose();

      const requestObj = {
        caseInfo: {
          case_id: defaultInfo.case_id,
          case_number: defaultInfo.case_number,
          case_ref: defaultInfo.case_ref,
        },
        evidenceInfo: {
          evidence_id: defaultInfo.evidence_id,
          uuid: defaultInfo.uuid,
          evidence_number: defaultInfo.evidence_number,
        },
        moveData: {
          entered_by_id: currentUser.user_id,
          entered_by: currentUser.full_name,
          new_location_id:
            defaultInfo.is_parent === 1 ? data.received_by.location_id : 0,
          new_location_name: data.received_by.full_name,
          current_location_name: data.received_from,
          reason: data.reason || "Evidence Re-Collected",
          timestamp: db_timestamp(data.timestamp, true),
          signature: data.signature,
          coc_type: "Re-Collect",
        },
      };

      await EvidenceAPI.moveEvidence(requestObj);

      onSubmit(requestObj);

      enqueueSnackbar(`Evidence Item Re-collected from ${data.received_from}`, {
        variant: "success",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          minHeight: 450,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Recollect Evidence Item
        </div>
        {users && (
          <>
            <Form ref={form} colCount={1}>
              <SimpleItem
                dataField="received_by"
                label={{ text: "Received By" }}
                editorType="dxSelectBox"
                isRequired={true}
                editorOptions={{
                  dataSource: users,
                  searchEnabled: true,
                  displayExpr: "full_name",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  itemTemplate: "received_by",
                }}
              />
              <Template
                name="received_by"
                render={(data) => <UserSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="received_from"
                label={{ text: "Received From" }}
                isRequired={true}
                editorOptions={{
                  placeholder: "Enter Name...",
                }}
              />
              <SimpleItem
                dataField="timestamp"
                label={{ text: "Timestamp" }}
                editorType="dxDateBox"
                isRequired={true}
                editorOptions={{
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  value: new Date(),
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Re-Collection Notes" }}
                editorType={"dxTextArea"}
              />
              <SimpleItem
                dataField="custody_to_sig"
                label={{ text: "Recieved By Signature" }}
              >
                <SignatureField
                  sigFieldRef={receivedBySig}
                  title="Received By Signature"
                />
              </SimpleItem>
              <SimpleItem
                dataField="custody_from_sig"
                label={{ text: "Received From Signature" }}
              >
                <SignatureField
                  sigFieldRef={receivedFromSig}
                  title="Received From Signature"
                />
              </SimpleItem>
            </Form>
            <ToolBarItems
              submitText="Recollect Evidence"
              onSubmit={handleSubmit}
              onCancel={() => handleClose()}
            />
          </>
        )}
        {!users && <Loader />}
      </div>
    </Modal>
  );
};

const InsertRecordModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const custodyToSig = useRef(null);
  const custodyFromSig = useRef(null);

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let data = { ...form.current.instance.option().formData };

      handleClose();

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = custodyToSig.current.isEmpty()
        ? null
        : {
            signature: custodyToSig.current.getSignature(),
            signer: data.custody_to,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = custodyFromSig.current.isEmpty()
        ? null
        : {
            signature: custodyFromSig.current.getSignature(),
            signer: data.custody_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      data.signature = JSON.stringify(signature);

      await EvidenceAPI.createCocRecord({
        evidence_id: defaultInfo.evidence_id,
        custody_to: data.custody_to,
        custody_from: data.custody_from,
        reason: data.reason || "Chain of Custody Record Created",
        entered_by: currentUser.full_name,
        timestamp: db_timestamp(data.timestamp, true),
        signature: data.signature,
        coc_type: data.coc_type || null,
      });

      onSubmit({
        custody_to: data.custody_to,
        custody_from: data.custody_from,
        reason: data.reason,
        entered_by: currentUser.full_name,
        timestamp: db_timestamp(data.timestamp, true),
        signature: data.signature,
        coc_type: data.coc_type || null,
      });

      enqueueSnackbar(`Chain of Custody Record Inserted`, {
        variant: "success",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          minHeight: 450,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Create Chain of Custody Record
        </div>
        <Form ref={form} colCount={1}>
          <SimpleItem
            dataField="custody_from"
            label={{ text: "Custody From" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="custody_to"
            label={{ text: "Custody To" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="timestamp"
            label={{ text: "Timestamp" }}
            editorType="dxDateBox"
            isRequired={true}
            editorOptions={{
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, true),
              value: new Date(),
            }}
            validationRules={[
              {
                type: "custom",
                message: "Invalid Date",
                validationCallback: validateTimestamp,
              },
            ]}
          />
          <SimpleItem
            dataField="coc_type"
            label={{ text: "COC Type" }}
            isRequired={true}
            editorType="dxSelectBox"
            editorOptions={{
              items: ["Move", "Checkout", "Release", "Re-Collect"],
            }}
          />
          <SimpleItem
            dataField="reason"
            label={{ text: "COC Notes" }}
            isRequired={true}
            editorType="dxTextArea"
          />
          <SimpleItem
            dataField="custody_from_sig"
            label={{ text: "Custody From Signature" }}
          >
            <SignatureField
              sigFieldRef={custodyFromSig}
              title="Custody From Signature"
            />
          </SimpleItem>
          <SimpleItem
            dataField="custody_to_sig"
            label={{ text: "Custody To Signature" }}
          >
            <SignatureField
              sigFieldRef={custodyToSig}
              title="Custody To Signature"
            />
          </SimpleItem>
        </Form>
        <ToolBarItems
          submitText="Create Record"
          onSubmit={handleSubmit}
          onCancel={() => handleClose()}
        />
      </div>
    </Modal>
  );
};

const CheckoutItemModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const reasons = [];
  const checkoutToSig = useRef(null);

  const handleSubmit = async (event) => {
    let data = { ...form.current.instance.option().formData };

    const ipAddress = await getIpAddress();

    const signature = {};

    signature.custody_to_sig = checkoutToSig.current.isEmpty()
      ? null
      : {
          signature: checkoutToSig.current.getSignature(),
          signer: currentUser.full_name,
          timestamp: moment().toISOString(),
          userAgent: window.navigator.userAgent,
          ip_address: ipAddress,
          monolith_user: {
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
            full_name: currentUser.full_name,
            email: currentUser.email,
            title: currentUser.title,
            user_id: currentUser.user_id,
          },
        };

    data.signature = JSON.stringify(signature);
    handleClose();

    const requestObj = {
      caseInfo: {
        case_id: defaultInfo.case_id,
        case_number: defaultInfo.case_number,
        case_ref: defaultInfo.case_ref,
      },
      evidenceInfo: {
        evidence_id: defaultInfo.evidence_id,
        uuid: defaultInfo.uuid,
        evidence_number: defaultInfo.evidence_number,
      },
      moveData: {
        entered_by_id: currentUser.user_id,
        entered_by: currentUser.full_name,
        new_location_id: currentUser.location_id,
        new_location_name: currentUser.full_name,
        current_location_name: defaultInfo.location_name,
        reason: data.reason || "Evidence checked out by user",
        timestamp: db_timestamp(data.timestamp, true),
        signature: data.signature,
        coc_type: "Checkout",
      },
    };

    await EvidenceAPI.moveEvidence(requestObj);

    onSubmit(requestObj);

    enqueueSnackbar(`Evidence Item Checked Out`, {
      variant: "success",
    });
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          minHeight: 350,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Checkout Evidence Item
        </div>
        {!reasons && <Loader />}
        {reasons && (
          <>
            <Form ref={form} colCount={1}>
              <SimpleItem
                dataField="timestamp"
                label={{ text: "Timestamp" }}
                editorType="dxDateBox"
                isRequired={true}
                editorOptions={{
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  value: new Date(),
                }}
                validationRules={[
                  {
                    type: "custom",
                    message: "Invalid Date",
                    validationCallback: validateTimestamp,
                  },
                ]}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Checkout Notes" }}
                editorType={"dxTextArea"}
              />
              <SimpleItem
                dataField="custody_to_sig"
                label={{ text: "Checkout Signature" }}
              >
                <SignatureField
                  sigFieldRef={checkoutToSig}
                  title="Checked Out By Signature"
                />
              </SimpleItem>
            </Form>
            <ToolBarItems
              submitText="Checkout Evidence"
              onSubmit={handleSubmit}
              onCancel={() => handleClose()}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const IntakeItemModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const receivedBySig = useRef(null);
  const receivedFromSig = useRef(null);

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      let formData = Object.assign(
        {},
        form.current.instance.option("formData")
      );

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = receivedBySig.current.isEmpty()
        ? null
        : {
            signature: receivedBySig.current.getSignature(),
            signer: formData.custody_to.location_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = receivedFromSig.current.isEmpty()
        ? null
        : {
            signature: receivedFromSig.current.getSignature(),
            signer: formData.custody_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      handleClose();
      //Convert intake time to UTC
      if (formData.coc_intake_timestamp) {
        formData.coc_intake_timestamp = db_timestamp(
          formData.coc_intake_timestamp,
          true
        );

        formData.signature = JSON.stringify(signature);

        formData.entered_by = currentUser.full_name;
      }

      formData.evidence_id = defaultInfo.evidence_id;
      formData.custody_to = formData.custody_to.location_id;
      formData.coc_type = "Intake";
      formData.reason = formData.reason ? formData.reason : "Evidence Intake";

      EvidenceAPI.createIntakeDetails(formData).then((result) => {
        onSubmit();

        enqueueSnackbar(`Entered Evidence Intake Details`, {
          variant: "success",
        });

        logActivity(
          defaultInfo.case_id,
          currentUser.user_id,
          `Entered Evidence Intake Details: ${defaultInfo.evidence_number}`
        );
      });
    } else {
      enqueueSnackbar(`Please complete all required fields.`, {
        variant: "error",
      });
    }
  };

  const { data: locations } = useQuery({
    queryKey: ["evidence:locations", { include_groups: false }],
    queryFn: () => LocationsAPI.getLocations({}, { include_groups: false }),
    enabled: open,
  });

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 800,
          minHeight: 500,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 400px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Create Evidence Intake Details
        </div>
        {locations ? (
          <>
            <Form ref={form} colCount={2}>
              <SimpleItem
                dataField="custody_to"
                label={{ text: "Received By" }}
                isRequired={true}
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: locations,
                  hint: "This is the person or location in your org that received the item.",
                  dropDownOptions: {
                    maxHeight: 250,
                  },
                  displayExpr: "location_name",
                  searchEnabled: true,
                  itemTemplate: "custody_to",
                }}
              ></SimpleItem>
              <Template
                name="custody_to"
                render={(data) => <LocationSelectBoxItem data={data} />}
              />
              <SimpleItem
                dataField="custody_from"
                label={{ text: "Received From" }}
                isRequired={true}
                editorOptions={{
                  placeholder: "John Smith, FedEx: tracking 12345...",
                  hint: "This is the person or entity the item was received from.",
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="custody_to_sig"
                label={{ text: "Received By Signature" }}
              >
                <SignatureField
                  sigFieldRef={receivedBySig}
                  title="Received By Signature"
                />
              </SimpleItem>
              <SimpleItem
                dataField="custody_from_sig"
                label={{ text: "Received From Signature" }}
              >
                <SignatureField
                  sigFieldRef={receivedFromSig}
                  title="Received From Signature"
                />
              </SimpleItem>
              <SimpleItem
                dataField="coc_intake_timestamp"
                label={{ text: "Intake Timestamp" }}
                isRequired={true}
                editorType="dxDateBox"
                editorOptions={{
                  hint: "Date and time the item was received.",
                  type: "datetime",
                  useMaskBehavior: true,
                  displayFormat: getDateFormat(false, true),
                  pickerType: "calendar",
                  value: new Date(),
                }}
              ></SimpleItem>
              <SimpleItem
                dataField="location_received"
                label={{ text: "Location Received" }}
                editorOptions={{
                  placeholder: "Address, Front Office, etc...",
                }}
              />
              <SimpleItem
                dataField="reason"
                label={{ text: "Notes" }}
                colSpan={2}
                editorType="dxTextArea"
                editorOptions={{
                  height: 75,
                }}
              ></SimpleItem>
            </Form>
            <ToolBarItems
              submitText="Create Intake Details"
              onSubmit={handleSubmit}
              onCancel={() => handleClose()}
              style={{ marginTop: 10 }}
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Modal>
  );
};

const ReturnToParentModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { currentUser } = usePermissions();
  const form = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const checkoutFromSig = useRef(null);

  const handleSubmit = async (event) => {
    let data = { ...form.current.instance.option().formData };

    const ipAddress = await getIpAddress();

    const signature = {};

    signature.custody_from_sig = checkoutFromSig.current.isEmpty()
      ? null
      : {
          signature: checkoutFromSig.current.getSignature(),
          signer: currentUser.full_name,
          timestamp: moment().toISOString(),
          userAgent: window.navigator.userAgent,
          ip_address: ipAddress,
          monolith_user: {
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
            full_name: currentUser.full_name,
            email: currentUser.email,
            title: currentUser.title,
            user_id: currentUser.user_id,
          },
        };

    data.signature = JSON.stringify(signature);

    handleClose();

    const requestObj = {
      caseInfo: {
        case_id: defaultInfo.case_id,
        case_number: defaultInfo.case_number,
        case_ref: defaultInfo.case_ref,
      },
      evidenceInfo: {
        evidence_id: defaultInfo.evidence_id,
        uuid: defaultInfo.uuid,
        evidence_number: defaultInfo.evidence_number,
      },
      moveData: {
        entered_by_id: currentUser.user_id,
        entered_by: currentUser.full_name,
        new_location_id: 0,
        new_location_name: `Parent Evidence (${defaultInfo.parent_name})`,
        current_location_name: defaultInfo.location_name,
        reason: data.reason || "Evidence returned to parent",
        timestamp: db_timestamp(data.timestamp, true),
        signature: data.signature,
        coc_type: "Move",
      },
    };

    await EvidenceAPI.moveEvidence(requestObj);

    onSubmit(requestObj);

    enqueueSnackbar(`Evidence item returned to parent`, {
      variant: "success",
    });
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 500,
          minHeight: 450,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 250px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Return Evidence Item to Parent
        </div>
        <div style={{ paddingBottom: 15 }}>
          Are you sure you wish to return this item to it's parent evidence? The
          item will be moved to the parent and a coc record will be created.
        </div>
        <Form ref={form} colCount={1}>
          <SimpleItem
            dataField="reason"
            label={{ text: "Reason" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="timestamp"
            label={{ text: "Timestamp" }}
            editorType="dxDateBox"
            isRequired={true}
            editorOptions={{
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, true),
              value: new Date(),
            }}
            validationRules={[
              {
                type: "custom",
                message: "Invalid Date",
                validationCallback: validateTimestamp,
              },
            ]}
          />
          <SimpleItem
            dataField="custody_from_sig"
            label={{ text: "Signature" }}
          >
            <SignatureField sigFieldRef={checkoutFromSig} title="Signature" />
          </SimpleItem>
        </Form>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Return to Parent"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

const CocRecord = styled(({ className, evidenceInfo, record }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);
  return (
    <div className={className}>
      <div className="outer-container">
        <div>
          <div className="record-type">{record.type}</div>
          <div className="record-timestamp">
            <div
              style={{ marginRight: 5 }}
              title={monolithMoment({
                timestamp: record.timestamp,
                includeTime: true,
              })}
            >
              {moment.utc(record.timestamp).fromNow()}
            </div>
          </div>
          {!open && (
            <div className="coc-preview-data">
              <div className="coc-data">
                Custody From:{" "}
                <span title={record.custody_from}>{record.custody_from}</span>
              </div>
              <div className="coc-data">
                Custody To:{" "}
                <span title={record.custody_to}>{record.custody_to}</span>
              </div>
              <div className="coc-data">
                Description: <span title={record.reason}>{record.reason}</span>
              </div>
            </div>
          )}
        </div>
        <div className="view-btn" onClick={() => handleClick()}>
          <div className="view-btn-title">View Details</div>
          <ArrowDownwardOutlinedIcon
            style={{
              //rotate
              transform: !open ? "rotate(-90deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </div>
      </div>
      {open && <Details item={record} />}
    </div>
  );
})`
  font-size: 14px;
  padding: 15px;
  outline: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 5px;

  & .outer-container {
    display: flex;
  }

  & .record-type {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }

  & .record-timestamp {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
    :hover {
      color: ${({ theme }) => theme.palette.text.primary};
      cursor: pointer;
    }
  }

  & .coc-preview-data {
    display: flex;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 11px;
    margin-top: 10px;
  }

  & .coc-data {
    margin-right: 15px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .coc-data span {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .view-btn {
    cursor: pointer;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    align-items: center;
    margin-left: auto;
    svg {
      font-size: 15px;
    }
    &:hover,
    &:hover svg,
    &:hover .view-btn-title {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: underline;
    }
  }
  & .view-btn-title {
    margin-right: 5px;
  }
`;

const Details = styled(({ className, item }) => {
  const { hasPermission, MONOLITH_PERMISSIONS } = usePermissions();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const queryClient = useQueryClient();
  const theme = useTheme();

  const onEdit = () => {
    queryClient.refetchQueries({
      queryKey: ["evidence:cocData", { evidence_id: item.evidence_id }],
    });
  };

  let signature = item?.signature || {};

  return (
    <div className={className}>
      <div style={{ display: "flex", width: "fit-content" }}>
        {hasPermission(MONOLITH_PERMISSIONS.COC_UPDATE) && (
          <div
            className="action-menu-item"
            onClick={() => setShowEditModal(true)}
            style={{ marginBottom: 10 }}
          >
            <EditOutlinedIcon style={{ color: theme.palette.primary.main }} />
            <div className="action-menu-label">Edit</div>
          </div>
        )}
        <EditCocRecordModal
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
          onSubmit={onEdit}
          defaultInfo={item}
        />
        {hasPermission(MONOLITH_PERMISSIONS.COC_DELETE) && (
          <div
            className="action-menu-item"
            onClick={() => setShowDeleteModel(true)}
            style={{ marginBottom: 10 }}
          >
            <DeleteOutlineOutlinedIcon
              style={{ color: theme.palette.error.main }}
            />
            <div className="action-menu-label">Delete</div>
          </div>
        )}
        <DeleteModal
          open={showDeleteModel}
          handleClose={() => setShowDeleteModel(false)}
          onSubmit={onEdit}
          defaultInfo={item}
        />
      </div>
      <DetailItem label="Type" value={item.type} />
      <DetailItem
        label="Timestamp"
        value={monolithMoment({
          timestamp: item.timestamp,
          includeTime: true,
          includeZone: true,
        })}
      />
      <DetailItem
        label="Custody From"
        value={
          <div>
            <div>{item.custody_from}</div>
            {signature?.custody_from_sig && (
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  src={signature.custody_from_sig.signature}
                  style={{
                    maxHeight: 30,
                    filter: theme.name === "DARK" ? "invert(1)" : "unset",
                  }}
                  alt="signature"
                />
              </div>
            )}
          </div>
        }
      />
      <DetailItem
        label="Custody To"
        value={
          <div>
            <div>{item.custody_to}</div>
            {signature?.custody_to_sig && (
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <img
                  src={signature.custody_to_sig.signature}
                  style={{
                    maxHeight: 30,
                    filter: theme.name === "DARK" ? "invert(1)" : "unset",
                  }}
                  alt="signature"
                />
              </div>
            )}
          </div>
        }
      />
      <DetailItem label="Description" value={item.reason} />
    </div>
  );
})`
  margin-top: 15px;
`;

export const DetailItem = styled(({ className, label, value }) => {
  return (
    <div className={className}>
      <div className="request-detail-label">{label}</div>
      <div className="request-detail-value">{value}</div>
    </div>
  );
})`
  display: flex;
  margin-bottom: 8px;
  font-size: 12px;
  .request-detail-label {
    color: ${(props) => props.theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 100px;
  }
  .request-detail-value {
    color: ${(props) => props.theme.palette.text.primary};
    white-space: pre-line;
  }
`;

const EditCocRecordModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const form = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const custodyToSig = useRef(null);
  const custodyFromSig = useRef(null);

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      const data = { ...form.current.instance.option().formData };

      handleClose();

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = custodyToSig.current.isEmpty()
        ? null
        : {
            signature: custodyToSig.current.getSignature(),
            signer: data.custody_to,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = custodyFromSig.current.isEmpty()
        ? null
        : {
            signature: custodyFromSig.current.getSignature(),
            signer: data.custody_from,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      if (signature.custody_to_sig && signature.custody_from_sig) {
        data.signature = JSON.stringify(signature);
      }

      await EvidenceAPI.updateCocRecord(defaultInfo.coc_id, {
        ...data,
        timestamp: db_timestamp(data.timestamp, true),
      });

      onSubmit({
        ...data,
        timestamp: db_timestamp(data.timestamp, true),
      });

      enqueueSnackbar(`Chain of Custody Record Inserted`, {
        variant: "success",
      });
    }
  };

  const defaultFormData = {
    custody_from: defaultInfo.custody_from,
    custody_to: defaultInfo.custody_to,
    reason: defaultInfo.reason,
    timestamp: defaultInfo.timestamp,
    type: defaultInfo.type,
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 650,
          minHeight: 450,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 325px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Edit Chain of Custody Record
        </div>
        <Form ref={form} colCount={2} defaultFormData={{ ...defaultFormData }}>
          <SimpleItem
            dataField="custody_from"
            label={{ text: "Custody From" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="custody_to"
            label={{ text: "Custody To" }}
            isRequired={true}
          />
          <SimpleItem
            dataField="timestamp"
            label={{ text: "Timestamp" }}
            editorType="dxDateBox"
            isRequired={true}
            editorOptions={{
              type: "datetime",
              useMaskBehavior: true,
              displayFormat: getDateFormat(false, true),
            }}
            validationRules={[
              {
                type: "custom",
                message: "Invalid Date",
                validationCallback: validateTimestamp,
              },
            ]}
          />
          {defaultInfo.type !== "Intake" ? (
            <SimpleItem
              dataField="type"
              label={{ text: "COC Type" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: ["Move", "Checkout", "Release", "Re-Collect"],
              }}
            />
          ) : (
            <EmptyItem />
          )}
          <SimpleItem
            dataField="reason"
            label={{ text: "Notes" }}
            colSpan={2}
            isRequired={true}
            editorType="dxTextArea"
            editorOptions={{
              height: 100,
            }}
          />
          <SimpleItem
            dataField="custody_from_sig"
            label={{ text: "Custody From Signature" }}
          >
            <SignatureField
              sigFieldRef={custodyFromSig}
              defaultSignature={
                defaultInfo?.signature?.custody_from_sig?.signature || null
              }
              title="Custody From Signature"
            />
          </SimpleItem>
          <SimpleItem
            dataField="custody_to_sig"
            label={{ text: "Custody To Signature" }}
          >
            <SignatureField
              sigFieldRef={custodyToSig}
              defaultSignature={
                defaultInfo?.signature?.custody_to_sig?.signature || null
              }
              title="Custody To Signature"
            />
          </SimpleItem>
        </Form>
        <ToolBarItems
          submitText="Edit Record"
          onSubmit={handleSubmit}
          onCancel={() => handleClose()}
        />
      </div>
    </Modal>
  );
};

const DeleteModal = ({
  open,
  handleClose = () => {},
  onSubmit = () => {},
  defaultInfo,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    EvidenceAPI.deleteCocRecord(defaultInfo.coc_id).then(() => {
      enqueueSnackbar(`Chain of Custody Record Deleted.`, {
        variant: "success",
      });
      onSubmit();
    });
    handleClose();
  };

  const handleCancel = () => handleClose();

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          width: 400,
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: "calc(50% - 200px)",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          padding: 20,
          outline: "none",
        }}
      >
        <div style={{ marginBottom: 15, fontSize: "large" }}>
          Delete Chain of Custody Record
        </div>
        <div style={{ margin: "10px 0px" }}>
          Are you sure you want to delete this Chain of Custody Record?
        </div>
        <div style={{ margin: "20px 0px" }}>
          <DetailItem label="Type" value={defaultInfo.type} />
          <DetailItem
            label="Timestamp"
            value={monolithMoment({
              timestamp: defaultInfo.timestamp,
              includeTime: true,
              includeZone: true,
            })}
          />
          <DetailItem
            label="Custody From"
            value={
              <div>
                <div>{defaultInfo.custody_from}</div>
              </div>
            }
          />
          <DetailItem
            label="Custody To"
            value={
              <div>
                <div>{defaultInfo.custody_to}</div>
              </div>
            }
          />
          <DetailItem label="Description" value={defaultInfo.reason} />
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <ToolBarItems
            submitText="Delete Record"
            submitColor="error"
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CocContent;
