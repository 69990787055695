// React & 3rd party libraries
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import {
  useInfiniteQuery,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { useDebounce, useDebouncedCallback } from "use-debounce";

// Icon imports
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import ZoomInMapOutlinedIcon from "@mui/icons-material/ZoomInMapOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import { Briefcase } from "lucide-react";

// APIs, custom hooks, utilities
import CasesApi, { getCases } from "../../api/cases/index.js";
import UserApi from "../../api/users/users.js";
import OfficesApi from "../../api/offices/index.js";
import ClientsAPI from "../../api/clients/index.js";
import { usePermissions } from "../../hooks/usePermissions";
import { getDateFormat, monolithMoment } from "../../utils/date-format.js";
import { useQueryFilter } from "../../Monolith-UI/QueryFilter/QueryFilter.js";
import synchronizeColumnState from "../../utils/synchronize-column-state.js";

// Custom components
import { Input } from "@monolith-forensics/monolith-ui";
import Loader from "../../components/Loader.js";
import Table, { Column, useTable } from "../../Monolith-UI/Table/Table.js";
import ComboButton from "../../Monolith-UI/ComboButton/ComboButton.js";
import Flyout, { FlyoutHeader } from "../../Monolith-UI/Flyout/Flyout.js";
import TaskButton from "../../Monolith-UI/TaskButton.js";
import CaseColumnDefs from "../../components/Cases/CaseColumnDefs.js";
import CaseDimensions from "../../components/Cases/CaseDimensions.js";
import { addCustomFieldsToColumnDefs } from "../../components/Evidence/CustomFieldCell.js";
import { ItemTotal } from "../Cases/CaseEvidence/index";

const visibleColumns = [
  "case_number",
  "case_ref",
  "case_open_date",
  "case_type",
  "case_status",
];

const CasesTable = styled(
  ({
    className,
    client_id = null,
    contact_id = null,
    stateStoreKey = "cases:list:user",
    setHasCases = true,
    currentView = { value: "user-view" },
    currentUser = { user_id: null },
  }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const queryFilter = useRef(null);

    const pageSize = 100;

    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [flyoutData, setFlyoutData] = useState(null);

    const table = useTable();

    const [columnState, setColumnState] = useState(() => {
      return synchronizeColumnState(
        CaseColumnDefs.map((col) => {
          return {
            ...col,
            visible: visibleColumns.includes(col.dataField),
          };
        }),
        JSON.parse(localStorage.getItem(stateStoreKey) || "{}")
      );
    });

    const currentSort = useMemo(() => {
      let [sort] = columnState
        ?.filter((c) => c.sorting?.active)
        .map((c) => ({ field: c.dataField, sort: c.sorting?.direction }));

      return sort;
    }, [columnState]);

    const [query, setQuery] = useState(null);
    const [searchText, setSearchText] = useState(
      localStorage.getItem(`${stateStoreKey}:searchText`)
    );
    const [debouncedSearchText] = useDebounce(searchText, 500);
    const userID =
      currentView.value === "user-view" ? currentUser.user_id : undefined;

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteQuery({
        queryKey: [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              client_id,
              contact_id,
              user_id: userID,
              include_assignees: true,
            },
          },
        ],
        queryFn: ({ pageParam }) =>
          CasesApi.query({
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              page: pageParam,
              client_id: client_id,
              user_id: userID,
              include_assignees: true,
            },
          }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextPage;
        },
        getPreviousPageParam: (firstPage) => {
          if (firstPage.page - 1 === 0) return null;
          return firstPage.page - 1;
        },
        initialPageParam: 1,
        enabled: !!query,
        placeholderData: (data) => data,
      });

    const records = data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.data];
    }, []);

    const totalRecords = data?.pages?.[0]?.total || 0;

    const debouncedFetchNextPage = useDebouncedCallback((e) => {
      fetchNextPage();
    }, 50);

    const { data: customFields } = useQuery({
      queryKey: ["cases:customFields:list"],
      queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
    });

    useQueries({
      queries: [
        {
          queryKey: ["cases:types"],
          queryFn: () => CasesApi.getCaseTypes(),
          enabled: !!data,
        },
        {
          queryKey: ["cases:statuses"],
          queryFn: () => CasesApi.getCaseStatuses(),
          enabled: !!data,
        },
        {
          queryKey: ["cases:progress"],
          queryFn: () => CasesApi.getCaseProgress(),
          enabled: !!data,
        },
        {
          queryKey: [
            "users:list",
            { includeObservers: false, includeInactive: false },
          ],
          queryFn: () =>
            UserApi.getUsers({
              includeObservers: false,
              includeInactive: false,
            }),
          enabled: !!data,
        },
        {
          queryKey: ["offices:list"],
          queryFn: () => OfficesApi.getOffices(),
          enabled: !!data,
        },
        {
          queryKey: ["clients:list"],
          queryFn: () => ClientsAPI.getClients(),
          enabled: !!data,
        },
        {
          queryKey: ["clients:organizations"],
          queryFn: () => ClientsAPI.getOrganizations(),
          enabled: !!data,
        },
        {
          // Fetch custom fields boxes
          queryKey: ["cases:customFields:list"],
          queryFn: () => CasesApi.getCaseCustomAttributes({ fieldsOnly: true }),
          enabled: !!data,
        },
        {
          // Fetch custom fields values
          queryKey: ["case:custom_fields:values"],
          queryFn: () => CasesApi.getCaseCustomAttributes(),
          enabled: !!data,
        },
      ],
    });

    // update filter dimensions with custom fields
    const filterDimensions = useMemo(() => {
      if (customFields) {
        //update filter dimensions
        return [
          ...CaseDimensions,
          ...customFields.map((field) => ({
            name: field.field_name,
            field: `custom_field_${field.field_id}`,
            id: `custom_field_${field.field_id}`,
            type:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "string"
                : "text",
            mode:
              field.editor_type === "dateBox"
                ? "date"
                : field.editor_type === "dropDown" ||
                  field.editor_type === "tagBox"
                ? "multi-select"
                : "text",
            options: field.options
              ? JSON.parse(field.options).map((o) => ({
                  option: o,
                }))
              : null,
            selectionDisplayName: field.options ? "option" : null,
            selectionIdField: field.options ? "option" : null,
          })),
        ];
      }
      return CaseDimensions;
    }, [customFields]);

    const handleFilter = (newFilter) => {
      setQuery((q) => {
        return {
          query: {
            ...q?.query,
            ...newFilter,
            page: 1,
            client_id: client_id,
            user_id: userID,
          },
        };
      });
    };
    const handleColumnVisibility = (column, visible) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          if (c.dataField === column.dataField) {
            return {
              ...c,
              visible,
            };
          }
          return c;
        });
      });
    };
    const handleExportCaseTable = () => {
      // show snackbar
      enqueueSnackbar("Exporting table...", {
        variant: "info",
      });

      CasesApi.exportCaseList({
        query: {
          ...query?.query,
          order: query?.query?.order || currentSort,
        },
        type: "xlsx",
        columns: columnState
          .filter((c) => c.visible !== false)
          .sort((a, b) => a.order - b.order)
          .map((c) => {
            return { dataField: c.dataField, header: c.caption, ...c };
          }),
        date_format: getDateFormat(true, true),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        const { signedUrl, filename } = res;
        const el = document.createElement("a");
        el.href = signedUrl.replace(
          "http://localhost:3000",
          "http://localhost:3001"
        );
        el.download = filename;
        el.click();
        // remove snackbar
      });
    };
    const handleRefresh = () => {
      queryClient.refetchQueries({
        queryKey: [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              client_id: client_id,
              user_id: userID,
            },
          },
        ],
      });
    };
    const handleReload = () => {
      queryClient.refetchQueries({
        queryKey: [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              client_id: client_id,
              user_id: userID,
            },
          },
        ],
      });
    };
    const handleClearFilters = () => {
      queryFilter.current.clear();
    };
    const handleSort = (field) => {
      const savedColumn =
        columnState?.find((svc) => field === svc.dataField) || {};
      const order = !!savedColumn ? savedColumn?.sorting?.direction : null;

      let newOrder = null;

      // if sorted on a different column, reset the sort
      if (query?.query?.order?.field !== field) {
        newOrder = {
          field,
          sort: "asc",
        };
      }

      // otherwise rotate the sort options on the current column
      else {
        switch (order) {
          case "asc":
            newOrder = {
              field,
              sort: "desc",
            };
            break;
          case "desc":
            newOrder = null;
            break;
          default:
            newOrder = {
              field,
              sort: "asc",
            };
        }
      }

      let newCols = columnState?.map((c) => {
        if (c.dataField === field) {
          return {
            ...c,
            sorting: {
              active: newOrder ? true : false,
              direction: newOrder?.sort,
            },
          };
        }
        delete c.sorting;
        return c;
      });

      let newQuery = {
        query: {
          ...query.query,
          order: newOrder,
        },
      };

      setColumnState(newCols);

      setQuery(newQuery);
    };
    const handleColumnReorder = (newOrder) => {
      setColumnState((cs) => {
        return newOrder.map((o) => {
          return {
            ...cs.find((c) => c.dataField === o.column),
            order: o.order,
          };
        });
      });
    };
    const handleColumnResize = (e) => {
      setColumnState((cs) => {
        return cs.map((c) => {
          const col = e.columns.find((col) => col.dataField === c.dataField);
          if (col) {
            return {
              ...c,
              width: parseInt(col.width.replace(/px/g, "")),
            };
          }
          return c;
        });
      });
    };
    const handleActionButtonClick = (rowData) => {
      setIsFlyoutVisible(true);
      setFlyoutData(rowData);
    };
    const handleGetNextItem = (case_id) => {
      const current = records?.findIndex((i) => i.case_id === case_id);
      const nextItem = records[current + 1] || records[0];

      setFlyoutData(nextItem);
    };
    const handleGetPrevItem = (case_id) => {
      const current = records?.findIndex((i) => i.case_id === case_id);
      const prevItem = records[current - 1] || records[records.length - 1];

      setFlyoutData(prevItem);
    };
    // Detect scroll to bottom
    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (
        scrollHeight - scrollTop <=
        clientHeight + 100 * data?.pages?.length
      ) {
        if (hasNextPage && !isFetchingNextPage) {
          debouncedFetchNextPage();
        }
      }
    };
    const updateCache = (updateDetails = { rowData: {}, updateData: {} }) => {
      const itemUUID = updateDetails.rowData.uuid;
      queryClient.setQueryData(
        [
          stateStoreKey,
          {
            query: {
              ...query?.query,
              order: query?.query?.order || currentSort,
              pageSize,
              client_id: client_id,
              user_id: userID,
            },
          },
        ],
        (data) => {
          if (!data) return null;
          const newPages = data?.pages.map((page) => {
            return {
              ...page,
              data: page?.data?.map((item) => {
                if (item.uuid === itemUUID) {
                  return {
                    ...item,
                    ...updateDetails.updateData,
                  };
                }
                return item;
              }),
            };
          });

          return {
            ...data,
            pages: newPages,
          };
        }
      );
    };

    // Sync column state to local storage
    useEffect(() => {
      let oldState = JSON.parse(localStorage.getItem(stateStoreKey)) || {};
      localStorage.setItem(
        stateStoreKey,
        JSON.stringify({
          ...oldState,
          cols: columnState,
        })
      );
    }, [columnState]);

    // Add custom fields to column state
    useEffect(() => {
      if (!customFields) return;
      setColumnState((cs) => {
        return addCustomFieldsToColumnDefs(customFields, cs);
      });
    }, [customFields]);

    useEffect(() => {
      setQuery((q) => ({
        query: {
          ...q?.query,
          search: debouncedSearchText ? debouncedSearchText : null,
          page: 1,
        },
      }));
    }, [debouncedSearchText]);

    const { queryButton, conditions } = useQueryFilter({
      dimensions: filterDimensions.sort((a, b) => a.name.localeCompare(b.name)),
      onQuerySet: handleFilter,
      queryFilter: queryFilter,
      stateStoring: {
        enabled: true,
        type: "localStorage",
        storageKey: stateStoreKey,
      },
    });

    if (totalRecords === 0) {
      setHasCases(false);
    }

    return (
      <div className={className}>
        <div
          style={{
            display: "flex",
            flex: "initial",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          <div>{queryButton}</div>
          <ItemTotal total={totalRecords} Icon={Briefcase} />
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              minWidth: "fit-content",
            }}
          >
            <ComboButton
              type="multi-select"
              data={columnState.filter((c) => c.showInColumnChooser !== false)}
              displayField="caption"
              idField={"dataField"}
              selectedItems={columnState.filter((c) => c.visible !== false)}
              variant="outlined"
              closeOnSelect={false}
              showSearch={true}
              dropDownTitle={() => {
                return (
                  <div
                    style={{
                      margin: "5px 0px",
                      padding: 3,
                      color: theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "center",
                      minWidth: 200,
                    }}
                  >
                    Select Columns
                  </div>
                );
              }}
              onItemDeSelect={(item) => {
                handleColumnVisibility(item, false);
              }}
              onItemSelect={(item) => {
                handleColumnVisibility(item, true);
              }}
              textColor={theme.palette.text.secondary}
              title={"Select Columns"}
            >
              <ViewColumnOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Export Table"}
              onClick={handleExportCaseTable}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Clear Filters"}
              onClick={handleClearFilters}
            >
              <FilterListOffIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={table.isCompact ? "Zoom In" : "Zoom Out"}
              onClick={() => table.toggleCompact()}
            >
              {table.isCompact && (
                <ZoomOutMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
              {!table.isCompact && (
                <ZoomInMapOutlinedIcon style={{ fontSize: 18 }} />
              )}
            </ComboButton>
            <ComboButton
              type="button"
              variant={"outlined"}
              textColor={
                table.isStriped
                  ? theme.palette.primary.main
                  : theme.palette.text.secondary
              }
              title={table.isStriped ? "Hide Stripes" : "Show Stripes"}
              onClick={() => table.toggleStripes()}
            >
              <ReorderOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            <ComboButton
              type="button"
              variant="outlined"
              textColor={theme.palette.text.secondary}
              title={"Refresh Table"}
              onClick={handleRefresh}
            >
              <SyncOutlinedIcon style={{ fontSize: 18 }} />
            </ComboButton>
            {/* SEARCH */}
            <Input
              placeholder="Search Cases"
              variant="outlined"
              height={30}
              defaultValue={searchText}
              onKeyUp={(e) => {
                const currentValue = e.target.value.trim();
                setSearchText(currentValue);

                localStorage.setItem(
                  `${stateStoreKey}:searchText`,
                  currentValue
                );
              }}
              style={{
                marginLeft: "10px",
                border: searchText
                  ? `1px solid ${theme.palette.primary.main}`
                  : "",
              }}
            />
          </div>
        </div>
        {data && (
          <>
            {<div>{conditions}</div>}
            <Table
              data={records}
              totalRecords={totalRecords}
              reloadFn={handleReload}
              keyValue="case_id"
              tableInstance={table}
              onHeaderClick={(col) =>
                col?.sorting?.enabled === false
                  ? null
                  : handleSort(col.dataField)
              }
              onRowUpdated={(e) => updateCache(e)}
              onScroll={handleScroll}
              onColumnReorder={handleColumnReorder}
              onColumnResize={handleColumnResize}
              showActionColumn={true}
              onActionButtonClick={handleActionButtonClick}
              focusedRow={flyoutData}
            >
              {columnState.map((col) => {
                return <Column key={col.dataField} {...col} />;
              })}
            </Table>
          </>
        )}
        {!data && <Loader message={"Retrieving Cases..."} />}
        {isFlyoutVisible && (
          <CaseFlyout
            setIsFlyoutVisible={setIsFlyoutVisible}
            setFlyoutData={setFlyoutData}
            flyoutData={flyoutData}
            handleGetNextItem={handleGetNextItem}
            handleGetPrevItem={handleGetPrevItem}
            reloadFn={handleReload}
            columnDefs={columnState}
          />
        )}
      </div>
    );
  }
)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CaseFlyout = styled(
  ({
    className,
    setIsFlyoutVisible,
    setFlyoutData,
    flyoutData,
    handleGetNextItem = () => {},
    handleGetPrevItem = () => {},
    reloadFn = () => {},
    columnDefs = [],
  }) => {
    const theme = useTheme();
    return (
      <Flyout
        onClose={() => {
          setIsFlyoutVisible(false);
          setFlyoutData(null);
        }}
      >
        <FlyoutHeader>
          <div style={{ width: "100%" }}>
            <h4
              className="flyout-title"
              style={{
                margin: "5px 0px",
                color: theme.palette.text.primary,
              }}
            >
              {flyoutData.case_number || "..."}
            </h4>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: 12,
                }}
              >
                Case Details
              </div>
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TaskButton
                  onClick={() => handleGetPrevItem(flyoutData.case_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowLeftOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
                <TaskButton
                  onClick={() => handleGetNextItem(flyoutData.case_id)}
                  style={{
                    fontSize: 10,
                  }}
                  variant="outlined"
                >
                  <KeyboardArrowRightOutlinedIcon style={{ fontSize: 17 }} />
                </TaskButton>
              </div>
            </div>
          </div>
        </FlyoutHeader>
        <FlyoutContent
          defaultInfo={flyoutData}
          reloadFn={reloadFn}
          columnDefs={columnDefs}
        />
      </Flyout>
    );
  }
)``;

const FlyoutContent = styled(
  ({ className, defaultInfo, reloadFn = () => {}, columnDefs = [] }) => {
    const { hasPermission } = usePermissions();
    const queryClient = useQueryClient();

    const { data: itemData } = useQuery({
      queryKey: ["cases", "list", { case_id: defaultInfo.case_id }],
      queryFn: () =>
        getCases({ case_id: defaultInfo.case_id, include_assignees: true }),
      initialData: [defaultInfo],
      select: (res) => {
        return res[0];
      },
    });

    const title = document.querySelector(".flyout-title");
    if (title) {
      title.innerText = itemData?.case_number || defaultInfo?.case_id || "";
    }

    const handleChange = (data) => {
      reloadFn();
      queryClient.setQueryData(
        ["cases", "list", { case_id: defaultInfo.case_id }],
        (oldData) => {
          const dataObj = oldData[0] || {};
          return [{ ...dataObj, ...data }];
        }
      );
    };

    const dataFieldIgnoreList = ["case_synopsis"];

    const data = {
      ...itemData,
      ...(itemData?.custom_attributes?.reduce((acc, attr) => {
        acc[`custom_field_${attr.field_id}`] = attr.value;
        return acc;
      }, {}) || {}),
    };

    const detail = (
      <div className="detail-container">
        {columnDefs
          .filter((c) => !dataFieldIgnoreList.includes(c.dataField))
          .filter((c) =>
            c.dataField.includes("custom_field_") ? !!data[c.dataField] : true
          )
          .map((c) => {
            return (
              <div className="detail-item" key={nanoid()}>
                <div className="detail-label">{c.caption}</div>
                {c.render ? (
                  c.render(data, { onChange: handleChange })
                ) : c.dataType === "date" ? (
                  <>
                    {monolithMoment({
                      timestamp: data[c.dataField],
                      includeTime: true,
                    })}
                  </>
                ) : (
                  <div className="detail-value">{data[c.dataField]}</div>
                )}
              </div>
            );
          })}
      </div>
    );

    return (
      <div className={className}>
        {!!itemData.case_id && (
          <>
            {detail}
            <div className="detail-label" style={{ marginTop: 30 }}>
              Description
            </div>
            <div style={{ position: "relative" }}>
              <div className="item-description">{itemData.case_synopsis}</div>
            </div>
          </>
        )}
        {!itemData && <Loader message={"Loading..."} />}
      </div>
    );
  }
)`
  padding: 20px;
  & {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    margin-right: 5px;
  }
  & .action-menu {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  & .action-menu-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
    & svg {
      font-size: 15px;
      margin-right: 5px;
    }
  }
  & .detail-item {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 10px 0px;
    font-size: 12px;
  }
  & .detail-label {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-right: 10px;
    min-width: 150px;
  }
  & .detail-value {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  & .item-description {
    position: relative;
    resize: none;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: transparent;
    white-space: pre-wrap;
    width: 100%;
    height: fit-content;
    max-height: 30%;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;
    transition: border 0.2s ease-in-out;
    outline: none;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.divider};
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
  & .item-description-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.divider};
    display: flex;
    alignitems: center;
    fontsize: 12px;
    padding: 10px;
  }
`;

export default CasesTable;
