import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { Extension } from "@tiptap/core";
import Suggestion from "@tiptap/suggestion";
import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  List,
  ListOrdered,
  Text,
  Image as ImageIcon,
  Calendar,
  ClockIcon,
} from "lucide-react";
import { startImageUpload } from "../plugins/upload-images.js";
import styled from "styled-components";
import { PluginKey } from "@tiptap/pm/state";
import { monolithMoment } from "../../../utils/date-format.js";

const Command = Extension.create({
  name: "slash-command",
  addOptions() {
    return {
      suggestion: {
        char: "/",
        pluginKey: new PluginKey("slash-command"),
        command: ({ editor, range, props }) => {
          props.command({ editor, range });
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

const getSuggestionItems = ({ query }, options = {}) => {
  return [
    {
      title: "Text",
      description: "Just start typing with plain text.",
      searchTerms: ["p", "paragraph"],
      icon: <Text size={18} />,
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .toggleNode("paragraph", "paragraph")
          .run();
      },
    },
    {
      title: "Heading 1",
      description: "Large section heading.",
      searchTerms: ["title", "big", "large"],
      icon: <Heading1 size={18} />,
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 1 })
          .run();
      },
    },
    {
      title: "Heading 2",
      description: "Medium section heading.",
      searchTerms: ["subtitle", "medium"],
      icon: <Heading2 size={18} />,
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 2 })
          .run();
      },
    },
    {
      title: "Heading 3",
      description: "Small section heading.",
      searchTerms: ["subtitle", "small"],
      icon: <Heading3 size={18} />,
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 3 })
          .run();
      },
    },
    {
      title: "Heading 4",
      description: "Smaller section heading.",
      searchTerms: ["subtitle", "small"],
      icon: <Heading4 size={18} />,
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 4 })
          .run();
      },
    },
    {
      title: "Bullet List",
      description: "Create a simple bullet list.",
      searchTerms: ["unordered", "point", "bullet"],
      icon: <List size={18} />,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBulletList().run();
      },
    },
    {
      title: "Numbered List",
      description: "Create a list with numbering.",
      searchTerms: ["ordered", "numbered"],
      icon: <ListOrdered size={18} />,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleOrderedList().run();
      },
    },
    {
      title: "Current Date",
      description: "Insert the current date.",
      searchTerms: ["date"],
      icon: <Calendar size={18} />,
      command: ({ editor, range }) => {
        const currentDate = monolithMoment({ timestamp: Date.now() });
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent(currentDate)
          .run();
      },
    },
    {
      title: "Current Timestamp",
      description: "Insert the current timestamp.",
      searchTerms: ["timestamp"],
      icon: <ClockIcon size={18} />,
      command: ({ editor, range }) => {
        const currentDate = monolithMoment({
          timestamp: Date.now(),
          includeTime: true,
          includeZone: true,
        });
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertContent(currentDate)
          .run();
      },
    },
    {
      title: "Image",
      description: "Upload an image from your computer.",
      searchTerms: ["photo", "picture", "media"],
      icon: <ImageIcon size={18} />,
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).run();
        // upload image
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = async () => {
          if (input.files?.length) {
            const file = input.files[0];
            const pos = editor.view.state.selection.from;
            startImageUpload(file, editor.view, pos, options.handleImageUpload);
          }
        };
        input.click();
      },
    },
  ]
    .filter((item) => {
      if (options?.exclude) {
        return !options.exclude.includes(item.title);
      }
      return true;
    })
    .filter((item) => {
      if (typeof query === "string" && query.length > 0) {
        const search = query.toLowerCase();
        return (
          item.title.toLowerCase().includes(search) ||
          item.description.toLowerCase().includes(search) ||
          (item.searchTerms &&
            item.searchTerms.some((term) => term.includes(search)))
        );
      }
      return true;
    });
};

export const updateScrollView = (container, item) => {
  const containerHeight = container.offsetHeight;
  const itemHeight = item ? item.offsetHeight : 0;

  const top = item.offsetTop;
  const bottom = top + itemHeight;

  if (top < container.scrollTop) {
    container.scrollTop -= container.scrollTop - top + 5;
  } else if (bottom > containerHeight + container.scrollTop) {
    container.scrollTop += bottom - containerHeight - container.scrollTop + 5;
  }
};

const CommandList = styled(({ className, items, command, editor, range }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = useCallback(
    (index) => {
      const item = items[index];
      if (item) {
        if (item.title === "Continue writing") {
        } else {
          command(item);
        }
      }
    },
    [
      //   complete,
      command,
      editor,
      items,
    ]
  );

  useEffect(() => {
    const navigationKeys = ["ArrowUp", "ArrowDown", "Enter"];
    const onKeyDown = (e) => {
      if (navigationKeys.includes(e.key)) {
        e.preventDefault();
        if (e.key === "ArrowUp") {
          setSelectedIndex((selectedIndex + items.length - 1) % items.length);
          return true;
        }
        if (e.key === "ArrowDown") {
          setSelectedIndex((selectedIndex + 1) % items.length);
          return true;
        }
        if (e.key === "Enter") {
          selectItem(selectedIndex);
          return true;
        }
        return false;
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [items, selectedIndex, setSelectedIndex, selectItem]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  const commandListContainer = useRef(null);

  useLayoutEffect(() => {
    const container = commandListContainer?.current;

    const item = container?.children[selectedIndex];

    if (item && container) updateScrollView(container, item);
  }, [selectedIndex]);

  return items.length > 0 ? (
    <div
      id="slash-command"
      ref={commandListContainer}
      className={className + " slash-command"}
    >
      {items.map((item, index) => {
        return (
          <button
            className={`slash-command-item ${
              index === selectedIndex ? "selected" : ""
            }`}
            key={index}
            onClick={() => selectItem(index)}
          >
            <div className="item-icon">{item.icon}</div>
            <div>
              <div className="item-title">{item.title}</div>
              <div className="item-description">{item.description}</div>
            </div>
          </button>
        );
      })}
    </div>
  ) : null;
})`
  &.slash-command {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    z-index: 16001;
    height: auto;
    max-height: 330px;
    width: 18rem;
    overflow-y: auto;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 0.375rem;
    background-color: ${({ theme }) => theme.palette.background.default};
    padding: 0.5rem;
    box-shadow: ${({ theme }) => theme.shadows[2]};
    transition: all 0.2s ease-in-out;
  }

  .slash-command-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.375rem;
    padding: 0.25rem 0.5rem;
    text-align: left;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.default};
    outline: none;
    border: none;

    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
      cursor: pointer;
    }
  }

  .slash-command-item.selected {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  .item-icon {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    margin-right: 0.5rem;
  }

  .item-title {
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 0.125rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .item-description {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.text.secondary};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const renderItems = () => {
  let component = null;
  let popup = null;

  return {
    onStart: (props) => {
      component = new ReactRenderer(CommandList, {
        props,
        editor: props.editor,
      });

      // @ts-ignore
      popup = tippy("body", {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
        zIndex: 16000,
      });
    },
    onUpdate: (props) => {
      component?.updateProps(props);

      popup &&
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
    },
    onKeyDown: (props) => {
      if (props.event.key === "Escape") {
        popup?.[0].hide();

        return true;
      }

      // @ts-ignore
      return component?.ref?.onKeyDown(props);
    },
    onExit: () => {
      popup?.[0].destroy();
      component?.destroy();
    },
  };
};

export const getSlashCommand = (options = {}) => {
  return Command.configure({
    suggestion: {
      items: (e) => getSuggestionItems(e, options),
      render: renderItems,
    },
  });
};
