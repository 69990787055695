import { useTheme } from "styled-components";
import { usePermissions } from "../../../hooks/usePermissions";
import { useRef } from "react";
import { useQueries } from "@tanstack/react-query";
import CaseStorageAPI from "../../../api/storage/index.js";
import { Button, Modal } from "@mui/material";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../utils/date-format.js";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader.js";
import { Form, SimpleItem } from "devextreme-react/ui/form.js";
import LocationSelectBoxItem from "../../../components/SelectBoxItems/LocationSelectBoxItem.js";
import { Template } from "devextreme-react/core/template.js";
import LocationsAPI from "../../../api/locations/index.js";
import DataSource from "devextreme/data/data_source";
import { getIpAddress } from "../../../api/index.js";
import StorageSelectBoxItem from "../../../components/SelectBoxItems/StorageSelectBoxItem.js";
import SignatureField from "../../../components/SignatureField/SignatureField.js";
import moment from "moment";

const AssignStoragePopup = ({
  caseInfo,
  onSubmit = () => {},
  open,
  handleClose,
  table,
}) => {
  const theme = useTheme();
  const form = useRef(null);
  const assignedBySig = useRef(null);
  const { currentUser } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const result = useQueries({
    queries: [
      {
        queryKey: [
          "storage:numbers",
          { excludeAssigned: true, excludeGeneral: true },
        ],
        queryFn: () =>
          CaseStorageAPI.getStorageNumbers({
            excludeAssigned: true,
            excludeGeneral: true,
          }),
        enabled: open,
      },
      {
        queryKey: ["evidence:locations", { include_groups: false }],
        queryFn: (params) =>
          LocationsAPI.getLocations({}, { include_groups: false }),
        enabled: open,
      },
    ],
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option().formData };

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_from_sig = assignedBySig.current.isEmpty()
        ? null
        : {
            signature: assignedBySig.current.getSignature(),
            signer: currentUser.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      formData.signature = JSON.stringify(signature);

      CaseStorageAPI.assignStorageToCase({
        case_id: caseInfo.case_id,
        storageIDs: formData.storage_items,
        entered_by: currentUser.full_name,
        entered_by_id: currentUser.user_id,
        custody_from: "Storage Cache",
        reason: formData.reason || "Storage item assigned to case.",
        timestamp: db_timestamp(formData.timestamp),
        location_id: formData.location_id,
        signature: formData.signature,
        coc_type: "Intake",
      }).then((result) => {
        enqueueSnackbar(`Storage Item Assigned to Case.`, {
          variant: "success",
        });
        onSubmit();
      });
      handleClose();
    }
  };

  // check if the query is loading
  const isDone = result.every((item) => item.isSuccess);

  const fieldData = {
    storage: result[0]?.data?.data || [],
    locations: result[1]?.data || [],
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        style={{ zIndex: 1400 }}
      >
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 500,
            backgroundColor: theme.palette.background.default,
            position: "fixed",
            left: "calc(50% - 250px)",
            maxHeight: "calc(100vh - 100px)",
            padding: 20,
            outline: "none",
          }}
        >
          <div style={{ marginBottom: 15, fontSize: "large" }}>
            Assign Storage Item
          </div>
          {!isDone && <Loader />}
          {isDone && (
            <>
              <Form ref={form} colCount={1}>
                <SimpleItem
                  dataField="storage_items"
                  label={{ text: "Storage Items" }}
                  isRequired={true}
                  editorType="dxTagBox"
                  editorOptions={{
                    hint: "Select storage items that should be assigned to case.",
                    searchEnabled: true,
                    dataSource: new DataSource({
                      store: fieldData.storage,
                      paginate: true,
                      pageSize: 10,
                    }),
                    displayExpr: "storage_number",
                    valueExpr: "storage_id",
                    searchExpr: ["storage_number", "serial_number", "type"],
                    showSelectionControls: false,
                    maxDisplayedTags: 20,
                    selectAllMode: "allPages",
                    showDropDownButton: true,
                    dropDownOptions: {
                      height: "350px",
                    },
                    itemTemplate: "storage_items",
                  }}
                />
                <Template
                  name="storage_items"
                  render={(data) => <StorageSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="location_id"
                  label={{ text: "Initial Location" }}
                  isRequired={true}
                  editorType="dxSelectBox"
                  editorOptions={{
                    hint: "Select the new location for this storage item.",
                    searchEnabled: true,
                    dataSource: new DataSource({
                      store: fieldData.locations,
                      key: "location_id",
                      group: "office_name",
                    }),
                    displayExpr: "location_name",
                    valueExpr: "location_id",
                    grouped: true,
                    dropDownOptions: {
                      height: "350px",
                    },
                    itemTemplate: "location_id",
                  }}
                />
                <Template
                  name="location_id"
                  render={(data) => <LocationSelectBoxItem data={data} />}
                />
                <SimpleItem
                  dataField="timestamp"
                  label={{ text: "Timestamp" }}
                  isRequired={true}
                  editorType="dxDateBox"
                  editorOptions={{
                    type: "datetime",
                    useMaskBehavior: true,
                    displayFormat: getDateFormat(false, true),
                    value: new Date(),
                  }}
                  validationRules={[
                    {
                      type: "custom",
                      message: "Invalid Date",
                      validationCallback: validateTimestamp,
                    },
                  ]}
                />
                <SimpleItem
                  dataField={"reason"}
                  label={{ text: "Assign Notes" }}
                  editorType="dxTextArea"
                />
                <SimpleItem
                  dataField="custody_from_sig"
                  label={{ text: "Assigned By Signature" }}
                >
                  <SignatureField
                    sigFieldRef={assignedBySig}
                    title="Assigned By Signature"
                  />
                </SimpleItem>
              </Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: 5,
                  marginTop: 30,
                }}
              >
                <Button
                  variant="text"
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" size="small" onClick={handleSubmit}>
                  Assign Storage
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AssignStoragePopup;
