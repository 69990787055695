import { Box, Button, useTheme } from "@mui/material";
import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { useEffect, useRef, useState } from "react";
import {
  createIssueType,
  deleteIssueType,
  getIssueTypes,
  updateIssueType,
} from "../../api";
import Loader from "../../components/Loader";
import ToolBarItems from "../../components/ToolBarItems";
import { usePermissions } from "../../hooks/usePermissions";
import styled from "styled-components";

const QaIssueTypesList = styled(
  ({
    className,
    issueTypes,
    setIssueTypes,
    setDeleteSelection,
    deleteIssuePopup,
  }) => {
    useEffect(() => {
      getIssueTypes().then((result) => {
        setIssueTypes(result);
      });
    }, []);

    return (
      <div className={className}>
        {!issueTypes && <Loader />}
        {issueTypes &&
          issueTypes.map((type) => {
            return (
              <QaIssueTypeItem
                key={type.issue_type_id}
                data={type}
                issueTypes={issueTypes}
                setIssueTypes={setIssueTypes}
                setDeleteSelection={setDeleteSelection}
                deleteIssuePopup={deleteIssuePopup}
              />
            );
          })}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const QaIssueTypeItem = ({
  data,
  issueTypes,
  setIssueTypes,
  setDeleteSelection,
  deleteIssuePopup,
}) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();

  const handleTypeUpdate = (event) => {
    if (event.currentTarget.innerText === "") {
      event.currentTarget.innerText = data.issue_type;
      return;
    }
    if (event.currentTarget.innerText !== data.issue_type) {
      updateIssueType(data.issue_type_id, {
        issue_type: event.currentTarget.innerText,
      });
      setIssueTypes(
        issueTypes.map((type) => {
          if (type.issue_type_id === data.issue_type_id) {
            type.issue_type = event.currentTarget.innerText;
          }
          return type;
        })
      );
    }
  };

  const handleDelete = (event) => {
    setDeleteSelection(data);
    deleteIssuePopup.current.instance.show();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 10px",
        cursor: "pointer",
        "&:hover": { backgroundColor: theme.palette.action.hover },
      }}
    >
      <div>
        <Box
          contentEditable={true}
          suppressContentEditableWarning
          sx={{
            fontSize: "larger",
            padding: "2px",
            cursor: "text",
            "&:hover": { outline: `1px solid slategray` },
            "&:focus": { outline: `1px solid slategray` },
          }}
          onBlur={handleTypeUpdate}
        >
          {data.issue_type}
        </Box>
        <div style={{ color: "slategrey", padding: "2px" }}>
          {data.total} QA Entries
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Button
          variant="text"
          color="error"
          size="small"
          disabled={!hasPermission()}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </Box>
  );
};

const CreateIssueTypePopup = ({
  createIssueTypePopup,
  issueTypes,
  setIssueTypes,
}) => {
  const form = useRef(null);

  return (
    <Popup
      ref={createIssueTypePopup}
      showTitle={true}
      showCloseButton={true}
      title="Create New QA Issue Type"
      defaultHeight={185}
      defaultWidth={400}
      onHidden={(e) => {
        form.current.instance.resetValues();
      }}
    >
      <Form ref={form}>
        <SimpleItem
          dataField="issue_type"
          label={{ text: "QA Issue Type" }}
          isRequired={true}
        ></SimpleItem>
      </Form>
      <ToolBarItems
        submitText="Create Issue Type"
        onSubmit={() => {
          if (form.current.instance.validate().isValid) {
            let values = Object.assign(
              {},
              form.current.instance.option("formData")
            );

            createIssueType(values).then((result) => {
              setIssueTypes([
                ...issueTypes,
                {
                  issue_type_id: result.issue_type_id,
                  issue_type: values.issue_type,
                  total: 0,
                },
              ]);
            });

            createIssueTypePopup.current.instance.hide();
          }
        }}
        onCancel={() => {
          createIssueTypePopup.current.instance.hide().then(() => {
            form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const DeleteIssuePopup = ({
  deleteIssuePopup,
  issueTypes,
  setIssueTypes,
  deleteSelection,
  setDeleteSelection,
}) => {
  const [items, setItems] = useState(issueTypes);
  const form = useRef(null);

  useEffect(() => {
    deleteSelection &&
      setItems(
        issueTypes.filter(
          (item) => item.issue_type_id !== deleteSelection.issue_type_id
        )
      );
  }, [deleteSelection]);

  return (
    <Popup
      ref={deleteIssuePopup}
      showTitle={true}
      showCloseButton={true}
      title={`Delete QA Issue Type ${deleteSelection?.issue_type || ""}`}
      defaultHeight={250}
      defaultWidth={450}
      onHidden={(e) => {
        if (form.current) form.current.instance.resetValues();
      }}
    >
      {deleteSelection?.total === 0 && (
        <div style={{ margin: "15px 0px", minHeight: 115 }}>
          Are you sure you want to delete this issue type?
        </div>
      )}
      {deleteSelection?.total > 0 && (
        <div style={{ minHeight: 115 }}>
          <div style={{ margin: "15px 0px" }}>
            Select the issue type that should replace this one when deleted:
          </div>
          <Form ref={form}>
            <SimpleItem
              dataField="issue_type"
              label={{ text: "QA Issue Type" }}
              isRequired={true}
              editorType="dxSelectBox"
              editorOptions={{
                items: items,
                displayExpr: "issue_type",
              }}
            ></SimpleItem>
          </Form>
        </div>
      )}
      <ToolBarItems
        submitText="Delete Issue Type"
        submitColor="error"
        onSubmit={() => {
          if (form.current) {
            if (form.current.instance.validate().isValid) {
              let values = Object.assign(
                {},
                form.current.instance.option("formData")
              );
              deleteIssueType(deleteSelection.issue_type_id, {
                replace: values.issue_type.issue_type,
              });
              setIssueTypes(
                items.map((item) => {
                  if (item.issue_type_id === values.issue_type.issue_type_id) {
                    item.total += deleteSelection.total;
                  }
                  return item;
                })
              );
              deleteIssuePopup.current.instance.hide();
            }
          } else {
            deleteIssuePopup.current.instance.hide();
            deleteIssueType(deleteSelection.issue_type_id);
            setIssueTypes(items);
          }
        }}
        onCancel={() => {
          deleteIssuePopup.current.instance.hide().then(() => {
            if (form.current) form.current.instance.resetValues();
          });
        }}
      />
    </Popup>
  );
};

const QaIssueTypeSettings = styled(({ className }) => {
  const [issueTypes, setIssueTypes] = useState(null);
  const [deleteSelection, setDeleteSelection] = useState(null);
  const createIssueTypePopup = useRef(null);
  const deleteIssuePopup = useRef(null);

  return (
    <div className={className} style={{ maxWidth: 800, padding: "0px 30px" }}>
      <div>
        Create, delete, and edit quality assurance issue types. Create QA issue
        types that identify events that do not meet your organizations quality
        assurance standards.
      </div>
      <div style={{ margin: "25px 0px" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            createIssueTypePopup.current.instance.show();
          }}
        >
          + Create Type
        </Button>
      </div>
      <QaIssueTypesList
        issueTypes={issueTypes}
        setIssueTypes={setIssueTypes}
        setDeleteSelection={setDeleteSelection}
        deleteIssuePopup={deleteIssuePopup}
      />
      <CreateIssueTypePopup
        createIssueTypePopup={createIssueTypePopup}
        issueTypes={issueTypes}
        setIssueTypes={setIssueTypes}
      />
      {issueTypes && (
        <DeleteIssuePopup
          deleteIssuePopup={deleteIssuePopup}
          issueTypes={issueTypes}
          setIssueTypes={setIssueTypes}
          deleteSelection={deleteSelection}
          setDeleteSelection={setDeleteSelection}
        />
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 0;
`;

export default QaIssueTypeSettings;
