import { httpDelete, httpGet, httpPatch, httpPost } from "../connection";

export async function getContacts(options = {}) {
  return (await httpGet(`/contacts`, options))?.data || [];
}

async function get(query = {}) {
  const { id, ...rest } = query;
  if (id) {
    return await httpGet(`/contacts/${id}`, rest);
  }
  return await httpGet(`/contacts`, rest);
}

async function query(options) {
  return await httpPost(`/contacts/query`, options);
}

export async function updateContact(contact_id, values) {
  return await httpPatch(`/contacts/${contact_id}`, values);
}

export async function createContact(values = {}) {
  return await httpPost(`/contacts`, values);
}

export async function uploadContacts(values = {}) {
  return await httpPost(`/contacts/upload`, values);
}

export async function deleteContact(contact_id) {
  return await httpDelete(`/contacts/${contact_id}`);
}

export async function getContactTypes() {
  return await httpGet(`/contacts/types`);
}

export async function getContactsByCase(values = { case_id: null }) {
  return await httpGet(`/contacts?case_id=${values.case_id}`);
}

export async function linkCaseContact(
  values = { client_id: null, case_id: null }
) {
  return await httpPost(`/contacts/${values.client_id}/link`, values);
}

export async function unlinkCaseContact(
  values = { client_id: null, case_id: null }
) {
  return await httpDelete(
    `/contacts/${values.client_id}/link?case_id=${values.case_id}`
  );
}

export async function getCaseByContact(options) {
  return await httpGet(`/cases`, options);
}

async function getOrganizations() {
  return await httpGet(`/contacts/organizations`);
}

async function exportContactsList(options) {
  return await httpPost(`/contacts/export`, options);
}

const ContactsAPI = {
  query,
  get,
  getContacts,
  getContactTypes,
  getOrganizations,
  exportContactsList,
  createContact,
  linkCaseContact,
  unlinkCaseContact,
};

export default ContactsAPI;
