import { useMediaQuery, useTheme } from "@mui/material";
import DataSource from "devextreme/data/data_source";
import moment from "moment";
import { useSnackbar } from "notistack";

import { useEffect, useMemo, useRef, useState } from "react";
import { createBulkCocRecords, getIpAddress } from "../../../../../api";
import { useAuth } from "../../../../../contexts/AuthContext";
import {
  db_timestamp,
  getDateFormat,
  validateTimestamp,
} from "../../../../../utils/date-format";

import { Popup } from "devextreme-react/ui/popup";
import { Form, SimpleItem } from "devextreme-react/ui/form";
import { ScrollView } from "devextreme-react/ui/scroll-view";
import CollapseSection from "../../../../../components/CollaspeSection";
import SignatureField from "../../../../../components/SignatureField/SignatureField";
import ToolBarItems from "../../../../../components/ToolBarItems";
import { Template } from "devextreme-react/core/template.js";
import UserSelectBoxItem from "../../../../../components/SelectBoxItems/UserSelectBoxItem.js";
import { useQuery } from "@tanstack/react-query";
import UserApi from "../../../../../api/users/users.js";

const ReleaseItemsPopup = ({ releaseItemsPopup, onComplete = () => {} }) => {
  const form = useRef(null);
  const { currentUser } = useAuth();
  const releasedToSig = useRef(null);
  const releasedBySig = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const totalEvidence = useRef(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [selectionState, setSelectionState] = useState(null);

  const { data: users, isSuccess } = useQuery({
    queryKey: [
      "users:list",
      { includeObservers: false, includeInactive: false },
    ],
    queryFn: () =>
      UserApi.getUsers({ includeObservers: false, includeInactive: false }),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const handleSubmit = async (event) => {
    if (form.current.instance.validate().isValid) {
      const formData = { ...form.current.instance.option().formData };

      const ipAddress = await getIpAddress();

      const signature = {};

      signature.custody_to_sig = releasedToSig.current.isEmpty()
        ? null
        : {
            signature: releasedToSig.current.getSignature(),
            signer: formData.released_to,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      signature.custody_from_sig = releasedBySig.current.isEmpty()
        ? null
        : {
            signature: releasedBySig.current.getSignature(),
            signer: formData.released_by.full_name,
            timestamp: moment().toISOString(),
            userAgent: window.navigator.userAgent,
            ip_address: ipAddress,
            monolith_user: {
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              full_name: currentUser.full_name,
              email: currentUser.email,
              title: currentUser.title,
              user_id: currentUser.user_id,
            },
          };

      formData.signature = JSON.stringify(signature);

      releaseItemsPopup.current.instance.hide();

      // Send Intake Record request to API
      createBulkCocRecords({
        formData: {
          custody_to: formData.released_to,
          custody_from: formData.released_by.full_name,
          timestamp: db_timestamp(formData.timestamp, true),
          entered_by: currentUser.full_name,
          entered_by_id: currentUser.user_id,
          coc_type: "Release",
          signature: formData.signature,
          reason: formData.reason || "Evidence Released",
          new_location_id: 1,
        },
        options: { ...selectionState.options },
      }).then((result) => {
        if (result.success) {
          enqueueSnackbar(
            `Evidence Items Released to ${formData.released_to}`,
            {
              variant: "success",
            }
          );
          onComplete();
        } else {
          enqueueSnackbar("Error releasing items: " + result.message, {
            variant: "error",
          });
        }
      });
    }
  };

  const handleFormReset = () => {
    if (form.current) form.current.instance.resetValues();
    if (releasedBySig.current) releasedBySig.current.clear();
    if (releasedToSig.current) releasedToSig.current.clear();
  };

  useEffect(() => {
    if (users && form.current) {
      form.current.instance.getEditor("released_by").option(
        "dataSource",
        new DataSource({
          store: users,
          key: "user_id",
        })
      );
    }
  }, [users, isSuccess]);

  useEffect(() => {
    if (releaseItemsPopup.current) {
      releaseItemsPopup.current.setSelectionState = setSelectionState;
    }
  }, [releaseItemsPopup.current]);

  return (
    <Popup
      ref={releaseItemsPopup}
      defaultWidth={500}
      defaultHeight={375}
      resizeEnabled={true}
      showCloseButton={true}
      showTitle={true}
      title="Release Evidence Items"
      deferRendering={false}
      fullScreen={true}
      onShowing={(e) => {
        totalEvidence.current.innerText = `${
          selectionState?.total || 0
        } Evidence Items`;
        form.current.instance
          .getEditor("timestamp")
          .option("value", new Date());
      }}
      onHidden={handleFormReset}
    >
      <ScrollView showScrollbar="always" useNative={false}>
        <div
          style={{
            margin: "auto",
            width: matches ? "90vw" : "70vw",
            maxWidth: 500,
            userSelect: "none",
          }}
        >
          <CollapseSection title={"Release Details"} allowCollapse={false}>
            <div style={{ marginBottom: 15 }}>
              <div
                ref={totalEvidence}
                style={{ color: theme.palette.primary.main }}
              ></div>
            </div>
            <div style={{ marginBottom: 15 }}>
              Releasing this evidence will also release its child evidence items
              if they are currently located with the parent. Any child items
              located outside this parent will not be released.
            </div>
            {useMemo(() => {
              return (
                <Form ref={form} colCount={1}>
                  <SimpleItem
                    dataField="released_by"
                    label={{ text: "Released By" }}
                    editorType="dxSelectBox"
                    isRequired={true}
                    editorOptions={{
                      dataSource: new DataSource({
                        store: users || [],
                        key: "user_id",
                      }),
                      searchEnabled: true,
                      displayExpr: "full_name",
                      dropDownOptions: {
                        maxHeight: 250,
                      },
                      itemTemplate: "released_by",
                    }}
                  />
                  <Template
                    name="released_by"
                    render={(data) => <UserSelectBoxItem data={data} />}
                  />
                  <SimpleItem
                    dataField="released_to"
                    label={{ text: "Released To" }}
                    isRequired={true}
                    editorOptions={{
                      placeholder: "Enter name...",
                    }}
                  />
                  <SimpleItem
                    dataField="timestamp"
                    label={{ text: "Timestamp" }}
                    editorType="dxDateBox"
                    isRequired={true}
                    editorOptions={{
                      type: "datetime",
                      useMaskBehavior: true,
                      displayFormat: getDateFormat(false, true),
                    }}
                    validationRules={[
                      {
                        type: "custom",
                        message: "Invalid Date",
                        validationCallback: validateTimestamp,
                      },
                    ]}
                  />
                  <SimpleItem
                    dataField={"reason"}
                    label={{ text: "Release Notes" }}
                    editorType="dxTextArea"
                  />
                  <SimpleItem
                    dataField="custody_from_sig"
                    label={{ text: "Released By Signature" }}
                  >
                    <SignatureField
                      sigFieldRef={releasedBySig}
                      title="Released By Signature"
                    />
                  </SimpleItem>
                  <SimpleItem
                    dataField="custody_to_sig"
                    label={{ text: "Released To Signature" }}
                  >
                    <SignatureField
                      sigFieldRef={releasedToSig}
                      title="Released To Signature"
                    />
                  </SimpleItem>
                </Form>
              );
            }, [])}
          </CollapseSection>
          <div
            style={{
              marginTop: 30,
            }}
          >
            <ToolBarItems
              submitText="Release Evidence"
              onSubmit={handleSubmit}
              onCancel={() => {
                releaseItemsPopup.current.instance.hide();
              }}
            />
          </div>
        </div>
      </ScrollView>
    </Popup>
  );
};

export default ReleaseItemsPopup;
