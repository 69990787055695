import {
  DateInput,
  SelectBox,
  TagBox,
  TextInput,
} from "@monolith-forensics/monolith-ui";
import { safeParseJSON } from "../../../utils/File-parsers/safeParseJSON.js";
import { getDateFormat } from "../../../utils/date-format.js";

const CustomFieldRender = ({ field, key, inputProps, onChange }) => {
  switch (field.editor_type) {
    case "textBox":
      return (
        <TextInput
          size="sm"
          variant="outlined"
          label={field.field_name}
          placeholder={field.description}
          required={field.is_required === 1}
          description={field.description}
          key={key}
          {...inputProps}
        />
      );
    case "dropDown":
      return (
        <SelectBox
          size="sm"
          variant="outlined"
          label={field.field_name}
          placeholder={field.description}
          description={field.description}
          required={field.is_required === 1}
          clearable
          data={(safeParseJSON(field.options) || []).map((opt) => ({
            label: opt,
            value: opt,
          }))}
          key={key}
          {...inputProps}
        />
      );
    case "dateBox":
      return (
        <DateInput
          label={field.field_name}
          placeholder={field.description}
          description={field.description}
          required={field.is_required === 1}
          variant="outlined"
          arrow
          size="sm"
          clearable
          enableCalendar={true}
          format={getDateFormat(true, false)}
          key={key}
          {...inputProps}
        />
      );
    case "tagBox":
      return (
        <TagBox
          size="sm"
          variant="outlined"
          label={field.field_name}
          placeholder={"Select tags"}
          description={field.description}
          required={field.is_required === 1}
          searchable={false}
          clearable
          allowCustomValue={false}
          data={(safeParseJSON(field.options) || []).map((opt) => ({
            label: opt,
            value: opt,
          }))}
          key={key}
          {...inputProps}
        />
      );
    default:
      return null;
  }
};

export default CustomFieldRender;
