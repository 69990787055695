import { useEffect, useRef, useState } from "react";
import { Popup } from "devextreme-react/ui/popup";

import { Button } from "@mui/material";
import {
  getFprSettings,
  storeFprSettings,
} from "../../../../api/integrations/fpr";
import Loader from "../../../../components/Loader";
import ToolBarItems from "../../../../components/ToolBarItems";
import { FPRSettings } from "./FPRSettings";
import CollapseSection from "../../../../components/CollaspeSection";
import { useQueryClient, useQuery } from "@tanstack/react-query";

export const Types = {
  CPU: "CPUs",
  ETECH: "ETech",
  LOOSE_DRIVES: "Loose Drives",
  STORAGE_MEDIA: "Storage Media",
  CLOUD: "Cloud",
  OTHER: "Other",
};

export const FPR_OBJECT = {
  [Types.CPU]: [],
  [Types.ETECH]: [],
  [Types.LOOSE_DRIVES]: [],
  [Types.STORAGE_MEDIA]: [],
  [Types.CLOUD]: [],
  [Types.OTHER]: [],
};

const FprIntegration = () => {
  const [updating, setUpdating] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const fprEnabledPopup = useRef(null);

  const queryClient = useQueryClient();
  const { isLoading, data: fprSettings } = useQuery({
    queryKey: ["fpr:settings"],
    queryFn: getFprSettings,
    initialData: [
      {
        settings: { enabled: false },
        options: {},
      },
    ],
    refetchOnWindowFocus: false,
    select: (data) => {
      let parsedData = {};
      if (data.length > 0) parsedData = { ...data[0] };
      if (parsedData.settings)
        parsedData.settings = JSON.parse(parsedData.settings);
      if (parsedData.options)
        parsedData.options = JSON.parse(parsedData.options);
      return parsedData;
    },
  });

  if (isLoading) return null;

  const fprEnabled = fprSettings?.settings?.enabled || false;

  const openFprEnabledPopup = () => fprEnabledPopup.current.instance.show();

  const closeFprEnabledPopup = () => fprEnabledPopup.current.instance.hide();

  const updateFprEnabled = async () => {
    setUpdating(true);
    storeFprSettings({ enabled: true })
      .then(() => {
        closeFprEnabledPopup();
      })
      .finally(() => {
        queryClient.invalidateQueries({ queryKey: ["fpr:settings"] });
        setUpdating(false);
      });
  };

  const openSettings = () => setShowSettings(true);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Popup
          ref={fprEnabledPopup}
          title="Enable FPR Integration"
          showTitle={true}
          showCloseButton={true}
          defaultHeight={175}
          defaultWidth={400}
        >
          <p>Are you sure you want to enable FPR Integration?</p>
          <div style={{ marginTop: 20 }}>
            {updating && <Loader />}
            {!updating && (
              <ToolBarItems
                submitText="Enable FPR"
                onSubmit={updateFprEnabled}
                onCancel={closeFprEnabledPopup}
              />
            )}
          </div>
        </Popup>
      </div>

      {!showSettings && (
        <CollapseSection title="Forensic Partner Reports">
          <div
            style={{
              textAlign: "justify",
              padding: "0px 20px",
              marginBottom: 20,
            }}
          >
            <p
              style={{
                textDecoration: "underline",
              }}
            >
              Description:
            </p>
            <p>
              This integration adds a new report option to the metrics reports
              you can create. The purpose of this integration is to help
              organizations that report metrics to the United States Secret
              Service as a Forensic Partner. You should not enable this
              integration unless you report metrics to the USSS.
            </p>
            <p>
              Once enabled, Monolith will create custom evidence fields called
              "FPR - Evidence Details" and "FPR - Status" that users can
              populate with FPR specific evidence characteristics. You will also
              need to map your Monolith evidence types and case types to the
              types used by the FPR report.
            </p>
            <p
              style={{
                textDecoration: "underline",
              }}
            >
              Creating Reports:
            </p>
            <p>
              When a Monolith FPR report is created, you can review the metrics
              and correct any evidence or case details. You can then export the
              metrics to an Excel file. This Excel report is created in the same
              format as the FPR Excel template provided by the USSS.
            </p>
            <p>
              Once a report has been created and submitted to USSS - you can
              mark the submitted items as "Reported" so that you can avoid
              duplicate evidence submissions.
            </p>
            <p>
              <p
                style={{
                  textDecoration: "underline",
                }}
              >
                User Configuration:
              </p>
              <ul>
                <li>Map Monolith evidence types to FPR evidence types.</li>
                <li>Map monolith case types to FPR case types.</li>
                <li>
                  A case contact of type "Suspect" will be used to complete the
                  FPR subject fields.
                </li>
              </ul>
              <p
                style={{
                  textDecoration: "underline",
                }}
              >
                Actions:
              </p>
              <ul>
                <li>
                  A custom evidence field called "FPR - Evidence Details" will
                  be created.
                  <ul>
                    <li>
                      Options: Encrypted, Decrypted, Hashcat Used to Decrypt,
                      Passware Used to Decrypt, Password Locked, Password
                      Unlocked, Locally Unlocked, USSS Unlocked, Vendor
                      Unlocked.
                    </li>
                  </ul>
                </li>
                <li>
                  A custom evidence field called "FPR - Status" will be created.
                  <ul>
                    <li>Options: Reported, Not Reported</li>
                  </ul>
                </li>
                <li>
                  A new metrics report called "Forensic Partner Reporting - FPR"
                  will be available.
                </li>
              </ul>
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              size="small"
              onClick={fprEnabled ? openSettings : openFprEnabledPopup}
            >
              {fprEnabled ? "Configure" : "Enable"}
            </Button>
          </div>
        </CollapseSection>
      )}

      {showSettings && <FPRSettings fprSettings={fprSettings} />}
    </div>
  );
};

export default FprIntegration;
